import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label, Modal, ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import "react-datepicker/dist/react-datepicker.css"

import { MDBDataTable } from "mdbreact"
import "assets/scss/datatables.scss"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment-timezone"

import {
  buscarNCMs, doCreateNCM, doEditNCM, doRemoveNCM
} from "../../store/auth/profile/actions"
import { isArray } from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import { getDashboardData } from "../../store/auth/login/actions"
import { AvForm } from "availity-reactstrap-validation"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const TabelaNCM = props => {
  const [ncm, setNCM] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState("")
  const [present, setPresent] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [modal, setModal] = useState(false)
  const [itemRemove, setItemRemove] = useState()

  const usePermissionRemove = usePermission('tabelaNCM_excluir')
  const usePermissionEdit = usePermission('tabelaNCM_editar')

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }

    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const isInforangra = props.user?.usuario_info

  const doUpdate = () => {
    props.buscarNCMs({ ncm, description, category, present, removed })
  }

  const goToNCM = item => {
    props.history.push("/ncm")
    props.doEditNCM(item)
  }

  const showConfirmDeleteNCM = item => {
    setItemRemove(item)
    setModal(true)
  }

  const deleteNCM = () => {
    props.doRemoveNCM(itemRemove)
    setModal(false)
    doUpdate()
  }

  const [tableData, setTableData] = useState({
    columns: [
      { label: "Registro", field: "Registro", sort: "asc", width: 150 },
      { label: "NCM", field: "NCM", sort: "asc", width: 150 },
      { label: "Descrição", field: "Descrição", sort: "asc", width: 150 },
      { label: "Categoria", field: "Categoria", sort: "asc", width: 150 },
      {
        label: "Início de vigência",
        field: "Início de vigência",
        sort: "asc",
        width: 150
      },
      {
        label: "Fim de vigência",
        field: "Fim de vigência",
        sort: "asc",
        width: 150
      },
      {
        label: "Unidade Trib.",
        field: "Unidade Trib.",
        sort: "asc",
        width: 150
      },
      {
        label: "Descrição Unidade",
        field: "Descrição Unidade",
        sort: "asc",
        width: 150
      },
      { label: "Ações", field: "Ações", sort: "asc", width: 100 }
    ],
    rows: []
  })

  useEffect(() => {
    doUpdate()
  }, [])

  useEffect(() => {
    if (isArray(props.ncms)) {

      setTableData({
        columns: [
          { label: "Registro", field: "Registro", sort: "asc", width: 150 },
          { label: "NCM", field: "NCM", sort: "asc", width: 150 },
          { label: "Descrição", field: "Descrição", sort: "asc", width: 150 },
          { label: "Categoria", field: "Categoria", sort: "asc", width: 150 },
          {
            label: "Início de vigência",
            field: "Início de vigência",
            sort: "asc",
            width: 150
          },
          {
            label: "Fim de vigência",
            field: "Fim de vigência",
            sort: "asc",
            width: 150
          },
          {
            label: "Unidade Trib.",
            field: "Unidade Trib.",
            sort: "asc",
            width: 150
          },
          {
            label: "Descrição Unidade",
            field: "Descrição Unidade",
            sort: "asc",
            width: 150
          },
          { label: "Ações", field: "Ações", sort: "asc", width: 150 }
        ],
        rows: props.ncms.map(item => ({
          Registro: item.id,
          NCM: item.ncm,
          Descrição: item.descricao,
          Categoria: item.categoria,
          "Início de vigência": describeDateTime(item.vigencia_inicio),
          "Fim de vigência": describeDateTime(item.vigencia_final),
          "Unidade Trib.": item.uTrib,
          "Descrição Unidade": item.uTribDescricao,
          Ações: (
              <div className="d-flex justify-content-start">
                { isInforangra && usePermissionEdit && <Link to="#" className="me-3 text-primary">
                  <i
                    className="mdi mdi-pencil font-size-18 me-3"
                    id="edittooltip"
                    onClick={ e => goToNCM(item) }
                    title="Editar"
                  />
                </Link> }
                { isInforangra && usePermissionRemove && <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                     onClick={ () => showConfirmDeleteNCM(item) } title="Remover"/>
                </Link> }
              </div>
          )
        }))
      })
    }

    return () => setTableData({ columns: [], rows: []});
  }, [props.ncms])

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */ }
          <Breadcrumbs title="Tabelas" breadcrumbItem="Tabela NCM" />
            <div className="button-items my-3">
            <Link to="/ncm">
              <Button
                type="button"
                color="success"
                className="btn-rounded waves-effect waves-light"
                onClick={ e => props.doCreateNCM() }
                disabled={ !isInforangra && !usePermission('tabelaNCM_inserir') }
              >
                <i className="bx bx-plus" /> Cadastrar
              </Button>
            </Link>
          </div>

          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-3">Tabela NCM</h4>

                    <Form>
                      <Row>
                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <Label>NCM</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={ ncm }
                              onChange={ e => setNCM(e.target.value) }
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Descrição</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={ description }
                              onChange={ e => setDescription(e.target.value) }
                            />
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6">
                          <FormGroup className="mt-3 mb-0">
                            <Label>Categoria</Label>
                            <input
                              type="text"
                              className="form-control"
                              value={ category }
                              onChange={ e => setCategory(e.target.value) }
                            />
                          </FormGroup>
                        </div>

                        <div className="col-auto col-sm d-flex align-items-center">
                          <FormGroup className="mt-3 mb-0">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkbox-present"
                                checked={ present }
                                onChange={ e => setPresent(e.target.checked) }
                                disabled={ removed }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkbox-present"
                              >
                                Vigente
                              </label>
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-auto col-sm d-flex align-items-center">
                          <FormGroup className="mt-3 mb-0">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkbox-removed"
                                checked={ removed }
                                onChange={ e => setRemoved(e.target.checked) }
                                disabled={ present }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkbox-removed"
                              >
                                Excluídos
                              </label>
                            </div>
                          </FormGroup>
                        </div>

                        <div className="col-xl col-sm-6 align-self-start mt-4 pt-1">
                          <div className="mt-3">
                            <Button
                              type="button"
                              color="primary"
                              className="w-md"
                              onClick={ e => doUpdate() }
                              disabled={ !usePermission('tabelaNCM_listar') }>
                              Buscar
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                <Card>
                  { props.ncmsLoading ? (
                    <div className="loader-container position-absolute">
                      <Loader
                        type="ThreeDots"
                        color="#556EE6"
                        height={ 80 }
                        width={ 80 }
                      />
                    </div>
                  ) : (
                    <CardBody>
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={ tableData }
                        entriesLabel={ "Mostrar Registros" }
                        infoLabel={ ["Exibindo", "a", "de", "registros"] }
                        noRecordsFoundLabel={ "Nenhum registro encontrado" }
                        paginationLabel={ ["Anterior", "Próximo"] }
                        searchLabel={ "Pesquisar" }
                      />
                    </CardBody>
                  ) }
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={ modal }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              deleteNCM()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover esse registro?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModal(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

TabelaNCM.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func
}

const mapStatetoProps = state => {
  const { ncmsLoading, ncms } = state.Profile
  const { user } = state.Login
  return {
    ncmsLoading,
    ncms,
    user
  }
}

export default connect(mapStatetoProps, {
  buscarNCMs,
  getDashboardData,
  doCreateNCM,
  doEditNCM,
  doRemoveNCM
})(withRouter(TabelaNCM))
