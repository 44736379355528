import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row, UncontrolledTooltip } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { alterarTemplateEmail, buscarTemplatesEmail } from "../../store/auth/profile/actions"
import moment from "moment"
import Loader from "react-loader-spinner"
import TemplatesEmailModal from "./templates-email-modal"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

const TemplatesEmailLista = props => {
  const { templatesEmail } = props
  const [modal, setModal] = useState(false)
  const [templateEmail, setTemplateEmail] = useState()

  const usePermissionEdit = usePermission('templatesEmail_editar')

  useEffect(() => {
    props.buscarTemplatesEmail()
  }, [])

  useEffect(() => {
    if(!props.loading) {
      props.buscarTemplatesEmail()
    }
  }, [props.loading])

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const describeTipo = tipo => {
    if (!tipo) {
      return "N/D"
    }

    switch (tipo) {
      case "CONFIRMACAO_CONTA":
        return "Confirmação de conta"
      case "RECUPERACAO_SENHA":
        return "Recuperação de senha"
      case "VINCULO_ACEITO":
        return "Vinculo aceito"
      case "VINCULO_REJEITADO":
        return "Vinculo rejeitado"
      case "SUGESTAO_CRIADA":
        return "Sugestão criada"
      case "SUGESTAO_ALTERADA":
        return "Sugestão alterada"
      case "VINCULO_CANCELADO":
        return "Vinculo cancelado"
      default:
        return "Desconhecido"
    }
  }

  const toggleModal = item => {
    setTemplateEmail(item)
    setModal(!modal)
  }

  const saveTemplate = templateEmail => {
    props.alterarTemplateEmail(templateEmail)
    setModal(!modal)
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return props.templatesEmailLoading || props.loading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Templates de e-mails"
            breadcrumbItem="Lista de Templates de e-mails"
          />
          {/* Render Breadcrumbs */ }
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Templates de e-mails</CardTitle>

                  <div className="table-responsive">
                    <table
                      className="dataTable table table-bordered table-striped table-nowrap table-centered mb-0 table-hover">
                      <thead>
                      <tr>
                        <th>Tipo</th>
                        <th>Título</th>
                        <th>Data de alteração</th>
                        <th>Ações</th>
                      </tr>
                      </thead>

                      <tbody>
                      { (isEmpty(templatesEmail) && (
                        <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              Não há nenhum template de e-mail cadastrado!
                            </h5>
                          </td>
                        </tr>
                      )) ||
                      map(templatesEmail, (item, i) => (
                        <tr key={ i }>
                          <td className="text-truncate text-dark">
                            { describeTipo(item.tipo) }
                          </td>
                          <td className="text-truncate text-dark">
                            { item.titulo }
                          </td>
                          <td className="text-truncate text-dark">
                            { describeDateTime(item.updated_at) }
                          </td>
                          <td>
                            { usePermissionEdit &&
                              <Link to="#" className="me-3 text-primary">
                                <i
                                  className="mdi mdi-pencil font-size-18 me-3"
                                  id="edittooltip"
                                  onClick={ e => toggleModal(item) }
                                  title="Editar"
                                />
                              </Link>
                            }
                          </td>
                        </tr>
                      )) }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <TemplatesEmailModal
        templateEmail={ templateEmail }
        isOpen={ modal }
        toggle={ toggleModal }
        saveTemplate={ saveTemplate }
      />
    </React.Fragment>
  )
}

TemplatesEmailLista.propTypes = {
  templatesEmail: PropTypes.array,
  templatesEmailLoading: PropTypes.bool,
  buscarTemplatesEmail: PropTypes.func,
  alterarTemplateEmail: PropTypes.func
}

const mapStatetoProps = state => {
  const { templatesEmail, templatesEmailLoading, templateEmail, loading } = state.Profile
  const { user } = state.Login
  return {
    templatesEmail,
    templatesEmailLoading,
    user,
    templateEmail,
    loading
  }
}

export default connect(mapStatetoProps, {
  buscarTemplatesEmail,
  alterarTemplateEmail
})(withRouter(TemplatesEmailLista))
