import PropTypes from "prop-types"
import React from "react"
import {FormGroup, Label} from "reactstrap"
import Select from "react-select"
import {MultiSelect} from "react-multi-select-component"

const FormFilterCompany = props => {
    const { attribute, placeholder, description, value, setValue, options, multiple } = props

    const ArrowRenderer = ({ expanded }) =><span className="arrow"></span>
    const ClearRenderer = () =><span className="clear"></span>

    return (
        <React.Fragment>
            <FormGroup className="mt-3 mb-0">
                <Label htmlFor={ (attribute || 'empresa') + "-select" }>
                    { description || ( 'Empresa' + (multiple ? '(s)' : '')) }
                </Label>
                { multiple && <MultiSelect
                    id={ (attribute || 'empresa') + "-multi-multi-select" }
                    name={ (attribute || 'empresa') + "-multi-multi-select" }
                    options={options}
                    value={value}
                    onChange={ value => { setValue(value) } }
                    labelledBy="Selecione"
                    disableSearch={true}
                    overrideStrings={{
                        "allItemsAreSelected": "Todos selecionados.",
                        "clearSearch": "Limpar",
                        "clearSelected": "Deselecionar todos",
                        "noOptions": "Não há opções",
                        "search": "Pesquisar",
                        "selectAll": "Selecionar todos",
                        "selectAllFiltered": "Selecionar todos",
                        "selectSomeItems": "Selecione",
                        "create": "Criar",}
                    }
                    ClearIcon={<ClearRenderer />}
                    ClearSelectedIcon={<ClearRenderer />}
                    ArrowRenderer={ ArrowRenderer }
                /> }
                { !multiple &&
                    <Select
                        id={ (attribute || 'empresa') + "-select" }
                        name={ (attribute || 'empresa') + "-select" }
                        className="basic-single"
                        options={ options }
                        placeholder={ placeholder || "Digite a empresa..." }
                        isClearable={true}
                        isSearchable={true}
                        noOptionsMessage={() => "Nenhuma empresa encontrada"}
                        onChange={e => {
                            setValue(e.target.value)
                        }}
                        value={ value ? options.find(e => e.value === value) : undefined}
                    />
                }
            </FormGroup>
        </React.Fragment>
    )
}

FormFilterCompany.propTypes = {
    attribute: PropTypes.string,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    value: PropTypes.any,
    setValue: PropTypes.func,
    options: PropTypes.any
}

export default FormFilterCompany
