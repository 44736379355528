import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { connect } from "react-redux"
import { buscarNCMCategorias, ncm } from "store/auth/profile/actions"
import PropTypes from "prop-types"
import Select from "react-select"
import DatePicker from "react-datepicker"
import Loader from "react-loader-spinner"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";

const NCM = (props) => {

  const [ncmCategoriasArr, setNcmCategoriasArr] = useState()
  const [ncm, setNcm] = useState()
  const [descricao, setDescricao] = useState()
  const [vigenciaInicio, setVigenciaInicio] = useState(new Date())
  const [vigenciaFinal, setVigenciaFinal] = useState(new Date())
  const [uTrib, setUTrib] = useState()
  const [uTribDescricao, setUTribDescricao] = useState()
  const [IPI, setIPI] = useState()
  const [usuarioId, setUsuarioId] = useState()
  const [gtinProducao, setGtinProducao] = useState()
  const [gtinHomologacao, setGtinHomologacao] = useState()
  const [observacao, setObservacao] = useState(EditorState.createEmpty())
  const [dePara, setDePara] = useState()
  const [categoria, setCategoria] = useState()

  const handleValidSubmit = (e, v) => {
    props.ncm({
      id: props.activeNcm ? props.activeNcm.id : null,
      ncm,
      descricao,
      vigencia_inicio: vigenciaInicio,
      vigencia_final: vigenciaFinal ? vigenciaFinal : null,
      uTrib,
      uTribDescricao,
      IPI: IPI ? IPI.replaceAll(',', '.') : IPI,
      usuario_id: usuarioId,
      gtin_producao: gtinProducao ? gtinProducao : null,
      gtin_homologacao: gtinHomologacao ? gtinHomologacao : null,
      observacao: JSON.stringify(convertToRaw(observacao.getCurrentContent())),
      de_para: dePara,
      categoria
    }, props.history)
  }

  const setIPIValue = (value) => {
    value = value.replace(/[^0-9]/g,'');
    if(value.length <= 10) {
      while(value.length < 3) {
        value = value.concat(0)
      }

      value = value.substr(0, value.length - 2) + ',' + value.substr(value.length - 2);
      setIPI(value)
    }
  }

  useEffect(() => {
    setNcmCategoriasArr(Array.isArray(props.ncmCategorias) ? props.ncmCategorias.map( ncm => ({ label: ncm, value: ncm })): [])
    return () => { setNcmCategoriasArr([])}
  }, [props.ncmCategorias])

  useEffect(() => {
    props.buscarNCMCategorias();
  }, [])

  useEffect(() => {
    if (props.activeNcm) {
      setNcm(props.activeNcm.ncm);
      setDescricao(props.activeNcm.descricao);
      setVigenciaInicio(props.activeNcm.vigencia_inicio ? new Date(props.activeNcm.vigencia_inicio) : "");
      setVigenciaFinal(props.activeNcm.vigencia_final ? new Date(props.activeNcm.vigencia_final) : "");
      setUTrib(props.activeNcm.uTrib);
      setUTribDescricao(props.activeNcm.uTribDescricao);
      setIPI(props.activeNcm.IPI ? props.activeNcm.IPI.replaceAll('.', ',') : props.activeNcm.IPI);
      setUsuarioId(props.activeNcm.usuario_id);
      setGtinProducao(props.activeNcm.gtin_producao ? new Date(props.activeNcm.gtin_producao) : "");
      setGtinHomologacao(props.activeNcm.gtin_homologacao ? new Date(props.activeNcm.gtin_homologacao) : "");
      setObservacao(
        props.activeNcm.observacao ?
          EditorState.createWithContent(convertFromRaw(JSON.parse(props.activeNcm.observacao))) :
          EditorState.createEmpty()
      );
      setDePara(props.activeNcm.de_para);
      setCategoria(props.activeNcm.categoria);
    } else {
      setNcm("");
      setDescricao("");
      setVigenciaInicio(new Date());
      setVigenciaFinal(undefined);
      setUTrib("");
      setUTribDescricao("");
      setIPI("0,00");
      setUsuarioId("");
      setGtinProducao(undefined);
      setGtinHomologacao(undefined);
      setObservacao(prevState => EditorState.createEmpty());
      setDePara("");
      setCategoria("");
    }

    return () => {
      setNcm("");
      setDescricao("");
      setVigenciaInicio(undefined);
      setVigenciaFinal(undefined);
      setUTrib("");
      setUTribDescricao("");
      setIPI("0,00");
      setUsuarioId("");
      setGtinProducao(undefined);
      setGtinHomologacao(undefined);
      setObservacao(prevState => EditorState.createEmpty());
      setDePara("");
      setCategoria("");
    }
  }, [props.activeNcm])

  return props.ncmCategoriasLoading ||
    !props.activeNcm ||
    props.ncmLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tabela de NCMs" breadcrumbItem={ (props.activeNcm === "edit" && "Editar NCM") || "Criar NCM" }/>

          <StickerToolbarButtons>
            { usePermission('tabelaNCMEdit_editar') && props.activeNcm &&
                <Button type="submit" color="primary" onClick={ e => handleValidSubmit() }
                        disabled={ !ncm || !categoria || !descricao
                            || !vigenciaInicio || !uTrib }>
                  Salvar NCM
                </Button>
           }
            { usePermission('tabelaNCMEdit_inserir') && !props.activeNcm &&
                <Button type="submit" color="primary" onClick={ e => handleValidSubmit() }
                        disabled={ !ncm || !categoria || !descricao
                            || !vigenciaInicio || !uTrib }>
                </Button>
            }
          </StickerToolbarButtons>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">NCM - Nomenclatura Comum do Mercosul</CardTitle>
                  <p className="card-title-desc">
                    A Nomenclatura Comum do Mercosul (NCM) é uma Nomenclatura regional para categorização de mercadorias adotada pelo Brasil, Argentina, Paraguai e Uruguai desde 1995, sendo utilizada em todas as operações de comércio exterior dos países do Mercosul.
                    Mais informações clique <a href="https://portalunico.siscomex.gov.br/classif/#/nomenclatura/avancada?perfil=publico" target="_blank" rel="noopener noreferrer">aqui</a>.
                  </p>

                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="ncm" className="col-form-label col-lg-2">
                          NCM
                        </Label>
                        <Col lg="10">
                          <Input
                            id="ncm"
                            name="ncm"
                            type="text"
                            className="form-control"
                            placeholder="Digite o título da ncm..."
                            required
                            maxLength={ 10 }
                            value={ ncm }
                            onChange={ e => setNcm(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label htmlFor="categoria" className="col-form-label col-lg-2">
                          Categoria
                        </label>
                        <div className="col-lg-10">
                          <Select
                            id="categoria"
                            name="categoria"
                            className="basic-single"
                            options={ ncmCategoriasArr }
                            placeholder="Digite a Categoria..."
                            isClearable={ true }
                            isSearchable={ true }
                            noOptionsMessage={ () => "Nenhuma categoria encontrada" }
                            onChange={ e => setCategoria(e?.value) }
                            value={ categoria ? ncmCategoriasArr.find(e => e.value === categoria) : undefined }
                            isDisabled={ !usePermission('tabelaNCMEdit_editar') }
                            required
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="descricao" className="col-form-label col-lg-2">
                          Descrição
                        </Label>
                        <Col lg="10">
                          <Input
                            id="descricao"
                            name="descricao"
                            type="text"
                            className="form-control"
                            placeholder="Digite a descrição da ncm..."
                            required
                            value={ descricao }
                            maxLength={ 1100 }
                            onChange={ e => setDescricao(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="vigenciaInicio" className="col-form-label col-lg-2">
                          Início da vigência
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="vigenciaInicio"
                            selected={ vigenciaInicio }
                            onChange={ date => {
                              setVigenciaInicio(date)
                              setVigenciaFinal(null)
                            } }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            required
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="vigenciaFinal" className="col-form-label col-lg-2">
                          Fim da vigência
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="vigenciaFinal"
                            selected={ vigenciaFinal }
                            onChange={ date => setVigenciaFinal(date) }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            minDate={ vigenciaInicio }
                            dateFormat="dd/MM/yyyy"
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="uTrib" className="col-form-label col-lg-2">
                          Unidade Tributável
                        </Label>
                        <Col lg="10">
                          <Input
                            id="uTrib"
                            name="uTrib"
                            type="text"
                            className="form-control"
                            placeholder="Digite a unidade..."
                            required
                            value={ uTrib }
                            maxLength={ 5 }
                            onChange={ e => setUTrib(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="uTribDescricao" className="col-form-label col-lg-2">
                          Descrição da Unidade
                        </Label>
                        <Col lg="10">
                          <Input
                            id="uTribDescricao"
                            name="uTribDescricao"
                            type="text"
                            className="form-control"
                            placeholder="Digite a descrição da unidade..."
                            value={ uTribDescricao }
                            maxLength={ 50 }
                            onChange={ e => setUTribDescricao(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="ipi" className="col-form-label col-lg-2">
                          IPI
                        </Label>
                        <Col lg="10">
                          <Input
                            id="ipi"
                            name="ipi"
                            type="text"
                            className="form-control"
                            value={ IPI }
                            onChange={ e => setIPIValue(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="gtinProducao" className="col-form-label col-lg-2">
                          GTIN Produção
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="gtinProducao"
                            selected={ gtinProducao }
                            onChange={ date => setGtinProducao(date) }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="gtinHomologacao" className="col-form-label col-lg-2">
                          GTIN Homologação
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            id="gtinHomologacao"
                            selected={ gtinHomologacao }
                            onChange={ date => setGtinHomologacao(date) }
                            className="form-control"
                            placeholderText="Selecione uma data"
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="observacao" className="col-form-label col-lg-2">
                          Observação
                        </Label>
                        <Col lg="10">
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={ observacao }
                            onEditorStateChange={ e => setObservacao(e) }
                            placeholder="Insira observações sobre o NCM..."
                            readOnly={ !usePermission('tabelaNCMEdit_editar') }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label htmlFor="dePara" className="col-form-label col-lg-2">
                          Mudou para
                        </Label>
                        <Col lg="10">
                          <Input
                            id="dePara"
                            name="dePara"
                            type="text"
                            className="form-control"
                            placeholder="Informe a mudança..."
                            value={ dePara }
                            maxLength={ 10 }
                            onChange={ e => setDePara(e.target.value) }
                            disabled={ !usePermission('tabelaNCMEdit_editar') }/>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

NCM.propTypes = {
  buscarNCMCategorias: PropTypes.func,
  ncm: PropTypes.func
}

const mapStatetoProps = state => {
  const { ncmCategorias, activeNcm, ncmLoading } = state.Profile
  const { user } = state.Login
  return { ncmCategorias, activeNcm, user, ncmLoading }
}

export default connect(mapStatetoProps, {
  buscarNCMCategorias,
  ncm
})(NCM)
