import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import 'react-phone-number-input/style.css'

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
    buscarGrupoCardapio,
    buscarGruposCardapioItemPorGrupo,
    buscarGruposCardapioObservacaoPorGrupo,
    cadastrarGrupoCardapio,
    cadastrarGrupoCardapioMantemCadastro,
    cadastrarGrupoCardapioObservacao,
    editarGrupoCardapio,
    editarGrupoCardapioObservacao,
    removerGrupoCardapio,
    removerGrupoCardapioObservacao
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import toastr from "toastr"
import {apiBuscarConfiguracoesImpressoraPesquisa} from "../../helpers/backend_helper"
import Loader from "react-loader-spinner"
import FormInputSwitch from "../../components/Common/form-input-switch"
import FormInputNumber from "../../components/Common/form-input-number"
import FormInputText from "../../components/Common/form-input-text"
import FormInputAsyncSelect from "../../components/Common/form-input-async-select"
import "toastr/build/toastr.min.css"
import FormSelectCompany from "../../components/Common/form-select-company"
import FormInputDate from "../../components/Common/form-input-date"
import moment from "moment-timezone"
import FormInputColor from "../../components/Common/form-input-color"
import FormInputFile from "../../components/Common/form-input-file"
import GrupoCardapioObservacaoLista from "./grupo-cardapio-observacao-lista"
import GrupoCardapioItemLista from "./grupo-cardapio-item-lista"
import {getCompanies} from "../../helpers/utils"

const brazilMoment = (...args) => moment(...args)
registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarCardapioDigitalGrupo = props => {

    const usePermissionGruposCardapioObservacaoList = usePermission("gruposCardapioObservacao_listar")
    const usePermissionGruposCardapioItemList = usePermission("gruposCardapioItem_listar")
    const usePermissionEdit = usePermission("gruposCardapio_editar")
    const usePermissionInsert = usePermission("gruposCardapio_inserir")
    const usePermissionRemove = usePermission("gruposCardapio_excluir")
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [companies, setCompanies] = useState([{label: "Todas", value: ""}])
    const [clonado, setClonado] = useState(false)
    const [loading, setLoading] = useState(false)

    const [id, setId] = useState()
    const [empresa, setEmpresa] = useState()
    const [codigo, setCodigo] = useState()
    const [iCodigo, setICodigo] = useState(0)
    const [descricao, setDescricao] = useState()
    const [dataAlteracao, setDataAlteracao] = useState() // Alteração do cadastro - AltData
    const [usuarioAlteracao, setUsuarioAlteracao] = useState() // Usuário que alterou - AltFunc
    const [impressora, setImpressora] = useState()
    const [textoTela, setTextoTela] = useState()
    const [corBotao, setCorBotao] = useState()
    const [corFonte, setCorFonte] = useState()
    const [subGrupo, setSubGrupo] = useState('N')
    const [desktopVisivel, setDesktopVisivel] = useState('N')
    const [mobileVisivel, setMobileVisivel] = useState('N')
    const [infinitiFoodVisivel, setInfinitiFoodVisivel] = useState('N')
    const [menuDigitalVisivel, setMenuDigitalVisivel] = useState('N')
    const [imagem, setImagem] = useState()
    const [ordem, setOrdem] = useState()

    useEffect(() => {
        if (props.grupoCardapioAtivo && props.grupoCardapioAtivo.id) {
            props.buscarGrupoCardapio(props.grupoCardapioAtivo)
        } else {
            preencherFormulario(null)

            setId(null)
            setEmpresa(companies[0].value)
        }

        setClonado(false)

    }, [props.grupoCardapioAtivo])

    useEffect(() => {
        if (props.permanecerCadastro === true) {
            setLoading(true)

            setTimeout(() => {
                preencherFormulario(null)

                setId(null)
                setEmpresa(companies[0].value)

                setClonado(false)
                setLoading(false)
            }, 1000)
        }
    }, [props.permanecerCadastro])

    useEffect(() => {
        if (props.grupoCardapio && props.grupoCardapio?.id) {
            preencherFormulario(props.grupoCardapio)

            props.buscarGruposCardapioObservacaoPorGrupo({
                company: props.grupoCardapio?.idEmpresa, codigoGrupoCardapio: props.grupoCardapio?.Codigo
            })

            props.buscarGruposCardapioItemPorGrupo({
                company: props.grupoCardapio?.idEmpresa, codigoGrupoCardapio: props.grupoCardapio?.Codigo
            })

        }
    }, [props.grupoCardapio])

    useEffect(() => {
        if (props.grupoCardapio?.Codigo &&
            ((props.novoGrupoCardapioObservacao && props.novoGrupoCardapioObservacao.id) ||
                (props.grupoCardapioObservacaoRemovido) ||
                (props.grupoCardapioObservacaoEditado))) {
            props.buscarGruposCardapioObservacaoPorGrupo({
                company: props.grupoCardapio?.idEmpresa, codigoGrupoCardapio: props.grupoCardapio?.Codigo
            })
        }
    }, [props.novoGrupoCardapioObservacao, props.grupoCardapioObservacaoEditado, props.grupoCardapioObservacaoRemovido])

    const preencherFormulario = (grupoCardapio) => {
        setId(grupoCardapio?.id)
        setEmpresa(grupoCardapio?.idEmpresa)
        setCodigo(grupoCardapio?.Codigo)
        setICodigo(grupoCardapio?.iCodigo)
        setDescricao(grupoCardapio?.Descricao)
        setImpressora(grupoCardapio?.Impressora ? buildConfiguracaoImpressora(grupoCardapio?.Impressora.idId, grupoCardapio?.Impressora.serie) : undefined)
        setTextoTela(grupoCardapio?.Texto_Tela)
        setCorBotao(grupoCardapio?.Cor_Botao)
        setCorFonte(grupoCardapio?.Cor_Fonte)
        setSubGrupo(grupoCardapio?.SubGrupo ? grupoCardapio?.SubGrupo : 'N')
        setDesktopVisivel(grupoCardapio?.Desktop_Visivel ? grupoCardapio?.Desktop_Visivel : 'N')
        setMobileVisivel(grupoCardapio?.Mobile_Visivel ? grupoCardapio?.Mobile_Visivel : 'N')
        setInfinitiFoodVisivel(grupoCardapio?.Infinitifood_Visivel ? grupoCardapio?.Infinitifood_Visivel : 'N')
        setMenuDigitalVisivel(grupoCardapio?.MenuDigital_Visivel ? grupoCardapio?.MenuDigital_Visivel : 'N')
        setImagem(grupoCardapio?.Imagem)
        setOrdem(grupoCardapio?.Ordem)

        setDataAlteracao(grupoCardapio?.AltData ? brazilMoment(grupoCardapio?.AltData, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setUsuarioAlteracao(`${grupoCardapio?.AltFunc} - ${grupoCardapio?.AltFunc_Nome}`)
    }

    const excluirGrupoCardapio = () => {
        props.removerGrupoCardapio({id}, props.history)
    }

    const clonarGrupoCardapio = () => {
        setId(null)
        setCodigo(null)
        setEmpresa(companies[0].value)
        props.grupoCardapio.id = null
        props.grupoCardapio.Codigo = null
        props.grupoCardapio.iCodigo = null // caso seja clonado não deverá haver o iCodigo

        window.scrollTo(0, 0)
    }

    const gravarGrupoCardapio = (e, cadastrarOutroGrupoCardapio) => {
        let valid = true
        valid = valid && (!!empresa)
        valid = valid && (!!descricao)
        valid = valid && (!!codigo)

        if (!valid) {
            const divs = document.getElementsByName("validate-required")
            for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "block"
            }
            window.scrollTo(0, 0)
            toastr.error('Há campos obrigatórios não preenchidos!', 'Validação de informações')

            return
        }

        salvarGrupoCardapio(cadastrarOutroGrupoCardapio)
    }

    const salvarGrupoCardapio = (cadastrarOutroGrupoCardapio) => {
        const grupoCardapio = props.grupoCardapio ? Object.assign({}, props.grupoCardapio) : {}

        grupoCardapio.idEmpresa = empresa
        grupoCardapio.Codigo = codigo
        grupoCardapio.iCodigo = iCodigo
        grupoCardapio.Descricao = descricao
        grupoCardapio.Impressora = impressora ? impressora.value : null
        grupoCardapio.Texto_Tela = textoTela
        grupoCardapio.Cor_Botao = corBotao
        grupoCardapio.Cor_Fonte = corFonte
        grupoCardapio.SubGrupo = subGrupo
        grupoCardapio.Desktop_Visivel = desktopVisivel
        grupoCardapio.Mobile_Visivel = mobileVisivel
        grupoCardapio.Infinitifood_Visivel = infinitiFoodVisivel
        grupoCardapio.MenuDigital_Visivel = menuDigitalVisivel
        grupoCardapio.Imagem = imagem
        grupoCardapio.Ordem = ordem

        if (grupoCardapio.id) {
            props.editarGrupoCardapio(grupoCardapio, props.history)
        } else {
            if (cadastrarOutroGrupoCardapio) {
                props.cadastrarGrupoCardapioMantemCadastro(grupoCardapio, props.history)
            } else {
                props.cadastrarGrupoCardapio(grupoCardapio, props.history)
            }
        }
    }

    const buildConfiguracaoImpressora = (idId, serie) => {
        return {label: `${idId} - ${serie}`, value: idId}
    }

    const buscarConfiguracaoImpressora = (inputValue, callback) => {
        apiBuscarConfiguracoesImpressoraPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({idId, serie}) => {
                    return buildConfiguracaoImpressora(idId, serie)
                })

                callback(options)
            })
    }

    useEffect(() => {
        const tmpCompanies = getCompanies(props.user)
        setCompanies(tmpCompanies)
        setEmpresa(tmpCompanies[0].value)

        return () => {
            setCompanies([])
        }
    }, [])

    const cadastrarGrupoCardapioObservacao = (itemSelecionado) => {
        let grupoCardapioObservacao = { ...itemSelecionado }
        grupoCardapioObservacao.idEmpresa = empresa
        grupoCardapioObservacao.Codigo_Grupo = codigo
        props.cadastrarGrupoCardapioObservacao(grupoCardapioObservacao, props.history)
    }

    const removerGrupoCardapioObservacao = (itemSelecionado) => {
        props.removerGrupoCardapioObservacao({id: itemSelecionado.id}, props.history)
    }

    const editarGrupoCardapioObservacao = (itemSelecionado) => {
        props.editarGrupoCardapioObservacao(itemSelecionado, props.history)
    }

    if (!props.user || !possuiVinculo) {
        return (<AlertaSemVinculo/>)
    }

    return <React.Fragment>
        {props.grupoCardapioLoading || loading ? (<div className="loader-container position-absolute">
            <Loader
                type="ThreeDots"
                color="#556EE6"
                height={80}
                width={80}
            />
        </div>) : (<div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title="Cadastro"
                    breadcrumbItem="Grupo (categoria)"
                />
                <StickerToolbarButtons>
                    {id && <>
                        <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!empresa || !(id && usePermissionEdit) || props.grupoCardapio?.iCodigo}
                            onClick={e => gravarGrupoCardapio(e)}>
                            {clonado ? "Cadastrar Grupo" : "Salvar Grupo"}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!usePermissionInsert}
                            onClick={e => clonarGrupoCardapio(e)}>
                            {"Clonar Grupo"}
                        </Button>
                        <Button
                            type="submit"
                            color="danger"
                            className="waves-effect waves-light me-2"
                            disabled={!(usePermissionRemove) || props.grupoCardapio?.iCodigo}
                            onClick={e => excluirGrupoCardapio(e)}>
                            {"Remover Grupo"}
                        </Button>
                    </>
                    }

                    {!id && !clonado &&
                        <>
                            <Button
                                type="submit"
                                color="primary"
                                className="waves-effect waves-light me-2"
                                disabled={!empresa || !usePermissionInsert}
                                onClick={e => gravarGrupoCardapio(e)}>
                                {"Cadastrar Grupo"}
                            </Button>

                            <Button
                                type="submit"
                                color="secondary"
                                className="waves-effect waves-light me-2 btn-secondary"
                                disabled={!empresa || !usePermissionInsert}
                                onClick={e => gravarGrupoCardapio(e, true)}>
                                {"Cadastrar e Preencher novo Grupo "}
                            </Button>
                        </>
                    }

                    {clonado && <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        disabled={!empresa || !usePermissionInsert}
                        onClick={e => gravarGrupoCardapio(e)}>
                        {"Cadastrar Grupo"}
                    </Button>}
                </StickerToolbarButtons>
                <Row>
                    <Col lg="12">
                        <div>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Cadastro</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">
                                                {id && <FormGroup className="mb-4" row>
                                                    <Label htmlFor="empresa"
                                                           className="col-form-label col-lg-2">
                                                        Identificador
                                                    </Label>
                                                    <Col lg="10" className="col-form-label">
                                                        {id}
                                                    </Col>
                                                </FormGroup>}

                                                {id && <FormGroup className="mb-4" row>
                                                    <Label htmlFor="codigo" className="col-form-label col-lg-2">
                                                        Código
                                                    </Label>
                                                    <Col lg="10" className="col-form-label">
                                                        {codigo}
                                                    </Col>
                                                </FormGroup>}

                                                {!id && <FormInputNumber
                                                    attribute="codigo"
                                                    description="Código"
                                                    value={codigo}
                                                    setValue={setCodigo}
                                                    allowNegative={false}
                                                    max={9999999}
                                                    required={true}
                                                />}

                                                <FormSelectCompany
                                                    attribute="empresa"
                                                    value={empresa}
                                                    setValue={setEmpresa}
                                                    options={companies.filter(c => c.value)}
                                                    required={true}
                                                    disabled={(props.grupoCardapio?.id !== null && props.grupoCardapio?.id !== undefined) || false}
                                                />

                                                <FormInputText
                                                    attribute="descricao"
                                                    description="Descrição"
                                                    value={descricao}
                                                    setValue={setDescricao}
                                                    required={true}
                                                    maxLength={70}
                                                />

                                                <FormInputText
                                                    attribute="textoTela"
                                                    description="Descrição resumida"
                                                    value={textoTela}
                                                    setValue={setTextoTela}
                                                    required={true}
                                                    maxLength={70}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="impressora"
                                                    description="Impressora padrão de produção"
                                                    value={impressora}
                                                    setValue={setImpressora}
                                                    loadOptions={buscarConfiguracaoImpressora}
                                                    required={true}
                                                />

                                                <FormInputNumber
                                                    attribute="ordem"
                                                    description="Ordem que será exibidos nos sistemas"
                                                    value={ordem}
                                                    setValue={setOrdem}
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    max={999}
                                                    min={0}
                                                />

                                                <FormInputSwitch
                                                    attribute="subGrupo"
                                                    description="SubGrupo"
                                                    value={subGrupo}
                                                    setValue={setSubGrupo}
                                                />

                                                <FormInputSwitch
                                                    attribute="desktopVisivel"
                                                    description="Vísivel no Desktop"
                                                    value={desktopVisivel}
                                                    setValue={setDesktopVisivel}
                                                />

                                                <FormInputSwitch
                                                    attribute="mobileVisivel"
                                                    description="Vísivel no Mobile"
                                                    value={mobileVisivel}
                                                    setValue={setMobileVisivel}
                                                />

                                                <FormInputSwitch
                                                    attribute="infinitiFoodVisivel"
                                                    description="Visível no Infinitifood"
                                                    value={infinitiFoodVisivel}
                                                    setValue={setInfinitiFoodVisivel}
                                                />

                                                <FormInputSwitch
                                                    attribute="menuDigitalVisivel"
                                                    description="Visível no Menu Digital"
                                                    value={menuDigitalVisivel}
                                                    setValue={setMenuDigitalVisivel}
                                                />

                                                <FormInputFile
                                                    attribute="imagem"
                                                    description="Imagem"
                                                    extension={"image/png, image/jpeg"}
                                                    value={imagem}
                                                    setValue={setImagem}
                                                    loading={ false }
                                                    removeHandeler={ () => setImagem(null) }/>
                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Aparência botão Touch</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">

                                                <FormInputColor
                                                    attribute="corBotao"
                                                    description="Cor botão"
                                                    value={corBotao}
                                                    setValue={setCorBotao}
                                                />

                                                <FormInputColor
                                                    attribute="corFonte"
                                                    description="Cor fonte"
                                                    value={corFonte}
                                                    setValue={setCorFonte}
                                                />

                                                <Button
                                                    type="button"
                                                    style={ { backgroundColor: corBotao, color: corFonte } }
                                                    className="waves-effect waves-light me-2">
                                                    {"Botão de exemplo"}
                                                </Button>

                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                            { id && usePermissionGruposCardapioItemList && <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Itens do grupo</CardTitle>
                                    <GrupoCardapioItemLista
                                        listaGrupoCardapioItem={props.gruposCardapioItem}
                                        loading={props.gruposCardapioItemLoading}
                                    >
                                    </GrupoCardapioItemLista>
                                </CardBody>
                            </Card> }

                            { id && usePermissionGruposCardapioObservacaoList && <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Observações do grupo</CardTitle>
                                    <GrupoCardapioObservacaoLista
                                        listaGrupoCardapioObservacao={props.gruposCardapioObservacao}
                                        cadastrarGrupoCardapioObservacao={cadastrarGrupoCardapioObservacao}
                                        removerGrupoCardapioObservacao={removerGrupoCardapioObservacao}
                                        editarGrupoCardapioObservacao={editarGrupoCardapioObservacao}
                                        loading={props.grupoCardapioObservacaoLoading || props.gruposCardapioObservacaoLoading}
                                        disabled={ props.grupoCardapio?.iCodigo }
                                    >
                                    </GrupoCardapioObservacaoLista>
                                </CardBody>
                            </Card> }

                            { id && <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Dados de alteração do registro</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">

                                                <FormInputDate
                                                    attribute="dataAlteracao"
                                                    description="Alteração do cadastro"
                                                    value={dataAlteracao}
                                                    disabled={true}
                                                    dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                    withHour={true}
                                                />

                                                <FormInputText
                                                    attribute="usuarioAlteracao"
                                                    description="Usuário que alterou"
                                                    value={usuarioAlteracao}
                                                    disabled={true}
                                                />

                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card> }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)}
    </React.Fragment>
}

CadastrarCardapioDigitalGrupo.propTypes = {
    grupoCardapioAtivo: PropTypes.any,
    grupoCardapio: PropTypes.any,
    grupoCardapioLoading: PropTypes.bool,
    permanecerCadastro: PropTypes.any,
    grupoCardapioObservacao: PropTypes.any,
    grupoCardapioObservacaoLoading: PropTypes.bool,
    gruposCardapioObservacao: PropTypes.any,
    gruposCardapioObservacaoLoading: PropTypes.bool,
    gruposCardapioItem: PropTypes.any,
    gruposCardapioItemLoading: PropTypes.bool,
    novoGrupoCardapioObservacao: PropTypes.any,
    grupoCardapioObservacaoRemovido: PropTypes.any,

    buscarGrupoCardapio: PropTypes.func,
    cadastrarGrupoCardapio: PropTypes.func,
    cadastrarGrupoCardapioMantemCadastro: PropTypes.func,
    editarGrupoCardapio: PropTypes.func,
    removerGrupoCardapio: PropTypes.func,
    buscarGruposCardapioObservacaoPorGrupo: PropTypes.func,
    cadastrarGrupoCardapioObservacao: PropTypes.func,
    removerGrupoCardapioObservacao: PropTypes.func,
    editarGrupoCardapioObservacao: PropTypes.func,
}

const mapStatetoProps = state => {
    const {
        grupoCardapioAtivo,
        grupoCardapio,
        grupoCardapioLoading,
        permanecerCadastro,
        grupoCardapioObservacao,
        grupoCardapioObservacaoLoading,
        gruposCardapioItem,
        gruposCardapioItemLoading,
        gruposCardapioObservacao,
        gruposCardapioObservacaoLoading,
        novoGrupoCardapioObservacao,
        grupoCardapioObservacaoRemovido,
        grupoCardapioObservacaoEditado
    } = state.Profile
    const {user} = state.Login
    return {
        user,
        grupoCardapioAtivo,
        grupoCardapio,
        grupoCardapioLoading,
        permanecerCadastro,
        grupoCardapioObservacao,
        grupoCardapioObservacaoLoading,
        gruposCardapioObservacao,
        gruposCardapioObservacaoLoading,
        gruposCardapioItem,
        gruposCardapioItemLoading,
        novoGrupoCardapioObservacao,
        grupoCardapioObservacaoRemovido,
        grupoCardapioObservacaoEditado
    }
}

export default connect(mapStatetoProps, {
    cadastrarGrupoCardapio,
    removerGrupoCardapio,
    editarGrupoCardapio,
    buscarGrupoCardapio,
    cadastrarGrupoCardapioMantemCadastro,
    buscarGruposCardapioObservacaoPorGrupo,
    cadastrarGrupoCardapioObservacao,
    removerGrupoCardapioObservacao,
    editarGrupoCardapioObservacao,
    buscarGruposCardapioItemPorGrupo
})(withRouter(CadastrarCardapioDigitalGrupo))
