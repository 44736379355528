import PropTypes from "prop-types"
import React from "react"
import {FormGroup, Label} from "reactstrap"
import AsyncSelect from "react-select/async"
import {apiBuscarFornecedoresPesquisa} from "../../helpers/backend_helper"
import FormFilterAsyncSelect from "./form-filter-async-select"


const FormFilterFornecedor = props => {
    const { attribute, placeholder, description, empresas, value, setValue, multiple } = props

    const buildFornecedor = (codigo, cnpj, razaosocial) => {
        return {label: `${cnpj} - ${razaosocial}`, value: codigo}
    }

    const buscarFornecedores = (inputValue, callback) => {
        apiBuscarFornecedoresPesquisa({empresaId: empresas, argumento: inputValue})
            .then(results => {
                const options = results.map(({codigo, cnpj, razaosocial}) => {
                    return buildFornecedor(codigo, cnpj, razaosocial)
                })
                callback(options)
            })
    }

    return (
        <React.Fragment>
            <FormFilterAsyncSelect
                attribute={ attribute || 'fornecedor' }
                description={ description || ( 'Fornecedor' + (multiple ? '(s)' : '')) }
                placeholder={ placeholder || `Digite o ${description}...` }
                value={value}
                multiple={ multiple }
                setValue={ setValue }
                searchOptions={ buscarFornecedores }
            />
        </React.Fragment>
    )
}

FormFilterFornecedor.propTypes = {
    attribute: PropTypes.string,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    value: PropTypes.any,
    empresas: PropTypes.array,
    setValue: PropTypes.func,
}

export default FormFilterFornecedor
