import PropTypes from "prop-types"
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFiltersButton
} from "material-react-table"
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";
import {createTheme, ThemeProvider} from "@mui/material"
import {buildParams} from "../../helpers/data_helper"


const ListDataTable = forwardRef((props, ref) => {
    const { data, columns, showColumnFilters, defaultSorting, columnVisibility, enableRowActions, renderRowActions,
        setData, searchFn, advancedFilters, children, enableRowSelection, getRowId, onRowSelectionChange } = props

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    useImperativeHandle(ref, () => ({
        search() {
            fetchData().then()
        },
        getColumnFilters() {
            return columnFilters
        },
        getSorting() {
            return sorting
        }
    }));

    useEffect(() => {
        fetchData().then()
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        advancedFilters
    ])

    const fetchData = async () => {
        if (!(data || []).length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const sort = []
            if(Array.isArray(sorting) && sorting.length > 0) {
                sort.push(...sorting)
            } else if(Array.isArray(defaultSorting) && defaultSorting.length > 0) {
                sort.push(...defaultSorting)
            }
            const result = await searchFn(buildParams(pagination, globalFilter, columnFilters, advancedFilters, columns, sort))
            setData(result.data || []);
            setRowCount(result.count);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    const theme = createTheme({
        typography: {
            root: {
                fontSize: '11px !important',
                fontFamily: 'var(--bs-body-font-family) !important',
            }
        },
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        height: '18px',
                        width: '18px',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: 'var(--bs-table-color)',
                        fontSize: '11px',
                        fontWeight: '400',
                        fontFamily: 'var(--bs-body-font-family)',
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        color: 'var(--bs-table-color) !important',
                        fontSize: '11px !important',
                        fontWeight: '400 !important',
                        fontFamily: 'var(--bs-body-font-family) !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: '600',
                        fontSize: '11px',
                        fontFamily: 'var(--bs-body-font-family)',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: 'var(--bs-table-color) !important',
                        fontSize: '11px !important',
                        fontWeight: '400 !important',
                        fontFamily: 'var(--bs-body-font-family) !important',
                    },
                    input: {
                        padding: '0.47rem 0.75rem !important'
                    }
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        paddingBottom: '0px !important',
                        paddingTop: '0px !important',
                        fontSize: '11px',
                        fontFamily: 'var(--bs-body-font-family)',
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        marginRight: '0px !important',
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        fontSize: '11px',
                        fontFamily: 'var(--bs-body-font-family)',
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectLabel: {
                        fontSize: '11px',
                        fontFamily: 'var(--bs-body-font-family)',
                    },
                    displayedRows: {
                        fontSize: '11px',
                        fontFamily: 'var(--bs-body-font-family)',
                    }
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        fontSize: '11px',
                        fontFamily: 'var(--bs-body-font-family)',
                    }
                },
            }
        },
    });

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    muiTableProps={{ sx: { tableLayout: 'fixed', }, }}
                    columns={columns}
                    data={data}
                    initialState={{ density: 'compact', showColumnFilters, columnVisibility }}
                    defaultColumn={{
                        maxSize: 400,
                        minSize: 120,
                        size: 200, //default size is usually 180
                    }}
                    muiTopToolbarProps={{
                        sx: {
                            fontWeight: '600',
                            fontSize: '11px',
                            fontFamily: 'var(--bs-body-font-family)',
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            fontWeight: '600',
                            fontSize: '11px',
                            fontFamily: 'var(--bs-body-font-family)',
                        },
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            color: 'var(--bs-table-color)',
                            fontSize: '13px',
                            fontWeight: '400',
                            fontFamily: 'var(--bs-body-font-family)',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: { align: 'center', },
                            size: 80,
                            maxSize: 80
                        }}}
                    muiTableBodyProps={{
                        sx: (theme) => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'var(--bs-gray-100)',
                            },
                        }),
                    }}
                    muiTablePaginationProps={{
                        sx: {
                            color: 'var(--bs-gray-600)',
                            fontSize: '11px',
                            fontWeight: '400',
                            fontFamily: 'var(--bs-body-font-family)',
                        },
                    }}
                    muiTableFooterCellProps={{
                        sx: {
                            fontWeight: '600',
                            fontSize: '11px',
                            fontFamily: 'var(--bs-body-font-family)',
                        },
                    }}
                    muiTableBodyProps={{
                        sx: {
                            fontSize: '11px',
                            fontFamily: 'var(--bs-body-font-family)',
                        },
                    }}
                    enableGlobalFilter={ false }
                    positionPagination='bottom'
                    manualFiltering
                    manualPagination
                    manualSorting
                    enableDensityToggle={false}
                    enableColumnResizing={true}
                    enableRowActions={ enableRowActions }
                    renderRowActions={({ row, table }) => renderRowActions(row, table) }
                    renderToolbarInternalActions={({ table }) => {
                        return (
                            <>
                                <MRT_ToggleFiltersButton table={table} />
                                <MRT_ShowHideColumnsButton table={table} />
                                <MRT_FullScreenToggleButton table={table} />
                            </>
                        );
                    }}
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: 'Não foi possível buscar os dados!',
                            }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    localization={MRT_Localization_PT_BR}
                    state={{
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                        rowSelection
                    }}
                    enableRowSelection={enableRowSelection}
                    getRowId={getRowId}
                    onRowSelectionChange={ (row) => {
                        setRowSelection(row)
                        if(onRowSelectionChange) {
                            onRowSelectionChange(row)
                        }
                    }} //connect internal row selection state to your own
                />
            </ThemeProvider>

            { children }
        </React.Fragment>
    )
})

ListDataTable.propTypes = {
    data: PropTypes.any,
    columns: PropTypes.any,
    showColumnFilters: PropTypes.bool,
    advancedFilters: PropTypes.any,
    pagination: PropTypes.any,
    defaultSorting: PropTypes.any,
    globalFilter: PropTypes.string,
    rowCount: PropTypes.number,
    onColumnFiltersChange: PropTypes.func,
    onGlobalFilterChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSortingChange: PropTypes.func,
    setRowCount: PropTypes.func,
    setData: PropTypes.func,
    searchFn: PropTypes.func,
    enableRowSelection: PropTypes.bool,
    getRowId: PropTypes.func,
    onRowSelectionChange: PropTypes.func,
}

export default ListDataTable
