module.exports = {
  google: {
    API_KEY: "AIzaSyD10XeOnXnWbf-RCbEw3Lupqnx9T40kLKY",
    CLIENT_ID:
      "787446653713-49tdjl1r1l90mkqbkl4v0v4g730jh9nd.apps.googleusercontent.com",
    SECRET: "D_nkkaW2iGfcL7oyQPILGIfo"
  },
  facebook: {
    APP_ID: "1113734715675973"
  },
  recaptcha: {
    siteKey: "6LetyAgaAAAAAMHa-8P3Rs1KFqA7Chi8YGhQGNM6"
    // 2 chave 6LetyAgaAAAAAJ6Ov7M1jaEt1If5vLnFUF_BYGpQ
  },
  api: {
    url: process.env.REACT_APP_URL || "http://localhost:3000/api/v1/"
  },
  socket: {
    url: process.env.REACT_APP_SOCKET_URL || "http://localhost:3010"
  },
  onesignal: {
    options: {
      // appId: "edee47ee-b678-4445-b9dd-249d87161cf1", // dev lazaros
      appId: "215cec35-5eb2-4269-96ef-a2fb3a1958c6",
      autoResubscribe: true,
      autoRegister: true
    }
  }
}
