import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Media } from "reactstrap"
import Loader from "react-loader-spinner"

const MiniCards = props => {
  const { title, text, tooltip, iconClass, loading } = props
  return (
    <React.Fragment>
      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium" title={tooltip}>{title}</p>
                { loading ? (
                  <div className="loader-container position-absolute">
                    <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
                  </div>
                ) : (
                    <h4 className="mb-0">{ text }</h4>
                ) }
              </div>
              <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                <span className="avatar-title">
                  <i className={"bx " + iconClass + " font-size-24"} />
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniCards.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  iconClass: PropTypes.string,
  loading: PropTypes.bool,
}

export default MiniCards
