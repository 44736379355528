import React, {useEffect, useState} from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {
    Badge,
    Button,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap"
import {isEmpty, map} from "lodash";
import {Link} from "react-router-dom";
import {AvForm} from "availity-reactstrap-validation";
import {describeDateTime} from "../../helpers/utils";
import FormInputText from "../../components/Common/form-input-text";
import usePermission from "../../helpers/permission/usePermission";
import LoaderList from "../../components/Common/loaderList";
import FormInputNumber from "../../components/Common/form-input-number";
import FormInputAsyncSelect from "../../components/Common/form-input-async-select";
import {apiBuscarFornecedoresPesquisa, apiBuscarProdutosPesquisa} from "../../helpers/backend_helper";
import {MDBDataTable} from "mdbreact"
import DispositivoLogsModal from "../CardapioDigital/dispositivo-logs"

const GrupoCardapioObservacaoLista = props => {

    const [modalRemove, setModalRemove] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState()
    const [cadastrar, setCadastrar] = useState()

    const [id , setId] = useState() // Codigo_Grupo
    const [codigo , setCodigo] = useState() // Codigo_Grupo
    const [descricao , setDescricao] = useState() // Descricao
    const [descricaoImpressao , setDescricaoImpressao] = useState() // Descricao_Imp

    const usePermissionInsert = usePermission("produtoComposicao_inserir")
    const usePermissionEdit = usePermission("produtoComposicao_editar")
    const usePermissionRemove = usePermission("produtoComposicao_excluir")

    const [data, setData] = useState({
        columns: [
            { label: "Código", field: "Código", sort: "asc", width: 110 },
            { label: "Descrição", field: "Descrição", sort: "asc", width: 230 },
            { label: "Descrição para impresão", field: "Descrição para impresão", sort: "asc", width: 230 },
            { label: "iSistema", field: "iSistema", sort: "asc", width: 110 },
            { label: "Alteração do cadastro", field: "Alteração do cadastro", width: 110 },
            { label: "Usuário que alterou", field: "Usuário que alterou", sort: "asc", width: 110 },
            { label: "Ações", field: "Ações", width: 110 },
        ],
        rows: []
    })

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const removerGrupoCardapioObservacao = () => {
        props.removerGrupoCardapioObservacao(itemSelecionado)
        setModalRemove(false)
    }

    const cadastrarGrupoCardapioObservacao = () => {
        if(id) {
            props.editarGrupoCardapioObservacao({ id: id, Descricao: descricao, Descricao_Imp: descricaoImpressao })
        } else {
            props.cadastrarGrupoCardapioObservacao({ Descricao: descricao, Descricao_Imp: descricaoImpressao })
        }

        limparFormulario();
    }

    const limparFormulario = () => {
        setId(null)
        setCodigo(null)
        setDescricao(null)
        setDescricaoImpressao(null)
        setCadastrar(false)
    }

    const editRow = (item) => {
        setId(item.id)
        setDescricao(item.Descricao)
        setDescricaoImpressao(item.Descricao_Imp)
        setCadastrar(true)
    }

    useEffect(() => {
        if(Array.isArray(props.listaGrupoCardapioObservacao)) {
            const rows = props.listaGrupoCardapioObservacao?.map(item => ({
                Código: item.Codigo_OBS,
                Descrição: item.Descricao,
                "Descrição para impresão": item.Descricao_Imp,
                "iSistema": item.iSistema,
                "Alteração do cadastro": describeDateTime(item.AltData),
                "Usuário que alterou": `${item?.AltFunc} - ${(item?.AltFunc_Nome ? item.AltFunc_Nome : 'N/A')}`,
                "Ações": (<div>
                    { usePermissionEdit && !item.iSistema && !props.disabled && <Link to="#" className="me-3 text-primary">
                        <i className="mdi mdi-pencil font-size-18 me-3" id="edittooltip"
                           onClick={e => editRow(item)} title="Editar"/>
                    </Link>}

                    { usePermissionEdit && !item.iSistema && !props.disabled && <Link to="#" className="text-danger">
                        <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                           onClick={() => showConfirmDelete(item)} title="Remover"/>
                    </Link>
                    }</div>)
            }))

            setData({
                columns: data.columns,
                rows: rows
            })
        }
    }, [props.listaGrupoCardapioObservacao])

    return <>
        { props.loading ? <LoaderList></LoaderList> :
            <>
                <div className="table-responsive">
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={ data }
                        entriesLabel={ "Mostrar Registros" }
                        infoLabel={ ["Exibindo", "a", "de", "registros"] }
                        noRecordsFoundLabel={ "Nenhum registro encontrado" }
                        paginationLabel={ ["Anterior", "Próximo"] }
                        searchLabel={ "Pesquisar" }
                        entries={5}
                        entriesOptions={[5,10,20,50,100]}
                    />
                </div>

                { cadastrar && id && <FormGroup className="mb-4" row>
                    <Label htmlFor="codigo" className="col-form-label col-lg-2">
                        Código
                    </Label>
                    <Col lg="10" className="col-form-label">
                        {codigo}
                    </Col>
                </FormGroup>}

                { cadastrar && <FormInputText
                    attribute="observacaoDescricao"
                    description="Descrição"
                    value={descricao}
                    setValue={setDescricao}
                    required={true}
                    afterChange={ (value) => {
                        setDescricaoImpressao(value)
                    }}
                /> }

                { cadastrar && <FormInputText
                    attribute="observacaoDescricaoImpressao"
                    description="Descrição para impressão"
                    value={descricaoImpressao}
                    setValue={setDescricaoImpressao}
                    required={true}
                /> }

                <div className="d-flex justify-content-start">
                    { !cadastrar && <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        onClick={ e => setCadastrar(true) }
                        disabled={ !usePermissionInsert || props.loading || props.disabled }>
                        { "Incluir nova observação" }
                    </Button> }

                    { cadastrar && <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        disabled={ !codigo && !descricao && !descricaoImpressao }
                        onClick={ e => cadastrarGrupoCardapioObservacao(e) }>
                        { id ? "Salvar observação" : "Salvar nova observação" }
                    </Button> }

                    { cadastrar && <Button
                        type="submit"
                        color="danger"
                        className="waves-effect waves-light me-2"
                        onClick={ e => limparFormulario() }>
                        { "Cancelar" }
                    </Button> }
                </div>

                <Modal isOpen={modalRemove}>
                    <ModalHeader tag="h4">Confirmação</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={(e, v) => { removerGrupoCardapioObservacao() }} >
                            <Row form>
                                <Col className="col-12">
                                    Deseja realmente remover esse item?
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 10}}>
                                <Col>
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-success save-event">
                                            Confirmar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-light ms-2"
                                            onClick={e => setModalRemove(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </>
        }
    </>
}

GrupoCardapioObservacaoLista.propTypes = {
    listaGrupoCardapioObservacao: PropTypes.array,
    cadastrarGrupoCardapioObservacao: PropTypes.any,
    removerGrupoCardapioObservacao: PropTypes.any,
    editarGrupoCardapioObservacao: PropTypes.any,
    loading: PropTypes.bool,
    empresa: PropTypes.any,
    disabled: PropTypes.bool
}

export default GrupoCardapioObservacaoLista