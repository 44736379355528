import PropTypes from "prop-types"
import React from "react"
import {Col, FormGroup, Label} from "reactstrap"
import CurrencyInput from "react-currency-input-field"

const FormInputMoney = props => {
  const { attribute, description, value, setValue, required, changeHandeler, disabled } = props

  const changeHandelerInput = (valor, elementId) => {
    if (valor !== "" && valor !== undefined && valor !== null) {
      document.getElementById(elementId).style.display = 'none'
    } else {
      document.getElementById(elementId).style.display = 'block'
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-money" } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          <CurrencyInput
            value={ value }
            className={ "form-control " + (required && !value? "is-invalid" : "") }
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            onValueChange={(value, name) => {
              if(setValue) {
                setValue(Number((value?.toString() || "0").replaceAll(",", ".")))
                if (required) {
                  if (changeHandeler !== undefined) {
                    changeHandeler(event.target.value, `validate-required-${attribute}-money`)
                  } else {
                    changeHandelerInput(event.target.value, `validate-required-${attribute}-money`)
                  }
                }
              }
            }}
            id={ attribute + "-money" }
            name={ attribute + "-money" }
            placeholder= { `Digite o valor da(o) ${description}`}
            defaultValue={ 0 }
            decimalScale={ 2 }
            disableAbbreviations={ true }
            required={ required }
            disabled={ disabled }
          />
          { required && <div className={ value ? "" : "invalid-tooltip" }
               name="validate-required" id={ `validate-required-${ attribute }-money` }>
            { value ? "" : `${ description } é obrigatório(a)`}
          </div> }
        </Col>
      </FormGroup>

    </React.Fragment>
  )
}

FormInputMoney.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  trueValue: PropTypes.any,
  falseValue: PropTypes.any
}

export default FormInputMoney
