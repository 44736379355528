import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { cadastrarPergunta, doEditQuestion, listarFaqs } from "../store/auth/profile/actions"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import PropTypes from "prop-types"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../components/Common/sticker-toolbar-buttons";

const Pergunta = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [titulo, setTitulo] = useState("")
  const [icone, setIcone] = useState("")
  const [categoria, setCategoria] = useState("")

  const usePermissionEdit = usePermission('perguntasEdit_editar')

  const handleValidSubmit = (e, v) => {
    props.cadastrarPergunta(
      {
        id: props.editingQuestion ? props.editingQuestion.id : null,
        titulo,
        icone,
        categoria_id: categoria,
        conteudo: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      },
      props.history
    )
  }

  useEffect(() => {
    props.listarFaqs()
  }, [])

  useEffect(() => {
    if (props.editingQuestion) {
      setTitulo(props.editingQuestion.titulo)
      setIcone(props.editingQuestion.icone)
      setCategoria(props.editingQuestion.categoria_id)
      setEditorState(prevState =>
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(props.editingQuestion.conteudo))
        )
      )
    } else {
      setTitulo("")
      setIcone("")
      setCategoria("")
      setEditorState(prevState => EditorState.createEmpty())
    }

    return () => {
      setTitulo("")
      setIcone("")
      setCategoria("")
      setEditorState(prevState => EditorState.createEmpty())
    }
  }, [props.editingQuestion])

    return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */ }
          <Breadcrumbs
            title="Perguntas"
            breadcrumbItem={
              props.editingQuestion ? "Editar Pergunta" : "Criar Pergunta"
            }
          />
          <StickerToolbarButtons>
            { usePermissionEdit && <Button
                type="submit"
                color="primary"
                onClick={ e => handleValidSubmit() }
                disabled={ !titulo || !icone || !categoria }
            >
              { props.editingQuestion
                  ? "Salvar Pergunta"
                  : "Criar Pergunta" }
            </Button>}
          </StickerToolbarButtons>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    { props.editingQuestion
                      ? "Editar Pergunta"
                      : "Criar Nova Pergunta" }
                  </CardTitle>

                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="titulo"
                          className="col-form-label col-lg-2"
                        >
                          Título
                        </Label>
                        <Col lg="10">
                          <Input
                            id="titulo"
                            name="titulo"
                            type="text"
                            className="form-control"
                            placeholder="Dígite o título da pergunta..."
                            required
                            errorMessage="Campo obrigatório."
                            value={ titulo }
                            onChange={ e => setTitulo(e.target.value) }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Resposta
                        </Label>
                        <Col lg="10">
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            placeholder="Digite o conteúdo da resposta..."
                            editorState={ editorState }
                            onEditorStateChange={ e => setEditorState(e) }
                            required
                            errorMessage="Campo obrigatório."
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="icone"
                          className="col-form-label col-lg-2"
                        >
                          Ícone
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="icone"
                            name="icone"
                            type="text"
                            placeholder="Digite a classe do ícone... Exemplo: bx bx-credit-card-front"
                            className="form-control"
                            required
                            errorMessage="Campo obrigatório."
                            value={ icone }
                            onChange={ e => setIcone(e.target.value) }
                          />
                          <a
                            href="http://skote-v-light.react.themesbrand.com/icons-boxicons"
                            target="_blank"
                          >
                            Clique aqui para visualizar os ícones
                          </a>
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="icone"
                          className="col-form-label col-lg-2"
                        >
                          Categoria
                        </label>
                        <div className="col-lg-10">
                          <Input
                            type="select"
                            name="categoria_id"
                            required
                            errorMessage="Campo obrigatório."
                            value={ categoria }
                            onChange={ e => setCategoria(e.target.value) }
                          >
                            <option value="">Selecione...</option>
                            { props.faqs &&
                            props.faqs.map((faqCategoria, key) => (
                              <option key={ faqCategoria.id } value={ faqCategoria.id }>
                                { faqCategoria.titulo }
                              </option>
                            )) }
                          </Input>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

Pergunta.propTypes = {
  listarFaqs: PropTypes.func,
  cadastrarPergunta: PropTypes.func
}

const mapStatetoProps = state => {
  const { faqs, editingQuestion } = state.Profile
  return { faqs, editingQuestion }
}

export default connect(mapStatetoProps, {
  listarFaqs,
  cadastrarPergunta,
  doEditQuestion
})(Pergunta)
