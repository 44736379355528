import React, { useEffect } from "react"
import usePermission from "../helpers/permission/usePermission";
import { useHistory } from 'react-router-dom';

const Restricted: React.FunctionComponent = ({to, children}) => {

  const allowed = usePermission(to);

  if(allowed){
    return children;
  }
  useHistory().push("/404");
  return <></>
};

export default Restricted;
