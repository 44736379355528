import React from 'react';
import PermissionContext from "./permissionContext";

const PermissionProvider: React.FunctionComponent = ({fetchPermission, children}) => {

  const cache = {};

  const isAllowedTo = async (permission) => {
    if(Object.keys(cache).includes(permission)){
      return cache[permission];
    }
    const isAllowed = await fetchPermission(permission);
    cache[permission] = isAllowed;
    return isAllowed;
  };

  return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
