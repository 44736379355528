import React from "react"
import Loader from "react-loader-spinner";

const LoaderList = props => {
  return (
    <React.Fragment>
      <div className="loader-container position-absolute">
        <Loader
            type="ThreeDots"
            color="#556EE6"
            height={80}
            width={80}
        />
      </div>
    </React.Fragment>
  )
}

LoaderList.propTypes = {
}

export default LoaderList
