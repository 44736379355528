import PropTypes from "prop-types"
import React, {Component} from "react"
import {Card, CardBody, Col, UncontrolledTooltip} from "reactstrap"
import Loader from "react-loader-spinner"
import Tooltip from "../../components/Common/tooltip";

class MiniWidget extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
          <Col sm="3" key={ this.props.key }>
            <Card className="eq-height">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className={ this.props.icon}/>
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{this.props.title}</h5>
                  <Tooltip id={ this.props.id } tooltip={ this.props.tooltip }>
                  </Tooltip>
                </div>
                {this.props.loading ? (
                    <div className="loader-container position-absolute">
                      <Loader
                          type="ThreeDots"
                          color="#556EE6"
                          height={80}
                          width={ 80 }
                    />
                  </div>
                ) : (
                  <div className="text-muted mt-4">
                    <h4>
                      { this.props.value }{ " " }
                      <i className="mdi mdi-chevron-up ms-1 text-success" />
                    </h4>
                    <div className="d-flex">
                      <span className={"badge badge-soft-" + this.props.color + " font-size-12"}>
                        { " " }
                        { this.props.badgeValue }{ " " }
                      </span>{ " " }
                      <span className="ms-2 text-truncate">{ this.props.desc }</span>
                    </div>
                  </div>
                ) }
              </CardBody>
            </Card>
          </Col>
      </React.Fragment>
    )
  }
}

MiniWidget.propTypes = {
  desc: PropTypes.string,
  badgeValue: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.any,
  loading: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  key: PropTypes.string,
}

export default MiniWidget
