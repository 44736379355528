import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, Col, Container, Form, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import moment from "moment"
import momentTimezone from "moment-timezone"
import {buscarNotasSituacoes} from "../../store/auth/profile/actions"
import OverView from "./overview"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import {getDashboardData} from "../../store/auth/login/actions"
import usePermission from "helpers/permission/usePermission"
import NotasTable from "./notas-table"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import FormFilterSelect from "../../components/Common/form-filter-select"
import FormFilterPeriod from "../../components/Common/form-filter-period"
import {getCompanies, getCompaniesValuesByOptions, getDatasPeloPeriodo} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import {withTranslation} from "react-i18next";

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const brazilMoment = (...args) => momentTimezone(...args)

const NotasEmitidas = props => {

    const ref = useRef();

    const [startDate, setStartDate] = useState(
        moment().subtract(30, "days").toDate()
    )
    const [endDate, setEndDate] = useState(new Date())

    const [situation, setSituation] = useState("")
    const [periods, setPeriods] = useState([])
    const [period, setPeriod] = useState(null)
    const [tipoNota, setTipoNota] = useState("")
    const [totalNotas, setTotalNotas] = useState(null)
    const [filters, setFilters] = useState(null)

    const [companies, setCompanies] = useState([])
    const [companiesOptions, setCompaniesOptions] = useState([])

    const usePermissionListar = usePermission('notasEmitidas_listar')

    const [reports, setReports] = useState([
        {
            id: 'notashoje',
            title: props.t('notasemitidas.lista.notashoje.label'),
            tooltip: props.t('notasemitidas.lista.notashoje.tooltip'),
            icon: "mdi mdi-note-multiple-outline",
            color: "warning",
            value: "",
            desc: "",
            loading: true
        },
        {
            id: 'notasmes',
            title: props.t('notasemitidas.lista.notasmes.label'),
            tooltip: props.t('notasemitidas.lista.notasmes.tooltip'),
            icon: "mdi mdi-note-multiple-outline",
            color: "primary",
            value: "",
            desc: "",
            loading: true
        },
        {
            id: 'notasano',
            title: props.t('notasemitidas.lista.notasano.label'),
            tooltip: props.t('notasemitidas.lista.notasano.tooltip'),
            icon: "mdi mdi-note-multiple-outline",
            color: "info",
            value: "",
            desc: "",
            loading: true
        }
    ])

    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    useEffect(() => {
        const tmpPeriods = []
        for (let i = 0; i <= 11; i++) {
            tmpPeriods.push({
                label: moment().subtract(i, "month").format("MMMM/YY"),
                value: moment().subtract(i, "month").format("YYYY-MM-DD")
            })
        }

        let primeiroPeriodo = tmpPeriods[0].value
        setPeriod(primeiroPeriodo)

        tmpPeriods.unshift({ label: "Selecione", value: null })
        tmpPeriods.push({ label: "Personalizado", value: "Personalizado" })
        setPeriods(tmpPeriods)

        setCompaniesOptions(getCompanies(props.user))
        setCompanies(props.dashboardFilters.companiesSelected)
        if (possuiVinculo) {
            populateFiltros(primeiroPeriodo, props.dashboardFilters.companiesSelected);

            props.buscarNotasSituacoes()
            props.getDashboardData(null, null, props.dashboardFilters.companiesId, "NotasSemana")
            props.getDashboardData(null, null, props.dashboardFilters.companiesId, "NotasMeses")
            props.getDashboardData(null, null, props.dashboardFilters.companiesId, "NotasAnos")
        }

        return () => {
            setPeriods([])
            setCompaniesOptions([])
        }
    }, [])

    const describeDiff = (A, B, numberOnly = false) => {
        A = parseFloat(A)
        B = parseFloat(B)

        const diff = A && B ? (100 * Math.abs((B - A) / B)).toFixed(2) : 0

        if (numberOnly) {
            return parseInt(diff)
        }

        return A >= B ? `+ ${diff} %` : `- ${diff} %`
    }

    const abbreviateNumber = (num, fixed) => {
        if (num === null) {
            return null
        } // terminate early
        if (num === 0) {
            return "0"
        } // terminate early
        fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
        var b = num.toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c =
                k < 1
                    ? num.toFixed(0 + fixed)
                    : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ["", "K", "M", "B", "T"][k] // append power
        return e
    }

    const weekdayName = dia_numerico => {
        return [
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
            "Domingo"
        ][dia_numerico]
    }

    const monthName = month => {
        return [
            null,
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
        ][month]
    }

    useEffect(() => {
        setReports([
            {
                id: 'notashoje',
                title: props.t('notasemitidas.lista.notashoje.label'),
                tooltip: props.t('notasemitidas.lista.notashoje.tooltip'),
                icon: "mdi mdi-note-multiple-outline",
                color: "warning",
                value:
                    props.dashboardNotasSemana && props.dashboardNotasSemana.periodo
                        ? parseFloat(
                            props.dashboardNotasSemana.periodo[0][0].total_nf || 0
                        ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })
                        : 0,
                desc: describeDiff(
                    props.dashboardNotasSemana && props.dashboardNotasSemana.periodo
                        ? props.dashboardNotasSemana.periodo[0][0].total_nf || 0
                        : 0,
                    props.dashboardNotasSemana &&
                    props.dashboardNotasSemana.periodo_anterior
                        ? props.dashboardNotasSemana.periodo_anterior[0][0].total_nf || 0
                        : 0
                ),
                loading: props.loadingDashboardNotasSemana,
                series: [
                    {
                        name: "Valor",
                        data:
                            props.dashboardNotasSemana && props.dashboardNotasSemana.semana
                                ? props.dashboardNotasSemana.semana[0].map(e => e.total_nf)
                                : []
                    }
                ],
                diff: describeDiff(
                    props.dashboardNotasSemana && props.dashboardNotasSemana.periodo
                        ? props.dashboardNotasSemana.periodo[0][0].total_nf || 0
                        : 0,
                    props.dashboardNotasSemana &&
                    props.dashboardNotasSemana.periodo_anterior
                        ? props.dashboardNotasSemana.periodo_anterior[0][0].total_nf || 0
                        : 0,
                    true
                ),
                options: {
                    chart: {sparkline: {enabled: !0}},
                    stroke: {curve: "smooth", width: 2},
                    colors: ["#f1b44c"],
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: !1,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [25, 100, 100, 100]
                        }
                    },
                    tooltip: {
                        fixed: {enabled: !1},
                        x: {show: !1},
                        marker: {show: !1}
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val, object) {
                                if (object) {
                                    return (
                                        weekdayName(
                                            props.dashboardNotasSemana.semana[0][
                                                object.dataPointIndex
                                                ].weekday
                                        ) +
                                        " " +
                                        abbreviateNumber(val)
                                    )
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'notasmes',
                title: props.t('notasemitidas.lista.notasmes.label', { data: (brazilMoment(props.currentPeriod).format(
                    "MMM"
                ))}),
                tooltip: props.t('notasemitidas.lista.notasmes.tooltip'),
                icon: "mdi mdi-note-multiple-outline",
                color: "primary",
                value:
                    props.dashboardNotasMeses && props.dashboardNotasMeses.periodo
                        ? parseFloat(
                            props.dashboardNotasMeses.periodo[0][0].total_nf || 0
                        ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })
                        : 0,
                desc: describeDiff(
                    props.dashboardNotasMeses && props.dashboardNotasMeses.periodo
                        ? props.dashboardNotasMeses.periodo[0][0].total_nf || 0
                        : 0,
                    props.dashboardNotasMeses &&
                    props.dashboardNotasMeses.periodo_anterior
                        ? props.dashboardNotasMeses.periodo_anterior[0][0].total_nf || 0
                        : 0
                ),
                loading: props.loadingDashboardNotasMeses,
                series: [
                    {
                        name: "Valor",
                        data:
                            props.dashboardNotasMeses && props.dashboardNotasMeses.meses
                                ? props.dashboardNotasMeses.meses[0].map(e => e.total_nf)
                                : []
                    }
                ],
                diff: describeDiff(
                    props.dashboardNotasMeses && props.dashboardNotasMeses.periodo
                        ? props.dashboardNotasMeses.periodo[0][0].total_nf || 0
                        : 0,
                    props.dashboardNotasMeses &&
                    props.dashboardNotasMeses.periodo_anterior
                        ? props.dashboardNotasMeses.periodo_anterior[0][0].total_nf || 0
                        : 0,
                    true
                ),
                options: {
                    chart: {sparkline: {enabled: !0}},
                    stroke: {curve: "smooth", width: 2},
                    colors: ["#f1b44c"],
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: !1,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [25, 100, 100, 100]
                        }
                    },
                    tooltip: {
                        fixed: {enabled: !1},
                        x: {show: !1},
                        marker: {show: !1}
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val, object) {
                                if (object && props.dashboardNotasMeses.meses) {
                                    return (
                                        monthName(
                                            props.dashboardNotasMeses.meses[0][object.dataPointIndex]
                                                .month
                                        ) +
                                        " " +
                                        abbreviateNumber(val)
                                    )
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'notasano',
                title: props.t('notasemitidas.lista.notasano.label', { data: (brazilMoment(props.currentPeriod).format(
                        "YY"
                    ))}),
                tooltip: props.t('notasemitidas.lista.notasano.tooltip'),
                icon: "mdi mdi-note-multiple-outline",
                color: "info",
                value:
                    props.dashboardNotasAnos && props.dashboardNotasAnos.periodo
                        ? parseFloat(
                            props.dashboardNotasAnos.periodo[0][0].total_nf || 0
                        ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })
                        : 0,
                desc: describeDiff(
                    props.dashboardNotasAnos && props.dashboardNotasAnos.periodo
                        ? props.dashboardNotasAnos.periodo[0][0].total_nf || 0
                        : 0,
                    props.dashboardNotasAnos && props.dashboardNotasAnos.periodo_anterior
                        ? props.dashboardNotasAnos.periodo_anterior[0][0].total_nf || 0
                        : 0
                ),
                loading: props.loadingDashboardNotasAnos,
                series: [
                    {
                        name: "Valor",
                        data:
                            props.dashboardNotasAnos && props.dashboardNotasAnos.meses
                                ? props.dashboardNotasAnos.meses[0].map(e => e.total_nf)
                                : []
                    }
                ],
                diff: describeDiff(
                    props.dashboardNotasAnos && props.dashboardNotasAnos.periodo
                        ? props.dashboardNotasAnos.periodo[0][0].total_nf || 0
                        : 0,
                    props.dashboardNotasAnos && props.dashboardNotasAnos.periodo_anterior
                        ? props.dashboardNotasAnos.periodo_anterior[0][0].total_nf || 0
                        : 0,
                    true
                ),
                options: {
                    chart: {sparkline: {enabled: !0}},
                    stroke: {curve: "smooth", width: 2},
                    colors: ["#f1b44c"],
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: !1,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [25, 100, 100, 100]
                        }
                    },
                    tooltip: {
                        fixed: {enabled: !1},
                        x: {show: !1},
                        marker: {show: !1}
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val, object) {
                                if (object && props.dashboardNotasMeses.meses) {
                                    return (
                                        monthName(
                                            props.dashboardNotasMeses.meses[0][object.dataPointIndex]
                                                .month
                                        ) +
                                        " " +
                                        abbreviateNumber(val)
                                    )
                                }
                            }
                        }
                    }
                }
            }
        ])

        return () => setReports([])
    }, [
        props.dashboardNotasSemana,
        props.dashboardNotasMeses,
        props.dashboardNotasAnos
    ])

    const populateFiltros = (periodoSelecionado, companiesSelected) => {
        const filters = [{id: 'nf', value: 0, condition: Filters.greaterThan}]

        if (companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected) })
        } else if (companies) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies) })
        }

        if (situation) filters.push({id: 'status', value: situation})
        if (tipoNota) filters.push({id: 'tipo_nf', value: tipoNota})

        let periodo = period || periodoSelecionado
        let datasPeloPeriodo = getDatasPeloPeriodo(periodo, startDate, endDate)
        if(datasPeloPeriodo) {
            filters.push({id: 'data_emissao', value: datasPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo})
            filters.push({id: 'data_emissao', value: datasPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo})
        }

        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros();
        setTimeout(() => ref?.current?.search(), 300)
    }

    const onPickStartDate = date => {
        setStartDate(date)
        setEndDate(date)
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Gestão Fiscal" breadcrumbItem="Notas Emitidas"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Row>
                                    <OverView {...{company: companies}} />

                                    <Col xl="8">
                                        <CardWelcome/>

                                        <Row>
                                            <MiniWidget reports={reports}/>
                                        </Row>
                                    </Col>
                                </Row>

                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Notas Emitidas</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-xl col-sm-6">
                                                    <FormFilterCompany
                                                        attribute="companies"
                                                        value={companies}
                                                        description="Empresa(s)"
                                                        setValue={ value => setCompanies(value) }
                                                        options={companiesOptions}
                                                        multiple={ true }
                                                    />
                                                </div>

                                                <FormFilterPeriod
                                                    periodAttribute="period"
                                                    periodDescription="Período"
                                                    periodValue={period}
                                                    periodChangeHandeler={e => setPeriod(e)}
                                                    options={periods}
                                                    placeHolder="Selecione uma data"
                                                    startDateAttribute="startDate"
                                                    startDateDescription="Data Inicial"
                                                    startDateValue={startDate}
                                                    startDateChangeHandeler={e => onPickStartDate(e)}
                                                    endDateAttribute="endDate"
                                                    endDateDescription="Data Final"
                                                    endDateValue={endDate}
                                                    endDateChangeHandeler={e => setEndDate(e)}
                                                />

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="situacao"
                                                        value={situation}
                                                        description="Situação"
                                                        changeHandeler={ value => setSituation(value) }
                                                        options={ [{ value: ""  , label: "Qualquer", defaultValue: true },
                                                            ...(Array.isArray(props.notasSituacoes) ? props.notasSituacoes : []) ] }
                                                    />
                                                </div>
                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="tipoNota}"
                                                        value={tipoNota}
                                                        description="Entrada/Saída"
                                                        changeHandeler={ value => setTipoNota(value) }
                                                        options={ [
                                                            { value: "", label: "Qualquer", defaultValue: true },
                                                            { value: "0", label: "Entrada" },
                                                            { value: "1", label: "Saída" },
                                                        ] }
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6 align-self-start mt-4 pt-1">
                                                    <div className="mt-3">
                                                        <Button color="primary" className="w-md" onClick={e => pesquisar()}
                                                                disabled={!usePermissionListar}>
                                                            Buscar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                { filters && <Card>
                                    <CardBody>
                                        <NotasTable ref={ref} filters={filters} usePermissionListar={usePermissionListar}>
                                        </NotasTable>
                                    </CardBody>
                                </Card> }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

NotasEmitidas.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func
}

const mapStatetoProps = state => {
    const {notasSituacoes, notas} = state.Profile
    const {
        user,
        loadingDashboardNotasSemana,
        dashboardNotasSemana,
        loadingDashboardNotasMeses,
        dashboardNotasMeses,
        loadingDashboardNotasAnos,
        dashboardNotasAnos,
        currentPeriod, dashboardFilters
    } = state.Login
    return {
        notasSituacoes,
        notas,
        user,
        loadingDashboardNotasSemana,
        dashboardNotasSemana,
        loadingDashboardNotasMeses,
        dashboardNotasMeses,
        loadingDashboardNotasAnos,
        dashboardNotasAnos,
        currentPeriod, dashboardFilters
    }
}

export default withTranslation()(connect(mapStatetoProps, {
    buscarNotasSituacoes,
    getDashboardData
})(withRouter(NotasEmitidas)))
