import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import usePermission from "helpers/permission/usePermission"

const LinkRestricted: React.FunctionComponent = ({permissionKey, children, to, className, onClick}) => {

  const allowed = usePermission(permissionKey);

  if(allowed){
    return <Link to={to} className={ className } onClick={ onClick }>{ children }</Link>;
  }

  return <Link to="/#" className={ 'disabled-link ' + {...className} }>{ children }</Link>;
};

export default LinkRestricted;
