import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap"
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import {MDBDataTable} from "mdbreact"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {isArray} from "leaflet/src/core/Util"
import usePermission from "helpers/permission/usePermission"
import {map} from "lodash"
import {AvForm} from "availity-reactstrap-validation"
import MiniCards from "../../components/Common/mini-card"
import {
    buscarGruposProdutoPorNome,
    buscarProdutos,
    buscarQuantidadeProdutos,
    editarProduto,
    navegarEditarProduto,
    relatorioExecutar,
    removerProduto
} from "store/auth/profile/actions"
import moment from "moment-timezone"
import AsyncSelect from "react-select/async"
import {apiBuscarGruposProdutoPesquisa} from "helpers/backend_helper"
import CsvDownloader from "react-csv-downloader";
import LoaderList from "../../components/Common/loaderList";
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import {getCompanies, getCompaniesValuesByOptions} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const ProdutosLista = props => {

    const [inputValue, setInputValue] = useState() // utilizado no auto complete do grupo de produto
    const [companies, setCompanies] = useState([])
    const [status, setStatus] = useState("")
    const [codigoBarras, setCodigoBarras] = useState("")
    const [grupoProduto, setGrupoProduto] = useState()
    const [estoque, setEstoque] = useState("")
    const [miniCards, setMiniCards] = useState([])
    const [companiesOption, setCompaniesOption] = useState([{label: "Todas", value: ""}])
    const usePermissionRemove = usePermission("produtos_excluir")
    const usePermissionInsert = usePermission("produtos_inserir")
    const usePermissionEdit = usePermission("produtos_editar")
    const [modalRemove, setModalRemove] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState()
    const getColumns = (exportRows) => {
        let columns = [
            {label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150},
            {label: "Código", field: "Código", sort: "asc", width: 150},
            {label: "Descrição", field: "Descrição", sort: "asc", width: 270},
            {label: "UN", field: "Unidade", sort: "asc", width: 100},
            {label: "Custo", field: "Custo", sort: "asc", width: 100},
            {label: "Vlr. Venda", field: "Vlr. Venda", sort: "asc", width: 100},
            {label: "Saldo", field: "Saldo", sort: "asc", width: 100},
            {label: "Custo Total", field: "Custo Total", sort: "asc", width: 100},
            {label: "NCM", field: "NCM", sort: "asc", width: 100},
            {label: "CST/CSOSN", field: "CST/CSOSN", sort: "asc", width: 100},
            {label: "ICMS Alíquota", field: "ICMS Alíquota", sort: "asc", width: 100},
            {label: "PIS cst", field: "PIS cst", sort: "asc", width: 100},
            {label: "PIS Alíquota", field: "PIS Alíquota", sort: "asc", width: 100},
            {label: "COFINS cst", field: "COFINS cst", sort: "asc", width: 100},
            {label: "COFINS Alíquota", field: "COFINS Alíquota", sort: "asc", width: 100},
            {label: "Última alteração", field: "Última alteração", sort: "asc", width: 100},
            {label: "Último ajuste preço", field: "Último ajuste preço", sort: "asc", width: 100},
            {label: "Última entrada", field: "Última entrada", sort: "asc", width: 100},
            {label: "Última saída", field: "Última saída", sort: "asc", width: 100},
            {label: "Último Fornecedor", field: "Último Fornecedor", sort: "asc", width: 100},
            {label: "Status", field: "Status", sort: "asc", width: 150},
        ]
        if (!exportRows) {
            columns.push({label: "Ações", field: "Ações", sort: "asc", width: 100})
        }
        return columns;
    }
    const [modalInativar, setModalInativar] = useState(false)

    const [modalAtivar, setModalAtivar] = useState(false)

    const [tableData, setTableData] = useState({
        columns: getColumns(),
        rows: []
    })

    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    useEffect(() => {
        setCompaniesOption(getCompanies(props.user))
        setCompanies(props.dashboardFilters.companiesSelected)
        if (possuiVinculo) {
            doUpdate(props.dashboardFilters.companiesSelected)
        }

        return () => {
            setCompaniesOption([])
            setMiniCards({})
        }
    }, [])

    const popularMiniCards = () => {
        setMiniCards([
            {
                title: "Quantidade produtos",
                iconClass: "bx-data",
                text:
                    props.quantidadeProdutos && props.quantidadeProdutos.total
                        ? String(props.quantidadeProdutos.total)
                        : "0",
                loading: props.produtosLoading
            },
            {
                title: "Produtos ativos",
                iconClass: "bx-bookmark-plus",
                text:
                    props.quantidadeProdutos && props.quantidadeProdutos.ativos
                        ? String(props.quantidadeProdutos.ativos)
                        : "0",
                loading: props.produtosLoading
            },
            {
                title: "Produtos inativas",
                iconClass: "bx-bookmark-minus",
                text:
                    props.quantidadeProdutos && props.quantidadeProdutos.inativos
                        ? String(props.quantidadeProdutos.inativos)
                        : "0",
                loading: props.produtosLoading
            }])
    }

    useEffect(() => {
        popularMiniCards();
        return () => setMiniCards([])
    }, [props.quantidadeProdutos, props.produtosLoading])

    useEffect(() => {
        if (props.produtoLoading === false) {
            doUpdate()
            const grupoProdutoCodigo = grupoProduto?.value;
            props.buscarQuantidadeProdutos({company: getCompaniesValuesByOptions(companies), codigoBarras, grupoProduto: grupoProdutoCodigo, estoque})
        }
    }, [props.produtoLoading])

    const describeDateTime = dateStr => {
        if (!dateStr) {
            return "N/D"
        }

        return moment(dateStr).format("DD/MM/YY HH:mm")
    }
    const getRows = (produtos, exportRows) => {
        const rows = [];
        if (Array.isArray(produtos)) {
            for (const item of produtos) {
                const row = {
                    "ID Cloud": item.id,
                    "Código": item.Codigo,
                    "Descrição": item.Descricao,
                    "Unidade": item.Unidade,
                    "Custo": (
                        exportRows ? (parseFloat(item.Custo || 0).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })) : <div className="text-right">
                            {parseFloat(item.Custo || 0).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                            })}
                        </div>
                    ),
                    "Vlr. Venda": exportRows ? (parseFloat(item.Venda || 0).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL"
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.Venda || 0).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                            })}
                        </div>
                    ),
                    "Saldo": ((item.Anterior || 0) + (item.Aquisicao || 0) - (item.Saida || 0)),
                    "Custo Total": exportRows ? (parseFloat((item.Custo || 0) * ((item.Anterior || 0) + (item.Aquisicao || 0) - (item.Saida || 0))).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL"
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.Custo).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                            })}
                        </div>
                    ),
                    "NCM": item.ncm,
                    "CST/CSOSN": exportRows ? parseFloat(item.icms_cst || 0).toLocaleString("pt-br", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    }) : (
                        <div className="text-right">
                            {parseFloat(item.icms_cst || 0).toLocaleString("pt-br", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })}%
                        </div>
                    ),
                    "ICMS Alíquota": exportRows ? (parseFloat(item.icms_cst_aliquota || 0).toLocaleString("pt-br", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.icms_cst_aliquota || 0).toLocaleString("pt-br", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })}%
                        </div>
                    ),
                    "PIS cst": exportRows ? (parseFloat(item.pis || 0).toLocaleString("pt-br", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.pis || 0).toLocaleString("pt-br", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })}%
                        </div>
                    ),
                    "PIS Alíquota": exportRows ? (parseFloat(item.pis_aliquota || 0).toLocaleString("pt-br", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.pis_aliquota || 0).toLocaleString("pt-br", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })}%
                        </div>
                    ),
                    "COFINS cst": exportRows ? (parseFloat(item.cofins || 0).toLocaleString("pt-br", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.cofins || 0).toLocaleString("pt-br", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })}%
                        </div>
                    ),
                    "COFINS Alíquota": exportRows ? (parseFloat(item.cofins_aliquota || 0).toLocaleString("pt-br", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })) : (
                        <div className="text-right">
                            {parseFloat(item.cofins_aliquota || 0).toLocaleString("pt-br", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            })}%
                        </div>
                    ),
                    "Última alteração": describeDateTime(item.AltData),
                    "Último ajuste preço": describeDateTime(item.Data_Alt_Prc),
                    "Última entrada": describeDateTime(item.Data_Entrada),
                    "Última saída": describeDateTime(item.Data_Saida),
                    "Último Fornecedor": item.fornecedor_razaosocial,
                    Status: exportRows ? (["1", "A"].includes(item.Status) ? "Ativo" : item.Status === "2" ? "Inativo" : "N/A") : (
                        <Badge
                            className={"font-size-12 badge-soft-" + statusBadgeClass(item)}
                            color={statusBadgeClass(item)}
                            pill
                        >
                            {["1", "A"].includes(item.Status) ? "Ativo" : item.Status === "2" ? "Inativo" : "N/A"}
                        </Badge>
                    ),
                };
                if (!exportRows) {
                    row["Ações"] = (
                        <div>
                            {usePermissionEdit && <Link to="#" className="me-3 text-primary">
                                <i
                                    className="mdi mdi-pencil font-size-18 me-3"
                                    id="edittooltip"
                                    onClick={e => goToProduto(item)}
                                    title="Editar"
                                />
                            </Link>}
                            {usePermissionEdit && ["1", "A"].includes(item.status) &&
                                <Link to="#" className="text-warning">
                                    <i className="mdi mdi-bookmark-minus font-size-18 me-3" id="btnInativar"
                                       onClick={() => showConfirmInactivate(item)} title="Inativar"/>
                                </Link>}
                            {usePermissionEdit && item.status === "2" && <Link to="#" className="text-info">
                                <i className="mdi mdi-bookmark-plus font-size-18 me-3" id="btnAtivar"
                                   onClick={() => showConfirmActivate(item)} title="Ativar"/>
                            </Link>}
                            {usePermissionRemove && <Link to="#" className="text-danger">
                                <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                                   onClick={() => showConfirmDelete(item)} title="Remover"/>
                            </Link>}
                        </div>
                    )
                }
                rows.push(row)
            }
        }

        return rows;
    }

    const popularTabela = () => {
        if (isArray(props.produtos)) {
            setTableData({
                columns: getColumns(),
                rows: getRows(props.produtos)
            })
        }
    }

    useEffect(() => {
        popularTabela()
        return () => setTableData({columns: [], rows: []})
    }, [props.produtos, props.produtosLoading])

    const doUpdate = (companiesSelected) => {
        const grupoProdutoCodigo = grupoProduto?.value;
        props.buscarProdutos({
            company: getCompaniesValuesByOptions(companiesSelected ? companiesSelected : companies),
            status,
            codigoBarras,
            grupoProduto: grupoProdutoCodigo,
            estoque
        })

        props.buscarQuantidadeProdutos({company: getCompaniesValuesByOptions(companiesSelected ? companiesSelected : companies), codigoBarras, grupoProduto: grupoProdutoCodigo, estoque})
    }

    const goToProduto = item => {
        props.history.push("/cadastrar-produto")
        props.navegarEditarProduto(item)
    }

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const showConfirmInactivate = item => {
        setItemSelecionado(item)
        setModalInativar(true)
    }

    const showConfirmActivate = item => {
        setItemSelecionado(item)
        setModalAtivar(true)
    }

    const removerProduto = () => {
        props.removerProduto(itemSelecionado)
        setModalRemove(false)
    }

    const inativarProduto = () => {
        props.editarProduto({status: '2', id: itemSelecionado.id})
        setModalInativar(false)
    }

    const ativarProduto = () => {
        props.editarProduto({status: '1', id: itemSelecionado.id})
        setModalAtivar(false)
    }

    const statusBadgeClass = item => {
        switch (item.Status) {
            case "1": {
                return "primary"
            }
            case "A": {
                return "primary"
            }
            case "2": {
                return "warning"
            }
            default: {
                return "secondary"
            }
        }
    }

    const csvDataHeader = () => {
        const columns = getColumns(true)
        return columns.map(column => {
            return {id: column.field, displayName: column.label}
        });
    };

    const csvData = () => {
        const rows = getRows(props.produtos, true)
        return Promise.resolve(rows.map(row => {
            return row
        }));
    };

    const printPDF = () => {
        let reportName = 'produtos';
        const grupoProdutoCodigo = grupoProduto?.value;
        props.relatorioExecutar({
            company: getCompaniesValuesByOptions(companies),
            status,
            codigoBarras,
            grupoProduto: grupoProdutoCodigo,
            estoque,
            reportName
        });
    }

    const buscarGruposProdutoPesquisa = (inputValue, callback) => {
        apiBuscarGruposProdutoPesquisa({empresaId: getCompaniesValuesByOptions(companies), argumento: inputValue})
            .then(results => {
                const options = (results || []).map(({codigo, Descricao}) => {
                    return {label: `${codigo} - ${Descricao}`, value: codigo};
                })

                callback(options)
            })
    }

    if (
        !props.user ||
        !possuiVinculo
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Cadastro" breadcrumbItem="Produtos"/>
                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Produtos</h4>
                                        <Form>
                                            <Row>
                                                {map(miniCards, (card, key) => (
                                                    <MiniCards
                                                        title={card.title}
                                                        text={card.text}
                                                        iconClass={card.iconClass}
                                                        key={"_card_" + key}
                                                        loading={card.loading}
                                                    />
                                                ))}
                                            </Row>
                                            <Row>
                                                <div className="col col-auto">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <FormFilterCompany
                                                            attribute="companies"
                                                            value={companies}
                                                            description="Empresa(s)"
                                                            setValue={ value => setCompanies(value) }
                                                            options={companiesOption}
                                                            multiple={ true }
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-auto">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Status</Label>
                                                        <select
                                                            className="form-select select2-search-disable"
                                                            value={status}
                                                            onChange={e => setStatus(e.target.value)}
                                                        >
                                                            <option value="" defaultValue>
                                                                Qualquer
                                                            </option>
                                                            <option value="A">Ativo</option>
                                                            <option value="I">Inativo</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-2">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Código de barras</Label>
                                                        <Input
                                                            id="codigoBarras"
                                                            name="codigoBarras"
                                                            type="text"
                                                            className="form-control"
                                                            value={codigoBarras}
                                                            onChange={e => setCodigoBarras(e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-3">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Grupo de produtos</Label>
                                                        <AsyncSelect
                                                            id="grupoProduto"
                                                            name="grupoProduto"
                                                            className="basic-single"
                                                            placeholder="Digite o nome do grupo de produto..."
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            value={grupoProduto}
                                                            noOptionsMessage={() => "Nenhuma grupo de produto encontrado"}
                                                            loadingMessage={() => "Carregando"}
                                                            onChange={e => {
                                                                setGrupoProduto(e)
                                                            }}
                                                            cacheOptions
                                                            loadOptions={buscarGruposProdutoPesquisa}
                                                            defaultOptions
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-auto">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Estoque</Label>
                                                        <select
                                                            className="form-select select2-search-disable"
                                                            value={estoque}
                                                            onChange={e => setEstoque(e.target.value)}
                                                        >
                                                            <option value="" defaultValue>
                                                                Todos
                                                            </option>
                                                            <option value="COM">Com Saldo</option>
                                                            <option value="SEM">Sem Saldo</option>
                                                            <option value="NEGATIVO">Saldo Negativo</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={e => doUpdate()}
                                                            disabled={!usePermission("produtos_listar")}>
                                                            Buscar
                                                        </Button>
                                                    </div>

                                                    <div className="mt-3 col col-auto">
                                                        <Button
                                                            type="button"
                                                            color="success"
                                                            disabled={!(usePermissionEdit || usePermissionInsert)}
                                                            onClick={e => goToProduto(null)}>
                                                            <i className="bx bx-plus"/> Cadastrar
                                                        </Button>
                                                    </div>

                                                    {!props.executaRelatorioLoading && !props.produtosLoading &&
                                                        <div className="mt-3 col col-auto">
                                                            <CsvDownloader
                                                                filename="produtos"
                                                                extension=".csv"
                                                                separator=";"
                                                                wrapColumnChar=""
                                                                columns={csvDataHeader()}
                                                                datas={csvData()}>
                                                                <Button type="button" color="light">
                                                                    <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                                                    CSV
                                                                </Button>
                                                            </CsvDownloader>
                                                        </div>}
                                                    {!props.executaRelatorioLoading && !props.produtosLoading &&
                                                        <div className="mt-3 col col-auto">
                                                            <Button type="button" color="light"
                                                                    onClick={e => printPDF()}>
                                                                <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                                                PDF
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                <Card>
                                    { props.produtosLoading || props.executaRelatorioLoading ? (
                                        <LoaderList/>
                                    ) : (
                                        <CardBody>
                                            <MDBDataTable
                                                responsive
                                                striped
                                                bordered
                                                data={tableData}
                                                entriesLabel={"Mostrar Registros"}
                                                infoLabel={["Exibindo", "a", "de", "registros"]}
                                                noRecordsFoundLabel={"Nenhum registro encontrado"}
                                                paginationLabel={["Anterior", "Próximo"]}
                                                searchLabel={"Pesquisar"}
                                            />
                                        </CardBody>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modalRemove}>
                <ModalHeader tag="h4">Confirmação</ModalHeader>
                <ModalBody>
                    <AvForm
                        onValidSubmit={(e, v) => {
                            removerProduto()
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente remover esse registro?
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div className="text-right">
                                    <button type="submit" className="btn btn-success save-event">
                                        Confirmar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light ms-2"
                                        onClick={e => setModalRemove(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalInativar}>
                <ModalHeader tag="h4">Confirmação</ModalHeader>
                <ModalBody>
                    <AvForm
                        onValidSubmit={(e, v) => {
                            inativarProduto()
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente inativar esse registro ?
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div className="text-right">
                                    <button type="submit" className="btn btn-success save-event">
                                        Confirmar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light ms-2"
                                        onClick={e => setModalInativar(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalAtivar}>
                <ModalHeader tag="h4">Confirmação</ModalHeader>
                <ModalBody>
                    <AvForm
                        onValidSubmit={(e, v) => {
                            ativarProduto()
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente ativar esse registro ?
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div className="text-right">
                                    <button type="submit" className="btn btn-success save-event">
                                        Confirmar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light ms-2"
                                        onClick={e => setModalAtivar(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

ProdutosLista.propTypes = {
    produtos: PropTypes.array,
    quantidadeProdutos: PropTypes.any,
    produtosLoading: PropTypes.bool,
    produto: PropTypes.any,
    produtoLoading: PropTypes.bool,
    buscarProdutos: PropTypes.func,
    buscarGruposProdutoPorNome: PropTypes.func,
    removerProduto: PropTypes.func,
    editarProduto: PropTypes.func,
    buscarQuantidadeProdutos: PropTypes.func
}

const mapStatetoProps = state => {
    const {
        produtosLoading,
        produtos,
        produto,
        produtoLoading,
        quantidadeProdutos,
        gruposProduto,
        gruposProdutoLoading,
        executaRelatorioLoading
    } = state.Profile
    const {user, dashboardFilters} = state.Login
    return {
        produtosLoading,
        produtos,
        user,
        produto,
        produtoLoading,
        quantidadeProdutos,
        gruposProduto,
        gruposProdutoLoading,
        executaRelatorioLoading, dashboardFilters
    }
}

export default connect(mapStatetoProps, {
    buscarProdutos,
    buscarGruposProdutoPorNome,
    removerProduto,
    editarProduto,
    navegarEditarProduto,
    buscarQuantidadeProdutos,
    relatorioExecutar

})(withRouter(ProdutosLista))
