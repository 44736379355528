import {
  API_ERROR,
  DASHBOARD_DATA,
  DASHBOARD_FILTERS,
  GET_DASHBOARD_DATA,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SOCIAL_LOGIN
} from "./actionTypes"
import { getOneSignalHelper } from "../../../helpers/onesignal_helper"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history }
  }
}

export const loginSuccess = data => {
  getOneSignalHelper().setUserData(data.user).then()

  return {
    type: LOGIN_SUCCESS,
    payload: { data }
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history }
  }
}

export const logoutUserSuccess = () => {
  getOneSignalHelper().removerUserData().then()

  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type }
  }
}

export const getDashboardData = (
  periodStart = null,
  periodEnd = null,
  companiesId = null,
  type = null
) => {
  return {
    type: GET_DASHBOARD_DATA,
    payload: { periodStart, periodEnd, companiesId, type }
  }
}

export const dashboardData = data => {
  return {
    type: DASHBOARD_DATA,
    payload: data
  }
}

export const setDashboardFilters = filters => {
  return {
    type: DASHBOARD_FILTERS,
    payload: { filters }
  }
}

