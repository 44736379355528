import {all, call, fork, put, takeEvery} from "redux-saga/effects"
import { saveAs } from "file-saver"
// Login Redux States
import {
    ALTERAR_PERFIL,
    ALTERAR_TEMPLATE_EMAIL,
    ALTERAR_VINCULOS,
    BUSCAR_CAIXAS_OPERADORES,
    BUSCAR_CODIGOS_BARRAS,
    BUSCAR_CODIGOS_BARRAS_POR_PRODUTO,
    BUSCAR_CONTAS_PAGAR,
    BUSCAR_CONTAS_PAGAR_ATRASADOS,
    BUSCAR_CONTAS_PAGAR_HOJE,
    BUSCAR_CONTAS_PAGAR_MES,
    BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES,
    BUSCAR_DISPOSITIVO_LOGS,
    BUSCAR_DISPOSITIVOS,
    BUSCAR_DRE,
    BUSCAR_EMPRESA,
    BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA,
    BUSCAR_EMPRESA_POR_USUARIO,
    BUSCAR_ESTOQUE,
    BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE,
    BUSCAR_ESTOQUES,
    BUSCAR_EVOLUCAO_CONTAS_PAGAR,
    BUSCAR_EVOLUCAO_FISCAL,
    BUSCAR_FORNECEDOR,
    BUSCAR_FORNECEDORES,
    BUSCAR_FORNECEDORES_NOME_CNPJ,
    BUSCAR_GRUPO_CARDAPIO,
    BUSCAR_GRUPO_CARDAPIO_ITEM,
    BUSCAR_GRUPO_CARDAPIO_OBSERVACAO,
    BUSCAR_GRUPO_ESTOQUE,
    BUSCAR_GRUPO_PRODUTO,
    BUSCAR_GRUPOS_CARDAPIO,
    BUSCAR_GRUPOS_CARDAPIO_ITEM,
    BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO,
    BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO,
    BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO,
    BUSCAR_GRUPOS_ESTOQUE,
    BUSCAR_GRUPOS_PRODUTO,
    BUSCAR_GRUPOS_PRODUTO_POR_NOME,
    BUSCAR_IMAGEM_PRODUTO,
    BUSCAR_MOVIMENTACAO_CAIXAS,
    BUSCAR_NCM_CATEGORIAS,
    BUSCAR_NCMS,
    BUSCAR_NCMS_DESCRICAO,
    BUSCAR_NOTA,
    BUSCAR_NOTAS,
    BUSCAR_NOTAS_SITUACOES,
    BUSCAR_NOTIFICACOES,
    BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA,
    BUSCAR_NOTIFICACOES_USUARIO,
    BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS,
    BUSCAR_PERFIL,
    BUSCAR_PERFIS,
    BUSCAR_PERMISSAO_PERFIL,
    BUSCAR_PLANO_CONTAS,
    BUSCAR_PRODUTO,
    BUSCAR_PRODUTOS,
    BUSCAR_PRODUTOS_COMPOSICAO,
    BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO,
    BUSCAR_PRODUTOS_REFERENCIA,
    BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO,
    BUSCAR_QUANTIDADE_FORNECEDORES,
    BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO,
    BUSCAR_QUANTIDADE_GRUPOS_PRODUTO,
    BUSCAR_QUANTIDADE_PRODUTOS,
    BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES,
    BUSCAR_SUGESTOES,
    BUSCAR_SUGESTOES_PRODUTOS,
    BUSCAR_SUGESTOES_STATUS,
    BUSCAR_TABELA_CEST_DESCRICAO,
    BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO,
    BUSCAR_TAREFAS_POR_EMPRESA,
    BUSCAR_TEMPLATES_EMAIL,
    BUSCAR_USUARIOS_POR_EMPRESA,
    BUSCAR_VENDAS_ANO,
    BUSCAR_VENDAS_ANO_ANTERIOR,
    BUSCAR_VENDAS_ANO_EVOLUCAO,
    BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO,
    BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS,
    BUSCAR_VENDAS_DIA,
    BUSCAR_VENDAS_MES,
    BUSCAR_VENDAS_MES_ANTERIOR,
    BUSCAR_VENDAS_SEMANA,
    BUSCAR_VINCULOS,
    BUSCAR_VINCULOS_POR_USUARIO,
    CADASTRAR_CATEGORIA,
    CADASTRAR_CATEGORIA_RESULT,
    CADASTRAR_LANCAMENTO,
    CADASTRAR_PERFIL,
    CADASTRAR_PERGUNTA,
    CADASTRAR_PERGUNTA_RESULT,
    CRIAR_CODIGO_BARRAS,
    CRIAR_ESTOQUE,
    CRIAR_ESTOQUE_MANTEM_CADASTRO,
    CRIAR_FORNECEDOR,
    CRIAR_GRUPO_CARDAPIO,
    CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO,
    CRIAR_GRUPO_CARDAPIO_OBSERVACAO,
    CRIAR_GRUPO_ESTOQUE,
    CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO,
    CRIAR_GRUPO_PRODUTO,
    CRIAR_PRODUTO,
    CRIAR_PRODUTO_COMPOSICAO,
    CRIAR_PRODUTO_MANTEM_CADASTRO,
    CRIAR_PRODUTO_REFERENCIA,
    DELETE_NOTIFICATION,
    DO_DELETE_CATEGORY,
    DO_DELETE_QUESTION,
    EDIT_PROFILE,
    EDITAR_CODIGO_BARRAS,
    EDITAR_EMPRESA,
    EDITAR_EMPRESA_CERTIFICADO_DIGITAL,
    EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO,
    EDITAR_ESTOQUE,
    EDITAR_FORNECEDOR,
    EDITAR_GRUPO_CARDAPIO,
    EDITAR_GRUPO_CARDAPIO_OBSERVACAO,
    EDITAR_GRUPO_ESTOQUE,
    EDITAR_GRUPO_PRODUTO,
    EDITAR_LANCAMENTO,
    EDITAR_PRODUTO,
    EDITAR_PRODUTO_COMPOSICAO,
    EDITAR_PRODUTO_REFERENCIA,
    ESTORNAR_PAGAMENTO_LANCAMENTO,
    EXPORTAR_USUARIO_CSV,
    EXPORTAR_USUARIO_CSV_RESULT,
    LIMPAR_DRE,
    LISTAR_FAQS,
    MARCAR_NOTIFICACAO_USUARIO_LIDA,
    MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA,
    NCM,
    NOTIFICACAO_USUARIO_WEBSOCKET,
    PAGAR_LANCAMENTO,
    REJEITAR_VINCULOS,
    RELATORIO_EXECUTAR,
    REMOVE_NCM,
    REMOVER_CODIGO_BARRAS,
    REMOVER_EMPRESA_CERTIFICADO_DIGITAL,
    REMOVER_ESTOQUE,
    REMOVER_FORNECEDOR,
    REMOVER_GRUPO_CARDAPIO,
    REMOVER_GRUPO_CARDAPIO_OBSERVACAO,
    REMOVER_GRUPO_ESTOQUE,
    REMOVER_GRUPO_PRODUTO,
    REMOVER_LANCAMENTO,
    REMOVER_PERFIL,
    REMOVER_PRODUTO,
    REMOVER_PRODUTO_COMPOSICAO,
    REMOVER_PRODUTO_REFERENCIA,
    SET_USER_PREFERENCE,
    SUGESTAO
} from "./actionTypes"
import {
    alterarPerfilResult,
    alterarTemplateEmailResult,
    alterarVinculosResult,
    buscarCodigosBarrasPorProdutoResult,
    buscarCodigosBarrasResult,
    buscarContasPagarAtrasadosResult,
    buscarContasPagarHojeResult,
    buscarContasPagarMesResult,
    buscarContasPagarProximosMesesResult,
    buscarContasPagarResult,
    buscarDREResult,
    buscarEmpresaPorUsuarioResult,
    buscarEmpresaResult,
    buscarEstoqueFilialPorEstoqueResult,
    buscarEstoqueResult,
    buscarEstoquesResult,
    buscarEvolucaoContasPagarResult,
    buscarEvolucaoFiscalResult,
    buscarFornecedoresNomeCNPJResult,
    buscarFornecedoresResult,
    buscarFornecedorResult,
    buscarGrupoCardapioItemResult,
    buscarGrupoCardapioObservacaoResult,
    buscarGrupoCardapioResult,
    buscarGrupoEstoqueResult,
    buscarGrupoProdutoResult,
    buscarGruposCardapioItemPorGrupoResult,
    buscarGruposCardapioItemResult,
    buscarGruposCardapioObservacaoPorGrupoResult,
    buscarGruposCardapioObservacaoResult,
    buscarGruposCardapioResult,
    buscarGruposEstoqueResult,
    buscarGruposProdutoPorNomeResult,
    buscarGruposProdutoResult,
    buscarImagemProdutoResult,
    buscarLicencasPorEmpresaResult,
    buscarNotificacoesSistemaPorEmpresaResult,
    buscarNotificacoesUsuarioNaoLidasResult,
    buscarNotificacoesUsuarioResult,
    buscarPerfilResult,
    buscarPerfisResult,
    buscarPermissaoPerfilResult,
    buscarPlanoContasResult,
    buscarProdutoResult,
    buscarProdutosComposicaoPorProdutoResult,
    buscarProdutosComposicaoResult,
    buscarProdutosReferenciaPorProdutoResult,
    buscarProdutosReferenciaResult,
    buscarProdutosResult,
    buscarQuantidadeFornecedoresResult,
    buscarQuantidadeGruposCardapioResult,
    buscarQuantidadeGruposProdutoResult,
    buscarQuantidadeProdutosResult,
    buscarTabelaUnidadesPorUnidadeDescricaoResult,
    buscarTarefasPorEmpresaResult,
    buscarVendasAnoAnteriorResult,
    buscarVendasAnoEvolucaoPorAtendimentoResult,
    buscarVendasAnoEvolucaoPorStatusResult,
    buscarVendasAnoEvolucaoResult,
    buscarVendasAnoResult,
    buscarVendasDiaResult,
    buscarVendasMesAnteriorResult,
    buscarVendasMesResult,
    buscarVendasSemanaResult,
    buscarVinculosPorUsuarioResult,
    cadastrarCategoriaResult,
    cadastrarCodigoBarrasResult,
    cadastrarEstoqueMantemCadastroResult,
    cadastrarEstoqueResult,
    cadastrarFornecedorResult,
    cadastrarGrupoCardapioMantemCadastroResult,
    cadastrarGrupoCardapioObservacaoResult,
    cadastrarGrupoCardapioResult,
    cadastrarGrupoEstoqueMantemCadastroResult,
    cadastrarGrupoEstoqueResult,
    cadastrarGrupoProdutoResult,
    cadastrarLancamentoResult,
    cadastrarNotificacaoResult,
    cadastrarPerfilResult,
    cadastrarPerguntaResult,
    cadastrarProdutoComposicaoResult,
    cadastrarProdutoMantemCadastroResult,
    cadastrarProdutoReferenciaResult,
    cadastrarProdutoResult,
    caixasOperadores,
    dispositivoLogs,
    dispositivos,
    editarCodigoBarrasResult,
    editarEmpresaCertificadoDigitalResult,
    enviarCertificadoDigitalResult,
    editarEmpresaResult,
    editarEstoqueResult,
    editarFornecedorResult,
    editarGrupoCardapioObservacaoResult,
    editarGrupoCardapioResult,
    editarGrupoEstoqueResult,
    editarGrupoProdutoResult,
    editarLancamentoResult,
    editarProdutoComposicaoResult,
    editarProdutoReferenciaResult,
    editarProdutoResult,
    estornarPagamentoLancamentoResult,
    exportarUsuarioCSVResult,
    limparDREResult,
    listarFaqsResult,
    marcarNotificacaoUsuarioLidaResult,
    marcarNotificacaoUsuarioNaoLidaResult,
    movimentacaoCaixas,
    ncmCategorias,
    ncmResult,
    ncms,
    nota,
    notas,
    notasSituacoes,
    notificacaoUsuarioWebsocketResults,
    notificacoes,
    pagarLancamentoResult,
    profileError,
    profileSuccess,
    quantidadeSolicitacoesPendentes,
    rejeitarVinculosResult,
    relatorioExecutarResult,
    removerCodigoBarrasResult,
    removerEmpresaCertificadoDigitalResult,
    removerEstoqueResult,
    removerFornecedorResult,
    removerGrupoCardapioObservacaoResult,
    removerGrupoCardapioResult,
    removerGrupoEstoqueResult,
    removerGrupoProdutoResult,
    removerLancamentoResult,
    removerPerfilResult,
    removerProdutoComposicaoResult,
    removerProdutoReferenciaResult,
    removerProdutoResult,
    sugestaoResult,
    sugestoes,
    sugestoesProdutos,
    sugestoesStatus,
    templatesEmail
} from "./actions"

//Include Both Helper File with needed methods
import {
    apiAlterarPerfil,
    apiAtualizarNCM,
    apiAtualizarNotificacao,
    apiAtualizarPerfil,
    apiBuscarCaixasOperadores,
    apiBuscarCodigosBarras,
    apiBuscarCodigosBarrasPorProduto,
    apiBuscarContasPagar,
    apiBuscarContasPagarAtrasados,
    apiBuscarContasPagarHoje,
    apiBuscarContasPagarMes,
    apiBuscarContasPagarProximosMeses,
    apiBuscarDispositivoLogs,
    apiBuscarDispositivos,
    apiBuscarDRE,
    apiBuscarEmpresa,
    apiBuscarEvolucaoContasPagar,
    apiBuscarFileGrupoCardapio,
    apiBuscarFileImagemProduto,
    apiBuscarFornecedor,
    apiBuscarFornecedores,
    apiBuscarFornecedoresPesquisa,
    apiBuscarGrupoCardapio,
    apiBuscarGrupoCardapioItem,
    apiBuscarGrupoCardapioObservacao,
    apiBuscarGrupoProduto,
    apiBuscarGruposCardapio,
    apiBuscarGruposCardapioItem,
    apiBuscarGruposCardapioItemPorGrupoCardapio,
    apiBuscarGruposCardapioObservacao,
    apiBuscarGruposCardapioObservacaoPorGrupoCardapio,
    apiBuscarGruposProduto,
    apiBuscarGruposProdutoPesquisa,
    apiBuscarImagemPorProduto,
    apiBuscarLicencasPorEmpresa,
    apiBuscarMovimentacaoCaixas,
    apiBuscarNCMCategorias,
    apiBuscarNCMs,
    apiBuscarNCMsPesquisa,
    apiBuscarNota,
    apiBuscarNotas,
    apiBuscarNotasSituacoes,
    apiBuscarNotificacoesUsuario,
    apiBuscarNotificacoesUsuarioNaoLidas,
    apiBuscarNotificoesSistemaPorEmpresa,
    apiBuscarPermissoesPorPerfil,
    apiBuscarPlanoContas,
    apiBuscarProduto,
    apiBuscarProdutos,
    apiBuscarProdutosComposicao,
    apiBuscarProdutosComposicaoPorProduto,
    apiBuscarProdutosReferencia,
    apiBuscarProdutosReferenciaPorProduto,
    apiBuscarSugestoes,
    apiBuscarSugestoesProdutos,
    apiBuscarSugestoesStatus,
    apiBuscarTabelaCestPesquisa,
    apiBuscarTabelaUnidadesPesquisa,
    apiBuscarTarefasPorEmpresa,
    apiBuscarTemplatesEmail,
    apiBuscarVendasAno,
    apiBuscarVendasDia,
    apiBuscarVendasMes,
    apiBuscarVendasSemana,
    apiCadastrarCategoria,
    apiCadastrarCodigoBarras,
    apiCadastrarFornecedor,
    apiCadastrarGrupoCardapio,
    apiCadastrarGrupoCardapioObservacao,
    apiCadastrarGrupoProduto,
    apiCadastrarImagemProduto,
    apiCadastrarLancamento,
    apiCadastrarNCM,
    apiCadastrarPerfil,
    apiCadastrarPergunta,
    apiCadastrarPermissao,
    apiCadastrarProduto,
    apiCadastrarProdutoComposicao,
    apiCadastrarProdutoReferencia,
    apiCadastrarSugestao,
    apiEditarCodigoBarras,
    apiEditarEmpresa,
    apiEditarFornecedor,
    apiEditarGrupoCardapio,
    apiEditarGrupoCardapioObservacao,
    apiEditarGrupoProduto,
    apiEditarLancamento,
    apiEditarProduto,
    apiEditarProdutoComposicao,
    apiEditarProdutoReferencia,
    apiEstornarPagamentoLancamento,
    apiExcluirCategoria,
    apiExcluirNCM,
    apiExcluirNotificacao,
    apiExcluirPergunta,
    apiInserirFileGrupoCardapio,
    apiInserirFileImagemProduto,
    apiListarFaqs,
    apiMarcarNotificacaoUsuarioLida,
    apiMarcarNotificacaoUsuarioNaoLida,
    apiPagarLancamento,
    apiQuantidadeFornecedores,
    apiQuantidadeGruposCardapio,
    apiQuantidadeGruposProduto,
    apiQuantidadeProdutos,
    apiRelatorioExecutar,
    apiRemoverCodigoBarras,
    apiRemoverFornecedor,
    apiRemoverGrupoCardapio,
    apiRemoverGrupoCardapioObservacao,
    apiRemoverGrupoProduto,
    apiRemoverImagemProduto,
    apiRemoverLancamento,
    apiRemoverPerfil,
    apiRemoverPermissao,
    apiRemoverProduto,
    apiRemoverProdutoComposicao,
    apiRemoverProdutoReferencia,
    apiTemplateEmail,
    apiUsuarioPreferencia,
    apiUsuariosEmpresa,
    getBuscarPerfil,
    getBuscarPerfis,
    apiAlterarVinculos,
    apiBuscarQuantidadeSolicitacoesPendentes,
    apiBuscarVinculosPorUsuario,
    apiRejeitarVinculos,
    apiExportaCSVUsuarios,
    apiBuscarEmpresaPorUsuario,
    apiBuscarGruposEstoque,
    apiBuscarGrupoEstoque,
    apiCadastrarGrupoEstoque,
    apiEditarGrupoEstoque,
    apiRemoverGrupoEstoque,
    apiRemoverEstoque,
    apiEditarEstoque,
    apiCadastrarEstoque,
    apiBuscarEstoque,
    apiBuscarEstoques,
    apiBuscarEstoqueFilialPorEstoque,
    apiInserirFileEmpresaCertificado, apiRemoverEmpresaCertificado, apiBuscarEvolucaoFiscal, apiInserirFileCertificado
} from "../../../helpers/backend_helper"
import swal from "sweetalert"
import {notificacaoResult, usuariosPorEmpresa} from "store/auth/profile/actions"
import {apiBuscarNotificacoes, apiCadastrarNotificacao} from "helpers/backend_helper"
import {NOTIFICACAO} from "store/auth/profile/actionTypes"
import {LOGIN_SUCCESS} from "../login/actionTypes"

function* editProfile({payload: {user}}) {
    try {
        const response = yield call(apiAtualizarPerfil, user)
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
        yield put(profileSuccess(response))
    } catch (error) {
        yield put(profileError(error))
    }
}

function* alterarVinculos({payload: {vinculos, status, perfil}}) {
    try {
        const response = yield call(apiAlterarVinculos, {
            vinculos,
            status,
            perfil
        })

        yield put(alterarVinculosResult(response, vinculos, status))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        yield put(alterarVinculosResult(error, vinculos, status))
        swal({title: "Não foi possível realizar a alteração!", icon: "error"}).then()
    }
}

function* buscarQuantidadeSolicitacoesPendentes() {
    try {
        const response = yield call(apiBuscarQuantidadeSolicitacoesPendentes)
        yield put(quantidadeSolicitacoesPendentes(response))
    } catch (error) {
        yield put(quantidadeSolicitacoesPendentes(error))
    }
}

function* buscarNotasSituacoes() {
    try {
        const response = yield call(apiBuscarNotasSituacoes)
        yield put(notasSituacoes(response))
    } catch (error) {
        yield put(notasSituacoes(error))
    }
}

function* buscarCaixasOperadores() {
    try {
        const response = yield call(apiBuscarCaixasOperadores)
        yield put(caixasOperadores(response))
    } catch (error) {
        yield put(caixasOperadores(error))
    }
}

function* buscarNotas({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarNotas, {...filters, nfDiffZero: true})
        yield put(notas(response))
    } catch (error) {
        yield put(notas(error))
    }
}

function* buscarMovimentacaoCaixas({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarMovimentacaoCaixas, filters)
        yield put(movimentacaoCaixas(response))
    } catch (error) {
        yield put(movimentacaoCaixas(error))
    }
}

function* buscarNCMs({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarNCMs, filters)
        yield put(ncms(response))
    } catch (error) {
        yield put(ncms(error))
    }
}

function* buscarNCMsPorDescricao({payload: {argumento}}) {
    try {
        const response = yield call(apiBuscarNCMsPesquisa(), {argumento})
        yield put(ncms(response))
    } catch (error) {
        yield put(ncms(error))
    }
}

function* buscarNCMCategorias() {
    try {
        const response = yield call(apiBuscarNCMCategorias)
        yield put(ncmCategorias(response))
    } catch (error) {
        yield put(ncmCategorias(error))
    }
}

function* ncm({payload: {data, history}}) {
    try {
        let response
        if (data.id) {
            response = yield call(apiAtualizarNCM, data)
        } else {
            response = yield call(apiCadastrarNCM, data)
        }
        yield put(ncmResult(response))

        swal({
            title: "O NCM foi gravada com sucesso!",
            icon: "success"
        }).then()
        history.push("/tabela-ncm")
    } catch (error) {
        yield put(ncmResult(error))

        swal({
            title: "Gerou erro ao gravar o NCM!",
            icon: "error"
        }).then()
    }
}

function* removerNcm({payload: {data}}) {
    try {
        const response = yield call(apiExcluirNCM, data)
        yield put(ncmResult(response))

        swal({
            title: "O NCM foi removida com sucesso!",
            icon: "success"
        }).then()
    } catch (error) {
        yield put(ncmResult(error))
    }
}

function* buscarNota({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarNota, filters)
        yield put(nota(response))
    } catch (error) {
        yield put(nota(error))
    }
}

function* buscarPerfis() {
    try {
        const response = yield call(getBuscarPerfis)
        yield put(buscarPerfisResult(response))
    } catch (error) {
        yield put(buscarPerfisResult(error))
    }
}

function* buscarPerfil({payload: {data}}) {
    try {
        const response = yield call(getBuscarPerfil, data)
        yield put(buscarPerfilResult(response))
    } catch (error) {
        yield put(buscarPerfilResult(error))
    }
}

function* cadastrarPerfil({payload: {data, permissoes, history}}) {
    try {
        const response = yield call(apiCadastrarPerfil, data)
        yield put(cadastrarPerfilResult(response))

        if (response && response.id && permissoes && permissoes.length > 0) {
            const permissoesAlteradas = permissoes.filter(item => item.update)
            for (const permissao of permissoesAlteradas) {
                if (permissao.idPermissao && !permissao.permissao) {
                    yield call(apiRemoverPermissao, {id: permissao.idPermissao})
                } else if (!permissao.idPermissao && permissao.permissao) {
                    yield call(apiCadastrarPermissao, {idPerfil: response.id, idAcao: permissao.idAcao})
                }
            }
        }

        swal({title: "Perfil criado com sucesso!", icon: "success"}).then()
        history.push("/perfil")
    } catch (error) {
        yield put(cadastrarPerfilResult(error))
        swal({
            title: "Gerou erro ao gravar o perfil e permissões!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
    }
}

function* alterarPerfil({payload: {data, permissoes, history}}) {
    try {
        const response = yield call(apiAlterarPerfil, data)
        yield put(alterarPerfilResult(response))

        if (permissoes && permissoes.length > 0) {
            const permissoesAlteradas = permissoes.filter(item => item.update)
            for (const permissao of permissoesAlteradas) {
                if (permissao.idPermissao && !permissao.permissao) {
                    yield call(apiRemoverPermissao, {id: permissao.idPermissao})
                } else if (!permissao.idPermissao && permissao.permissao) {
                    yield call(apiCadastrarPermissao, {idPerfil: permissao.idPerfil, idAcao: permissao.idAcao})
                }
            }
        }

        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
        history.push("/perfil")
    } catch (error) {
        yield put(alterarPerfilResult(error))

        swal({
            title: "Gerou erro ao alterar o perfil e permissões!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
    }
}

function* removerPerfil({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverPerfil, data)
        yield put(removerPerfilResult(response))

        swal({title: "Removido perfil com sucesso realizado com sucesso!", icon: "success"}).then()
        history.push("/perfil")
    } catch (error) {
        yield put(removerPerfilResult(error))

        swal({
            title: "Gerou erro ao remover o perfil e permissões!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
    }
}

function* cadastrarCategoria({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarCategoria, data)
        yield put(cadastrarCategoriaResult(response))

        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
        history.push("/perguntas-frequentes")
    } catch (error) {
        yield put(cadastrarCategoriaResult(error))
    }
}

function* deleteCategory({payload: {data}}) {
    try {
        const response = yield call(apiExcluirCategoria, data)
        yield put(cadastrarCategoriaResult(response))

        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        yield put(cadastrarCategoriaResult(error))
    }
}

function* deleteQuestion({payload: {data}}) {
    try {
        const response = yield call(apiExcluirPergunta, data)
        yield put(cadastrarPerguntaResult(response))

        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        yield put(cadastrarCategoriaResult(error))
    }
}

function* cadastrarPergunta({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarPergunta, data)
        yield put(cadastrarPerguntaResult(response))

        swal({title: "Alteração realizado com sucesso!", icon: "success"})
        history.push("/perguntas-frequentes")
    } catch (error) {
        yield put(cadastrarPerguntaResult(error))
    }
}

function* listarFaqs() {
    try {
        const response = yield call(apiListarFaqs)
        yield put(listarFaqsResult(response))
    } catch (error) {
        yield put(listarFaqsResult(error))
    }
}

function* buscarSugestoesStatus({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarSugestoesStatus, filters)
        yield put(sugestoesStatus(response))
    } catch (error) {
        yield put(sugestoesStatus(error))
    }
}

function* buscarSugestoesProdutos({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarSugestoesProdutos, filters)
        yield put(sugestoesProdutos(response))
    } catch (error) {
        yield put(sugestoesProdutos(error))
    }
}

function* buscarSugestoes() {
    try {
        const response = yield call(apiBuscarSugestoes)
        yield put(sugestoes(response))
    } catch (error) {
        yield put(sugestoes(error))
    }
}

function* sugestao({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarSugestao, data)
        yield put(sugestaoResult(response))

        swal({
            title: "A sua sugestão foi enviada com sucesso! Muito obrigado!",
            icon: "success"
        }).then()
        history.push("/sugestoes")
    } catch (error) {
        swal({
            title: "Gerou erro ao enviar a sugestão!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(cadastrarPerguntaResult(error))
    }
}

function* setUserPreference({payload: {key, value}}) {
    try {
        yield call(apiUsuarioPreferencia, {key, value})
    } catch (error) {
        console.log(error)
    }
}

function* buscarNotificacoes() {
    try {
        const response = yield call(apiBuscarNotificacoes)
        yield put(notificacoes(response))
    } catch (error) {
        yield put(notificacoes(error))
    }
}

function* notificacao({payload: {data, history}}) {
    try {
        let response
        if (data.id) {
            response = yield call(apiAtualizarNotificacao, data)
        } else {
            response = yield call(apiCadastrarNotificacao, data)
        }
        yield put(notificacaoResult(response))

        swal({
            title: "A sua notificação foi gravada com sucesso! Muito obrigado!",
            icon: "success"
        }).then()
        history.push("/notificacoes")
    } catch (error) {
        yield put(cadastrarNotificacaoResult(error))
    }
}

function* removerNotificacao({payload: {data}}) {
    try {
        const response = yield call(apiExcluirNotificacao, data)
        yield put(cadastrarNotificacaoResult(response))

        swal({
            title: "A sua notificação foi removida com sucesso!",
            icon: "success"
        }).then()
    } catch (error) {
        yield put(cadastrarNotificacaoResult(error))
    }
}

function* buscarUsuariosPorEmpresa({payload}) {
    try {
        const response = yield call(apiUsuariosEmpresa, payload.filters)
        yield put(usuariosPorEmpresa(response))
    } catch (error) {
        yield put(usuariosPorEmpresa(error))
    }
}

function* buscarNotificacoesUsuario({payload: {data}}) {
    try {
        const response = yield call(apiBuscarNotificacoesUsuario, data)
        yield put(buscarNotificacoesUsuarioResult(response))
    } catch (error) {
        yield put(buscarNotificacoesUsuarioResult(error))
    }
}

function* buscarNotificacoesUsuarioNaoLidas() {
    try {
        const response = yield call(apiBuscarNotificacoesUsuarioNaoLidas)
        yield put(buscarNotificacoesUsuarioNaoLidasResult(response))
    } catch (error) {
        yield put(buscarNotificacoesUsuarioNaoLidasResult(error))
    }
}

function* marcarNotificacaoUsuarioLida({payload}) {
    try {
        const response = yield call(apiMarcarNotificacaoUsuarioLida, payload.data)
        yield put(
            marcarNotificacaoUsuarioLidaResult({response, data: payload.data})
        )
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        yield put(marcarNotificacaoUsuarioLidaResult(error))
    }
}

function* marcarNotificacaoUsuarioNaoLida({payload}) {
    try {
        const response = yield call(
            apiMarcarNotificacaoUsuarioNaoLida,
            payload.data
        )
        yield put(
            marcarNotificacaoUsuarioNaoLidaResult({response, data: payload.data})
        )
        swal({title: "Alteração realizado com sucesso!", icon: "success"})
    } catch (error) {
        yield put(marcarNotificacaoUsuarioNaoLidaResult(error))
    }
}

function* notificacaoUsuarioWebsocket({payload}) {
    if (payload && payload.data && payload.data.notificacaoUsuario) {
        if (Array.isArray(payload.data.pendingNotificacoesUsuario)) {
            if (
                !payload.data.pendingNotificacoesUsuario.some(
                    notificacaoUsuario =>
                        notificacaoUsuario.id === payload.data.notificacaoUsuario.id
                )
            ) {
                yield put(
                    notificacaoUsuarioWebsocketResults(payload.data.notificacaoUsuario)
                )
            } else if (payload.data.pendingNotificacoesUsuario.length === 0) {
                yield put(
                    notificacaoUsuarioWebsocketResults(payload.data.notificacaoUsuario)
                )
            }
        } else {
            yield put(
                notificacaoUsuarioWebsocketResults(payload.data.notificacaoUsuario)
            )
        }
    }
}

function* buscarDispositivos() {
    try {
        const response = yield call(apiBuscarDispositivos)
        yield put(dispositivos(response))
    } catch (error) {
        yield put(dispositivos(error))
    }
}

function* buscarDispositivoLogs({payload: {filters}}) {
    try {
        const response = yield call(apiBuscarDispositivoLogs, filters)
        yield put(dispositivoLogs(response))
    } catch (error) {
        yield put(dispositivoLogs(error))
    }
}

function* buscarTemplatesEmail() {
    try {
        const response = yield call(apiBuscarTemplatesEmail)
        yield put(templatesEmail(response))
    } catch (error) {
        yield put(templatesEmail(error))
    }
}

function* alterarTemplateEmail({payload: {data}}) {
    try {
        const response = yield call(apiTemplateEmail, data)
        yield put(alterarTemplateEmailResult(response))

        swal({
            title: "O template de e-mail foi gravado com sucesso!",
            icon: "success"
        })
    } catch (error) {
        yield put(alterarTemplateEmailResult(error))
    }
}

function* buscarDRE({payload: {vinculosId, periodStart, periodEnd, verticalAnalyze, horizontalAnalyze, type}}) {
    try {
        const response = yield call(apiBuscarDRE, {
            vinculosId,
            periodStart,
            periodEnd,
            verticalAnalyze,
            horizontalAnalyze,
            type
        })
        yield put(buscarDREResult(response))
    } catch (error) {
        yield put(buscarDREResult(error))
    }
}

function* relatorioExecutar({payload: {data}}) {
    try {
        const response = yield call(apiRelatorioExecutar, data)

        const request = new Request(response.url, {
            headers: {
                Accept: "application/pdf",
                "Content-Type": "application/pdf",
            },
            method: 'GET'
        })
        fetch(request).then((response) => {

            return response.blob()
        }).then((blob) => {
            const file = new Blob([blob], {
                type: 'application/pdf',
            })

            const url = window.URL.createObjectURL(file)
            const link = document.createElement('a')
            link.setAttribute('download', `${data.reportName}.pdf`)
            link.href = url
            document.body.appendChild(link)
            link.click()
        })

        yield put(relatorioExecutarResult(response))
    } catch (error) {
        yield put(relatorioExecutarResult(error))
    }
}

function* limparDRE({payload: {}}) {
    yield put(limparDREResult())
}

function* buscarContasPagar({
                                payload: {
                                    emissaoPeriod,
                                    emissaoStartDate,
                                    emissaoEndDate,
                                    vencimentoPeriod,
                                    vencimentoStartDate,
                                    vencimentoEndDate,
                                    situation,
                                    tipoConta,
                                    codigoFornecedor,
                                    company
                                }
                            }) {
    try {
        const response = yield call(apiBuscarContasPagar, {
            emissaoPeriod, emissaoStartDate, emissaoEndDate, vencimentoPeriod, vencimentoStartDate, vencimentoEndDate,
            situation, tipoConta, codigoFornecedor, company
        })
        yield put(buscarContasPagarResult(response))
    } catch (error) {
        yield put(buscarContasPagarResult(error))
    }
}

function* buscarContasPagarHoje({payload: {company}}) {
    try {
        const response = yield call(apiBuscarContasPagarHoje, {company})
        yield put(buscarContasPagarHojeResult(response))
    } catch (error) {
        yield put(buscarContasPagarHojeResult(error))
    }
}

function* buscarContasPagarMes({payload: {company}}) {
    try {
        const response = yield call(apiBuscarContasPagarMes, {company})
        yield put(buscarContasPagarMesResult(response))
    } catch (error) {
        yield put(buscarContasPagarMesResult(error))
    }
}

function* buscarContasPagarProximosMeses({payload: {company}}) {
    try {
        const response = yield call(apiBuscarContasPagarProximosMeses, {company})
        yield put(buscarContasPagarProximosMesesResult(response))
    } catch (error) {
        yield put(buscarContasPagarProximosMesesResult(error))
    }
}

function* buscarContasPagarAtrasados({payload: {company}}) {
    try {
        const response = yield call(apiBuscarContasPagarAtrasados, {company})
        yield put(buscarContasPagarAtrasadosResult(response))
    } catch (error) {
        yield put(buscarContasPagarAtrasadosResult(error))
    }
}

function* buscarEvolucaoContasPagar({payload: {company}}) {
    try {
        const response = yield call(apiBuscarEvolucaoContasPagar, {company})
        yield put(buscarEvolucaoContasPagarResult(response))
    } catch (error) {
        yield put(buscarEvolucaoContasPagarResult(error))
    }
}

function* buscarPlanoContas({payload: {company}}) {
    try {
        const response = yield call(apiBuscarPlanoContas, {empresaId: company})
        yield put(buscarPlanoContasResult(response))
    } catch (error) {
        yield put(buscarPlanoContasResult(error))

    }
}

function* buscarFornecedoresPorNomeCNPJ({payload: {company, argumento}}) {
    try {
        const response = yield call(apiBuscarFornecedoresPesquisa, {empresaId: company, argumento})
        yield put(buscarFornecedoresNomeCNPJResult(response))
    } catch (error) {
        yield put(buscarFornecedoresNomeCNPJResult(error))
    }
}

function* buscarFornecedores({payload: {company, status}}) {
    try {
        const response = yield call(apiBuscarFornecedores, {empresaId: company, status: status})
        yield put(buscarFornecedoresResult(response))
    } catch (error) {
        yield put(buscarFornecedoresResult(error))
    }
}

function* buscarFornecedor({payload: {data}}) {
    try {
        const response = yield call(apiBuscarFornecedor, data)
        yield put(buscarFornecedorResult(response))
    } catch (error) {
        yield put(buscarFornecedorResult(error))
    }
}

function* buscarQuantidadeFornecedores({payload: {company}}) {
    try {
        const response = yield call(apiQuantidadeFornecedores, {empresaId: company})
        yield put(buscarQuantidadeFornecedoresResult(response))
    } catch (error) {
        yield put(buscarQuantidadeFornecedoresResult(error))
    }
}

function* cadastrarFornecedor({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarFornecedor, data)
        yield put(cadastrarFornecedorResult(response))
        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/fornecedores")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao cadastrar o fornecedor!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        // yield put(cadastrarFornecedorResult(error))
    }
}

function* editarFornecedor({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarFornecedor, data)
        yield put(editarFornecedorResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/fornecedores")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o fornecedor!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        // yield put(editarFornecedorResult(error))
    }
}

function* removerFornecedor({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverFornecedor, data)
        yield put(removerFornecedorResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/fornecedores")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o fornecedor!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerFornecedorResult(error))
    }
}


function* cadastrarLancamento({payload: {data}}) {
    try {
        const response = yield call(apiCadastrarLancamento, data)
        yield put(cadastrarLancamentoResult(response))

        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        yield put(cadastrarLancamentoResult(error))
    }
}

function* editarLancamento({payload: {data}}) {
    try {
        const response = yield call(apiEditarLancamento, data)
        yield put(editarLancamentoResult(response))

        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        yield put(editarLancamentoResult(error))
    }
}

function* removerLancamento({payload: {data}}) {
    try {
        const response = yield call(apiRemoverLancamento, data)
        yield put(removerLancamentoResult(response))

        swal({title: "Lançamento removido com sucesso!", icon: "success"})
    } catch (error) {
        yield put(removerLancamentoResult(error))
    }
}

function* pagarLancamento({payload: {data}}) {
    try {
        const response = yield call(apiPagarLancamento, data)
        yield put(pagarLancamentoResult(response))

        swal({title: "Pagamento efetuado com sucesso!", icon: "success"})
    } catch (error) {
        yield put(pagarLancamentoResult(error))
    }
}

function* estornarPagamentoLancamento({payload: {data}}) {
    try {
        const response = yield call(apiEstornarPagamentoLancamento, data)
        yield put(estornarPagamentoLancamentoResult(response))

        swal({title: "Estorno efetuado com sucesso!", icon: "success"})
    } catch (error) {
        yield put(estornarPagamentoLancamentoResult(error))
    }
}

function* buscarPermissaoPerfil({payload: {data}}) {
    try {
        const response = yield call(apiBuscarPermissoesPorPerfil, data)
        yield put(buscarPermissaoPerfilResult(response))
    } catch (error) {
        yield put(buscarPermissaoPerfilResult(error))
    }
}

function* buscarGruposProduto({payload: {company}}) {
    try {
        const response = yield call(apiBuscarGruposProduto, {empresaId: company})
        yield put(buscarGruposProdutoResult(response))
    } catch (error) {
        yield put(buscarGruposProdutoResult(error))
    }
}

function* buscarGruposProdutoPorNome({payload: {company, argumento}}) {
    try {
        const response = yield call(apiBuscarGruposProdutoPesquisa, {empresaId: company, argumento})
        yield put(buscarGruposProdutoPorNomeResult(response))
    } catch (error) {
        yield put(buscarGruposProdutoPorNomeResult(error))
    }
}

function* buscarGrupoProduto({payload: {data}}) {
    try {
        const response = yield call(apiBuscarGrupoProduto, data)
        yield put(buscarGrupoProdutoResult(response))
    } catch (error) {
        yield put(buscarGrupoProdutoResult(error))
    }
}

function* buscarQuantidadeGruposProduto({payload: {company}}) {
    try {
        const response = yield call(apiQuantidadeGruposProduto, {empresaId: company})
        yield put(buscarQuantidadeGruposProdutoResult(response))
    } catch (error) {
        yield put(buscarQuantidadeGruposProdutoResult(error))
    }
}

function* cadastrarGrupoProduto({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarGrupoProduto, data)
        yield put(cadastrarGrupoProdutoResult(response))
        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-produto")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao cadastrar o grupo de produto!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        // yield put(cadastrarGrupoProdutoResult(error))
    }
}

function* editarGrupoProduto({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarGrupoProduto, data)
        yield put(editarGrupoProdutoResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-produto")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o grupo de produto!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        // yield put(editarGrupoProdutoResult(error))
    }
}

function* removerGrupoProduto({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverGrupoProduto, data)
        yield put(removerGrupoProdutoResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-produto")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o grupo de produto!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerGrupoProdutoResult(error))
    }
}

function* buscarProdutos({payload: {companies, status, codigoBarras, grupoProduto, estoque}}) {
    try {
        const response = yield call(apiBuscarProdutos, {
            companies,
            status: status,
            codigoBarras,
            grupoProdutoCodigo: grupoProduto,
            estoque
        })
        yield put(buscarProdutosResult(response))
    } catch (error) {
        yield put(buscarProdutosResult(error))
    }
}

function* buscarProduto({payload: {data}}) {
    try {
        const response = yield call(apiBuscarProduto, data)
        yield put(buscarProdutoResult(response))
    } catch (error) {
        yield put(buscarProdutoResult(error))
    }
}

function* buscarQuantidadeProdutos({payload: {data}}) {
    try {
        const response = yield call(apiQuantidadeProdutos, data)
        yield put(buscarQuantidadeProdutosResult(response))
    } catch (error) {
        yield put(buscarQuantidadeProdutosResult(error))
    }
}

function* buscarImagemProduto({payload: {company, codigoProduto}}) {
    try {
        const response = yield call(apiBuscarImagemPorProduto, {empresaId: company, produto: codigoProduto})

        if (Array.isArray(response)) {
            for (const imagem of response) {
                const fileImagem = yield call(apiBuscarFileImagemProduto, {id: imagem.id})
                imagem.file = fileImagem
            }
        }

        yield put(buscarImagemProdutoResult(response))
    } catch (error) {
        yield put(buscarImagemProdutoResult(error))
    }
}

function* cadastrarFileImagemProduto(id, imagem) {
    const formData = new FormData()
    formData.append(`file`, imagem.file)

    yield call(apiInserirFileImagemProduto, id, formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
}

function* cadastrarImagemProduto(data, imagem) {
    const imagemData = {idEmpresa: data.idEmpresa, Codigo: data.Codigo, nome: imagem.nome}

    if (!imagem.id) {
        let response = yield call(apiCadastrarImagemProduto, imagemData)
        imagem.id = response.id
    }

    yield cadastrarFileImagemProduto(imagem.id, imagem)
}

function* removerImagemProduto(data, imagem) {
    yield call(apiRemoverImagemProduto, imagem)
}

function* cadastrarProduto({payload: {data, history, mantemCadastro}}) {
    try {
        const imagem = data.imagem
        delete data.imagem

        const response = yield call(apiCadastrarProduto, data)

        if (imagem) {
            if (imagem.id && imagem.remover) {
                yield removerImagemProduto(data, imagem)
            } else if (!imagem.remover) {
                yield cadastrarImagemProduto(data, imagem)
            }
        }

        if (mantemCadastro) {
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
            yield put(cadastrarProdutoMantemCadastroResult(true))
        } else {
            yield put(cadastrarProdutoResult())
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then(
                () => {
                    if (history) history.push("/produtos")
                }
            )
        }
    } catch (error) {
        let mensagem = null
        if (error?.response?.data?.original) {
            mensagem = error?.response?.data?.original.sqlMessage
        } else {
            mensagem = error?.response?.data
        }

        swal({title: "Gerou erro ao cadastrar o produto!", icon: "error", text: mensagem}).then()
        if (mantemCadastro) {
            yield put(cadastrarProdutoMantemCadastroResult(error))
        } else {
            yield put(cadastrarProdutoResult(error))
        }
    }
}

function* editarProduto({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarProduto, data)
        const imagem = data.imagem

        delete data.imagem
        if (imagem) {

            if (imagem.id && imagem.remover) {
                yield removerImagemProduto(data, imagem)
            } else if (!imagem.remover) {
                yield cadastrarImagemProduto(data, imagem)
            }
        }

        yield put(editarProdutoResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/produtos")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o produto!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarProdutoResult(error))
    }
}

function* removerProduto({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverProduto, data)
        yield put(removerProdutoResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/produtos")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o produto!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerProdutoResult(error))
    }
}

function* buscarTabelaUnidadesPorUnidadeDescricao({payload: {company, argumento}}) {
    try {
        const response = yield call(apiBuscarTabelaUnidadesPesquisa, {empresaId: company, argumento})
        yield put(buscarTabelaUnidadesPorUnidadeDescricaoResult(response))
    } catch (error) {
        yield put(buscarTabelaUnidadesPorUnidadeDescricaoResult(error))
    }
}

function* buscarTabelaCestPorCestDescricao({payload: {argumento}}) {
    try {
        const response = yield call(apiBuscarTabelaCestPesquisa, {argumento})
        yield put(buscarTabelaUnidadesPorUnidadeDescricaoResult(response))
    } catch (error) {
        yield put(buscarTabelaUnidadesPorUnidadeDescricaoResult(error))
    }
}

function* buscarCodigosBarras({payload: {company}}) {
    try {
        const response = yield call(apiBuscarCodigosBarras, {empresaId: company})
        yield put(buscarCodigosBarrasResult(response))
    } catch (error) {
        yield put(buscarCodigosBarrasResult(error))
    }
}

function* buscarCodigosBarrasPorProduto({payload: {company, codigoProduto}}) {
    try {
        const response = yield call(apiBuscarCodigosBarrasPorProduto, {empresaId: company, produto: codigoProduto})
        yield put(buscarCodigosBarrasPorProdutoResult(response))
    } catch (error) {
        yield put(buscarCodigosBarrasPorProdutoResult(error))
    }
}

function* cadastrarCodigoBarras({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarCodigoBarras, data)
        yield put(cadastrarCodigoBarrasResult(response))
        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao cadastrar o codigo barras!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(cadastrarCodigoBarrasResult(error))
    }
}

function* editarCodigoBarras({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarCodigoBarras, data)
        yield put(editarCodigoBarrasResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o codigo barras!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarCodigoBarrasResult(error))
    }
}

function* removerCodigoBarras({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverCodigoBarras, data)
        yield put(removerCodigoBarrasResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o codigo barras!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerCodigoBarrasResult(error))
    }
}

function* buscarProdutosReferencia({payload: {company}}) {
    try {
        const response = yield call(apiBuscarProdutosReferencia, {empresaId: company})
        yield put(buscarProdutosReferenciaResult(response))
    } catch (error) {
        yield put(buscarProdutosReferenciaResult(error))
    }
}

function* buscarProdutosReferenciaPorProduto({payload: {company, codigoProduto}}) {
    try {
        const response = yield call(apiBuscarProdutosReferenciaPorProduto, {empresaId: company, produto: codigoProduto})
        yield put(buscarProdutosReferenciaPorProdutoResult(response))
    } catch (error) {
        yield put(buscarProdutosReferenciaPorProdutoResult(error))
    }
}

function* cadastrarProdutoReferencia({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarProdutoReferencia, data)
        yield put(cadastrarProdutoReferenciaResult(response))
        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao cadastrar a referência!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(cadastrarProdutoReferenciaResult(error))
    }
}

function* editarProdutoReferencia({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarProdutoReferencia, data)
        yield put(editarProdutoReferenciaResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao editar a referência!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarProdutoReferenciaResult(error))
    }
}

function* removerProdutoReferencia({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverProdutoReferencia, data)
        yield put(removerProdutoReferenciaResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir a referência!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerProdutoReferenciaResult(error))
    }
}

function* buscarProdutosComposicao({payload: {company}}) {
    try {
        const response = yield call(apiBuscarProdutosComposicao, {empresaId: company})
        yield put(buscarProdutosComposicaoResult(response))
    } catch (error) {
        yield put(buscarProdutosComposicaoResult(error))
    }
}

function* buscarProdutosComposicaoPorProduto({payload: {company, codigoProduto}}) {
    try {
        const response = yield call(apiBuscarProdutosComposicaoPorProduto, {empresaId: company, produto: codigoProduto})
        yield put(buscarProdutosComposicaoPorProdutoResult(response))
    } catch (error) {
        yield put(buscarProdutosComposicaoPorProdutoResult(error))
    }
}

function* cadastrarProdutoComposicao({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarProdutoComposicao, data)
        yield put(cadastrarProdutoComposicaoResult(response))
        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao cadastrar a composição!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(cadastrarProdutoComposicaoResult(error))
    }
}

function* editarProdutoComposicao({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarProdutoComposicao, data)
        yield put(editarProdutoComposicaoResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao editar a composição!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarProdutoComposicaoResult(error))
    }
}

function* removerProdutoComposicao({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverProdutoComposicao, data)
        yield put(removerProdutoComposicaoResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir a composição!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerProdutoComposicaoResult(error))
    }
}

function* buscarGruposCardapio({payload: {company, status, codigoBarras, grupoProduto, estoque}}) {
    try {
        const response = yield call(apiBuscarGruposCardapio, {
            empresaId: company,
            status: status,
            codigoBarras,
            grupoProdutoCodigo: grupoProduto,
            estoque
        })
        yield put(buscarGruposCardapioResult(response))
    } catch (error) {
        yield put(buscarGruposCardapioResult(error))
    }
}

function* buscarGrupoCardapio({payload: {data}}) {
    try {
        const response = yield call(apiBuscarGrupoCardapio, data)

        if (response) {
            try {
                const fileImagem = yield call(apiBuscarFileGrupoCardapio, {id: response.id})
                response.Imagem = {nome: response.Descricao, file: fileImagem}
            } catch (error) {
            }
        }

        yield put(buscarGrupoCardapioResult(response))
    } catch (error) {
        yield put(buscarGrupoCardapioResult(error))
    }
}

function* buscarQuantidadeGruposCardapio({payload: {data}}) {
    try {
        const response = yield call(apiQuantidadeGruposCardapio, data)
        yield put(buscarQuantidadeGruposCardapioResult(response))
    } catch (error) {
        yield put(buscarQuantidadeGruposCardapioResult(error))
    }
}

function* cadastrarGrupoCardapio({payload: {data, history, mantemCadastro}}) {
    try {
        const imagem = data.Imagem
        data.Imagem = null

        const response = yield call(apiCadastrarGrupoCardapio, data)

        if (imagem) {
            yield cadastrarFileGrupoCardapio(response.id, imagem)
        }

        if (mantemCadastro) {
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
            yield put(cadastrarGrupoCardapioMantemCadastroResult(true))
        } else {
            yield put(cadastrarGrupoCardapioResult(response))
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then(
                () => {
                    if (history) history.push("/grupos-cardapio")
                }
            )
        }
    } catch (error) {
        let mensagem = null
        if (error?.response?.data?.original) {
            mensagem = error?.response?.data?.original.sqlMessage
        } else {
            mensagem = error?.response?.data
        }

        swal({title: "Gerou erro ao cadastrar o grupo do cardápio!", icon: "error", text: mensagem}).then()
        if (mantemCadastro) {
            yield put(cadastrarGrupoCardapioMantemCadastroResult(error))
        } else {
            yield put(cadastrarGrupoCardapioResult(error))
        }
    }
}

function* editarGrupoCardapio({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarGrupoCardapio, data)
        const imagem = data.Imagem
        data.Imagem = null

        if (imagem) {
            yield cadastrarFileGrupoCardapio(data.id, imagem)
        }

        yield put(editarGrupoCardapioResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-cardapio")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o grupo do cardápio!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarGrupoCardapioResult(error))
    }
}

function* removerGrupoCardapio({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverGrupoCardapio, data)
        yield put(removerGrupoCardapioResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-cardapio")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o grupo do cardápio!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerGrupoCardapioResult(error))
    }
}

function* cadastrarFileGrupoCardapio(id, imagem) {
    const formData = new FormData()
    formData.append(`file`, imagem.file)

    yield call(apiInserirFileGrupoCardapio, id, formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
}

function* buscarGruposCardapioObservacao({payload: {company}}) {
    try {
        const response = yield call(apiBuscarGruposCardapioObservacao, {empresaId: company})
        yield put(buscarGruposCardapioObservacaoResult(response))
    } catch (error) {
        yield put(buscarGruposCardapioObservacaoResult(error))
    }
}

function* buscarGruposCardapioObservacaoPorGrupo({payload: {company, codigoGrupoCardapio}}) {
    try {
        const response = yield call(apiBuscarGruposCardapioObservacaoPorGrupoCardapio, {
            empresaId: company,
            grupoCardapio: codigoGrupoCardapio
        })
        yield put(buscarGruposCardapioObservacaoPorGrupoResult(response))
    } catch (error) {
        yield put(buscarGruposCardapioObservacaoPorGrupoResult(error))
    }
}

function* buscarGrupoCardapioObservacao({payload: {company, codigoProduto}}) {
    try {
        const response = yield call(apiBuscarGrupoCardapioObservacao, {empresaId: company, produto: codigoProduto})
        yield put(buscarGrupoCardapioObservacaoResult(response))
    } catch (error) {
        yield put(buscarGrupoCardapioObservacaoResult(error))
    }
}

function* cadastrarGrupoCardapioObservacao({payload: {data, history}}) {
    try {
        const response = yield call(apiCadastrarGrupoCardapioObservacao, data)
        yield put(cadastrarGrupoCardapioObservacaoResult(response))
        swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao cadastrar a composição!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(cadastrarGrupoCardapioObservacaoResult(error))
    }
}

function* editarGrupoCardapioObservacao({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarGrupoCardapioObservacao, data)
        yield put(editarGrupoCardapioObservacaoResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao editar a composição!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarGrupoCardapioObservacaoResult(error))
    }
}

function* removerGrupoCardapioObservacao({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverGrupoCardapioObservacao, data)
        yield put(removerGrupoCardapioObservacaoResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir a composição!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerGrupoCardapioObservacaoResult(error))
    }
}

function* buscarGruposCardapioItem({payload: {company}}) {
    try {
        const response = yield call(apiBuscarGruposCardapioItem, {empresaId: company})
        yield put(buscarGruposCardapioItemResult(response))
    } catch (error) {
        yield put(buscarGruposCardapioItemResult(error))
    }
}

function* buscarGruposCardapioItemPorGrupo({payload: {company, codigoGrupoCardapio}}) {
    try {
        const response = yield call(apiBuscarGruposCardapioItemPorGrupoCardapio, {
            empresaId: company,
            grupoCardapio: codigoGrupoCardapio
        })
        yield put(buscarGruposCardapioItemPorGrupoResult(response))
    } catch (error) {
        yield put(buscarGruposCardapioItemPorGrupoResult(error))
    }
}

function* buscarGrupoCardapioItem({payload: {company, codigoProduto}}) {
    try {
        const response = yield call(apiBuscarGrupoCardapioItem, {empresaId: company, produto: codigoProduto})
        yield put(buscarGrupoCardapioItemResult(response))
    } catch (error) {
        yield put(buscarGrupoCardapioItemResult(error))
    }
}

function* buscarVendasDia({payload: {data, valorVenda, ticket}}) {
    try {
        const response = yield call(apiBuscarVendasDia, {data, valorVenda, ticket})
        yield put(buscarVendasDiaResult(response))
    } catch (error) {
        yield put(buscarVendasDiaResult(error))
    }
}

function* buscarVendasSemana({payload: {data, valorVenda, ticket}}) {
    try {
        const response = yield call(apiBuscarVendasSemana, {data, valorVenda, ticket})
        yield put(buscarVendasSemanaResult(response))
    } catch (error) {
        yield put(buscarVendasSemanaResult(error))
    }
}

function* buscarVendasMes({payload: {data, valorVenda, ticket}}) {
    try {
        const response = yield call(apiBuscarVendasMes, {data, valorVenda, ticket})
        yield put(buscarVendasMesResult(response))
    } catch (error) {
        yield put(buscarVendasMesResult(error))
    }
}

function* buscarVendasMesAnterior({payload: {data, valorVenda, ticket}}) {
    try {
        const response = yield call(apiBuscarVendasMes, {data, valorVenda, ticket})
        yield put(buscarVendasMesAnteriorResult(response))
    } catch (error) {
        yield put(buscarVendasMesAnteriorResult(error))
    }
}

function* buscarVendasAno({payload: {data, valorVenda, valorFaturado, valorRecebido, agruparAtendimento, agruparData}}) {
    try {
        const response = yield call(apiBuscarVendasAno, {data, valorVenda, valorFaturado, valorRecebido, agruparAtendimento, agruparData})
        yield put(buscarVendasAnoResult(response))
    } catch (error) {
        yield put(buscarVendasAnoResult(error))
    }
}

function* buscarVendasAnoAnterior({payload: {data, valorVenda, valorFaturado, valorRecebido, agruparAtendimento, agruparData}}) {
    try {
        const response = yield call(apiBuscarVendasAno, {data, valorVenda, valorFaturado, valorRecebido, agruparAtendimento, agruparData})
        yield put(buscarVendasAnoAnteriorResult(response))
    } catch (error) {
        yield put(buscarVendasAnoAnteriorResult(error))
    }
}

function* buscarVendasAnoEvolucao({payload: {data, valorVenda, valorFaturado, valorRecebido, valorTaxaServico, valorTaxaEntega, quantidadePessoas, agruparAtendimento, agruparData, agruparStatus }}) {
    try {
        const response = yield call(apiBuscarVendasAno, {data, valorVenda, valorFaturado, valorRecebido, valorTaxaServico, valorTaxaEntega, quantidadePessoas, agruparAtendimento, agruparData, agruparStatus})
        yield put(buscarVendasAnoEvolucaoResult(response))
    } catch (error) {
        yield put(buscarVendasAnoEvolucaoResult(error))
    }
}

function* buscarVendasAnoEvolucaoPorAtendimento({payload: {data, valorVenda, valorFaturado, valorRecebido, valorTaxaServico, valorTaxaEntega, agruparAtendimento, agruparData }}) {
    try {
        const response = yield call(apiBuscarVendasAno, {data, valorVenda, valorFaturado, valorRecebido, valorTaxaServico, valorTaxaEntega, agruparAtendimento, agruparData})
        yield put(buscarVendasAnoEvolucaoPorAtendimentoResult(response))
    } catch (error) {
        yield put(buscarVendasAnoEvolucaoPorAtendimentoResult(error))
    }
}

function* buscarVendasAnoEvolucaoPorStatus({payload: {data, valorVenda, valorFaturado, valorRecebido, agruparData, agruparStatus}}) {
    try {
        const response = yield call(apiBuscarVendasAno, {data, valorVenda, valorFaturado, valorRecebido, agruparData, agruparStatus})
        yield put(buscarVendasAnoEvolucaoPorStatusResult(response))
    } catch (error) {
        yield put(buscarVendasAnoEvolucaoPorStatusResult(error))
    }
}

function* buscarEmpresa({payload: { data }}) {
    try {
        const response = yield call(apiBuscarEmpresa, data)
        yield put(buscarEmpresaResult(response))
    } catch (error) {
        yield put(buscarEmpresaResult(error))
    }
}

function* editarEmpresa({payload: {data, history, path}}) {
    try {
        const response = yield call(apiEditarEmpresa, data)

        yield put(editarEmpresaResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(() => { if(history) history.push("/empresas") })
    } catch (error) {
        swal({
            title: "Gerou erro ao editar a empresa!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarEmpresaResult(error))
    }
}

function* editarEmpresaCertificadoDigital({payload: {data, history}}) {
    try {
        const response = yield cadastrarFileEmpresaCertificado(data.id, data.certificado, data.senhaCertificado, data.cnpj)

        yield put(editarEmpresaCertificadoDigitalResult(response))
        swal({title: "Inclusão realizada com sucesso!", icon: "success"}).then(() => { if(history) history.push("/certificados-digitais") })
    } catch (error) {
        swal({
            title: "Gerou erro ao inserir o certificado digital na empresa!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarEmpresaCertificadoDigitalResult(error))
    }
}

function* enviarCertificadoDigital({payload: {data, history}}) {
    try {
        const response = yield cadastrarFileCertificado(data.certificado, data.senhaCertificado, data.cnpj)

        yield put(enviarCertificadoDigitalResult(response))
        swal({title: "Inclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao inserir o certificado digital na empresa!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(enviarCertificadoDigitalResult(error))
    }
}

function* removerEmpresaCertificadoDigital({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverEmpresaCertificado, data.id)

        yield put(removerEmpresaCertificadoDigitalResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o certificado digital da empresa!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerEmpresaCertificadoDigitalResult(error))
    }
}

function* buscarEmpresaPorUsuario({payload: { data }}) {
    try {
        const response = yield call(apiBuscarEmpresaPorUsuario, data)
        yield put(buscarEmpresaPorUsuarioResult(response))
    } catch (error) {
        yield put(buscarEmpresaPorUsuarioResult(error))
    }
}

function* buscarLicencasPorEmpresa({payload: {company, ignoreCompany}}) {
    try {
        const response = yield call(apiBuscarLicencasPorEmpresa, {empresaId: company, ignoreCompany})
        yield put(buscarLicencasPorEmpresaResult(response))
    } catch (error) {
        yield put(buscarLicencasPorEmpresaResult(error))
    }
}

function* buscarTarefasPorEmpresa({payload: {company, ignoreCompany}}) {
    try {
        const response = yield call(apiBuscarTarefasPorEmpresa, { empresaId: company, ignoreCompany })
        yield put(buscarTarefasPorEmpresaResult(response))
    } catch (error) {
        yield put(buscarTarefasPorEmpresaResult(error))
    }
}

function* buscarNotificacoesSistemaPorEmpresa({payload: {company, ignoreCompany}}) {
    try {
        const response = yield call(apiBuscarNotificoesSistemaPorEmpresa, { empresaId: company, ignoreCompany })
        yield put(buscarNotificacoesSistemaPorEmpresaResult(response))
    } catch (error) {
        yield put(buscarNotificacoesSistemaPorEmpresaResult(error))
    }
}

function* buscarVinculosPorUsuario({payload: {company, ignoreCompany, usuarioId}}) {
    try {
        const response = yield call(apiBuscarVinculosPorUsuario, { empresaId: company, ignoreCompany, usuarioId })
        yield put(buscarVinculosPorUsuarioResult(response))
    } catch (error) {
        yield put(buscarVinculosPorUsuarioResult(error))
    }
}

function* rejeitarVinculos({payload: {data, history}}) {
    try {
        const response = yield call(apiRejeitarVinculos, data)
        yield put(rejeitarVinculosResult(response))
        swal({title: "Vínculos rejeitados com sucesso!", icon: "success"}).then()
    } catch (error) {
        swal({
            title: "Gerou erro ao rejeitar os vínculos!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()

        yield put(rejeitarVinculosResult(error))
    }
}

function* exportarUsuarioCSV({payload: {data}}) {
    try {
        const response = yield call(apiExportaCSVUsuarios, data.paramsExport)
        saveAs(new Blob([response]), `${data.name}.csv`)

        yield put(exportarUsuarioCSVResult(response))
    } catch (error) {
        yield put(exportarUsuarioCSVResult(error))
    }
}

function* buscarGruposEstoque({payload: { filters }}) {
    try {
        const response = yield call(apiBuscarGruposEstoque, filters)
        yield put(buscarGruposEstoqueResult(response))
    } catch (error) {
        yield put(buscarGruposEstoqueResult(error))
    }
}

function* buscarGrupoEstoque({payload: {data}}) {
    try {
        const response = yield call(apiBuscarGrupoEstoque, data)
        yield put(buscarGrupoEstoqueResult(response))
    } catch (error) {
        yield put(buscarGrupoEstoqueResult(error))
    }
}

function* cadastrarGrupoEstoque({payload: {data, history, mantemCadastro}}) {
    try {
        const response = yield call(apiCadastrarGrupoEstoque, data)

        if (mantemCadastro) {
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
            yield put(cadastrarGrupoEstoqueMantemCadastroResult(true))
        } else {
            yield put(cadastrarGrupoEstoqueResult(response))
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then(
                () => {
                    if (history) history.push("/grupos-estoque")
                }
            )
        }
    } catch (error) {
        let mensagem = null
        if (error?.response?.data?.original) {
            mensagem = error?.response?.data?.original.sqlMessage
        } else {
            mensagem = error?.response?.data
        }

        swal({title: "Gerou erro ao cadastrar o grupo de estoque!", icon: "error", text: mensagem}).then()
        if (mantemCadastro) {
            yield put(cadastrarGrupoEstoqueMantemCadastroResult(error))
        } else {
            yield put(cadastrarGrupoEstoqueResult(error))
        }
    }
}

function* editarGrupoEstoque({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarGrupoEstoque, data)
        yield put(editarGrupoEstoqueResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-estoque")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o grupo de estoque!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarGrupoEstoqueResult(error))
    }
}

function* removerGrupoEstoque({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverGrupoEstoque, data)
        yield put(removerGrupoEstoqueResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-estoque")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o grupo de estoque!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerGrupoEstoqueResult(error))
    }
}

function* buscarEstoques({payload: { filters }}) {
    try {
        const response = yield call(apiBuscarEstoques, filters)
        yield put(buscarEstoquesResult(response))
    } catch (error) {
        yield put(buscarEstoquesResult(error))
    }
}

function* buscarEstoque({payload: {data}}) {
    try {
        const response = yield call(apiBuscarEstoque, data)
        yield put(buscarEstoqueResult(response))
    } catch (error) {
        yield put(buscarEstoqueResult(error))
    }
}

function* cadastrarEstoque({payload: {data, history, mantemCadastro}}) {
    try {
        const response = yield call(apiCadastrarEstoque, data)

        if (mantemCadastro) {
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then()
            yield put(cadastrarEstoqueMantemCadastroResult(true))
        } else {
            yield put(cadastrarEstoqueResult(response))
            swal({title: "Inclusão realizado com sucesso!", icon: "success"}).then(
                () => {
                    if (history) history.push("/estoques")                }
            )
        }
    } catch (error) {
        let mensagem = null
        if (error?.response?.data?.original) {
            mensagem = error?.response?.data?.original.sqlMessage
        } else {
            mensagem = error?.response?.data
        }

        swal({title: "Gerou erro ao cadastrar o estoque!", icon: "error", text: mensagem}).then()
        if (mantemCadastro) {
            yield put(cadastrarEstoqueMantemCadastroResult(error))
        } else {
            yield put(cadastrarEstoqueResult(error))
        }
    }
}

function* editarEstoque({payload: {data, history}}) {
    try {
        const response = yield call(apiEditarEstoque, data)
        yield put(editarEstoqueResult(response))
        swal({title: "Alteração realizado com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/estoques")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao editar o estoque!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(editarEstoqueResult(error))
    }
}

function* removerEstoque({payload: {data, history}}) {
    try {
        const response = yield call(apiRemoverEstoque, data)
        yield put(removerEstoqueResult(response))
        swal({title: "Exclusão realizada com sucesso!", icon: "success"}).then(
            () => {
                if (history) history.push("/grupos-estoque")
            }
        )
    } catch (error) {
        swal({
            title: "Gerou erro ao excluir o estoque!",
            icon: "error",
            text: error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data
        }).then()
        yield put(removerEstoqueResult(error))
    }
}

function* buscarEstoqueFilialPorEstoque({payload: {company, estoque}}) {
    try {
        const response = yield call(apiBuscarEstoqueFilialPorEstoque, {
            empresaId: company,
            estoque: estoque
        })
        yield put(buscarEstoqueFilialPorEstoqueResult(response))
    } catch (error) {
        yield put(buscarEstoqueFilialPorEstoqueResult(error))
    }
}
function* cadastrarFileEmpresaCertificado(id, certificado, senha, cnpj) {
    const formData = new FormData()
    formData.append(`file`, certificado.file)
    formData.append(`senha`, senha)
    formData.append(`cnpj`, cnpj)

    return yield call(apiInserirFileEmpresaCertificado, id, formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
}

function* cadastrarFileCertificado(certificado, senha, cnpj) {
    const formData = new FormData()
    formData.append(`file`, certificado.file)
    formData.append(`senha`, senha)
    formData.append(`cnpj`, cnpj)

    return yield call(apiInserirFileCertificado, formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
}

function* buscarEvolucaoFiscal({payload: {company, periodo}}) {
    try {
        const response = yield call(apiBuscarEvolucaoFiscal, {
            companiesId: company,
            period: periodo
        })
        yield put(buscarEvolucaoFiscalResult(response))
    } catch (error) {
        yield put(buscarEvolucaoFiscalResult(error))
    }
}

export function* watchProfile() {
    yield takeEvery(EDIT_PROFILE, editProfile)
    yield takeEvery(ALTERAR_VINCULOS, alterarVinculos)
    yield takeEvery(BUSCAR_PERFIS, buscarPerfis)
    yield takeEvery(BUSCAR_PERFIL, buscarPerfil)
    yield takeEvery(CADASTRAR_PERFIL, cadastrarPerfil)
    yield takeEvery(ALTERAR_PERFIL, alterarPerfil)
    yield takeEvery(REMOVER_PERFIL, removerPerfil)
    yield takeEvery(CADASTRAR_CATEGORIA, cadastrarCategoria)
    yield takeEvery(CADASTRAR_PERGUNTA, cadastrarPergunta)
    yield takeEvery(LISTAR_FAQS, listarFaqs)
    yield takeEvery(DO_DELETE_CATEGORY, deleteCategory)
    yield takeEvery(CADASTRAR_CATEGORIA_RESULT, listarFaqs)
    yield takeEvery(DO_DELETE_QUESTION, deleteQuestion)
    yield takeEvery(CADASTRAR_PERGUNTA_RESULT, listarFaqs)
    yield takeEvery(BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES, buscarQuantidadeSolicitacoesPendentes)
    yield takeEvery(BUSCAR_NOTAS_SITUACOES, buscarNotasSituacoes)
    yield takeEvery(BUSCAR_NOTAS, buscarNotas)
    yield takeEvery(BUSCAR_MOVIMENTACAO_CAIXAS, buscarMovimentacaoCaixas)
    yield takeEvery(BUSCAR_NCMS, buscarNCMs)
    yield takeEvery(BUSCAR_NCMS_DESCRICAO, buscarNCMsPorDescricao)
    yield takeEvery(BUSCAR_NCM_CATEGORIAS, buscarNCMCategorias)
    yield takeEvery(NCM, ncm)
    yield takeEvery(REMOVE_NCM, removerNcm)
    yield takeEvery(BUSCAR_NOTA, buscarNota)
    yield takeEvery(BUSCAR_SUGESTOES_STATUS, buscarSugestoesStatus)
    yield takeEvery(BUSCAR_SUGESTOES_PRODUTOS, buscarSugestoesProdutos)
    yield takeEvery(SUGESTAO, sugestao)
    yield takeEvery(BUSCAR_SUGESTOES, buscarSugestoes)
    yield takeEvery(BUSCAR_CAIXAS_OPERADORES, buscarCaixasOperadores)
    yield takeEvery(SET_USER_PREFERENCE, setUserPreference)
    yield takeEvery(NOTIFICACAO, notificacao)
    yield takeEvery(DELETE_NOTIFICATION, removerNotificacao)
    yield takeEvery(BUSCAR_NOTIFICACOES, buscarNotificacoes)
    yield takeEvery(BUSCAR_USUARIOS_POR_EMPRESA, buscarUsuariosPorEmpresa)
    yield takeEvery(BUSCAR_NOTIFICACOES_USUARIO, buscarNotificacoesUsuario)
    yield takeEvery(BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS, buscarNotificacoesUsuarioNaoLidas)
    yield takeEvery(MARCAR_NOTIFICACAO_USUARIO_LIDA, marcarNotificacaoUsuarioLida)
    yield takeEvery(MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA, marcarNotificacaoUsuarioNaoLida)
    yield takeEvery(NOTIFICACAO_USUARIO_WEBSOCKET, notificacaoUsuarioWebsocket)
    yield takeEvery(BUSCAR_DISPOSITIVOS, buscarDispositivos)
    yield takeEvery(BUSCAR_DISPOSITIVO_LOGS, buscarDispositivoLogs)
    yield takeEvery(BUSCAR_TEMPLATES_EMAIL, buscarTemplatesEmail)
    yield takeEvery(ALTERAR_TEMPLATE_EMAIL, alterarTemplateEmail)
    yield takeEvery(BUSCAR_DRE, buscarDRE)
    yield takeEvery(RELATORIO_EXECUTAR, relatorioExecutar)
    yield takeEvery(LIMPAR_DRE, limparDRE)
    yield takeEvery(BUSCAR_CONTAS_PAGAR, buscarContasPagar)
    yield takeEvery(BUSCAR_CONTAS_PAGAR_HOJE, buscarContasPagarHoje)
    yield takeEvery(BUSCAR_CONTAS_PAGAR_MES, buscarContasPagarMes)
    yield takeEvery(BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES, buscarContasPagarProximosMeses)
    yield takeEvery(BUSCAR_CONTAS_PAGAR_ATRASADOS, buscarContasPagarAtrasados)
    yield takeEvery(BUSCAR_EVOLUCAO_CONTAS_PAGAR, buscarEvolucaoContasPagar)
    yield takeEvery(BUSCAR_PLANO_CONTAS, buscarPlanoContas)
    yield takeEvery(BUSCAR_FORNECEDORES_NOME_CNPJ, buscarFornecedoresPorNomeCNPJ)
    yield takeEvery(BUSCAR_FORNECEDORES, buscarFornecedores)
    yield takeEvery(BUSCAR_FORNECEDOR, buscarFornecedor)
    yield takeEvery(CRIAR_FORNECEDOR, cadastrarFornecedor)
    yield takeEvery(BUSCAR_QUANTIDADE_FORNECEDORES, buscarQuantidadeFornecedores)
    yield takeEvery(EDITAR_FORNECEDOR, editarFornecedor)
    yield takeEvery(REMOVER_FORNECEDOR, removerFornecedor)
    yield takeEvery(CADASTRAR_LANCAMENTO, cadastrarLancamento)
    yield takeEvery(EDITAR_LANCAMENTO, editarLancamento)
    yield takeEvery(REMOVER_LANCAMENTO, removerLancamento)
    yield takeEvery(PAGAR_LANCAMENTO, pagarLancamento)
    yield takeEvery(ESTORNAR_PAGAMENTO_LANCAMENTO, estornarPagamentoLancamento)
    yield takeEvery(BUSCAR_PERMISSAO_PERFIL, buscarPermissaoPerfil)
    yield takeEvery(BUSCAR_GRUPOS_PRODUTO, buscarGruposProduto)
    yield takeEvery(BUSCAR_GRUPOS_PRODUTO_POR_NOME, buscarGruposProdutoPorNome)
    yield takeEvery(BUSCAR_GRUPO_PRODUTO, buscarGrupoProduto)
    yield takeEvery(CRIAR_GRUPO_PRODUTO, cadastrarGrupoProduto)
    yield takeEvery(BUSCAR_QUANTIDADE_GRUPOS_PRODUTO, buscarQuantidadeGruposProduto)
    yield takeEvery(EDITAR_GRUPO_PRODUTO, editarGrupoProduto)
    yield takeEvery(REMOVER_GRUPO_PRODUTO, removerGrupoProduto)
    yield takeEvery(BUSCAR_PRODUTOS, buscarProdutos)
    yield takeEvery(BUSCAR_PRODUTO, buscarProduto)
    yield takeEvery(CRIAR_PRODUTO, cadastrarProduto)
    yield takeEvery(CRIAR_PRODUTO_MANTEM_CADASTRO, cadastrarProduto)
    yield takeEvery(BUSCAR_QUANTIDADE_PRODUTOS, buscarQuantidadeProdutos)
    yield takeEvery(EDITAR_PRODUTO, editarProduto)
    yield takeEvery(REMOVER_PRODUTO, removerProduto)
    yield takeEvery(BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO, buscarTabelaUnidadesPorUnidadeDescricao)
    yield takeEvery(BUSCAR_TABELA_CEST_DESCRICAO, buscarTabelaCestPorCestDescricao)
    yield takeEvery(BUSCAR_CODIGOS_BARRAS, buscarCodigosBarras)
    yield takeEvery(BUSCAR_CODIGOS_BARRAS_POR_PRODUTO, buscarCodigosBarrasPorProduto)
    yield takeEvery(CRIAR_CODIGO_BARRAS, cadastrarCodigoBarras)
    yield takeEvery(EDITAR_CODIGO_BARRAS, editarCodigoBarras)
    yield takeEvery(REMOVER_CODIGO_BARRAS, removerCodigoBarras)
    yield takeEvery(BUSCAR_PRODUTOS_REFERENCIA, buscarProdutosReferencia)
    yield takeEvery(BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO, buscarProdutosReferenciaPorProduto)
    yield takeEvery(CRIAR_PRODUTO_REFERENCIA, cadastrarProdutoReferencia)
    yield takeEvery(EDITAR_PRODUTO_REFERENCIA, editarProdutoReferencia)
    yield takeEvery(REMOVER_PRODUTO_REFERENCIA, removerProdutoReferencia)
    yield takeEvery(BUSCAR_PRODUTOS_COMPOSICAO, buscarProdutosComposicao)
    yield takeEvery(BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO, buscarProdutosComposicaoPorProduto)
    yield takeEvery(CRIAR_PRODUTO_COMPOSICAO, cadastrarProdutoComposicao)
    yield takeEvery(EDITAR_PRODUTO_COMPOSICAO, editarProdutoComposicao)
    yield takeEvery(REMOVER_PRODUTO_COMPOSICAO, removerProdutoComposicao)
    yield takeEvery(BUSCAR_IMAGEM_PRODUTO, buscarImagemProduto)

    yield takeEvery(BUSCAR_GRUPOS_CARDAPIO, buscarGruposCardapio)
    yield takeEvery(BUSCAR_GRUPO_CARDAPIO, buscarGrupoCardapio)
    yield takeEvery(CRIAR_GRUPO_CARDAPIO, cadastrarGrupoCardapio)
    yield takeEvery(CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO, cadastrarGrupoCardapio)
    yield takeEvery(BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO, buscarQuantidadeGruposCardapio)
    yield takeEvery(EDITAR_GRUPO_CARDAPIO, editarGrupoCardapio)
    yield takeEvery(REMOVER_GRUPO_CARDAPIO, removerGrupoCardapio)

    yield takeEvery(BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO, buscarGruposCardapioObservacao)
    yield takeEvery(BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO, buscarGruposCardapioObservacaoPorGrupo)
    yield takeEvery(BUSCAR_GRUPO_CARDAPIO_OBSERVACAO, buscarGrupoCardapioObservacao)
    yield takeEvery(CRIAR_GRUPO_CARDAPIO_OBSERVACAO, cadastrarGrupoCardapioObservacao)
    yield takeEvery(EDITAR_GRUPO_CARDAPIO_OBSERVACAO, editarGrupoCardapioObservacao)
    yield takeEvery(REMOVER_GRUPO_CARDAPIO_OBSERVACAO, removerGrupoCardapioObservacao)

    yield takeEvery(BUSCAR_GRUPOS_CARDAPIO_ITEM, buscarGruposCardapioItem)
    yield takeEvery(BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO, buscarGruposCardapioItemPorGrupo)
    yield takeEvery(BUSCAR_GRUPO_CARDAPIO_ITEM, buscarGrupoCardapioItem)

    yield takeEvery(BUSCAR_VENDAS_DIA, buscarVendasDia)
    yield takeEvery(BUSCAR_VENDAS_SEMANA, buscarVendasSemana)
    yield takeEvery(BUSCAR_VENDAS_MES, buscarVendasMes)
    yield takeEvery(BUSCAR_VENDAS_MES_ANTERIOR, buscarVendasMesAnterior)

    yield takeEvery(BUSCAR_VENDAS_ANO, buscarVendasAno)
    yield takeEvery(BUSCAR_VENDAS_ANO_ANTERIOR, buscarVendasAnoAnterior)
    yield takeEvery(BUSCAR_VENDAS_ANO_EVOLUCAO, buscarVendasAnoEvolucao)
    yield takeEvery(BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO, buscarVendasAnoEvolucaoPorAtendimento)
    yield takeEvery(BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS, buscarVendasAnoEvolucaoPorStatus)

    yield takeEvery(BUSCAR_EMPRESA, buscarEmpresa)
    yield takeEvery(EDITAR_EMPRESA, editarEmpresa)
    yield takeEvery(EDITAR_EMPRESA_CERTIFICADO_DIGITAL, editarEmpresaCertificadoDigital)
    yield takeEvery(EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO, enviarCertificadoDigital)
    yield takeEvery(REMOVER_EMPRESA_CERTIFICADO_DIGITAL, removerEmpresaCertificadoDigital)
    yield takeEvery(BUSCAR_EMPRESA_POR_USUARIO, buscarEmpresaPorUsuario)
    yield takeEvery(BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA, buscarLicencasPorEmpresa)
    yield takeEvery(BUSCAR_TAREFAS_POR_EMPRESA, buscarTarefasPorEmpresa)
    yield takeEvery(BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA, buscarNotificacoesSistemaPorEmpresa)
    yield takeEvery(BUSCAR_VINCULOS_POR_USUARIO, buscarVinculosPorUsuario)
    yield takeEvery(REJEITAR_VINCULOS, rejeitarVinculos)
    yield takeEvery(EXPORTAR_USUARIO_CSV, exportarUsuarioCSV)

    yield takeEvery(BUSCAR_GRUPOS_ESTOQUE, buscarGruposEstoque)
    yield takeEvery(BUSCAR_GRUPO_ESTOQUE, buscarGrupoEstoque)
    yield takeEvery(CRIAR_GRUPO_ESTOQUE, cadastrarGrupoEstoque)
    yield takeEvery(CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO, cadastrarGrupoEstoque)
    yield takeEvery(EDITAR_GRUPO_ESTOQUE, editarGrupoEstoque)
    yield takeEvery(REMOVER_GRUPO_ESTOQUE, removerGrupoEstoque)

    yield takeEvery(BUSCAR_ESTOQUES, buscarEstoques)
    yield takeEvery(BUSCAR_ESTOQUE, buscarEstoque)
    yield takeEvery(CRIAR_ESTOQUE, cadastrarEstoque)
    yield takeEvery(CRIAR_ESTOQUE_MANTEM_CADASTRO, cadastrarEstoque)
    yield takeEvery(EDITAR_ESTOQUE, editarEstoque)
    yield takeEvery(REMOVER_ESTOQUE, removerEstoque)
    yield takeEvery(BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE, buscarEstoqueFilialPorEstoque)

    yield takeEvery(BUSCAR_EVOLUCAO_FISCAL, buscarEvolucaoFiscal)
}

function* ProfileSaga() {
    yield all([fork(watchProfile)])
}

export default ProfileSaga
