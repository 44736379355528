import React, {Component} from "react"
import {Card, CardBody, Col, Row} from "reactstrap"
import {Link} from "react-router-dom"

import ReactApexChart from "react-apexcharts"
import moment from "moment"

import "moment/locale/pt-br"
import {connect} from "react-redux"
import Loader from "react-loader-spinner"
import {
    buscarVendasAno,
    buscarVendasAnoAnterior,
    buscarVendasAnoEvolucao,
    buscarVendasAnoEvolucaoPorAtendimento, buscarVendasAnoEvolucaoPorStatus
} from "../../store/auth/profile/actions"
import {abbreviateNumber, describeMoney} from "../../helpers/utils"

moment.locale("pt-br")

class MovimentoVendasKpiEvolucao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            evolucaoAtendimento: {
                series: [
                    {
                        name: "series1",
                        data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76]
                    }
                ],
                options: {
                    chart: {
                        toolbar: "false",
                        dropShadow: {
                            enabled: !0,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 8,
                            opacity: 0.2
                        }
                    },
                    dataLabels: {
                        enabled: !1
                    },
                    colors: ["#556ee6"],
                    stroke: {
                        curve: "smooth",
                        width: 3
                    }
                },
            },
            evolucaoStatus: {
                series: [
                    {
                        name: "series1",
                        data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76]
                    }
                ],
                options: {
                    chart: {
                        toolbar: "false",
                        dropShadow: {
                            enabled: !0,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 8,
                            opacity: 0.2
                        }
                    },
                    dataLabels: {
                        enabled: !1
                    },
                    colors: ["#556ee6"],
                    stroke: {
                        curve: "smooth",
                        width: 3
                    }
                },
            },
            currentPeriod: moment().format("YYYY"),
            previousPeriod: moment().subtract(1, "year").format("YYYY"),
            previousPeriodData: null,
            previousPercentage: 0,
            dataDifference: 0,
            averageLastMonths: 0
        }

        props.buscarVendasAno({data: moment().toDate(), valorVenda: true, valorRecebido: true})
        props.buscarVendasAnoAnterior({data: moment().subtract(1, "year").toDate(), valorVenda: true, valorRecebido: true})
        props.buscarVendasAnoEvolucao({data: moment().toDate(), valorVenda: true, valorTaxaServico: true, valorTaxaEntega: true, valorRecebido: true, agruparData: true})
        props.buscarVendasAnoEvolucaoPorAtendimento({data: moment().toDate(), valorVenda: true, agruparAtendimento: true, agruparData: true})
        props.buscarVendasAnoEvolucaoPorStatus({data: moment().toDate(), valorVenda: true, agruparStatus: true, agruparData: true})
    }

    static describeAtendimento(atendimento) {
        if(atendimento) {
            switch (atendimento) {
                case 'D':
                    return 'Delivery'
                case 'B':
                    return 'Balcão'
            }
        }
        return 'Mesa';
    }

    static describeStatus(status) {
        if(status) {
            switch (status) {
                case 'V':
                    return 'Venda'
                case 'C':
                    return 'Cancelada'
                case 'T':
                    return 'Cortesia'
                case 'D':
                    return 'Devolvida'
                case 'I':
                    return 'Consumo interno'
                case 'P':
                    return 'Desperdicio'
            }
        }
        return 'Venda';
    }

    static getDerivedStateFromProps(props, current_state) {
        const mesesNome = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
        const mesesValor = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00]

        let state = {}
        if (props.vendasAno && props.vendasAno[0] && props.vendasAno[0][0] &&
            props.vendasAnoAnterior && props.vendasAnoAnterior[0] && props.vendasAnoAnterior[0][0]) {

            const vendaAno = props.vendasAno[0][0].vendas
            const vendaAnoAnterior = props.vendasAnoAnterior[0][0].vendas

            const recebidoAno = props.vendasAno[0][0].recebido
            const recebidoAnoAnterior = props.vendasAnoAnterior[0][0].recebido

            state = {
                ...state,
                vendaPercentualAnterior: vendaAno && vendaAnoAnterior ? (100 * Math.abs((vendaAnoAnterior - vendaAno) / vendaAnoAnterior)).toFixed(2) : 0,
                vendaDiferenca: vendaAno - vendaAnoAnterior,
                recebidoPercentualAnterior: recebidoAno && recebidoAnoAnterior ? (100 * Math.abs((recebidoAnoAnterior - recebidoAno) / recebidoAnoAnterior)).toFixed(2) : 0,
                recebidoDiferenca: recebidoAno - recebidoAnoAnterior,
            }
        }

        state = MovimentoVendasKpiEvolucao.popularEvolucaoAtendimentoState(props, mesesValor, state, mesesNome)
        state = MovimentoVendasKpiEvolucao.popularEvolucaoStatusState(props, mesesValor, state, mesesNome)

        return state;
    }

    static popularEvolucaoStatusState(props, mesesValor, state, mesesNome) {
        if (props.vendasAnoEvolucao && props.vendasAnoEvolucao[0] &&
            props.vendasAnoEvolucaoPorStatus && props.vendasAnoEvolucaoPorStatus[0]) {

            const seriesMap = new Map()
            const totalRecebido = [...mesesValor]
            const totalTaxaServico = [...mesesValor]
            const totalTaxaEntrega = [...mesesValor]
            for (const evolucao of props.vendasAnoEvolucao[0]) {
                totalRecebido[evolucao.mes - 1] = (evolucao.recebido || 0).toFixed(2)
                totalTaxaServico[evolucao.mes - 1] = (evolucao.taxaServico || 0).toFixed(2)
                totalTaxaEntrega[evolucao.mes - 1] = (evolucao.taxaEntrega || 0).toFixed(2)
            }
            seriesMap.set("Recebido", totalRecebido)
            seriesMap.set("Taxa de serviço", totalTaxaServico)
            seriesMap.set("Taxa de entrega", totalTaxaEntrega)

            for (const evolucao of props.vendasAnoEvolucaoPorStatus[0]) {
                const status = MovimentoVendasKpiEvolucao.describeStatus(evolucao.status)
                let faturamentoMeses = seriesMap.get(status)
                faturamentoMeses = faturamentoMeses ? faturamentoMeses : [...mesesValor]
                faturamentoMeses[evolucao.mes - 1] = (evolucao.vendas || 0).toFixed(2)
                seriesMap.set(status, faturamentoMeses)
            }

            const series = []
            seriesMap.forEach((value, key) => {
                series.push({name: key, data: value})
            })

            state = {
                ...state,
                evolucaoStatus: {
                    series: series,
                    options: {
                        xaxis: {
                            categories: mesesNome
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val, index) {
                                    return abbreviateNumber(val)
                                }
                            }
                        },
                        chart: {
                            toolbar: "false",
                            dropShadow: {
                                enabled: !0,
                                color: "#000",
                                top: 18,
                                left: 7,
                                blur: 8,
                                opacity: 0.2
                            }
                        },
                        dataLabels: {
                            enabled: !1
                        },
                        colors: ["#556ee6", "#34c38f", "#f46a6a", "#f1b44c", "#50a5f1", "#74788d", "#e83e8c", "#6f42c1", "#343a40"],
                        stroke: {
                            curve: "smooth",
                            width: 3
                        },
                        tooltip: {
                            x: {
                                show: true,
                                formatter: function (value, options) {
                                    return mesesNome[value - 1]
                                }
                            }
                        }
                    },
                },
            }
        }
        return state
    }

    static popularEvolucaoAtendimentoState(props, mesesValor, state, mesesNome) {
        if (props.vendasAnoEvolucao && props.vendasAnoEvolucao[0] &&
            props.vendasAnoEvolucaoPorAtendimento && props.vendasAnoEvolucaoPorAtendimento[0]) {

            const seriesMap = new Map()
            const totalVendas = [...mesesValor]
            for (const evolucao of props.vendasAnoEvolucao[0]) {
                totalVendas[evolucao.mes - 1] = (evolucao.vendas || 0).toFixed(2)
            }
            seriesMap.set("Total de vendas", totalVendas)

            for (const evolucao of props.vendasAnoEvolucaoPorAtendimento[0]) {
                const atendimento = MovimentoVendasKpiEvolucao.describeAtendimento(evolucao.atendimento)
                let faturamentoMeses = seriesMap.get(atendimento)
                faturamentoMeses = faturamentoMeses ? faturamentoMeses : [...mesesValor]
                faturamentoMeses[evolucao.mes - 1] = (evolucao.vendas || 0).toFixed(2)
                seriesMap.set(atendimento, faturamentoMeses)
            }

            const series = []
            seriesMap.forEach((value, key) => {
                series.push({name: key, data: value})
            })

            state = {
                ...state,
                evolucaoAtendimento: {
                    series: series,
                    options: {
                        xaxis: {
                            categories: mesesNome
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val, index) {
                                    return abbreviateNumber(val)
                                }
                            }
                        },
                        chart: {
                            toolbar: "false",
                            dropShadow: {
                                enabled: !0,
                                color: "#000",
                                top: 18,
                                left: 7,
                                blur: 8,
                                opacity: 0.2
                            }
                        },
                        dataLabels: {
                            enabled: !1
                        },
                        colors: ["#556ee6", "#34c38f", "#f46a6a", "#f1b44c"],
                        stroke: {
                            curve: "smooth",
                            width: 3
                        },
                        tooltip: {
                            x: {
                                show: true,
                                formatter: function (value, options) {
                                    return mesesNome[value - 1]
                                }
                            }
                        }
                    },
                },
            }
        }
        return state
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <div className="clearfix">
                                    <h4 className="card-title mb-4" title="O resultado deste gráfico não inclui vendas cancelas, cortesia, consumo interno. Inclui apenas vendas ativas">Vendas</h4>
                                </div>

                                { (this.props.vendasAnoLoading || this.props.vendasAnoAnteriorLoading
                                    || this.props.vendasAnoEvolucaoLoading || this.props.vendasAnoEvolucaoPorAtendimentoLoading) ? (
                                    <div className="loader-container position-absolute">
                                        <Loader type="ThreeDots" color="#556EE6" height={80} width={80} />
                                    </div>
                                ) : (
                                    <Row>
                                        <Col lg="4">
                                            <div className="text-muted">
                                                <div className="mb-4">
                                                    <p>Este ano ({this.state.currentPeriod})</p>
                                                    <span>Vendido</span>
                                                    <h4> {describeMoney(this.props.vendasAno && this.props.vendasAno[0] && this.props.vendasAno[0][0] ? this.props.vendasAno[0][0].vendas : 0)} </h4>
                                                    <div>
                                                        {(this.state.vendaDiferenca >= 0 && (
                                                            <span className="badge badge-soft-success font-size-12 me-1">
                                                        {" "} + {this.state.vendaPercentualAnterior}%{" "}
                                                    </span>
                                                        )) || (
                                                            <span className="badge badge-soft-danger font-size-12 me-1">
                                                        {" "} - {this.state.vendaPercentualAnterior}%{" "} </span>
                                                        )}{" "}
                                                        Do período anterior
                                                    </div>

                                                    <div className="mt-2">
                                                        <span className="mt-2">Recebido</span>
                                                        <h4> {describeMoney(this.props.vendasAno && this.props.vendasAno[0] && this.props.vendasAno[0][0] ? this.props.vendasAno[0][0].recebido : 0 )} </h4>
                                                        <div>
                                                            {(this.state.recebidoDiferenca >= 0 && (
                                                                <span className="badge badge-soft-success font-size-12 me-1">
                                                            {" "} + {this.state.recebidoPercentualAnterior}%{" "}
                                                        </span>
                                                            )) || (
                                                                <span className="badge badge-soft-danger font-size-12 me-1">
                                                            {" "} - {this.state.recebidoPercentualAnterior}%{" "} </span>
                                                            )}{" "}
                                                            Do período anterior
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="mt-4">
                                                    <p className="mb-2">
                                                        Ano passado ({this.state.previousPeriod})
                                                    </p>
                                                    <span>Vendido</span>
                                                    <h5>
                                                        {describeMoney(this.props.vendasAnoAnterior && this.props.vendasAnoAnterior[0] && this.props.vendasAnoAnterior[0][0] ? this.props.vendasAnoAnterior[0][0].vendas : 0)}
                                                    </h5>
                                                    <span>Recebido</span>
                                                    <h5>
                                                        {describeMoney(this.props.vendasAnoAnterior && this.props.vendasAnoAnterior[0] && this.props.vendasAnoAnterior[0][0] ? this.props.vendasAnoAnterior[0][0].recebido : 0)}
                                                    </h5>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col lg="8">
                                            <div id="line-chart" className="apex-charts" dir="ltr">
                                                <ReactApexChart
                                                    series={this.state.evolucaoAtendimento?.series}
                                                    options={this.state.evolucaoAtendimento?.options}
                                                    type="line"
                                                    height={320}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <div className="clearfix">
                                    <h4 className="card-title mb-4" title="O resultado deste gráfico inclui vendas cancelas, cortesia, consumo interno, devolução, desperdício e venda. As informações inerecentes a recebido, taxa de serviço e taxa de entrega, são apenas consideradas de vendas.">Situação da Venda</h4>
                                </div>
                                <Row>
                                    <Col lg="12">
                                        <div id="line-chart" className="apex-charts" dir="ltr">
                                            <ReactApexChart
                                                series={this.state.evolucaoStatus?.series}
                                                options={this.state.evolucaoStatus?.options}
                                                type="line"
                                                height={320}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const {
        vendasAno,
        vendasAnoLoading,
        vendasAnoAnterior,
        vendasAnoAnteriorLoading,
        vendasAnoEvolucao,
        vendasAnoEvolucaoLoading,
        vendasAnoEvolucaoPorAtendimento,
        vendasAnoEvolucaoPorAtendimentoLoading,
        vendasAnoEvolucaoPorStatus,
        vendasAnoEvolucaoPorStatusLoading,
    } = state.Profile
    return {
        vendasAno,
        vendasAnoLoading,
        vendasAnoAnterior,
        vendasAnoAnteriorLoading,
        vendasAnoEvolucao,
        vendasAnoEvolucaoLoading,
        vendasAnoEvolucaoPorAtendimento,
        vendasAnoEvolucaoPorAtendimentoLoading,
        vendasAnoEvolucaoPorStatus,
        vendasAnoEvolucaoPorStatusLoading,
    }
}

export default connect(mapStatetoProps, {
    buscarVendasAno,
    buscarVendasAnoAnterior,
    buscarVendasAnoEvolucao,
    buscarVendasAnoEvolucaoPorAtendimento,
    buscarVendasAnoEvolucaoPorStatus,
})(MovimentoVendasKpiEvolucao)
