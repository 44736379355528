import {call, put, takeEvery, takeLatest} from "redux-saga/effects"

// Login Redux States
import {GET_DASHBOARD_DATA, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN} from "./actionTypes"
import {apiError, dashboardData, loginSuccess, logoutUserSuccess} from "./actions"

import {getJwtDashboardData, getPermissoes, postJwtLogin, postSocialLogin} from "../../../helpers/backend_helper"

function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(postJwtLogin, user)

        if (response.emailPendingValidation) {
            history.push("/verificar-email")
        } else {
            localStorage.setItem("authToken", response.token)
            localStorage.setItem("authUser", JSON.stringify(response))

            response.permissoes = yield call(getPermissoes)
            yield put(loginSuccess({ token: response.token, user: response.object, permissoes: response.permissoes, preferences: response.preferencias }))
            if (user.keepConnected) {
                localStorage.setItem("keepConnected", "true")
            } else {
                localStorage.removeItem("keepConnected")
            }

            if (response.token) {
                if (response.object.usuario_info) {
                    history.push("/dashboard-padrao")
                } else if (
                    (response.object.vinculos || []).some(vinculo =>
                        ["A", "T"].includes(vinculo.vinculo_status)
                    )
                ) {
                    history.push("/dashboard-padrao")
                } else {
                    history.push("/solicitar-vinculo")
                }
            }
        }
    } catch (error) {
        yield put(apiError(error))
    }
}

function* doGetDashboardData({payload: {periodStart, periodEnd, companiesId, type}}) {
    try {
        const response = yield call(getJwtDashboardData, {
            periodStart,
            periodEnd,
            companiesId,
            type
        })
        yield put(dashboardData(response))
    } catch (error) {
        yield put(apiError(error))
    }
}

function* logoutUser({payload: {history}}) {
    try {
        localStorage.removeItem("authUser")
        localStorage.removeItem("authToken")
        localStorage.removeItem("keepConnected")
        localStorage.removeItem("keepConnectedLastRefresh")
        history.push("/login")
        yield put(logoutUserSuccess())
    } catch (error) {
        yield put(apiError(error))
    }
}

function* socialLogin({payload: {data, history, type}}) {
    try {
        const response = yield call(postSocialLogin, data)
        localStorage.setItem("authToken", response.token)
        localStorage.setItem("authUser", JSON.stringify(response))

        response.permissoes = yield call(getPermissoes)
        yield put(loginSuccess({ token: response.token, user: response.object, permissoes: response.permissoes, preferences: response.preferencias }))

        if (response.token) {
            if (response.object.usuario_info) {
                history.push("/dashboard-padrao")
            } else if (
                (response.object.vinculos || []).some(vinculo =>
                    ["A", "T"].includes(vinculo.vinculo_status)
                )
            ) {
                history.push("/dashboard-padrao")
            } else {
                history.push("/solicitar-vinculo")
            }
        }
    } catch (error) {
        yield put(apiError(error))
    }
}

function* authSaga() {
    yield takeEvery(GET_DASHBOARD_DATA, doGetDashboardData)
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeLatest(SOCIAL_LOGIN, socialLogin)
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
