import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import Loader from "react-loader-spinner"

const MiniWidget = props => {
  return (
    <React.Fragment>
      { props.reports.map((report, key) => (
        <Col sm="4" key={ key }>
          <Card>
            <CardBody>
              <p className="text-muted mb-4">
                <i className={ report.icon + " h2 text-" + report.color + " align-middle mb-0 me-3" }
                />{ " " }
                { report.title }{ " " }
              </p>

              { report.loading || !report.options || !report.series ? (
                <div className="loader-container position-absolute">
                  <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
                </div>
              ) : (
                <Row>
                  <Col xs="6">
                    <div>
                      <h5>{ report.value }</h5>
                      <p className="text-muted text-truncate mb-0">
                        { report.desc }{ " " }
                        <i className={ "bx ms-1 " + (report.diff < 0 ? "bx-trending-up text-success" : "bx-trending-down text-danger") } />
                      </p>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <div className="apex-charts">
                        <ReactApexChart options={ report.options } series={ report.series } type="area" height={ 40 } />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) }
            </CardBody>
          </Card>
        </Col>
      )) }
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array
}
