import React from 'react';

export class PermissionContextType {
  isAllowedTo: (permission) => Promise<>;
}

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => Promise.resolve(false)
}

const PermissionContext = React.createContext( defaultBehaviour);

export default PermissionContext;
