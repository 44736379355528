import {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_RESULT,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESSFUL,
  SOLICITAR_VINCULO,
  SOLICITAR_VINCULO_FAILED,
  SOLICITAR_VINCULO_SUCCESSFUL
} from "./actionTypes"
import { LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS } from "../login/actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  confirmEmailResult: null,
  currentEmail: null
}

const account = (state = initialState, action) => {
  console.debug("[Register][Reducer]", { state, action })


  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        currentEmail: action.payload.user.email
      }
      break
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        registrationError: null,
        currentEmail: action.payload.user.email
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        // user: action.payload,
        registrationError: null
      }

      break
    case REGISTER_USER_FAILED:
      const error =
        action.payload &&
        action.payload.response &&
        action.payload.response.data &&
        Array.isArray(action.payload.response.data)
          ? action.payload.response.data.map(e => e.message).join("<br/>")
          : null

      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: error || action.payload
      }
      break

    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.data?.user,
        token: action.payload.data?.token,
        preferences: action.payload.data?.preferences ? JSON.parse(action.payload.data?.preferences) : null,
      }
      break

    case LOGOUT_USER:
      state = { ...state }
      break

    case LOGOUT_USER_SUCCESS:
      state = { ...initialState }
      break

    case SOLICITAR_VINCULO:
      state = {
        ...state,
        loading: true
      }
      break

    case SOLICITAR_VINCULO_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        registrationError: null
      }
      break

    case SOLICITAR_VINCULO_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: action.payload.response.data.message
      }
      break

    case CONFIRM_EMAIL:
      state = {
        ...state,
        loading: true,
        confirmEmailResult: null
      }
      break

    case CONFIRM_EMAIL_RESULT:
      const confirmEmailError =
        action.payload &&
        action.payload.response &&
        action.payload.response.data &&
        Array.isArray(action.payload.response.data)
          ? action.payload.response.data.map(e => e.message).join("<br/>")
          : null

      state = {
        ...state,
        loading: false,
        confirmEmailResult: (confirmEmailError && {
          type: "error",
          message: confirmEmailError
        }) || {
          type: "success",
          message: action.payload.message
        }
      }
      break

    // case ALTERAR_VINCULOS_RESULT:
    //   if (action.payload.data.success) {
    //     state.user.vinculos = action.payload.data.vinculos
    //   }
    //
    //   state = { ...state }
    //   break
    default:
      return state
  }

  return state
}

export default account
