import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {apiBuscarVinculosSolicitacoes} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {Badge, Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap"
import {AvField, AvForm} from "availity-reactstrap-validation"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const SolicatacaoVinculosTable = forwardRef((props, refFather) => {

    const ref = useRef()
    const {filters, columns, perfis, alterarVinculos, usePermissionEdit} = props
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [status, setStatus] = useState(null)
    const [rowSelection, setRowSelection] = useState({})

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        }
    }))

    const onStatus = status => {
        setStatus(status)
        setModal(true)
    }

    const describeStatusColor = vinculo_status => {
        switch (vinculo_status) {
            case "A":
            case "T":
                return "success"
            case "R":
                return "danger"
            case "P":
            case "E":
                return "warning"
            default:
                return ""
        }
    }

    const describeStatus = vinculo_status => {
        switch (vinculo_status) {
            case "A":
                return "Aceito"
            case "R":
                return "Rejeitado"
            case "P":
                return "Pendente"
            case "T":
                return "Aceite Temporário"
            case "E":
                return "Expirado"
            default:
                return "N/D"
        }
    }

    const alterar = (e, v) => {
        alterarVinculos(Object.keys(rowSelection), status, v?.category)
        setModal(false)
    }

    const onRowSelectionChange = (rowSelection) => {
        setRowSelection(rowSelection)
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "ID Cloud", accessorKey: "id", size: 100, enableHiding: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: "Status", accessorKey: "vinculo_status", size: 120,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={"font-size-12 badge-soft-" + describeStatusColor(cell.getValue())}
                            color={describeStatusColor(cell.getValue())}
                            pill>
                            {describeStatus(cell.getValue())}
                        </Badge>
                    </Box>),
                filterSelectOptions: [
                    {text: "Aceito", value: "A"},
                    {text: "Rejeitado", value: "R"},
                    {text: "Pendente", value: "P"},
                    {text: "Aceite Temporário", value: "T"},
                    {text: "Expirado", value: "E"},
                ],
                filterVariant: 'multi-select'
            },
            {header: "CNPJ", accessorKey: "empresaCNPJ", size: 120, filterFn: 'contains'},
            {header: "Razão Social", accessorKey: "empresaRazaoSocial", filterFn: 'contains'},
            {
                header: "Usuário",
                accessorKey: "usuarioNomeCompleto",
                Cell: ({cell}) => cell?.getValue() ? cell?.getValue() : "N/A"
            },
            {header: "E-mail", accessorKey: "email", size: 120, filterFn: 'contains'},
            {
                header: "Perfil",
                accessorKey: "perfilNome",
                size: 120,
                Cell: ({cell}) => cell?.getValue() ? cell?.getValue() : "N/A"
            },
            {
                header: "Data da Solicitação",
                accessorKey: "data_solicitacao",
                size: 150,
                Cell: ({cell}) => describeDateTime(cell?.getValue()),
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {
                header: "Data do Status",
                accessorKey: "vinculo_status_data",
                size: 150,
                Cell: ({cell}) => describeDateTime(cell?.getValue()),
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarVinculosSolicitacoes(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{}}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
                enableRowSelection={true}
                getRowId={row => row.id}
                onRowSelectionChange={onRowSelectionChange}
            />

            <div className="button-items my-3">
                <Button
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light me-2"
                    onClick={e => onStatus("A")}
                    disabled={!rowSelection || Object.keys(rowSelection).length <= 0 || !usePermissionEdit}>
                    Aceitar
                </Button>

                <Button
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light me-2"
                    onClick={e => onStatus("T")}
                    disabled={!rowSelection || Object.keys(rowSelection).length <= 0 || !usePermissionEdit}>
                    Aceitar por 24 horas
                </Button>

                <Button
                    type="button"
                    color="danger"
                    className="btn-rounded waves-effect waves-light"
                    onClick={e => onStatus("R")}
                    disabled={!rowSelection || Object.keys(rowSelection).length <= 0 || !usePermissionEdit}>
                    Rejeitar
                </Button>
            </div>

            <div className="alert alert-warning fade show" role="alert">
                Para aceitar ou rejeitar um vinculo selecione uma
                solicitação da lista.
            </div>

            {status !== "R" && (
                <Modal isOpen={modal}>
                    <ModalHeader tag="h4">Selecione o perfil</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={(e, v) => {
                            alterar(e, v)
                        }}>
                            <Row form>
                                <Col className="col-12">
                                    <AvField type="select" name="category"
                                             value={event ? event.category : "bg-primary"}>
                                        <option value="">Selecione...</option>
                                        {perfis &&
                                            perfis.map((item, key) => (
                                                <option key={item.value} value={item.id}>{item.nome}</option>
                                            ))}
                                    </AvField>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 10}}>
                                <Col>
                                    <div className="text-right">
                                        <button
                                            type="button"
                                            className="btn btn-light me-2"
                                            onClick={e => setModal(false)}>
                                            Voltar
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-success save-event">
                                            Continuar
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}
            {status === "R" && (
                <Modal isOpen={modal}>
                    <ModalHeader tag="h4">Confirmação</ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente Reprovar este vínculo?
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div className="text-right">
                                    <button
                                        type="button"
                                        className="btn btn-light me-2"
                                        onClick={e => setModal(false)}>
                                        Voltar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success save-event"
                                        onClick={e => alterar(e)}>
                                        Continuar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            )}
        </React.Fragment>
    )
})

export default SolicatacaoVinculosTable

SolicatacaoVinculosTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
    usePermissionEdit: PropTypes.bool,
    alterarVinculos: PropTypes.func,
}
