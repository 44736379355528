import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Date Picker
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import "react-phone-number-input/style.css"

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  buscarGrupoProduto,
  cadastrarGrupoProduto,
  editarGrupoProduto,
  removerGrupoProduto
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import Select from "react-select"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import {getCompanies} from "../../helpers/utils"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarGrupoProduto = props => {

  const usePermissionEdit = usePermission("gruposProduto_editar")
  const usePermissionInsert = usePermission("gruposProduto_inserir")
  const usePermissionRemove = usePermission("gruposProduto_excluir")
  const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

  const [companies, setCompanies] = useState([{ label: "Todas", value: "" }])
  const [id, setId] = useState()
  const [empresa, setEmpresa] = useState()
  const [codigo, setCodigo] = useState()
  const [descricao, setDescricao] = useState()
  const [perfumaria, setPerfumaria] = useState(false)
  const [descontoMaximo, setDescontoMaximo] = useState(0)
  const [descontoMaximoTodos, setDescontoMaximoTodos] = useState(false)
  const [semDesconto, setSemDesconto] = useState(false)
  const [idGrupo, setIdGrupo] = useState(false)
  const [tabela, setTabela] = useState(false)
  const [balanca, setBalanca] = useState(false)

  useEffect(() => {
    if (props.grupoProdutoAtivo && props.grupoProdutoAtivo.id) {
      props.buscarGrupoProduto(props.grupoProdutoAtivo)
    } else {
      setId(null)
      setEmpresa(companies[0].value)
    }

  }, [props.grupoProdutoAtivo])

  useEffect(() => {
    setId(props.grupoProduto?.id)
    setEmpresa(props.grupoProduto?.idEmpresa)
    setCodigo(props.grupoProduto?.Codigo)
    setDescricao(props.grupoProduto?.Descricao)
    setPerfumaria(props.grupoProduto?.Perfumaria == "S" ? true : false)
    setDescontoMaximo(props.grupoProduto?.Desc_maximo)
    setDescontoMaximoTodos(props.grupoProduto?.Desc_maximo_todos == "S" ? true : false)
    setSemDesconto(props.grupoProduto?.Sem_desconto == "S" ? true : false)
    setIdGrupo(props.grupoProduto?.idgrupo == "S" ? true : false)
    setTabela(props.grupoProduto?.Tabela == "S" ? true : false)
    setBalanca(props.grupoProduto?.Balanca == "S" ? true : false)
  }, [props.grupoProduto])

  const excluirGrupoProduto = () => {
    props.removerGrupoProduto({ id }, props.history)
  }

  const clonarGrupoProduto = () => {
    setId(null)
    setCodigo(null)
    setDescricao(`${ descricao } (cópia)`)
    setEmpresa(companies[0].value)
    props.grupoProduto.id = null
    props.grupoProduto.Codigo = null
    props.grupoProduto.iCodigo = null // caso seja clonado não deverá haver o iCodigo

    window.scrollTo(0, 0)
  }

  const gravarGrupoProduto = () => {
    let valid = true
    valid = valid && (empresa ? true : false)
    valid = valid && (codigo && codigo !== "" ? true : false)
    valid = valid && (descricao && descricao !== "" ? true : false)
    valid = valid && (descontoMaximo && (descontoMaximo > 100.0 || descontoMaximo < 0.0) ? false : true)

    if (valid) {
      salvarGrupoProduto()
    } else {
      const divs = document.getElementsByName("validate-required")
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.display = "block"
      }
    }
  }

  const changeHandeler = (valor, elementId, validacao) => {
    if (validacao !== null && validacao !== undefined) {
      if (validacao === false) {
        document.getElementById(elementId).style.display = "block"
      } else {
        document.getElementById(elementId).style.display = "none"
      }
    } else {
      if (valor !== "") {
        document.getElementById(elementId).style.display = "none"
      } else {
        document.getElementById(elementId).style.display = "block"
      }
    }
  }

  const salvarGrupoProduto = () => {
    const grupoProduto = props.grupoProduto ? Object.assign({}, props.grupoProduto) : {}
    grupoProduto.idEmpresa = empresa
    grupoProduto.Codigo = codigo
    grupoProduto.Descricao = descricao
    grupoProduto.Perfumaria = perfumaria ? "S" : "N"
    grupoProduto.Desc_maximo = descontoMaximo == "" ? 0 : descontoMaximo
    grupoProduto.Desc_maximo_todos = descontoMaximoTodos ? "S" : "N"
    grupoProduto.Sem_desconto = semDesconto ? "S" : "N"
    grupoProduto.idgrupo = idGrupo ? "S" : "N"
    grupoProduto.Tabela = tabela ? "S" : "N"
    grupoProduto.Balanca = balanca ? "S" : "N"

    if (grupoProduto.id) {
      props.editarGrupoProduto(grupoProduto, props.history)
    } else {
      props.cadastrarGrupoProduto(grupoProduto, props.history)
    }
  }

  useEffect(() => {
    const tmpCompanies = getCompanies(props.user)
    setCompanies(tmpCompanies)
    setEmpresa(tmpCompanies[0].value)

    return () => {
      setCompanies([])
    }
  }, [])

  if (!props.user || !possuiVinculo) {
    return (<AlertaSemVinculo/>)
  }

  return (<React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Cadastro"
          breadcrumbItem="Grupo de Produto"
        />
        <StickerToolbarButtons>
          <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light me-2"
              disabled={ !empresa || !((id && usePermissionEdit) || (!id && usePermissionInsert) || props.grupoProduto?.iCodigo) }
              onClick={ e => gravarGrupoProduto(e) }>
            { id ? "Salvar Grupo de Produto" : "Cadastrar Grupo de Produto" }
          </Button>
          <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light me-2"
              disabled={ !(id && usePermissionInsert) }
              onClick={ e => clonarGrupoProduto(e) }>
            { "Clonar Grupo de Produto" }
          </Button>
          <Button
              type="submit"
              color="danger"
              className="waves-effect waves-light me-2"
              disabled={ !(id && usePermissionRemove) || props.grupoProduto?.iCodigo }
              onClick={ e => excluirGrupoProduto(e) }>
            { "Remover Grupo de Produto" }
          </Button>
        </StickerToolbarButtons>
        <Row>
          <Col lg="12">
            <div>
              <Card>
                <CardBody>
                  <Form>
                    <div className="outer">
                      <div className="outer">
                        { id && <FormGroup className="mb-4" row>
                          <Label htmlFor="empresa" className="col-form-label col-lg-2">
                            Identificador
                          </Label>
                          <Col lg="10" className="col-form-label">
                            { id }
                          </Col>
                        </FormGroup> }

                        { id && <FormGroup className="mb-4" row>
                          <Label htmlFor="codigo" className="col-form-label col-lg-2">
                            Código
                          </Label>
                          <Col lg="10" className="col-form-label">
                            { codigo }
                          </Col>
                        </FormGroup> }

                        { !id && <FormGroup className="mb-4" row>
                          <Label htmlFor="codigo" className="col-form-label col-lg-2">
                            Código
                          </Label>
                          <Col lg="10">
                            <Input
                              id="codigo"
                              name="codigo"
                              type="number"
                              className="form-control"
                              value={ codigo }
                              onChange={ e => {
                                setCodigo(e.target.value)
                                changeHandeler(e.target.value, "validateCodigo")
                              } }
                              placeholder="Digite o Código"
                              required
                              maxLength={ 5 }
                              disabled={ props.grupoProduto?.id }
                            />
                            <div className={ codigo ? "" : "invalid-tooltip" }
                                 name="validate" id="validateCodigo">
                              { codigo ? "" : "Código é obrigatório" }
                            </div>
                          </Col>
                        </FormGroup> }

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="empresa" className="col-form-label col-lg-2">
                            Empresa
                          </Label>
                          <Col lg="10">
                            <Select
                              id="empresa"
                              name="empresa"
                              className="basic-single"
                              options={ companies.filter(c => c.value) }
                              placeholder="Digite a empresa..."
                              isClearable={ true }
                              isSearchable={ true }
                              noOptionsMessage={ () => "Nenhuma empresa encontrada" }
                              onChange={ e => {
                                setEmpresa(e?.value)
                                changeHandeler(e?.value, "validateCompany")
                              } }
                              value={ empresa ? companies.find(e => e.value === empresa) : undefined }
                              required
                              isDisabled={ props.grupoProduto?.id }
                            />
                            <div className={ empresa ? "" : "invalid-tooltip" }
                                 name="validate" id="validateCompany">
                              { empresa ? "" : "Empresa é obrigatório" }
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="descricao" className="col-form-label col-lg-2">
                            Descrição
                          </Label>
                          <Col lg="10">
                            <Input
                              id="descricao"
                              name="descricao"
                              type="text"
                              className="form-control"
                              value={ descricao }
                              onChange={ (e) => {
                                setDescricao(e.target.value)
                                changeHandeler(e.target.value, "validateDescricao")
                              } }
                              placeholder="Digite a Descrição"
                              required
                              maxLength={ 70 }
                            />
                            <div className={ "invalid-tooltip" } name="validate" id="validateDescricao">
                              { "Descrição é obrigatório" }
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="perfumaria" className="col-form-label col-lg-2">
                            Perfumaria
                          </Label>
                          <Col lg="10">
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="perfumaria-switch"
                                switch="none"
                                checked={ perfumaria  }
                                onChange={ () => setPerfumaria(!perfumaria) }
                              />
                              <label
                                htmlFor="perfumaria-switch"
                                data-on-label="Sim"
                                data-off-label="Não"
                              />
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="descontoMaximo" className="col-form-label col-lg-2">
                            Desconto Máximo
                          </Label>
                          <Col lg="10">
                            <InputGroup>
                              <Input
                                id="descontoMaximo"
                                name="descontoMaximo"
                                type="number"
                                className="form-control"
                                value={ descontoMaximo }
                                onChange={ e => {
                                  setDescontoMaximo(e.target.value)
                                  changeHandeler(e.target.value, "validateDescontoMaximo")
                                } }
                                placeholder="Digite o Desconto Máximo"
                                max={ 100 }
                                min={ 0 }
                                maxLength={3}
                              />
                              <span className="input-group-append">
                                <span className="input-group-text">%</span>
                              </span>
                            </InputGroup>
                            <div className={ "invalid-tooltip" } name="validate" id="validateDescontoMaximo">
                              { "Desconto máximo deve ser um valor entre 0 e 100" }
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="descontoMaximoTodos" className="col-form-label col-lg-2">
                            Desconto máximo para todos
                          </Label>
                          <Col lg="10">
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="descontoMaximoTodos-switch"
                                switch="none"
                                checked={ descontoMaximoTodos }
                                onChange={ () => setDescontoMaximoTodos(!descontoMaximoTodos) }
                              />
                              <label
                                htmlFor="descontoMaximoTodos-switch"
                                data-on-label="Sim"
                                data-off-label="Não"
                              />
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="semDesconto" className="col-form-label col-lg-2">
                            Sem desconto
                          </Label>
                          <Col lg="10">
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="semDesconto-switch"
                                switch="none"
                                checked={ semDesconto }
                                onChange={ () => setSemDesconto(!semDesconto) }
                              />
                              <label
                                htmlFor="semDesconto-switch"
                                data-on-label="Sim"
                                data-off-label="Não"
                              />
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="idGrupo" className="col-form-label col-lg-2">
                            Id grupo
                          </Label>
                          <Col lg="10">
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="idGrupo-switch"
                                switch="none"
                                checked={ idGrupo }
                                onChange={ () => setIdGrupo(!idGrupo) }
                              />
                              <label
                                htmlFor="idGrupo-switch"
                                data-on-label="Sim"
                                data-off-label="Não"
                              />
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="tabela" className="col-form-label col-lg-2">
                            Tabela
                          </Label>
                          <Col lg="10">
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="tabela-switch"
                                switch="none"
                                checked={ tabela }
                                onChange={ () => setTabela(!tabela) }
                              />
                              <label
                                htmlFor="tabela-switch"
                                data-on-label="Sim"
                                data-off-label="Não"
                              />
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label htmlFor="tabela" className="col-form-label col-lg-2">
                            Envia grupo para balança
                          </Label>
                          <Col lg="10">
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="balanca-switch"
                                switch="none"
                                checked={ balanca }
                                onChange={ () => setBalanca(!balanca) }
                              />
                              <label
                                htmlFor="balanca-switch"
                                data-on-label="Sim"
                                data-off-label="Não"
                              />
                            </div>
                          </Col>
                        </FormGroup>

                      </div>
                    </div>
                  </Form>

                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>)
}

CadastrarGrupoProduto.propTypes = {
  grupoProduto: PropTypes.any,
  grupoProdutoAtivo: PropTypes.any,
  grupoProdutoLoading: PropTypes.bool,
  cadastrarGrupoProduto: PropTypes.func,
  editarGrupoProduto: PropTypes.func,
  removerGrupoProduto: PropTypes.func
}

const mapStatetoProps = state => {
  const { grupoProdutoAtivo, grupoProduto, grupoProdutoLoading } = state.Profile
  const { user } = state.Login
  return { user, grupoProdutoAtivo, grupoProduto, grupoProdutoLoading }
}

export default connect(mapStatetoProps, {
  cadastrarGrupoProduto, removerGrupoProduto, editarGrupoProduto, buscarGrupoProduto
})(withRouter(CadastrarGrupoProduto))
