import PropTypes from "prop-types"
import React from "react"
import {FormGroup, Label} from "reactstrap"
import {NumericFormat} from "react-number-format"

const FormFilterNumber = props => {
    const {attribute, value, description, changeHandeler, suffix, decimalScale, max, min, allowNegative} = props

    return (
        <React.Fragment>
            <FormGroup className="mt-3 mb-0">
                <Label>{description}</Label>
                <NumericFormat
                    id={attribute + "-number"}
                    name={attribute + "-number"}
                    className="form-control"
                    value={value}
                    onValueChange={(values, sourceInfo) => {
                        changeHandeler(values.floatValue)
                    }}
                    suffix={suffix}
                    decimalSeparator=","
                    thousandSeparator="."
                    allowNegative={allowNegative !== undefined ? allowNegative : true}
                    decimalScale={decimalScale !== undefined ? decimalScale : 0}
                    isAllowed={(values, sourceInfo) => {
                        const {floatValue} = values
                        let valid = true
                        if (floatValue !== undefined) {
                            if (max !== undefined) {
                                valid &= floatValue <= max
                            }
                            if (min !== undefined) {
                                valid &= floatValue >= min
                            }
                        }
                        return valid
                    }}
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormFilterNumber.propTypes = {
    attribute: PropTypes.string,
    value: PropTypes.any,
    description: PropTypes.string,
    changeHandeler: PropTypes.func,
    suffix: PropTypes.string,
    decimalScale: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    allowNegative: PropTypes.bool
}

export default FormFilterNumber
