import React from "react"
import { Col, Container, Row } from "reactstrap"

const AlertaSemVinculo = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row className="justify-content-center">
            <Col sm={ 8 }>
              <div className="alert alert-warning fade show" role="alert">
                Seu cadastro não está vinculado a nenhuma empresa.
                <br />
                <br />
                Se você já solicitou o vínculo aguarde a liberação do vínculo
                ou faça contato com o administrador da empresa e peça a
                liberação com urgência.
                <br />
                <br />
                Se você já possuía acesso, deverá solicitar novamente para a
                empresa.
                <br />
                <br />
                Se você ainda não solicitou o vínculo informe o CNPJ e E-mail
                da empresa e clique no botão Enviar para registrar o pedido de
                vínculo.
                <br />
                <br />
                Depois é só aguardar a liberação e retornar ao sistema.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AlertaSemVinculo.propTypes = {
}

export default AlertaSemVinculo
