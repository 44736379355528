import OneSignal from "react-onesignal"
import { onesignal } from "../config"

class OneSignalHelper {
  initialize = () => {
    return OneSignal.init(onesignal.options)
  }

  async setUserData(user) {
    const enabled = await OneSignal.isPushNotificationsEnabled()
    if (enabled) {
      await OneSignal.setExternalUserId(user.id)
    }
  }

  async removerUserData(user) {
    const enabled = await OneSignal.isPushNotificationsEnabled()
    if (enabled) {
      await OneSignal.removeExternalUserId()
    }
  }
}

let _onesignalHelper = null

const initOneSignalHelper = () => {
  if (!_onesignalHelper) {
    _onesignalHelper = new OneSignalHelper()
  }
  return _onesignalHelper
}

const getOneSignalHelper = () => {
  return _onesignalHelper
}

export { initOneSignalHelper, getOneSignalHelper }
