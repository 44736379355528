import React, {useState} from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip} from "reactstrap";
import {isEmpty, map} from "lodash";
import {Link} from "react-router-dom";
import {AvForm} from "availity-reactstrap-validation";
import {describeDateTime} from "../../helpers/utils";
import FormInputText from "../../components/Common/form-input-text";
import usePermission from "../../helpers/permission/usePermission";
import LoaderList from "../../components/Common/loaderList";

const CodigoBarrasLista = props => {

    const [codigoBarras, setCodigoBarras] = useState() // Código de barras - tb_pro_codigo - cod_barra
    const [modalRemove, setModalRemove] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState()
    const [cadastrar, setCadastrar] = useState()
    const usePermissionInsert = usePermission("codigoBarras_inserir")
    const usePermissionRemove = usePermission("codigoBarras_excluir")

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const removerCodigoBarras = () => {
        props.removerCodigoBarras(itemSelecionado)
        setModalRemove(false)
    }

    const cadastrarCodigoBarras = () => {
        props.cadastrarCodigoBarras({ codigoBarras })
        setCodigoBarras(null)
        setCadastrar(false)
    }

    return <>
        {
            props.loading ? <LoaderList></LoaderList> :
                <>
                    <div className="table-responsive">
                        <Table className="table table-centered table-nowrap">
                            <thead>
                            <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Código de barras</th>
                                <th scope="col">Data de inclusão</th>
                                <th scope="col">Usuário incluiu</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(isEmpty(props.listaCodigoBarras) &&
                                    (<tr>
                                        <td colSpan={5}>
                                            <h5 className="text-truncate font-size-14 m-0">
                                                Não há nenhum código de barras
                                            </h5>
                                        </td>
                                    </tr>)) ||
                                map(props.listaCodigoBarras, (item, i) => (<tr key={i}>
                                    <td>
                                        <div>
                                            <p className="text-muted mb-0">{item.id}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="text-muted mb-0">
                                                {item.cod_barra}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="text-muted mb-0">
                                                {describeDateTime(item.inc_data)}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="text-muted mb-0">
                                                {item.inc_func}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        { usePermissionRemove && !props.loading && <Link to="#" className="text-danger">
                                            <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
                                               onClick={() => showConfirmDelete(item)} title="Remover"/>
                                        </Link>
                                        }
                                    </td>
                                </tr>))}
                            </tbody>
                        </Table>
                    </div>

                    {cadastrar && <FormInputText
                        attribute="codigoBarras"
                        description="Código de barras"
                        placeholder="Digite o novo código de barras"
                        value={codigoBarras}
                        setValue={setCodigoBarras}
                        maxLength={14}
                    />}

                    <div className="d-flex justify-content-start">
                        {!cadastrar && <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            onClick={e => setCadastrar(true)}
                            disabled={!usePermissionInsert}>
                            {"Incluir novo código"}
                        </Button>}

                        {cadastrar && <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!codigoBarras || props.loading}
                            onClick={e => cadastrarCodigoBarras(e)}>
                            {"Salvar novo código"}
                        </Button>}

                        {cadastrar && <Button
                            type="submit"
                            color="danger"
                            className="waves-effect waves-light me-2"
                            onClick={e => setCadastrar(false)}>
                            {"Cancelar"}
                        </Button>}
                    </div>

                    <Modal isOpen={modalRemove}>
                        <ModalHeader tag="h4">Confirmação</ModalHeader>
                        <ModalBody>
                            <AvForm onValidSubmit={(e, v) => {
                                removerCodigoBarras()
                            }}>
                                <Row form>
                                    <Col className="col-12">
                                        Deseja realmente remover esse item?
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 10}}>
                                    <Col>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-success save-event">
                                                Confirmar
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-light ms-2"
                                                onClick={e => setModalRemove(false)}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                </>
        }
    </>
}

CodigoBarrasLista.propTypes = {
    listaCodigoBarras: PropTypes.array,
    cadastrarCodigoBarras: PropTypes.any,
    removerCodigoBarras: PropTypes.any,
    loading: PropTypes.bool
}

export default CodigoBarrasLista