import { all, call, fork, put, takeEvery } from "redux-saga/effects"

//Account Redux states
import { CONFIRM_EMAIL, REGISTER_USER, SOLICITAR_VINCULO } from "./actionTypes"
import {
  confirmEmailResult,
  registerUserFailed,
  registerUserSuccessful,
  solicitarVinculoFailed,
  solicitarVinculoSuccessful
} from "./actions"

//Include Both Helper File with needed methods
import {apiCadastrarVinculo, apiConfirmEmail, postJwtRegister} from "../../../helpers/backend_helper"
import swal from "sweetalert"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtRegister, user)
    yield put(registerUserSuccessful(response))

    history.push("/verificar-email")
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* solicitarVinculo({ payload: { dados, history } }) {
  try {
    const response = yield call(apiCadastrarVinculo, dados)
    swal({ title: "Solicitação realizada com sucesso!", icon: "success" })
    yield put(solicitarVinculoSuccessful(response))
    history.push("/vinculos")
  } catch (error) {
    yield put(solicitarVinculoFailed(error))
  }
}

function* confirmEmail({ payload: { data, history } }) {
  try {
    const response = yield call(apiConfirmEmail, data)
    yield put(confirmEmailResult(response))

    swal({
      title: "Confirmação realizada com sucesso! Faça login para continuar.",
      icon: "success"
    })
    history.push("/login")
  } catch (error) {
    yield put(confirmEmailResult(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(CONFIRM_EMAIL, confirmEmail)
}

export function* watchSolicitarVinculo() {
  yield takeEvery(SOLICITAR_VINCULO, solicitarVinculo)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
  yield all([fork(watchSolicitarVinculo)])
}

export default accountSaga
