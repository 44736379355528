import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { doEditCategory, doEditQuestion } from "../../store/auth/profile/actions"
import LinkRestricted from "../LinkRestricted"

const SidebarMenu = props => {

  const isInforangra = props.user?.usuario_info
  const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      return false;
    }
    return false;
  }


  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menu</li>
          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-home"/>
              <span>Dashboards</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="dashboard_listar" to="/dashboard-padrao">Padrão</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <LinkRestricted permissionKey="usuarios_listar" to="/usuarios">
              <i className="bx bx-user"/>
              <span>{"Usuários"}</span>
            </LinkRestricted>
          </li>

          <li>
            <LinkRestricted permissionKey="empresas_listar" to="/empresas">
              <i className="bx bx-home"/>
              <span>{isInforangra ? "Empresas" : "Minhas empresas"}</span>
            </LinkRestricted>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-certification"/>
              <span>Certificados digitais</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="empresas_editar" to="/enviar-certificado">Enviar
                  certificado</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="empresas_listar" to="/certificados-digitais">Certificados</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-copy-alt"/>
              <span>Gestão Fiscal</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="notasEmitidas_listar" to="/notas-emitidas">Notas
                  Emitidas</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx bx-analyse"/>
              <span>Gestão Financeira</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="movimentoCaixa_listar" to="/movimento-caixas">Movimento de
                  Caixas</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="contasPagar_listar" to="/contas-pagar">Contas a pagar</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="movimentoVendas_listar" to="/movimento-vendas">Movimento de vendas do
                  Fastfood</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx bx-customize"/>
              <span>Módulo SMD</span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-detail"/>
                  <span>Cadastro</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <LinkRestricted permissionKey="fornecedores_listar" to="/fornecedores">Fornecedores</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="gruposProduto_listar" to="/grupos-produto">Categorias
                      (Grupos)</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="produtos_listar" to="/produtos">Produtos (estoque)</LinkRestricted>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bxs-pizza"/>
              <span>Módulo FASTFOOD</span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-detail"/>
                  <span>Cadastro</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <LinkRestricted permissionKey="fornecedores_listar" to="/fornecedores">Fornecedores</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="gruposCardapio_listar" to="/grupos-cardapio">Grupo
                      (categoria)</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="gruposEstoque_listar" to="/grupos-estoque">Grupo de estoque
                      (categoria de insumos)</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="estoques_listar" to="/estoques">Estoque (insumos)</LinkRestricted>
                  </li>
                </ul>
              </li>
              <li>
                <LinkRestricted permissionKey="movimentoVendas_listar" to="/movimento-vendas">Movimento de
                  vendas</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-report"/>
              <span>Relatórios</span>
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-money"/>
                  <span>Financeiro</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <LinkRestricted permissionKey="dreGerencial_listar" to="/dre">DRE Gerencial</LinkRestricted>
                  </li>
                  <li>
                    <LinkRestricted permissionKey="movimentoVendas_listar" to="/movimento-vendas">Movimento de vendas do
                      Fastfood</LinkRestricted>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-command"/>
              <span>Vínculos</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="solicitarVinculos_inserir"
                                to="/solicitar-vinculo">Solicitar</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="vinculos_listar" to="/vinculos">Listar</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect" aria-expanded="false">
              <i className="bx bx-book-content"/>
              <span>Cardápio - Peça Aqui</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="dispositivos_listar"
                                to="/cardapio-dispositivos">Dispostivos</LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className={"has-arrow waves-effect"} aria-expanded="false">
              <i className="bx bx-help-circle"/>
              <span>Perguntas Frequentes</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="perguntas_listar" to="/perguntas-frequentes">Lista</LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="perguntasEdit_editar" to="/pergunta"
                                onClick={e => props.doEditQuestion(null)}>
                  Cadastro de Pergunta
                </LinkRestricted>
              </li>
              <li>
                <LinkRestricted permissionKey="categoriasEdit_editar" to="/categoria"
                                onClick={e => props.doEditCategory(null)}>
                  Cadastro de Categoria
                </LinkRestricted>
              </li>
            </ul>
          </li>

          <li>
            <LinkRestricted permissionKey="sugestoes_listar" to="/sugestoes" className="waves-effect">
              <i className="bx bx-star"/>
              <span>Sugestões</span>
            </LinkRestricted>
          </li>

          <li>
            <LinkRestricted permissionKey="notificacoes_listar" to="/notificacoes" className="waves-effect">
              <i className="bx bx-bell"/>
              <span>Notificações</span>
            </LinkRestricted>
          </li>

          <li>
            <LinkRestricted permissionKey="templatesEmail_listar" to="/templates-email" className="waves-effect">
              <i className="bx bx-mail-send"/>
              <span>Templates de e-mail</span>
            </LinkRestricted>
          </li>

          <li>
            <LinkRestricted permissionKey="painelNotificacoes_listar" to="/painel-notificacao" className="waves-effect">
              <i className="bx bxs-bell-ring"/>
              <span>Painel de notificações</span>
            </LinkRestricted>
          </li>

          <li>
            <Link to="/#" className={"has-arrow waves-effect"} aria-expanded="false">
              <i className="bx bx-table"/>
              <span>Manutenção</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <LinkRestricted permissionKey="perfil_listar" to="/perfil">
                  Perfil de usuário
                </LinkRestricted>
              </li>
              <li>
                <Link to="/#" className={"has-arrow waves-effect"} aria-expanded="false">
                  <i className="bx bx-table"/>
                  <span>Tabelas</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <LinkRestricted permissionKey="tabelaNCM_listar" to="/tabela-ncm">
                      Tabela NCM
                    </LinkRestricted>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const {user} = state.Login
  return {user}
}

SidebarMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  user: PropTypes.any
}

export default withRouter(
    connect(mapStatetoProps, {
      doEditCategory,
      doEditQuestion
    })((SidebarMenu))
)
