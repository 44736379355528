import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {apiBuscarVendas, apiBuscarVinculos} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {Badge} from "reactstrap"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const VinculosTable = forwardRef((props, refFather) => {

    const ref = useRef()
    const {filters, columns} = props
    const [data, setData] = useState([])

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        }
    }));

    const describeStatusColor = vinculo_status => {
        switch (vinculo_status) {
            case "A":
            case "T":
                return "success"
            case "R":
                return "danger"
            case "P":
            case "E":
                return "warning"
            default:
                return ""
        }
    }

    const describeStatus = vinculo_status => {
        switch (vinculo_status) {
            case "A":
                return "Aceito"
            case "R":
                return "Rejeitado"
            case "P":
                return "Pendente"
            case "T":
                return "Aceite Temporário"
            case "E":
                return "Expirado"
            default:
                return "N/D"
        }
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "ID Cloud", accessorKey: "id", size: 80, enableHiding: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {header: "Status", accessorKey: "vinculo_status", size: 120,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={ "font-size-12 badge-soft-" + describeStatusColor(cell.getValue()) }
                            color={ describeStatusColor(cell.getValue()) }
                            pill>
                            { describeStatus(cell.getValue()) }
                        </Badge>
                    </Box>),
                filterSelectOptions: [
                    {text: "Aceito", value: "A"},
                    {text: "Rejeitado", value: "R"},
                    {text: "Pendente", value: "P"},
                    {text: "Aceite Temporário", value: "T"},
                    {text: "Expirado", value: "E"},
                ],
                filterVariant: 'multi-select'},
            {header: "CNPJ", accessorKey: "empresaCNPJ", size: 120},
            {header: "Razão Social", accessorKey: "empresaRazaoSocial", size: 120},
            {header: "E-mail", accessorKey: "email", size: 120, filterFn: 'contains'},
            {header: "Perfil", accessorKey: "perfilNome", size: 120 },
            {header: "Data da Solicitação", accessorKey: "data_solicitacao", size: 150, Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Data do Status", accessorKey: "vinculo_status_data", size: 150,
                Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarVinculos(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{
                }}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
            />
        </React.Fragment>
    )
})

export default VinculosTable

VinculosTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
}
