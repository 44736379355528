import PropTypes from "prop-types"
import React from "react"
import {Card, CardBody, Col, Media, UncontrolledTooltip} from "reactstrap"
import Loader from "react-loader-spinner"

const Tooltip = props => {
  const { id, tooltip } = props
  return (
      <React.Fragment>
        <i id={id} className="bx bx-help-circle font-size-14 ms-2 text-primary"/>
        <UncontrolledTooltip
            placement="top"
            target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </React.Fragment>
  )
}

Tooltip.propTypes = {
  id: PropTypes.string,
  tooltip: PropTypes.string
}

export default Tooltip
