import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardTitle, Col, Collapse, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import 'react-phone-number-input/style.css'

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { editarEmpresaCertificadoDigital} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import Loader from "react-loader-spinner"
import FormInputText from "../../components/Common/form-input-text"
import "toastr/build/toastr.min.css"
import moment from "moment-timezone"
import {getCompanies } from "../../helpers/utils"
import toastr from "toastr"
import FormSelectCompany from "../../components/Common/form-select-company";
import FormInputFile from "../../components/Common/form-input-file";
import FormInputPassword from "../../components/Common/form-input-password";

const brazilMoment = (...args) => moment(...args)
registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastroCertificadoDigital = props => {

    const isInforangra = props.user?.usuario_info
    const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
    const usePermissionEdit = usePermission("empresas_editar") && isInforangra
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [loading, setLoading] = useState(false)

    const [companies, setCompanies] = useState([{label: "Todas", value: ""}])

    const [empresaId, setEmpresaId] = useState()
    const [razaoSocial, setRazaoSocial] = useState()
    const [cnpj, setCnpj] = useState()
    const [certificado, setCertificado] = useState()
    const [certificadoSenha, setCertificadoSenha] = useState()

    useEffect(() => {
        const tmpCompanies = getCompanies(props.user)
        setCompanies(tmpCompanies)
        setEmpresaId(tmpCompanies[0].value)
        setCnpj(tmpCompanies[0].CNPJ)
        setRazaoSocial(tmpCompanies[0].RazaoSocial)

        return () => {
            setCompanies([])
        }
    }, [])

    const gravarCertificado = (e) => {
        const empresa = props.empresa ? Object.assign({}, props.empresa) : {}

        let valid = true
        const empresaValid = empresaId !== undefined
        valid = valid && empresaValid

        if(valid) {
            empresa.id = empresaId
            empresa.certificado = certificado
            empresa.senhaCertificado = certificadoSenha
            empresa.cnpj = cnpj
            props.editarEmpresaCertificadoDigital(empresa, props.history)
        } else {
            const divs = [...document.getElementsByName("validate-required")]
            for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "block"
            }

            window.scrollTo(0, 0)
            toastr.error('Há campos obrigatórios não preenchidos ou inválidos!', 'Validação de informações')
        }
    }

    if (!props.user || !possuiVinculo) {
        return (<AlertaSemVinculo/>)
    }

    return <React.Fragment>
        {props.empresaLoading || loading ? (<div className="loader-container position-absolute">
            <Loader
                type="ThreeDots"
                color="#556EE6"
                height={80}
                width={80}
            />
        </div>) : (<div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title="Cadastro"
                    breadcrumbItem="Certificado digital"
                />
                <StickerToolbarButtons>
                    <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        disabled={!usePermissionEdit}
                        onClick={e => gravarCertificado(e)}>
                        {"Enviar"}
                    </Button>
                </StickerToolbarButtons>

                <Row>
                    <Col lg="12">
                        <div>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Enviar certificado</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">

                                                <FormSelectCompany
                                                    attribute="empresaId"
                                                    value={empresaId}
                                                    setValue={ (value) => {
                                                        setEmpresaId(value)

                                                        const company = companies.find(c => c.value === value)
                                                        setCnpj(company?.CNPJ)
                                                        setRazaoSocial(company?.RazaoSocial)
                                                    }}
                                                    options={companies.filter(c => c.value)}
                                                    required={true}
                                                />

                                                <FormInputText
                                                    attribute="cnpj"
                                                    description="CNPJ/CPF"
                                                    value={cnpj}
                                                    setValue={setCnpj}
                                                    disabled={true}
                                                />

                                                <FormInputText
                                                    attribute="razaoSocial"
                                                    description="Razão Social/Nome"
                                                    value={razaoSocial}
                                                    setValue={setRazaoSocial}
                                                    disabled={true}
                                                />

                                                <FormInputFile
                                                    attribute="certificado"
                                                    description="Certificado"
                                                    extension={".pfx"}
                                                    value={certificado}
                                                    setValue={setCertificado}
                                                    loading={ false }
                                                    required={ true}
                                                    removeHandeler={ () => setCertificado(null) }/>

                                                <FormInputPassword
                                                    attribute="certificadoSenha"
                                                    description="Senha do certificado"
                                                    value={certificadoSenha}
                                                    setValue={setCertificadoSenha}
                                                    required={true}
                                                />

                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)}
    </React.Fragment>
}

CadastroCertificadoDigital.propTypes = {
    user: PropTypes.any,
    editarEmpresaCertificadoDigital: PropTypes.func,
}

const mapStatetoProps = state => {
    const {
        empresaCertificadoLoading,
    } = state.Profile
    const {user} = state.Login
    return {
        user,
        empresaCertificadoLoading,
    }
}

export default connect(mapStatetoProps, {
    editarEmpresaCertificadoDigital,
})(withRouter(CadastroCertificadoDigital))
