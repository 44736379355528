import React, {useEffect, useState} from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {describeDate, describeDateTime} from "../../helpers/utils"
import LoaderList from "../../components/Common/loaderList"
import {MDBDataTable} from "mdbreact"
import {Box} from "@mui/material"
import {Badge} from "reactstrap"
import toastr from "toastr"

const EmpresaLicencaLista = props => {

    const [data, setData] = useState({
        columns: [
            {label: "Código", field: "Código", sort: "asc", width: 110},
            {label: "Descrição", field: "Descrição", sort: "asc", width: 230},
            {label: "Status", field: "Status", sort: "asc", width: 110},
            {label: "Validade do Registro", field: "Validade do Registro", sort: "asc", width: 110},
            {label: "Sistema liberado até", field: "Sistema liberado até", sort: "asc", width: 110},
            {label: "Central", field: "Central", sort: "asc", width: 110},
            {label: "Token", field: "Token", sort: "asc", width: 110},
            {label: "Número de série", field: "Número de série", sort: "asc", width: 230},
            {label: "Backup - Resultado", field: "Backup - Resultado", sort: "asc", width: 230},
            {label: "Backup 2 - Resultado", field: "Backup 2 - Resultado", sort: "asc", width: 230},
            {label: "Backup - Agenda", field: "Backup - Agenda", sort: "asc", width: 110},
            {label: "Backup - Base", field: "Backup - Base", sort: "asc", width: 230},
            {label: "Backup - Destino", field: "Backup - Destino", sort: "asc", width: 230},
            {label: "Backup - Segundo destino", field: "Backup - Segundo destino", sort: "asc", width: 110},
            {label: "Backup - Início", field: "Backup - Início", sort: "asc", width: 110},
            {label: "Backup - Fim", field: "Backup - Fim", sort: "asc", width: 110},
        ],
        rows: []
    })

    const getBadgeElement = (value) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (value == 'S' ? 'primary higher' : 'soft-dark')}
            color={value == 'S' ? 'primary higher' : 'soft-dark'}
            pill
        >
            {value == 'S' ? 'Sim' : 'Não'}
        </Badge>
    }

    const statusBadgeClass = status => {
        switch (status) {
            case 'A': {
                return "success"
            }
            case 'I':
            case 'S': {
                return "danger"
            }
            case 1: {
                return "warning"
            }
            case 2: {
                return "primary"
            }
            case 3: {
                return "secondary"
            }
            case 6: {
                return "soft-dark"
            }
            default: {
                return "soft-success"
            }

        }
    }

    const describeStatus = status => {
        switch (status) {
            case 'A': {
                return "Ativo"
            }
            case 'I': {
                return "Inativo"
            }
            case 'S': {
                return "Suspenso"
            }
            case 1: {
                return "Libera por 10 dias"
            }
            case 2: {
                return "Libera por 20 dias"
            }
            case 3: {
                return "Libera por 3 meses"
            }
            case 6: {
                return "Libera por 6 meses"
            }
            default: {
                return "Libera por 9 meses o Fastfood e por 1 Ano o SMD"
            }
        }
    }

    const onChaveCopy = (chave, titulo) => {
        navigator.clipboard.writeText(chave)

        toastr.options.positionClass = "toast-bottom-right"
        toastr.success("Copiada para a área de transferências.", titulo)
    }

    useEffect(() => {
        if (Array.isArray(props.listaLicenca)) {
            const rows = props.listaLicenca?.map(item => ({
                "Código": item.id,
                "Descrição": item.descricao,
                "Status": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <Badge
                        className={"font-size-12 badge-soft-" + statusBadgeClass(item.Status)}
                        color={statusBadgeClass(item.Status)}
                        pill
                    >
                        {describeStatus(item.Status)}
                    </Badge>
                </Box>),
                "Validade do Registro": describeDate(item.Validade_Registro),
                "Sistema liberado até": describeDateTime(item.Sistema_Liberado_Data),
                "Central": (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        {getBadgeElement(item.Central)}
                    </Box>),
                "Token": ( <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={ { maxWidth: "200px" } }
                         onClick={e => onChaveCopy(item.Token, 'Token')}
                         title={item.Token}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Token}
                    </div>
                </Box>),
                "Número de série": item.Numero_serie,
                "Backup - Resultado":
                    (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <div className="d-inline-block justify-content-between align-items-center text-truncate"
                             style={ { maxWidth: "200px" } }
                             onClick={e => onChaveCopy(item.Backup_Resultado, 'Backup - Resultado')}
                             title={item.Backup_Resultado}>
                            <i className={"bx bx-copy hover__cursor"}/> {item.Backup_Resultado}
                        </div>
                    </Box>),
                "Backup 2 - Resultado": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={ { maxWidth: "200px" } }
                         onClick={e => onChaveCopy(item.Backup_Resultado2, 'Backup 2 - Resultado')}
                         title={item.Backup_Resultado2}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Backup_Resultado2}
                    </div>
                </Box>),
                "Backup - Agenda": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={ { maxWidth: "200px" } }
                         onClick={e => onChaveCopy(item.Backup_Agenda, 'Backup - Agenda')}
                         title={item.Backup_Agenda}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Backup_Agenda}
                    </div>
                </Box>),
                "Backup - Base": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={ { maxWidth: "200px" } }
                         onClick={e => onChaveCopy(item.Backup_Base, 'Backup - Base')}
                         title={item.Backup_Base}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Backup_Base}
                    </div>
                </Box>),
                "Backup - Destino": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={ { maxWidth: "200px" } }
                         onClick={e => onChaveCopy(item.Backup_Destino, 'Backup - Destino')}
                         title={item.Backup_Destino}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Backup_Destino}
                    </div>
                </Box>),
                "Backup - Segundo destino": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={ { maxWidth: "200px" } }
                         onClick={e => onChaveCopy(item.Backup_Destino2, 'Backup - Segundo destino')}
                         title={item.Backup_Destino2}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Backup_Destino2}
                    </div>
                </Box>),
                "Backup - Início": describeDateTime(item.Backup_Inicio),
                "Backup - Fim": describeDateTime(item.Backup_Final)
            }))

            setData({
                columns: data.columns,
                rows: rows
            })
        }
    }, [props.listaLicenca])

    return <>
        {props.loading ? <LoaderList></LoaderList> :
            <>
                <div className="table-responsive">
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={data}
                        entriesLabel={"Mostrar Registros"}
                        infoLabel={["Exibindo", "a", "de", "registros"]}
                        noRecordsFoundLabel={"Nenhum registro encontrado"}
                        paginationLabel={["Anterior", "Próximo"]}
                        searchLabel={"Pesquisar"}
                        entries={5}
                        entriesOptions={[5, 10, 20, 50, 100]}
                    />
                </div>
            </>
        }
    </>
}

EmpresaLicencaLista.propTypes = {
    listaLicenca: PropTypes.array,
    loading: PropTypes.bool,
    empresa: PropTypes.any
}

export default EmpresaLicencaLista