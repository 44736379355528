import React, {Component} from "react"
import {Col, Row} from "reactstrap"

//Import Images
import MiniCards from "../../components/Common/mini-card"
import MiniWidget from "../../components/Common/mini-widget"
import {connect} from "react-redux"
import {
    buscarVendasDia,
    buscarVendasMes,
    buscarVendasMesAnterior,
    buscarVendasSemana
} from "../../store/auth/profile/actions"
import moment from "moment/moment";
import {describeDiff, describeDiffColor, describeMoney} from "../../helpers/utils"

class MovimentoVendasKpiCards extends Component {

    constructor(props) {
        super(props)
        this.state = {}

        props.buscarVendasDia({ data: moment().toDate(), valorVenda: true, ticket: false })
        props.buscarVendasSemana({ data: moment().toDate(), valorVenda: true, ticket: true })
        props.buscarVendasMes({ data: moment().toDate(), valorVenda: true, ticket: true })
        props.buscarVendasMesAnterior({ data: moment().subtract(1, "month").toDate(), valorVenda: true, ticket: true })
    }

    static getDerivedStateFromProps(props, current_state) {
        const state = {
            hoje: {
                title: "Vendas de hoje",
                tooltip: "Valor total da venda + taxa de entrega + taxa de serviço se houver no dia de hoje",
                iconClass: "bx-import",
                text: describeMoney(props.vendasDia && props.vendasDia[0] && props.vendasDia[0][0] ? props.vendasDia[0][0].vendas : 0),
                loading: props.vendasDiaLoading !== undefined ? props.vendasDiaLoading : false
            },
            semana: {
                title: "Vendas na semana",
                tooltip: "Valor total da venda + taxa de entrega + taxa de serviço se houver na semana (segunda à domingo)",
                iconClass: "bx-copy-alt",
                text: describeMoney(props.vendasSemana && props.vendasSemana[0] && props.vendasSemana[0][0] ? props.vendasSemana[0][0].vendas : 0),
                loading: props.vendasSemanaLoading !== undefined ? props.vendasSemanaLoading : false
            },
            mesAtual: {
                title: "Vendas no mês",
                tooltip: "Valor total da venda + taxa de entrega + taxa de serviço se houver no mês",
                icon: "bx bx-archive-in",
                value: describeMoney(props.vendasMes && props.vendasMes[0] && props.vendasMes[0][0] ? props.vendasMes[0][0].vendas : 0),
                badgeValue: describeDiff(
                    props.vendasMes && props.vendasMes[0] && props.vendasMes[0][0] ? props.vendasMes[0][0].vendas : 0,
                    props.vendasMesAnterior && props.vendasMesAnterior[0] && props.vendasMesAnterior[0][0] ? props.vendasMesAnterior[0][0].vendas : 0
                ),
                color: describeDiffColor(
                    props.vendasMes && props.vendasMes[0] && props.vendasMes[0][0] ? props.vendasMes[0][0].vendas : 0,
                    props.vendasMesAnterior && props.vendasMesAnterior[0] && props.vendasMesAnterior[0][0] ? props.vendasMesAnterior[0][0].vendas : 0
                ),
                desc: "Do mês anterior",
                loading: (props.vendasMesLoading || props.vendasMesAnteriorLoading) !== undefined ? props.vendasMesLoading || props.vendasMesAnteriorLoading : false
            },
            mesAnterior: {
                title: "Vendas do último mês",
                tooltip: "Valor total da venda + taxa de entrega + taxa de serviço se houver no mês passado",
                iconClass: "bx-archive-in",
                text: describeMoney(props.vendasMesAnterior && props.vendasMesAnterior[0] && props.vendasMesAnterior[0][0] ? props.vendasMesAnterior[0][0].vendas : 0),
                loading: props.vendasMesAnteriorLoading !== undefined ? props.vendasMesAnteriorLoading : false
            },
            ticketSemana: {
                title: "Ticket médio da semana",
                tooltip: "Média do valor total da venda da semana (segunda à domingo)",
                iconClass: "bx-purchase-tag-alt",
                text: describeMoney(props.vendasSemana && props.vendasSemana[0] && props.vendasSemana[0][0] ? props.vendasSemana[0][0].ticket : 0),
                loading: props.vendasSemanaLoading !== undefined ? props.vendasSemanaLoading : false
            },
            ticketMesAtual: {
                icon: "bx bx-purchase-tag-alt",
                title: "Ticket médio do mês",
                tooltip: "Média do valor total da venda do mês",
                value: describeMoney(props.vendasMes && props.vendasMes[0] && props.vendasMes[0][0] ? props.vendasMes[0][0].ticket : 0),
                badgeValue: describeDiff(
                    props.vendasMes && props.vendasMes[0] && props.vendasMes[0][0] ? props.vendasMes[0][0].ticket : 0,
                    props.vendasMesAnterior && props.vendasMesAnterior[0] && props.vendasMesAnterior[0][0] ? props.vendasMesAnterior[0][0].ticket : 0
                ),
                color: describeDiffColor(
                    props.vendasMes && props.vendasMes[0] && props.vendasMes[0][0] ? props.vendasMes[0][0].ticket : 0,
                    props.vendasMesAnterior && props.vendasMesAnterior[0] && props.vendasMesAnterior[0][0] ? props.vendasMesAnterior[0][0].ticket : 0
                ),
                desc: "Do mês anterior",
                loading: (props.vendasMesLoading || props.vendasMesAnteriorLoading) !== undefined ? (props.vendasMesLoading || props.vendasMesAnteriorLoading) : false
            },
        }

        return { ...state, ...props }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <MiniCards
                        title={this.state.hoje?.title}
                        tooltip={this.state.hoje?.tooltip}
                        text={this.state.hoje?.text}
                        iconClass={this.state.hoje?.iconClass}
                        loading={this.state.hoje?.loading}
                    />

                    <MiniCards
                        title={this.state.semana?.title}
                        tooltip={this.state.semana?.tooltip}
                        text={this.state.semana?.text}
                        iconClass={this.state.semana?.iconClass}
                        loading={this.state.semana?.loading}
                    />

                    <MiniCards
                        title={this.state.ticketSemana?.title}
                        tooltip={this.state.ticketSemana?.tooltip}
                        text={this.state.ticketSemana?.text}
                        iconClass={this.state.ticketSemana?.iconClass}
                        loading={this.state.ticketSemana?.loading}
                    />

                </Row>

                <Row>

                    <MiniWidget
                        icon={this.state.mesAtual?.icon}
                        title={this.state.mesAtual?.title}
                        tooltip={this.state.mesAtual?.tooltip}
                        value={this.state.mesAtual?.value}
                        badgeValue={this.state.mesAtual?.badgeValue}
                        color={this.state.mesAtual?.color}
                        desc={this.state.mesAtual?.desc}
                        loading={this.state.mesAtual?.loading}
                    />

                    <MiniCards
                        title={this.state.mesAnterior?.title}
                        text={this.state.mesAnterior?.text}
                        tooltip={this.state.mesAnterior?.tooltip}
                        iconClass={this.state.mesAnterior?.iconClass}
                        loading={this.state.mesAnterior?.loading}
                    />

                    <MiniWidget
                        icon={this.state.ticketMesAtual?.icon}
                        title={this.state.ticketMesAtual?.title}
                        tooltip={this.state.ticketMesAtual?.tooltip}
                        value={this.state.ticketMesAtual?.value}
                        badgeValue={this.state.ticketMesAtual?.badgeValue}
                        color={this.state.ticketMesAtual?.color}
                        desc={this.state.ticketMesAtual?.desc}
                        loading={this.state.ticketMesAtual?.loading}
                    />

                </Row>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const {
        vendasDia,
        vendasDiaLoading,
        vendasSemana,
        vendasSemanaLoading,
        vendasMes,
        vendasMesLoading,
        vendasMesAnterior,
        vendasMesAnteriorLoading,
    } = state.Profile
    return {
        vendasDia,
        vendasDiaLoading,
        vendasSemana,
        vendasSemanaLoading,
        vendasMes,
        vendasMesLoading,
        vendasMesAnterior,
        vendasMesAnteriorLoading,
    }
}

export default connect(mapStatetoProps, {
    buscarVendasDia,
    buscarVendasSemana,
    buscarVendasMes,
    buscarVendasMesAnterior,
})(MovimentoVendasKpiCards)
