
const statusClasses = {
  "enviada": "badge-soft-secondary",
  "concluído": "badge-soft-success",
  "em andamento": "badge-soft-warning",
  "n/d": "badge-soft-warning"
}

const Filters = {
  arrIncludes: 'in',
  // filterArrIncludesAll: string;
  arrIncludesSome: 'in',
  // filterBetween: string;
  // filterBetweenInclusive: string;
  contains: 'lk',
  // filterEmpty: string;
  // filterEndsWith: string;
  equals: 'eq',
  // filterEqualsString: string;
  // filterFuzzy: string;
  greaterThan: 'gt',
  greaterThanOrEqualTo: 'gte',
  // filterInNumberRange: string;
  // filterIncludesString: string;
  // filterIncludesStringSensitive: string;
  lessThan: 'lt',
  lessThanOrEqualTo: 'lte',
  // filterNotEmpty: string;
  notEquals: 'neq'
  // filterStartsWith: string;
  // filterWeakEquals: string;
}

export { statusClasses, Filters }
