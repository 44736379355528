import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardTitle, Col, Collapse, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import "react-phone-number-input/style.css"

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
    buscarCodigosBarrasPorProduto,
    buscarImagemProduto,
    buscarProduto,
    buscarProdutosComposicaoPorProduto,
    buscarProdutosReferenciaPorProduto,
    cadastrarCodigoBarras,
    cadastrarProduto,
    cadastrarProdutoComposicao,
    cadastrarProdutoMantemCadastro,
    cadastrarProdutoReferencia,
    editarProduto,
    removerCodigoBarras,
    removerProduto,
    removerProdutoComposicao,
    removerProdutoReferencia
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import Select from "react-select"
import FormInputText from "components/Common/form-input-text"
import AlertSemVinculo from "components/Common/alerta-sem-vinculo"
import FormInputSwitch from "components/Common/form-input-switch"
import FormInputMoney from "components/Common/form-input-money"
import FormInputNumber from "components/Common/form-input-number"
import {
    apiBuscarFornecedoresPesquisa,
    apiBuscarGruposProdutoPesquisa,
    apiBuscarNCMsPesquisa,
    apiBuscarNfeImpostoPesquisa,
    apiBuscarTabelaCestPesquisa,
    apiBuscarTabelaUnidadesPesquisa
} from "helpers/backend_helper"
import FormInputAsyncSelect from "components/Common/form-input-async-select"
import FormInputSelect from "components/Common/form-input-select"
import FormInputDate from "../../components/Common/form-input-date"
import moment from "moment-timezone"
import Loader from "react-loader-spinner"
import CodigoBarrasLista from "./codigo-barras-lista"
import ProdutoReferenciaLista from "./produto-referencia-lista"
import ProdutoComposicaoLista from "./produto-composicao-lista"
import FormInputFile from "../../components/Common/form-input-file"
import FormInputArea from "../../components/Common/form-input-area"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import {getCompanies} from "../../helpers/utils"

const brazilMoment = (...args) => moment(...args)

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarProduto = props => {

    const usePermissionEdit = usePermission("produtos_editar")
    const usePermissionInsert = usePermission("produtos_inserir")
    const usePermissionRemove = usePermission("produtos_excluir")
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [companies, setCompanies] = useState([{label: "Todas", value: ""}])
    const [clonado, setClonado] = useState(false)
    const [loading, setLoading] = useState(false)

    // accordions
    const [accordionSaldoEstoque, setAccordionSaldoEstoque] = useState(true)
    const [accordionReposicaoEstoque, setAccordionReposicaoEstoque] = useState(false)
    const [accordionPromocaoAvista, setAccordionPromocaoAvista] = useState(false)
    const [accordionPromocaoPrazo, setAccordionPromocaoPrazo] = useState(false)
    const [accordionFarmacia, setAccordionFarmacia] = useState(false)
    const [accordionBalanca, setAccordionBalanca] = useState(false)
    const [accordionComposicao, setAccordionComposicao] = useState(true)
    const [accordionDadosAdicionais, setAccordionDadosAdicionais] = useState(true)
    const [accordionEncartePromocional, setAccordionEncartePromocional] = useState(false)
    const [accordionGarantia, setAccordionGarantia] = useState(false)
    const [accordionClassificacao, setAccordionClassificacao] = useState(false)
    const [accordionAlteracao, setAccordionAlteracao] = useState(false)
    const [accordionCodigoBarras, setAccordionCodigoBarras] = useState(true)
    const [accordionReferencias, setAccordionReferencias] = useState(true)
    const [accordionCombustivel, setAccordionCombustivel] = useState(false)

    const [id, setId] = useState()
    const [empresa, setEmpresa] = useState()
    const [codigo, setCodigo] = useState()
    const [status, setStatus] = useState()
    const [descricao, setDescricao] = useState() // Descrição - descricao
    const [codigoBarra, setCodigoBarra] = useState() // Código de barra - Cod_Barra
    const [grupo, setGrupo] = useState() // Grupo (categoria) - Cod_GR
    const [subGrupo, setSubGrupo] = useState() // Sub-grupo - Cod_GR_Sub
    const [unidade, setUnidade] = useState() // Unidade - unidade
    const [unidadeCompra, setUnidadeCompra] = useState() // Unidade de compra - unidade_compra
    const [tipo, setTipo] = useState() // Tipo - tipo_pr
    const [vendaWebApp, setVendaWebApp] = useState() // Venda Web/APP - enviarapp S - Sim - N ou nulo - N
    const [regraImpostoSaida, setRegraImpostoSaida] = useState() // Regra para Imposto de saída - Imp_ID - tb_nfe_imposto - idEmpresa, idId
    const [precoCusto, setPrecoCusto] = useState() // Preço Custo - custo
    const [margemDCV, setMargemDCV] = useState() // Margem (DCV)% -  dcv
    const [venda, setVenda] = useState() // Preço de venda - venda
    const [ncm, setNcm] = useState() // Código NCM - cod_ncm
    const [cest, setCest] = useState() // Código CEST - cest

    // Campos na edição
    const [venda2, setVenda2] = useState() // Preço de venda 2 - pvenda2
    const [venda3, setVenda3] = useState() // Preço de venda 3 - pvenda3
    const [venda4, setVenda4] = useState() // Preço de venda 4 - pvenda4
    const [descontoMaximo, setDescontoMaximo] = useState() // Desc. Máximo - desc_maximo
    const [descontoPadrao, setDescontoPadrao] = useState() // Desc. Padrão - desc_padrao
    const [fracionado, setFracionado] = useState() // Fracionado - fracionado
    const [fabricante, setFabricante] = useState() // Fabricante → cod_lab
    const [fornecedor, setFornecedor] = useState() // Fornecedor → cod_fornec
    const [codigoFabricante, setCodigoFabricante] = useState() // Cód. Fabricante → cod_fabricante
    const [imagens, setImagens] = useState() // Imagem → tb_pro_img

    // Campos na edição - Saldo de Estoque
    const [anterior, setAnterior] = useState() // Est. Anterior - anterior
    const [aquisicao, setAquisicao] = useState() // Aquisição - aquisicao
    const [saida, setSaida] = useState() // Saída - saida

    // Campos na edição - Reposição de estoque
    const [minimo, setMinimo] = useState() // Estoque mínimo - minimo  (numerico 15,2)
    const [minimoSemanal, setMinimoSemanal] = useState() // Estoque semanal - minimo_semanal (numerico 15,2)
    const [maximo, setMaximo] = useState() // Estoque máximo - maximo (numerico 15,2)

    // Campos na edição - Promoção a vista
    const [emPromocao, setEmPromocao] = useState() // Em promoção - pr_vista
    const [precoPromocao, setPrecoPromocao] = useState() // Preço Promoção - pr_vista_vlr
    const [dataInicialPromocao, setDataInicialPromocao] = useState() // Data inicial - pr_vista_inicio
    const [dataFinalPromocao, setDataFinalPromocao] = useState() // Data final - pr_vista_validade

    // Campos na edição - Promoção a prazo
    const [emPromocaoPrazo, setEmPromocaoPrazo] = useState() // Em promoção - pr_prazo
    const [precoPromocaoPrazo, setPrecoPromocaoPrazo] = useState() // Preço Promoção - pr_prazo_vlr
    const [dataInicialPromocaoPrazo, setDataInicialPromocaoPrazo] = useState() // Data inicial - pr_prazo_inicio
    const [dataFinalPromocaoPrazo, setDataFinalPromocaoPrazo] = useState() // Data final - pr_prazo_validade

    // Campos na edição - Uso contínuo / SNGPC / Farmácia popular
    const [usuContinuo, setUsuContinuo] = useState() // Uso contínuo - Usu_Continuo
    const [farmaciaPopular, setFarmaciaPopular] = useState() // Farmácia popular - fp
    const [quantidadeDiaria, setQuantidadeDiaria] = useState() // Qtd/dias consumo - qtd_diaria
    const [quantidadeEmbalagem, setQuantidadeEmbalagem] = useState() // Qtd. embalagem - qtd_apres

    // Campos na edição - Balança
    const [enviarBalanca, setEnviarBalanca] = useState() // ok Enviar para balança - balanca
    const [balancaTecla, setBalancaTecla] = useState() // Nº Tecla - balanca_tecla
    const [balancaValidade, setBalancaValidade] = useState() // Validade - balanca_validade

    // Campos na edição - Dados adicionais
    const [comissionado, setComissionado] = useState("N") // Comissionado - comissionado S - Sim - N ou nulo - N
    const [balanco, setBalanco] = useState("N") // Balanço - balanco S - Sim - N ou nulo - N
    const [imprimirEtiqueta, setImprimirEtiqueta] = useState("N") // Imprimir etiqueta - etiqueta S - Sim - N ou nulo - N
    const [composto, setComposto] = useState("N") // Composto - Composto S - Sim - N ou nulo - N
    const [difal, setDifal] = useState("N") // DIFAL - Não calcular difal para este produto - Difal_NaoCalcular S - Sim - N ou nulo - N
    const [local, setLocal] = useState() // Localização - Local
    const [controlarNs, setControlarNs] = useState("N") // Controla nº de série - Controlar_NS S - Sim - N ou nulo - N
    const [codigoBeneficioFiscal, setCodigoBeneficioFiscal] = useState() // Código benefício fiscal - CBENEF
    const [comprimento, setComprimento] = useState() // Comprimento - Comprimento
    const [largura, setLargura] = useState() // Largura -  Largura
    const [altura, setAltura] = useState() // Altura - Altura
    const [pesoLiquido, setPesoLiquido] = useState() // Peso líquido - Peso_Liquido
    const [pesoBruto, setPesoBruto] = useState() // Peso bruto - Peso_Bruto
    const [dataVencimento, setDataVencimento] = useState() // Data vencimento → Data_Vencto
    const [codigo2, setCodigo2] = useState() // Código personalizado → Codigo2
    const [iat, setIat] = useState() // Indicador de arredondamento (seleção) → iat
    const [ippt, setIppt] = useState() // Indicador de produção (seleção) → ippt
    const [estoqueNaoControlar, setEstoqueNaoControlar] = useState() // Não controlar estoque → estoque_nao_controlar

    // Campos na edição - Encarte promocional
    const [dataEncarte, setDataEncarte] = useState() // Data validade - Encarte

    // Campos na edição - Informações sobre garantia
    const [garantiaDias, setGarantiaDias] = useState() // Garantia (em dias) - Garantia_Dias
    const [informacaoCupomFiscal, setInformacaoCupomFiscal] = useState() // Informação a ser impressa no cupom fiscal/nota fiscal eletrônica - Garantia_Obs

    // Campos na edição - Classificação / Curva ABC
    const [curvaValor, setCurvaValor] = useState() // Valor venda - Curva_Valor (apenas visualizar)
    const [curvaQuantidade, setCurvaQuantidade] = useState() // Qtd. venda - Curva_Qtd (apenas visualizar)

    // Campos na edição - Dados de alteração do registro
    const [dataAlteracao, setDataAlteracao] = useState() // Alteração do cadastro - AltData
    const [usuarioAlteracao, setUsuarioAlteracao] = useState() // Usuário que alterou - AltFunc
    const [dataAlteracaoPreco, setDataAlteracaoPreco] = useState() // Alteração preço - Data_Alt_Prc
    const [usuarioAlteracaoPreco, setUsuarioAlteracaoPreco] = useState() // Usuário que alterou preço - Func_Alt_Prc
    const [dataInclusao, setDataInclusao] = useState() // Inclusão - Data_Inclusao
    const [dataEntrada, setDataEntrada] = useState() // Última entrada estoque - Data_Entrada
    const [dataSaida, setDataSaida] = useState() // Última saída estoque - Data_Saida
    const [dataUltimoInventario, setDataUltimoInventario] = useState() // Último inventário (balanço) - Ult_Balanco

    // Campos na edição - Dados combustível (GLP)
    const [codigoANP, setCodigoANP] = useState() // Código ANP → Cod_ANP
    const [descricaoANP, setDescricaoANP] = useState() // Descrição ANP → COMB_DESCANP
    const [percentualGLP, setPercentualGLP] = useState() // Percentual GLP → COMB_PGLP
    const [percentualGasNacional, setPercentualGasNacional] = useState() // Percentual de Gás Natural Nacional → COMB_PGNN
    const [percentualGasImportado, setPercentualGasImportado] = useState() // Percentual de Gás Natural Importado → COMB_PGNI
    const [valorPartida, setValorPartida] = useState() // Valor de Partida → COMB_VPART

    const tipoOptions = [{value: "D", label: "Diversos"}, {value: "S", label: "Serviços"}, {
        value: "L", label: "Produtos/Liberado"
    }, {value: "M", label: "Materiais/Monitorado"}, {value: "N", label: "L. Negativa"}, {
        value: "P", label: "L. Positiva"
    }, {value: "U", label: "Neutro"}]

    const arredondamentoOptions = [{value: "A", label: "Arredondamento"}, {value: "T", label: "Truncamento"}]

    const producaoOptions = [{value: "P", label: "Produção própria"}, {value: "T", label: "Manufaturada por terceiros"}]

    useEffect(() => {
        toastr.options = {
            positionClass: "toast-bottom-right", closeButton: true
        }
    })

    useEffect(() => {
        if (props.produtoAtivo && props.produtoAtivo.id) {
            props.buscarProduto(props.produtoAtivo)
        } else {
            preencherFormulario(null)

            setId(null)
            setEmpresa(companies[0].value)
            setStatus('1')
        }

        setClonado(false)
    }, [props.produtoAtivo])

    useEffect(() => {
        if (props.permanecerCadastro === true) {
            setLoading(true)

            setTimeout(() => {
                preencherFormulario(null)

                setId(null)
                setEmpresa(companies[0].value)
                setStatus('1')

                setClonado(false)
                setLoading(false)
            }, 1000)
        }
    }, [props.permanecerCadastro])

    useEffect(() => {
        if (props.novoCodigoBarras && props.novoCodigoBarras.id && props.produto?.Codigo) {
            props.buscarCodigosBarrasPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.novoCodigoBarras])

    useEffect(() => {
        if (props.codigoBarrasRemovido && props.produto?.Codigo) {
            props.buscarCodigosBarrasPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.codigoBarrasRemovido])

    useEffect(() => {
        if (props.novoProdutoReferencia && props.novoProdutoReferencia.id && props.produto?.Codigo) {
            props.buscarProdutosReferenciaPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.novoProdutoReferencia])

    useEffect(() => {
        if (props.produtoReferenciaRemovido && props.produto?.Codigo) {
            props.buscarProdutosReferenciaPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.produtoReferenciaRemovido])

    useEffect(() => {
        if (props.novoProdutoComposicao && props.novoProdutoComposicao.id && props.produto?.Codigo) {
            props.buscarProdutosComposicaoPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.novoProdutoComposicao])

    useEffect(() => {
        if (props.produtoComposicaoRemovido && props.produto?.Codigo) {
            props.buscarProdutosComposicaoPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.produtoComposicaoRemovido])

    useEffect(() => {
        let imagens = null
        if (Array.isArray(props.imagemProduto) && !props.imagemProdutoLoading) {
            const imagem = Array.isArray(props.imagemProduto) ? props.imagemProduto[0] : props.imagemProduto
            if (imagem && imagem.file && imagem.file instanceof Blob) {
                imagens = imagem
            }
        }

        setImagens(imagens)
    }, [props.imagemProduto])

    const preencherFormulario = (produto) => {
        setId(produto?.id)
        setEmpresa(produto?.idEmpresa)
        setCodigo(produto?.Codigo)
        setStatus(produto?.Status == 'A' ? '1' : produto?.Status) // valor A é legado, deveria ser 1
        setDescricao(produto?.Descricao)
        setCodigoBarra(produto?.Cod_Barra)
        setGrupo(produto?.tabelaGrupoProduto ? buildGruposProduto(produto?.tabelaGrupoProduto.Codigo, produto?.tabelaGrupoProduto.Descricao) : undefined)
        setSubGrupo(produto?.tabelaSubGrupoProduto ? buildGruposProduto(produto?.tabelaSubGrupoProduto.Codigo, produto?.tabelaSubGrupoProduto.Descricao) : undefined)
        setUnidade(produto?.tabelaUnidade ? buildTabelaUnidade(produto?.tabelaUnidade.unidade, produto?.tabelaUnidade.descricao) : undefined)
        setUnidadeCompra(produto?.Unidade_Compra)
        setTipo(produto?.Tipo_PR)
        setVendaWebApp(produto?.EnviarApp ? produto?.EnviarApp : 'N')
        setRegraImpostoSaida(produto?.tabelaImposto ? buildRegraImpostoSaida(produto?.tabelaImposto.idId, produto?.tabelaImposto.descricao) : undefined)
        setPrecoCusto(produto?.Custo)
        setMargemDCV(produto?.Dcv)
        setVenda(produto?.Venda)
        setNcm(produto?.ncm ? buildNCM(produto?.ncm.ncm, produto?.ncm.descricao) : undefined)
        setCest(produto?.tabelaCest ? buildTabelaCest(produto?.tabelaCest.cest, produto?.tabelaCest.descricao) : undefined)

        // Campos na edição
        setVenda2(produto?.PVenda2)
        setVenda3(produto?.PVenda3)
        setVenda4(produto?.PVenda4)
        setDescontoMaximo(produto?.Desc_Maximo)
        setDescontoPadrao(produto?.Desc_Padrao)
        setFracionado(produto?.Fracionado)
        setFabricante(produto?.fabricante ? buildFornecedor(produto?.fabricante.codigo, produto?.fabricante.cnpj, produto?.fabricante.razaosocial) : undefined)
        setFornecedor(produto?.fornecedor ? buildFornecedor(produto?.fornecedor.codigo, produto?.fornecedor.cnpj, produto?.fornecedor.razaosocial) : undefined)
        setCodigoFabricante(produto?.Cod_Fabricante)

        // Campos na edição - Dados adicionais
        setComissionado(produto?.Comissionado ? produto?.Comissionado : 'N')
        setBalanco(produto?.Balanco ? produto?.Balanco : 'N')
        setImprimirEtiqueta(produto?.Etiqueta ? produto?.Etiqueta : 'N')
        setComposto(produto?.Composto ? produto?.Composto : 'N')
        setDifal(produto?.Difal_NaoCalcular ? produto?.Difal_NaoCalcular : 'N')
        setLocal(produto?.Local)
        setControlarNs(produto?.Controlar_NS ? produto?.Controlar_NS : 'N')
        setCodigoBeneficioFiscal(produto?.CBENEF)
        setComprimento(produto?.Comprimento)
        setLargura(produto?.Largura)
        setAltura(produto?.Altura)
        setPesoLiquido(produto?.Peso_Liquido)
        setPesoBruto(produto?.Peso_Bruto)
        setDataVencimento(produto?.Data_Vencto ? brazilMoment(produto?.Data_Vencto, "YYYY-MM-DD").toDate() : null)
        setCodigo2(produto?.Codigo2)
        setIat(produto?.iat)
        setIppt(produto?.ippt)
        setEstoqueNaoControlar(produto?.estoque_nao_controlar)

        // Campos na edição - Saldo de Estoque
        setAnterior(produto?.Anterior)
        setAquisicao(produto?.Aquisicao)
        setSaida(produto?.Saida)

        // Campos na edição - Reposição de estoque
        setMinimo(produto?.Minimo)
        setMinimoSemanal(produto?.Minimo_Semanal)
        setMaximo(produto?.Maximo)

        // Campos na edição - Promoção a vista
        setEmPromocao(produto?.PR_Vista)
        setPrecoPromocao(produto?.PR_Vista_Vlr)
        setDataInicialPromocao(produto?.PR_Vista_inicio ? brazilMoment(produto?.PR_Vista_inicio, "YYYY-MM-DD").toDate() : null)
        setDataFinalPromocao(produto?.PR_Vista_Validade ? brazilMoment(produto?.PR_Vista_Validade, "YYYY-MM-DD").toDate() : null)

        // Campos na edição - Promoção a prazo
        setEmPromocaoPrazo(produto?.PR_PR)
        setPrecoPromocaoPrazo(produto?.PR_PR_Vlr)
        setDataInicialPromocaoPrazo(produto?.PR_PR_inicio ? brazilMoment(produto?.PR_PR_inicio, "YYYY-MM-DD").toDate() : null)
        setDataFinalPromocaoPrazo(produto?.PR_PR_Validade ? brazilMoment(produto?.PR_PR_Validade, "YYYY-MM-DD").toDate() : null)

        // Campos na edição - Uso contínuo / SNGPC / Farmácia popular
        setUsuContinuo(produto?.Usu_Continuo)
        setFarmaciaPopular(produto?.FP)
        setQuantidadeDiaria(produto?.Qtd_Diaria)
        setQuantidadeEmbalagem(produto?.Qtd_Apres)

        // Campos na edição - Balança
        setEnviarBalanca(produto?.Balanca)
        setBalancaTecla(produto?.Balanca_Tecla)
        setBalancaValidade(produto?.Balanca_Validade)

        // Campos na edição - Encarte promocional
        setDataEncarte(produto?.Encarte ? brazilMoment(produto?.Encarte, "YYYY-MM-DD").toDate() : null)

        // Campos na edição - Informações sobre garantia
        setGarantiaDias(produto?.Garantia_Dias)
        setInformacaoCupomFiscal(produto?.Garantia_Obs)

        // Campos na edição - Classificação / Curva ABC
        setCurvaValor(produto?.Curva_Valor)
        setCurvaQuantidade(produto?.Curva_Qtd)

        // Campos na edição - Dados de alteração do registro
        setDataAlteracao(produto?.AltData ? brazilMoment(produto?.AltData, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setUsuarioAlteracao(`${produto?.AltFunc} - ${produto?.AltFunc_Nome}`)
        setDataAlteracaoPreco(produto?.Data_Alt_Prc ? brazilMoment(produto?.Data_Alt_Prc, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setUsuarioAlteracaoPreco(produto?.Func_Alt_Prc ? `${produto?.Func_Alt_Prc} - ${produto?.Func_Alt_Prc_Nome}` : '')
        setDataInclusao(produto?.Data_Inclusao ? brazilMoment(produto?.Data_Inclusao, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setDataEntrada(produto?.Data_Entrada ? brazilMoment(produto?.Data_Entrada, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setDataSaida(produto?.Data_Saida ? brazilMoment(produto?.Data_Saida, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setDataUltimoInventario(produto?.Ult_Balanco ? brazilMoment(produto?.Ult_Balanco, "YYYY-MM-DD HH:mm:ss").toDate() : null)

        // Campos na edição - Dados combustível (GLP)
        setCodigoANP(produto?.Cod_ANP)
        setDescricaoANP(produto?.COMB_DESCANP)
        setPercentualGLP(produto?.COMB_PGLP)
        setPercentualGasNacional(produto?.COMB_PGNN)
        setPercentualGasImportado(produto?.COMB_PGNI)
        setValorPartida(produto?.COMB_VPART)
    }

    useEffect(() => {
        if (props.produto && props.produto?.id) {
            preencherFormulario(props.produto);

            props.buscarCodigosBarrasPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })

            props.buscarProdutosReferenciaPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })

            props.buscarProdutosComposicaoPorProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })

            props.buscarImagemProduto({
                company: props.produto?.idEmpresa, codigoProduto: props.produto?.Codigo
            })
        }
    }, [props.produto])

    const excluirProduto = () => {
        props.removerProduto({id}, props.history)
    }

    const clonarProduto = () => {
        setId(null)
        setCodigo(null)
        setDescricao(`${descricao} (cópia)`)
        setEmpresa(companies[0].value)
        setClonado(true)
        setDataAlteracaoPreco(null)
        setUsuarioAlteracaoPreco(null)
        setDataInclusao(null)
        setDataEntrada(null)
        setDataSaida(null)
        setDataUltimoInventario(null)

        props.produto.id = null
        props.produto.codigo = null
        props.produto.iCodigo = null // caso seja clonado não deverá haver o iCodigo
        props.produto.AltData = null
        props.produto.AltFunc = null
        props.produto.Data_Alt_Prc = null
        props.produto.Func_Alt_Prc = null
        props.produto.Data_Inclusao = null
        props.produto.Data_Entrada = null
        props.produto.Data_Saida = null
        props.produto.Ult_Balanco = null

        window.scrollTo(0, 0)
    }

    const gravarProduto = (e, cadastrarOutroProduto) => {
        let valid = true
        valid = valid && (!!empresa)
        valid = valid && (!!codigo)
        valid = valid && (!!descricao)
        valid = valid && (!!unidade)
        valid = valid && (!!tipo)
        valid = valid && (!!grupo)
        valid = valid && (!!precoCusto)
        valid = valid && (!!margemDCV)
        valid = valid && (!!venda)

        if (!valid) {
            const divs = document.getElementsByName("validate-required")
            for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "block"
            }
            window.scrollTo(0, 0)
            toastr.error('Há campos obrigatórios não preenchidos!', 'Validação de informações')

            return
        }

        valid = valid && (dataFinalPromocaoPrazoValida())
        valid = valid && (dataFinalPromocaoValida())

        if (!valid) {
            window.scrollTo(0, 0)

            toastr.error('Há campos inválidos no formulário!', 'Validação de informações')
            return
        }

        salvarProduto(cadastrarOutroProduto)
    }

    const dataFinalPromocaoValida = () => {
        if (dataInicialPromocao && dataFinalPromocao) {
            const momentDataInicial = brazilMoment(dataInicialPromocao)
            const momentDataFinal = brazilMoment(dataFinalPromocao)
            if (momentDataInicial.isValid() && momentDataFinal.isValid() && momentDataInicial.isAfter(momentDataFinal)) {
                return false
            }
        }

        return true
    }

    const dataFinalPromocaoPrazoValida = () => {
        if (dataInicialPromocaoPrazo && dataFinalPromocaoPrazo) {
            const momentDataInicial = brazilMoment(dataInicialPromocaoPrazo)
            const momentDataFinal = brazilMoment(dataFinalPromocaoPrazo)
            if (momentDataInicial.isValid() && momentDataFinal.isValid() && momentDataInicial.isAfter(momentDataFinal)) {
                return false
            }
        }

        return true
    }

    const changeHandeler = (valor, elementId, validacao) => {
        if (validacao !== null && validacao !== undefined) {
            if (validacao === false) {
                document.getElementById(elementId).style.display = 'block'
            } else {
                document.getElementById(elementId).style.display = 'none'
            }
        } else {
            if (valor !== "" && valor !== undefined && valor !== null) {
                document.getElementById(elementId).style.display = 'none'
            } else {
                document.getElementById(elementId).style.display = 'block'
            }
        }
    }

    const salvarProduto = (cadastrarOutroProduto) => {
        const produto = props.produto ? Object.assign({}, props.produto) : {}
        produto.imagem = imagens
        produto.idEmpresa = empresa
        produto.Codigo = codigo
        produto.Descricao = descricao
        produto.Cod_Barra = codigoBarra
        produto.Cod_GR = grupo ? grupo.value : null
        produto.Cod_GR_Sub = subGrupo ? subGrupo.value : null
        produto.Unidade = unidade ? unidade.value : null
        produto.Unidade_Compra = unidadeCompra
        produto.Tipo_PR = tipo
        produto.EnviarApp = vendaWebApp
        produto.Imp_ID = regraImpostoSaida ? regraImpostoSaida.value : null
        produto.Custo = precoCusto
        produto.Dcv = margemDCV
        produto.Venda = venda
        produto.Cod_NCM = ncm ? ncm.value : null
        produto.CEST = cest ? cest.value : null

        // Campos na edição
        produto.PVenda2 = venda2
        produto.PVenda3 = venda3
        produto.PVenda4 = venda4
        produto.Desc_Maximo = descontoMaximo
        produto.Desc_Padrao = descontoPadrao
        produto.Fracionado = fracionado
        produto.Cod_Lab = fabricante ? fabricante.value : null
        produto.Cod_Fornec = fornecedor ? fornecedor.value : null
        produto.Cod_Fabricante = codigoFabricante

        // Campos na edição - Dados adicionais
        produto.Comissionado = comissionado
        produto.Balanco = balanco
        produto.Etiqueta = imprimirEtiqueta
        produto.Composto = Array.isArray(props.produtosComposicao) && props.produtosComposicao.length > 0 ? "S" : "N"
        produto.Difal_NaoCalcular = difal
        produto.Local = local
        produto.Controlar_NS = controlarNs
        produto.CBENEF = codigoBeneficioFiscal
        produto.Comprimento = comprimento
        produto.Largura = largura
        produto.Altura = altura
        produto.Peso_Liquido = pesoLiquido
        produto.Peso_Bruto = pesoBruto
        produto.Data_Vencto = dataVencimento
        produto.Codigo2 = codigo2
        produto.iat = iat
        produto.ippt = ippt
        produto.estoque_nao_controlar = estoqueNaoControlar

        // Campos na edição - Saldo de Estoque
        produto.Anterior = anterior
        produto.Aquisicao = aquisicao
        produto.Saida = saida

        // Campos na edição - Reposição de estoque
        produto.Minimo = minimo
        produto.Minimo_Semanal = minimoSemanal
        produto.Maximo = maximo

        // Campos na edição - Promoção a vista
        produto.PR_Vista = emPromocao
        produto.PR_Vista_Vlr = precoPromocao
        produto.PR_Vista_inicio = dataInicialPromocao
        produto.PR_Vista_Validade = dataFinalPromocao

        // Campos na edição - Promoção a prazo
        produto.PR_PR = emPromocaoPrazo
        produto.PR_PR_Vlr = precoPromocaoPrazo
        produto.PR_PR_inicio = dataInicialPromocaoPrazo
        produto.PR_PR_Validade = dataFinalPromocaoPrazo

        // Campos na edição - Uso contínuo / SNGPC / Farmácia popular
        produto.Usu_Continuo = usuContinuo
        produto.FP = farmaciaPopular
        produto.Qtd_Diaria = quantidadeDiaria
        produto.Qtd_Apres = quantidadeEmbalagem

        // Campos na edição - Balança
        produto.Balanca = enviarBalanca
        produto.Balanca_Tecla = balancaTecla
        produto.Balanca_Validade = balancaValidade

        // Campos na edição - Encarte promocional
        produto.Encarte = dataEncarte

        // Campos na edição - Informações sobre garantia
        produto.Garantia_Dias = garantiaDias
        produto.Garantia_Obs = informacaoCupomFiscal

        // Campos na edição - Classificação / Curva ABC
        produto.Curva_Valor = curvaValor
        produto.Curva_Qtd = curvaQuantidade

        // Campos na edição - Dados de alteração do registro
        produto.AltData = dataAlteracao
        produto.AltFunc = usuarioAlteracao
        produto.Data_Alt_Prc = dataAlteracaoPreco
        produto.Func_Alt_Prc = usuarioAlteracaoPreco
        produto.Data_Inclusao = dataInclusao
        produto.Data_Entrada = dataEntrada
        produto.Data_Saida = dataSaida
        produto.Ult_Balanco = dataUltimoInventario

        // Campos na edição - Dados combustível (GLP)
        produto.Cod_ANP = codigoANP
        produto.COMB_DESCANP = descricaoANP
        produto.COMB_PGLP = percentualGLP
        produto.COMB_PGNN = percentualGasNacional
        produto.COMB_PGNI = percentualGasImportado
        produto.COMB_VPART = valorPartida

        if (produto.id) {
            produto.Status = status
            props.editarProduto(produto, props.history)
        } else {
            produto.Status = '1'
            if (cadastrarOutroProduto) {
                props.cadastrarProdutoMantemCadastro(produto, props.history)
            } else {
                props.cadastrarProduto(produto, props.history)
            }
        }
    }

    const buildGruposProduto = (codigo, Descricao) => {
        return {label: `${codigo} - ${Descricao}`, value: codigo}
    }

    const buscarGruposProduto = (inputValue, callback) => {
        apiBuscarGruposProdutoPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({codigo, Descricao}) => {
                    return buildGruposProduto(codigo, Descricao)
                })

                callback(options)
            })
    }

    const buildTabelaUnidade = (unidade, descricao) => {
        return {label: `${unidade} - ${descricao}`, value: unidade}
    }

    const buscarTabelaUnidade = (inputValue, callback) => {
        apiBuscarTabelaUnidadesPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({unidade, descricao}) => {
                    return buildTabelaUnidade(unidade, descricao)
                })

                callback(options)
            })
    }

    const buildRegraImpostoSaida = (idId, descricao) => {
        return {label: `${idId} - ${descricao}`, value: idId}
    }

    const buscarRegraImpostoSaida = (inputValue, callback) => {
        apiBuscarNfeImpostoPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({idId, descricao}) => {
                    return buildRegraImpostoSaida(idId, descricao)
                })

                callback(options)
            })
    }

    const buildTabelaCest = (cest, descricao) => {
        return {label: `${cest} - ${descricao}`, value: cest}
    }

    const buscarTabelaCest = (inputValue, callback) => {
        apiBuscarTabelaCestPesquisa({argumento: inputValue})
            .then(results => {
                const options = results.map(({cest, descricao}) => {
                    return buildTabelaCest(cest, descricao)
                })

                callback(options)
            })
    }

    const buildNCM = (ncm, descricao) => {
        return {label: `${ncm} - ${descricao}`, value: ncm}
    }

    const buscarNCM = (inputValue, callback) => {
        apiBuscarNCMsPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({ncm, descricao}) => {
                    return buildNCM(ncm, descricao)
                })

                callback(options)
            })
    }

    const buildFornecedor = (codigo, cnpj, razaosocial) => {
        return {label: `${cnpj} - ${razaosocial}`, value: codigo}
    }

    const buscarFornecedores = (inputValue, callback) => {
        apiBuscarFornecedoresPesquisa({empresaId: empresa, argumento: inputValue})
            .then(results => {
                const options = results.map(({codigo, cnpj, razaosocial}) => {
                    return buildFornecedor(codigo, cnpj, razaosocial)
                })
                callback(options)
            })
    }

    useEffect(() => {
        const tmpCompanies = getCompanies(props.user)
        setCompanies(tmpCompanies)
        setEmpresa(tmpCompanies[0].value)

        return () => {
            setCompanies([])
        }
    }, [])

    const removerCodigoBarras = (itemSelecionado) => {
        props.removerCodigoBarras({id: itemSelecionado.id}, props.history)
    }

    const cadastrarCodigoBarras = (itemSelecionado) => {
        let codigoBarras = {cod_barra: itemSelecionado.codigoBarras}
        codigoBarras.idEmpresa = empresa
        codigoBarras.Codigo = codigo
        props.cadastrarCodigoBarras(codigoBarras, props.history)
    }

    const removerProdutoReferencia = (itemSelecionado) => {
        props.removerProdutoReferencia({id: itemSelecionado.id}, props.history)
    }

    const cadastrarProdutoReferencia = (itemSelecionado) => {
        let produtoreferencia = {referencia: itemSelecionado.produtoReferencia}
        produtoreferencia.idEmpresa = empresa
        produtoreferencia.Codigo = codigo
        props.cadastrarProdutoReferencia(produtoreferencia, props.history)
    }

    const removerProdutoComposicao = (itemSelecionado) => {
        props.removerProdutoComposicao({id: itemSelecionado.id}, props.history)
    }

    const cadastrarProdutoComposicao = (itemSelecionado) => {
        let produtoreferencia = {Codigo_estoque: itemSelecionado.codigoEstoque, Qtd: itemSelecionado.quantidade}
        produtoreferencia.idEmpresa = empresa
        produtoreferencia.Codigo = codigo
        props.cadastrarProdutoComposicao(produtoreferencia, props.history)
    }

    const removerImagemFile = (imagem) => {
        setImagens(null)

        imagem.remover = true
        setImagens(imagem)
    }

    if (!props.user || !possuiVinculo) {
        return (<AlertSemVinculo/>)
    }

    return <React.Fragment>
        {props.produtoLoading || loading ? (<div className="loader-container position-absolute">
            <Loader
                type="ThreeDots"
                color="#556EE6"
                height={80}
                width={80}
            />
        </div>) : (<div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title="Cadastro"
                    breadcrumbItem="Produto"
                />
                <StickerToolbarButtons>
                    {id && <>
                        <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!empresa || !(id && usePermissionEdit) || props.produto?.iCodigo}
                            onClick={e => gravarProduto(e)}>
                            {clonado ? "Cadastrar Produto" : "Salvar Produto"}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!usePermissionInsert}
                            onClick={e => clonarProduto(e)}>
                            {"Clonar Produto"}
                        </Button>
                        <Button
                            type="submit"
                            color="danger"
                            className="waves-effect waves-light me-2"
                            disabled={!(usePermissionRemove) || props.produto?.iCodigo}
                            onClick={e => excluirProduto(e)}>
                            {"Remover Produto"}
                        </Button>
                        </>
                    }

                    {!id && !clonado &&
                        <>
                        <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!empresa || !usePermissionInsert}
                            onClick={e => gravarProduto(e)}>
                            {"Cadastrar Produto"}
                        </Button>

                        <Button
                            type="submit"
                            color="secondary"
                            className="waves-effect waves-light me-2 btn-secondary"
                            disabled={!empresa || !usePermissionInsert}
                            onClick={e => gravarProduto(e, true)}>
                            {"Cadastrar e Preencher novo Produto "}
                        </Button>
                        </>
                    }

                    {clonado &&  <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        disabled={!empresa || !usePermissionInsert}
                        onClick={e => gravarProduto(e)}>
                        {"Cadastrar Produto"}
                    </Button>}
                </StickerToolbarButtons>
                <Row>
                    <Col lg="12">
                        <div>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Cadastro</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">
                                                {id && <FormGroup className="mb-4" row>
                                                    <Label htmlFor="empresa"
                                                           className="col-form-label col-lg-2">
                                                        Identificador
                                                    </Label>
                                                    <Col lg="10" className="col-form-label">
                                                        {id}
                                                    </Col>
                                                </FormGroup>}

                                                {id && <FormInputSwitch
                                                    attribute="status"
                                                    description="Ativo"
                                                    value={status}
                                                    setValue={setStatus}
                                                    trueValue={'1'}
                                                    falseValue={'2'}
                                                />}

                                                {id && <FormGroup className="mb-4" row>
                                                    <Label htmlFor="codigo" className="col-form-label col-lg-2">
                                                        Código
                                                    </Label>
                                                    <Col lg="10" className="col-form-label">
                                                        {codigo}
                                                    </Col>
                                                </FormGroup>}

                                                {!id && <FormInputNumber
                                                    attribute="codigo"
                                                    description="Código"
                                                    value={codigo}
                                                    setValue={setCodigo}
                                                    allowNegative={false}
                                                    max={9999999}
                                                    required={true}
                                                />}

                                                <FormGroup className="mb-4" row>
                                                    <Label htmlFor="empresa"
                                                           className="col-form-label col-lg-2">
                                                        Empresa
                                                    </Label>
                                                    <Col lg="10">
                                                        <Select
                                                            id="empresa"
                                                            name="empresa"
                                                            className="basic-single"
                                                            options={companies.filter(c => c.value)}
                                                            placeholder="Digite a empresa..."
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            noOptionsMessage={() => "Nenhuma empresa encontrada"}
                                                            onChange={e => {
                                                                setEmpresa(e?.value)
                                                                changeHandeler(e?.value, "validateCompany")
                                                            }}
                                                            value={empresa ? companies.find(e => e.value === empresa) : undefined}
                                                            required
                                                            isDisabled={props.produto?.id}
                                                        />
                                                        <div className={empresa ? "" : "invalid-tooltip"}
                                                             name="validate-required" id="validateCompany">
                                                            {empresa ? "" : "Empresa é obrigatório"}
                                                        </div>
                                                    </Col>
                                                </FormGroup>

                                                <FormInputText
                                                    attribute="descricao"
                                                    description="Descrição"
                                                    value={descricao}
                                                    setValue={setDescricao}
                                                    required={true}
                                                    maxLength={70}
                                                />

                                                <FormInputText
                                                    attribute="codigoBarra"
                                                    description="Código de barra"
                                                    value={codigoBarra}
                                                    setValue={setCodigoBarra}
                                                    maxLength={45}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="grupo"
                                                    description="Grupo (categoria)"
                                                    value={grupo}
                                                    setValue={setGrupo}
                                                    loadOptions={buscarGruposProduto}
                                                    required={true}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="subGrupo"
                                                    description="Sub-grupo"
                                                    value={subGrupo}
                                                    setValue={setSubGrupo}
                                                    loadOptions={buscarGruposProduto}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="unidade"
                                                    description="Unidade"
                                                    value={unidade}
                                                    setValue={setUnidade}
                                                    loadOptions={buscarTabelaUnidade}
                                                    required={true}
                                                />

                                                <FormInputSelect
                                                    attribute="tipo"
                                                    description="Tipo"
                                                    value={tipo}
                                                    setValue={setTipo}
                                                    options={tipoOptions}
                                                    required={true}
                                                />

                                                <FormInputNumber
                                                    attribute="unidadeCompra"
                                                    description="Unidade de compra"
                                                    value={unidadeCompra}
                                                    setValue={setUnidadeCompra}
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    max={99}
                                                    min={0}
                                                />

                                                <FormInputSwitch
                                                    attribute="vendaWebApp"
                                                    description="Venda Web/APP"
                                                    value={vendaWebApp}
                                                    setValue={setVendaWebApp}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="regraImpostoSaida"
                                                    description="Regra para Imposto de saída"
                                                    value={regraImpostoSaida}
                                                    setValue={setRegraImpostoSaida}
                                                    loadOptions={buscarRegraImpostoSaida}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="ncm"
                                                    description="Código NCM"
                                                    value={ncm}
                                                    setValue={setNcm}
                                                    loadOptions={buscarNCM}
                                                />

                                                <FormInputAsyncSelect
                                                    attribute="cest"
                                                    description="Código CEST"
                                                    value={cest}
                                                    setValue={setCest}
                                                    loadOptions={buscarTabelaCest}
                                                />

                                                <FormInputMoney
                                                    attribute="precoCusto"
                                                    description="Preço Custo"
                                                    value={precoCusto}
                                                    setValue={setPrecoCusto}
                                                    required={true}
                                                />

                                                <FormInputNumber
                                                    attribute="margem"
                                                    description="Margem (DCV)%"
                                                    value={margemDCV}
                                                    setValue={setMargemDCV}
                                                    allowNegative={false}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    required={true}
                                                    suffix="%"
                                                />

                                                <FormInputMoney
                                                    attribute="precoVenda"
                                                    description="Preço de venda"
                                                    value={venda}
                                                    setValue={setVenda}
                                                    required={true}
                                                />

                                                {(id || clonado) && <FormInputMoney
                                                    attribute="precoVenda2"
                                                    description="Preço de venda 2"
                                                    value={venda2}
                                                    setValue={setVenda2}
                                                />}

                                                {(id || clonado) && <FormInputMoney
                                                    attribute="precoVenda3"
                                                    description="Preço de venda 3"
                                                    value={venda3}
                                                    setValue={setVenda3}
                                                />}

                                                {(id || clonado) && <FormInputMoney
                                                    attribute="precoVenda4"
                                                    description="Preço de venda 4"
                                                    value={venda4}
                                                    setValue={setVenda4}
                                                />}

                                                {(id || clonado) && <FormInputNumber
                                                    attribute="descontoMaximo"
                                                    description="Desconto máximo %"
                                                    value={descontoMaximo}
                                                    setValue={setDescontoMaximo}
                                                    allowNegative={false}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    suffix="%"
                                                />}

                                                {(id || clonado) && <FormInputNumber
                                                    attribute="descontoPadrao"
                                                    description="Desconto padrão %"
                                                    value={descontoPadrao}
                                                    setValue={setDescontoPadrao}
                                                    allowNegative={false}
                                                    min={0}
                                                    max={99999}
                                                    decimalScale={2}
                                                    suffix="%"
                                                />}

                                                {(id || clonado) && <FormInputNumber
                                                    attribute="fracionado"
                                                    description="Fracionado"
                                                    value={fracionado}
                                                    setValue={setFracionado}
                                                    allowNegative={false}
                                                    max={9999999}
                                                />}

                                                {(id || clonado) && <FormInputMoney
                                                    attribute="fracionadoTotal"
                                                    description="Total fracionado"
                                                    value={(fracionado || 0) * (venda || 0)}
                                                    disabled={true}
                                                />}

                                                {(id || clonado) && <FormInputAsyncSelect
                                                    attribute="fabricante"
                                                    description="Fabricante"
                                                    value={fabricante}
                                                    setValue={setFabricante}
                                                    loadOptions={buscarFornecedores}
                                                />}

                                                {(id || clonado) && <FormInputNumber
                                                    attribute="codigoFabricante"
                                                    description="Código Fabricante"
                                                    value={codigoFabricante}
                                                    setValue={setCodigoFabricante}
                                                    allowNegative={false}
                                                    max={9999999}
                                                />}

                                                {(id || clonado) && <FormInputAsyncSelect
                                                    attribute="fornecedor"
                                                    description="Fornecedor"
                                                    value={fornecedor}
                                                    setValue={setFornecedor}
                                                    loadOptions={buscarFornecedores}
                                                />}

                                                {!id && <FormInputSwitch
                                                    attribute="comissionado"
                                                    description="Comissionado"
                                                    value={comissionado}
                                                    setValue={setComissionado}
                                                />}

                                                {!id && <FormInputSwitch
                                                    attribute="balanco"
                                                    description="Balanço"
                                                    value={balanco}
                                                    setValue={setBalanco}
                                                />}

                                                {!id && <FormInputSwitch
                                                    attribute="imprimirEtiqueta"
                                                    description="Imprimir etiqueta"
                                                    value={imprimirEtiqueta}
                                                    setValue={setImprimirEtiqueta}
                                                />}
                                            </div>
                                        </div>
                                    </Form>

                                    {(id || clonado) && <div className="hstack gap-3 mb-2">
                                        <Label className="col-form-label">Quadros de informações</Label>
                                        <button type="button" className="btn btn-soft-primary btn-sm" onClick={(e) => {
                                            setAccordionSaldoEstoque(true)
                                            setAccordionReposicaoEstoque(true)
                                            setAccordionPromocaoAvista(true)
                                            setAccordionPromocaoPrazo(true)
                                            setAccordionFarmacia(true)
                                            setAccordionBalanca(true)
                                            setAccordionComposicao(true)
                                            setAccordionDadosAdicionais(true)
                                            setAccordionEncartePromocional(true)
                                            setAccordionGarantia(true)
                                            setAccordionClassificacao(true)
                                            setAccordionAlteracao(true)
                                            setAccordionCodigoBarras(true)
                                            setAccordionReferencias(true)
                                            setAccordionCombustivel(true)
                                        }}>Exibir todos</button>
                                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={(e) => {
                                            setAccordionSaldoEstoque(false)
                                            setAccordionReposicaoEstoque(false)
                                            setAccordionPromocaoAvista(false)
                                            setAccordionPromocaoPrazo(false)
                                            setAccordionFarmacia(false)
                                            setAccordionBalanca(false)
                                            setAccordionComposicao(false)
                                            setAccordionDadosAdicionais(false)
                                            setAccordionEncartePromocional(false)
                                            setAccordionGarantia(false)
                                            setAccordionClassificacao(false)
                                            setAccordionAlteracao(false)
                                            setAccordionCodigoBarras(false)
                                            setAccordionReferencias(false)
                                            setAccordionCombustivel(false)
                                        }}>Não exibir</button>
                                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={(e) => {
                                            setAccordionSaldoEstoque(true)
                                            setAccordionReposicaoEstoque(true)
                                            setAccordionPromocaoAvista(false)
                                            setAccordionPromocaoPrazo(false)
                                            setAccordionFarmacia(false)
                                            setAccordionBalanca(false)
                                            setAccordionComposicao(false)
                                            setAccordionDadosAdicionais(false)
                                            setAccordionEncartePromocional(false)
                                            setAccordionGarantia(false)
                                            setAccordionClassificacao(false)
                                            setAccordionAlteracao(false)
                                            setAccordionCodigoBarras(true)
                                            setAccordionReferencias(false)
                                            setAccordionCombustivel(false)
                                        }}>Exibir padrões</button>
                                    </div> }

                                    {(id || clonado) && <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className={accordionSaldoEstoque ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionSaldoEstoque(!accordionSaldoEstoque) }}/>{" "}
                                            Saldo de estoque
                                        </label>
                                        <label className={accordionReposicaoEstoque ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionReposicaoEstoque(!accordionReposicaoEstoque) }} />{" "}
                                            Reposição de estoque
                                        </label>
                                        <label className={accordionPromocaoAvista ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionPromocaoAvista(!accordionPromocaoAvista)}}/>{" "}
                                            Promoção a vista
                                        </label>
                                        <label className={accordionPromocaoPrazo ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionPromocaoPrazo(!accordionPromocaoPrazo)}}/>{" "}
                                            Promoção a prazo
                                        </label>
                                        <label className={accordionFarmacia ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionFarmacia(!accordionFarmacia)}}/>{" "}
                                            Medicamentos
                                        </label>
                                        <label className={accordionBalanca ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionBalanca(!accordionBalanca)}}/>{" "}
                                            Balança
                                        </label>
                                        <label className={accordionComposicao ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionComposicao(!accordionComposicao)}}/>{" "}
                                            Composição
                                        </label>
                                        <label className={accordionDadosAdicionais ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionDadosAdicionais(!accordionDadosAdicionais)}}/>{" "}
                                            Dados adicionais
                                        </label>
                                    </div> }

                                    {(id || clonado) && <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className={accordionEncartePromocional ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionEncartePromocional(!accordionEncartePromocional)}}/>{" "}
                                            Encarte promocional
                                        </label>
                                        <label className={accordionGarantia ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionGarantia(!accordionGarantia)}}/>{" "}
                                            Garantia
                                        </label>
                                        <label className={accordionClassificacao ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionClassificacao(!accordionClassificacao)}}/>{" "}
                                            Classificação
                                        </label>
                                        <label className={accordionAlteracao ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionAlteracao(!accordionAlteracao)}}/>{" "}
                                            Auditoria
                                        </label>
                                        <label className={accordionCodigoBarras ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionCodigoBarras(!accordionCodigoBarras)}}/>{" "}
                                            Código de barras
                                        </label>
                                        <label className={accordionReferencias ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionReferencias(!accordionReferencias)}}/>{" "}
                                            Referências
                                        </label>
                                        <label className={accordionCombustivel ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionCombustivel(!accordionCombustivel)}}/>{" "}
                                            Dados combustível
                                        </label>
                                    </div> }

                                </CardBody>
                            </Card>

                            {(id || clonado) && <Collapse isOpen={accordionSaldoEstoque}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Saldo de Estoque</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">
                                                    <FormInputNumber
                                                        attribute="anterior"
                                                        description="Estoque anterior"
                                                        value={anterior}
                                                        setValue={setAnterior}
                                                        allowNegative={true}
                                                        max={9999999}
                                                        min={-9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="aquisicao"
                                                        description="Aquisição"
                                                        value={aquisicao}
                                                        setValue={setAquisicao}
                                                        allowNegative={true}
                                                        max={9999999}
                                                        min={-9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="saida"
                                                        description="Saída"
                                                        value={saida}
                                                        setValue={setSaida}
                                                        allowNegative={true}
                                                        max={9999999}
                                                        min={-9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="saldoEstoque"
                                                        description="Saldo estoque"
                                                        value={(anterior || 0) + (aquisicao || 0) - (saida || 0)}
                                                        decimalScale={2}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionReposicaoEstoque}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Reposição de estoque</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">
                                                    <FormInputNumber
                                                        attribute="minimo"
                                                        description="Estoque mínimo"
                                                        value={minimo}
                                                        setValue={setMinimo}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="minimoSemanal"
                                                        description="Estoque semanal"
                                                        value={minimoSemanal}
                                                        setValue={setMinimoSemanal}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="maximo"
                                                        description="Estoque máximo"
                                                        value={maximo}
                                                        setValue={setMaximo}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionPromocaoAvista}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Promoção a vista</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputSwitch
                                                        attribute="emPromocao"
                                                        description="Em promoção"
                                                        value={emPromocao}
                                                        setValue={setEmPromocao}
                                                    />

                                                    <FormInputMoney
                                                        attribute="precoPromocao"
                                                        description="Preço Promoção"
                                                        value={precoPromocao}
                                                        setValue={setPrecoPromocao}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataInicialPromocao"
                                                        description="Data inicial"
                                                        value={dataInicialPromocao}
                                                        setValue={setDataInicialPromocao}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataFinalPromocao"
                                                        description="Data final"
                                                        value={dataFinalPromocao}
                                                        setValue={setDataFinalPromocao}
                                                        validacao={dataFinalPromocaoValida()}
                                                        validacaoMensagem={"A data final deve ser posterior a data inicial"}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionPromocaoPrazo}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Promoção a prazo</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputSwitch
                                                        attribute="emPromocaoPrazo"
                                                        description="Em promoção"
                                                        value={emPromocaoPrazo}
                                                        setValue={setEmPromocaoPrazo}
                                                    />

                                                    <FormInputMoney
                                                        attribute="precoPromocaoPrazo"
                                                        description="Preço Promoção"
                                                        value={precoPromocaoPrazo}
                                                        setValue={setPrecoPromocaoPrazo}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataInicialPromocaoPrazo"
                                                        description="Data inicial"
                                                        value={dataInicialPromocaoPrazo}
                                                        setValue={setDataInicialPromocaoPrazo}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataFinalPromocaoPrazo"
                                                        description="Data final"
                                                        value={dataFinalPromocaoPrazo}
                                                        setValue={setDataFinalPromocaoPrazo}
                                                        validacao={dataFinalPromocaoPrazoValida()}
                                                        validacaoMensagem={"A data final deve ser posterior a data inicial"}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}
                            {(id || clonado) && <Collapse isOpen={accordionFarmacia}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Uso contínuo / SNGPC / Farmácia
                                            popular</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputSwitch
                                                        attribute="usuContinuo"
                                                        description="Usu contínuo"
                                                        value={usuContinuo}
                                                        setValue={setUsuContinuo}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="farmaciaPopular"
                                                        description="Farmácia popular"
                                                        value={farmaciaPopular}
                                                        setValue={setFarmaciaPopular}
                                                    />

                                                    <FormInputNumber
                                                        attribute="quantidadeDiaria"
                                                        description="Qtd/dias consumo"
                                                        value={quantidadeDiaria}
                                                        setValue={setQuantidadeDiaria}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />

                                                    <FormInputNumber
                                                        attribute="quantidadeEmbalagem"
                                                        description="Qtd. embalagem"
                                                        value={quantidadeEmbalagem}
                                                        setValue={setQuantidadeEmbalagem}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}
                            {(id || clonado) && <Collapse isOpen={accordionBalanca}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Balança</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputSwitch
                                                        attribute="enviarBalanca"
                                                        description="Enviar para balança"
                                                        value={enviarBalanca}
                                                        setValue={setEnviarBalanca}
                                                    />

                                                    <FormInputNumber
                                                        attribute="balancaTecla"
                                                        description="Nº Tecla"
                                                        value={balancaTecla}
                                                        setValue={setBalancaTecla}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />

                                                    <FormInputNumber
                                                        attribute="balancaValidade"
                                                        description="Validade"
                                                        value={balancaValidade}
                                                        setValue={setBalancaValidade}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {id && <Collapse isOpen={accordionComposicao}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Composição</CardTitle>
                                        <ProdutoComposicaoLista
                                            listaProdutoComposicao={props.produtosComposicao}
                                            cadastrarProdutoComposicao={cadastrarProdutoComposicao}
                                            removerProdutoComposicao={removerProdutoComposicao}
                                            loading={props.produtoComposicaoLoading || props.produtosComposicaoLoading}
                                            empresa={empresa}
                                            codigoProdutoPai={codigo}
                                        >
                                        </ProdutoComposicaoLista>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionDadosAdicionais}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Dados adicionais</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputSwitch
                                                        attribute="comissionado"
                                                        description="Comissionado"
                                                        value={comissionado}
                                                        setValue={setComissionado}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="balanco"
                                                        description="Balanço"
                                                        value={balanco}
                                                        setValue={setBalanco}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="imprimirEtiqueta"
                                                        description="Imprimir etiqueta"
                                                        value={imprimirEtiqueta}
                                                        setValue={setImprimirEtiqueta}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="composto"
                                                        description="Composto (somente leitura)"
                                                        value={composto}
                                                        setValue={setComposto}
                                                        disabled={true}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="difal"
                                                        description="DIFAL - Não calcular difal para este produto"
                                                        value={difal}
                                                        setValue={setDifal}
                                                    />

                                                    <FormInputText
                                                        attribute="local"
                                                        description="Localização"
                                                        value={local}
                                                        setValue={setLocal}
                                                        maxLength={10}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="controlarNs"
                                                        description="Controla nº de série"
                                                        value={controlarNs}
                                                        setValue={setControlarNs}
                                                    />

                                                    <FormInputText
                                                        attribute="codigoBeneficioFiscal"
                                                        description="Código benefício fiscal"
                                                        value={codigoBeneficioFiscal}
                                                        setValue={setCodigoBeneficioFiscal}
                                                        maxLength={10}
                                                    />

                                                    <FormInputNumber
                                                        attribute="comprimento"
                                                        description="Comprimento"
                                                        value={comprimento}
                                                        setValue={setComprimento}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="largura"
                                                        description="Largura"
                                                        value={largura}
                                                        setValue={setLargura}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="altura"
                                                        description="Altura (espessura)"
                                                        value={altura}
                                                        setValue={setAltura}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="pesoLiquido"
                                                        description="Peso líquido"
                                                        value={pesoLiquido}
                                                        setValue={setPesoLiquido}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputNumber
                                                        attribute="pesoBruto"
                                                        description="Peso bruto"
                                                        value={pesoBruto}
                                                        setValue={setPesoBruto}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        decimalScale={2}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataVencimento"
                                                        description="Data de vencimento"
                                                        value={dataVencimento}
                                                        setValue={setDataVencimento}
                                                    />

                                                    <FormInputText
                                                        attribute="codigo2"
                                                        description="Código personalizado"
                                                        value={codigo2}
                                                        setValue={setCodigo2}
                                                        maxLength={28}
                                                    />

                                                    <FormInputSelect
                                                        attribute="iat"
                                                        description="Indicador de arredondamento"
                                                        value={iat}
                                                        setValue={setIat}
                                                        options={arredondamentoOptions}
                                                    />

                                                    <FormInputSelect
                                                        attribute="ippt"
                                                        description="Indicador de produção"
                                                        value={ippt}
                                                        setValue={setIppt}
                                                        options={producaoOptions}
                                                    />

                                                    <FormInputSwitch
                                                        attribute="estoqueNaoControlar"
                                                        description="Não controlar estoque"
                                                        value={estoqueNaoControlar}
                                                        setValue={setEstoqueNaoControlar}
                                                    />

                                                    <FormInputFile
                                                        attribute="imagem"
                                                        description="Imagem do produto"
                                                        extension={".png, .jpeg, .jpg"}
                                                        value={imagens}
                                                        setValue={setImagens}
                                                        loading={props.imagemProdutoLoading}
                                                        removeHandeler={removerImagemFile}/>
                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionEncartePromocional}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Encarte promocional</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">
                                                    <FormInputDate
                                                        attribute="dataEncarte"
                                                        description="Data de validade"
                                                        value={dataEncarte}
                                                        setValue={setDataEncarte}
                                                        dateFormat={'dd/MM/yyyy'}
                                                        withHour={false}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionGarantia}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Informações sobre garantia</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">
                                                    <FormInputNumber
                                                        attribute="garantiaDias"
                                                        description="Garantia (em dias)"
                                                        value={garantiaDias}
                                                        setValue={setGarantiaDias}
                                                        allowNegative={false}
                                                        min={0}
                                                        max={999999}
                                                    />

                                                    <FormInputArea
                                                        attribute="informacaoCupomFiscal"
                                                        description="Informação a ser impressa no cupom fiscal/nota fiscal eletrônica"
                                                        value={informacaoCupomFiscal}
                                                        setValue={setInformacaoCupomFiscal}
                                                        maxLength={4000}
                                                        rows="4"
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionClassificacao}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Classificação / Curva ABC</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">
                                                    <FormInputNumber
                                                        attribute="curvaValor"
                                                        description="Valor venda"
                                                        value={curvaValor}
                                                        disabled={true}
                                                        decimalScale={2}
                                                    />
                                                    <FormInputNumber
                                                        attribute="curvaQuantidade"
                                                        description="Quantidade venda"
                                                        value={curvaQuantidade}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {id && <Collapse isOpen={accordionAlteracao}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Dados de alteração do registro</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputDate
                                                        attribute="dataAlteracao"
                                                        description="Alteração do cadastro"
                                                        value={dataAlteracao}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                    <FormInputText
                                                        attribute="usuarioAlteracao"
                                                        description="Usuário que alterou"
                                                        value={usuarioAlteracao}
                                                        disabled={true}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataAlteracaoPreco"
                                                        description="Alteração de preço"
                                                        value={dataAlteracaoPreco}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                    <FormInputText
                                                        attribute="usuarioAlteracaoPreco"
                                                        description="Usuário que alterou o preço"
                                                        value={usuarioAlteracaoPreco}
                                                        disabled={true}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataInclusao"
                                                        description="Data de inclusão"
                                                        value={dataInclusao}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataEntrada"
                                                        description="Data entrada no estoque"
                                                        value={dataEntrada}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataSaida"
                                                        description="Data saída do estoque"
                                                        value={dataSaida}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataUltimoInventario"
                                                        description="Último inventário (balanço)"
                                                        value={dataUltimoInventario}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                        withHour={true}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {id && <Collapse isOpen={accordionCodigoBarras}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Código de barras</CardTitle>
                                        <CodigoBarrasLista
                                            listaCodigoBarras={props.codigosBarras}
                                            cadastrarCodigoBarras={cadastrarCodigoBarras}
                                            removerCodigoBarras={removerCodigoBarras}
                                            loading={props.codigoBarrasLoading || props.codigosBarrasLoading}
                                        >
                                        </CodigoBarrasLista>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {id && <Collapse isOpen={accordionReferencias}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Referências do produto</CardTitle>
                                        <ProdutoReferenciaLista
                                            listaProdutoReferencia={props.produtosReferencia}
                                            cadastrarProdutoReferencia={cadastrarProdutoReferencia}
                                            removerProdutoReferencia={removerProdutoReferencia}
                                            loading={props.produtoReferenciaLoading || props.produtosReferenciaLoading}
                                        >
                                        </ProdutoReferenciaLista>
                                    </CardBody>
                                </Card>
                            </Collapse>}

                            {(id || clonado) && <Collapse isOpen={accordionCombustivel}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Dados combustível</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">
                                                    <FormInputText
                                                        attribute="codigoANP"
                                                        description="Código ANP"
                                                        value={codigoANP}
                                                        setValue={setCodigoANP}
                                                        maxLength={9}
                                                    />

                                                    <FormInputText
                                                        attribute="descricaoANP"
                                                        description="Descrição ANP"
                                                        value={descricaoANP}
                                                        setValue={setDescricaoANP}
                                                        maxLength={95}
                                                    />

                                                    <FormInputNumber
                                                        attribute="percentualGLP"
                                                        description="Percentual GLP"
                                                        value={percentualGLP}
                                                        setValue={setPercentualGLP}
                                                        allowNegative={false}
                                                        min={0}
                                                        max={999999}
                                                        decimalScale={2}
                                                        suffix="%"
                                                    />

                                                    <FormInputNumber
                                                        attribute="percentualGasNacional"
                                                        description="Percentual de Gás Natural Nacional"
                                                        value={percentualGasNacional}
                                                        setValue={setPercentualGasNacional}
                                                        allowNegative={false}
                                                        min={0}
                                                        max={999999}
                                                        decimalScale={2}
                                                        suffix="%"
                                                    />

                                                    <FormInputNumber
                                                        attribute="percentualGasImportado"
                                                        description="Percentual de Gás Natural Importado"
                                                        value={percentualGasImportado}
                                                        setValue={setPercentualGasImportado}
                                                        allowNegative={false}
                                                        min={0}
                                                        max={999999}
                                                        decimalScale={2}
                                                        suffix="%"
                                                    />

                                                    <FormInputMoney
                                                        attribute="valorPartida"
                                                        description="Valor de partida"
                                                        value={valorPartida}
                                                        setValue={setValorPartida}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)}
    </React.Fragment>
}

CadastrarProduto.propTypes = {
    produto: PropTypes.any,
    produtoAtivo: PropTypes.any,
    produtoLoading: PropTypes.bool,
    cadastrarProduto: PropTypes.func,
    editarProduto: PropTypes.func,
    removerProduto: PropTypes.func,
    produtosReferencia: PropTypes.any,
    produtosReferenciaLoading: PropTypes.any,
    produtoReferenciaLoading: PropTypes.any,
    removerProdutoReferencia: PropTypes.func,
    cadastrarProdutoReferencia: PropTypes.func,
    codigosBarras: PropTypes.any,
    codigosBarrasLoading: PropTypes.any,
    codigoBarrasLoading: PropTypes.any,
    removerCodigoBarra: PropTypes.func,
    cadastrarCodigoBarras: PropTypes.func,
    produtosComposicao: PropTypes.any,
    produtosComposicaoLoading: PropTypes.any,
    produtoComposicaoLoading: PropTypes.any,
    removerProdutoComposicao: PropTypes.func,
    cadastrarProdutoComposicao: PropTypes.func,
    imagemProduto: PropTypes.any,
    imagemProdutoLoading: PropTypes.any,
    permanecerCadastro: PropTypes.bool,
    cadastrarProdutoMantemCadastro: PropTypes.func,
}

const mapStatetoProps = state => {
    const {
        produtoAtivo,
        produto,
        produtoLoading,
        codigosBarras,
        codigosBarrasLoading,
        novoCodigoBarras,
        codigoBarrasRemovido,
        codigoBarrasLoading,
        produtosReferencia,
        produtosReferenciaLoading,
        novoProdutoReferencia,
        produtoReferenciaRemovido,
        produtoReferenciaLoading,
        produtosComposicao,
        produtosComposicaoLoading,
        novoProdutoComposicao,
        produtoComposicaoRemovido,
        produtoComposicaoLoading,
        imagemProduto,
        imagemProdutoLoading,
        permanecerCadastro
    } = state.Profile
    const {user} = state.Login
    return {
        user,
        produtoAtivo,
        produto,
        produtoLoading,
        codigosBarras,
        codigosBarrasLoading,
        novoCodigoBarras,
        codigoBarrasRemovido,
        codigoBarrasLoading,
        produtosReferencia,
        produtosReferenciaLoading,
        novoProdutoReferencia,
        produtoReferenciaRemovido,
        produtoReferenciaLoading,
        produtosComposicao,
        produtosComposicaoLoading,
        novoProdutoComposicao,
        produtoComposicaoRemovido,
        produtoComposicaoLoading,
        imagemProduto,
        imagemProdutoLoading,
        permanecerCadastro
    }
}

export default connect(mapStatetoProps, {
    cadastrarProduto, removerProduto, editarProduto, buscarProduto, buscarCodigosBarrasPorProduto,
    removerCodigoBarras, cadastrarCodigoBarras, buscarProdutosReferenciaPorProduto, removerProdutoReferencia,
    cadastrarProdutoReferencia, buscarProdutosComposicaoPorProduto, removerProdutoComposicao,
    cadastrarProdutoComposicao, buscarImagemProduto, cadastrarProdutoMantemCadastro,
})(withRouter(CadastrarProduto))
