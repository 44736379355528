import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, Col, Container, Form, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import {editarEmpresa, navegarEditarEmpresa} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import EmpresasTable from "./empresas-table"
import FormFilterSwitch from "../../components/Common/form-filter-switch"
import FormFilterNumber from "../../components/Common/form-filter-number"
import ReactApexChart from "react-apexcharts";
import {buildParamsGrouper} from "../../helpers/data_helper";
import {apiTotalizadorEmpresaCertificado} from "../../helpers/backend_helper";

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const EmpresaLista = props => {

    const ref = useRef()

    const [filters, setFilters] = useState(null)
    const [chart, setChart] = useState({})
    const [envioRemessaAutomatica, setEnvioRemessaAutomatica] = useState("")
    const [certificadoVencimento, setCertificadoVencimento] = useState("")
    const [certificadoDiasVencimento, setCertificadoDiasVencimento] = useState("")
    const [certificadoNaoEnviado, setCertificadoNaoEnviado] = useState("")

    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
    const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
    const usePermissionEdit = usePermission("empresas_editar")

    useEffect(() => {

        if (possuiVinculo) {
            const filtros = populateFiltros()
            buscaTotalizadorCertificadosDigitais(filtros)
        }

        return () => {
            setEnvioRemessaAutomatica("")
            setCertificadoVencimento("")
            setCertificadoDiasVencimento("")
            setCertificadoNaoEnviado("")
        }
    }, [])

    const populateFiltros = () => {
        const filters = []

        if (envioRemessaAutomatica) filters.push({
            id: 'envioRemessaAutomatica',
            value: envioRemessaAutomatica,
            condition: Filters.equals
        })
        if (certificadoVencimento) filters.push({
            id: 'certificadoVencimento',
            value: certificadoVencimento,
            condition: Filters.equals
        })
        if (certificadoDiasVencimento) filters.push({
            id: 'certificadoDiasVencimento',
            value: certificadoDiasVencimento,
            condition: Filters.equals
        })
        if (certificadoNaoEnviado) filters.push({
            id: 'certificadoNaoEnviado',
            value: certificadoNaoEnviado,
            condition: Filters.equals
        })

        if(isAdmin) filters.push({ id: 'ignoreCompany', value: true, condition: Filters.equals })

        setFilters(filters)
        return filters
    }

    const buscaTotalizadorCertificadosDigitais = (filtros) => {
        filtros = filtros.filter(filtro => filtro.id !== 'certificadoVencimento' && filtro.id !== 'certificadoDiasVencimento' && filtro.id !== 'certificadoNaoEnviado')
        let paramsGrouper = buildParamsGrouper(ref?.current?.getColumnFilters(), filtros, [], ["certificados_no_prazo", "certificados_a_vencer", "certificados_vencidos"])
        apiTotalizadorEmpresaCertificado(paramsGrouper).then(result => {
            let certificadosNoPrazo = Number.parseInt(result.data[0].certificados_no_prazo || 0)
            let certificadosAVencer = Number.parseInt(result.data[0].certificados_a_vencer || 0)
            let certificadosVencidos = Number.parseInt(result.data[0].certificados_vencidos || 0)

            setChart({
                series: [certificadosNoPrazo, certificadosAVencer, certificadosVencidos],
                options: {
                    labels: ['No prazo', 'À vencer', 'Vencidos'],
                    chart: {
                        type: 'donut',
                        height: '300'
                    },
                    dataLabels: {
                        formatter: function (val, opts) {
                            return opts.w.config.series[opts.seriesIndex]
                        },
                    },
                    plotOptions: {
                        pie: {
                            startAngle: -90,
                            endAngle: 90,
                            offsetY: 0,
                            donut: {
                                labels: {
                                    show: true,
                                    total: {
                                        show: true,
                                        fontSize: 13,
                                        label: 'Certificados'
                                    },
                                    value: {
                                        fontSize: 13,
                                        offsetY: -8
                                    }
                                },
                            },
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        horizontalAlign: 'center'
                    },
                    grid: {
                        padding: {
                            bottom: -55
                        },
                    },

                }})
        })
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => ref?.current?.search(), 300)
    }

    const goToEmpresa = item => {
        props.history.push("/cadastrar-empresa")
        props.navegarEditarEmpresa(item)
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Cadastro" breadcrumbItem="Empresas"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterSwitch
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="envioRemessaAutomatica"
                                                        description="Envio de remessa automático"
                                                        value={envioRemessaAutomatica}
                                                        changeHandeler={setEnvioRemessaAutomatica}
                                                    />
                                                </div>

                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterSwitch
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="certificadoVencimento"
                                                        description="Certificado vencido"
                                                        value={certificadoVencimento}
                                                        changeHandeler={e => {
                                                            setCertificadoVencimento(e)
                                                            if(e === "true") {
                                                                setCertificadoNaoEnviado(null)
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterNumber
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="certificadoDiasVencimento"
                                                        description="Dias para vencimento do certificado"
                                                        value={certificadoDiasVencimento}
                                                        changeHandeler={setCertificadoDiasVencimento}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />
                                                </div>

                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterSwitch
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="certificadoNaoEnviado"
                                                        description="Certificado não enviado"
                                                        value={certificadoNaoEnviado}
                                                        changeHandeler={ e => {
                                                            setCertificadoNaoEnviado(e)
                                                            if(e === "true") {
                                                                setCertificadoVencimento(null)
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>
                                                </div>

                                                { chart.options && <div className="col col-auto apex-charts mt-4 mt-sm-0">
                                                    <ReactApexChart options={ chart.options} series={ chart.series} type="donut" />
                                                </div>}

                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && <Card>
                                    <CardBody>
                                        <EmpresasTable ref={ref} filters={filters}
                                                       history={props.history}
                                                       usePermissionEdit={usePermissionEdit}
                                                       navegarEditarEmpresa={goToEmpresa}>
                                        </EmpresasTable>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

EmpresaLista.propTypes = {
    editarEmpresa: PropTypes.func,
    relatorioExecutar: PropTypes.func,
    navegarEditarEmpresa: PropTypes.func,
}

const mapStatetoProps = state => {
    const {} = state.Profile
    const {user} = state.Login
    return {user}
}

export default connect(mapStatetoProps, {
    navegarEditarEmpresa,
    editarEmpresa,
})(withRouter(EmpresaLista))
