import PropTypes from "prop-types"
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {Badge, Card, CardBody} from "reactstrap"
import {apiBuscarNotas, apiTotalizadorNotas} from "../../helpers/backend_helper"
import {Box} from "@mui/material"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime} from "../../helpers/utils"
// import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import toastr from "toastr"
import {buildParamsGrouper} from "../../helpers/data_helper"
// const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

const NotasTable = forwardRef((props, refFather) => {

    const ref = useRef();

    const {filters} = props
    const {usePermissionListar} = props
    const [data, setData] = useState([])
    const [totalNotas, setTotalNotas] = useState(null)
    const [totalNotasEntrada, setTotalNotasEntrada] = useState(null)
    const [totalNotasSaida, setTotalNotasSaida] = useState(null)

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
            buscaTotalizadorNotaFiscal()
        }
    }));

    const buscaTotalizadorNotaFiscal = () => {
        let paramsGrouper = buildParamsGrouper(ref?.current?.getColumnFilters(), filters, colunas, ["tot_icmstot_nf", "entrada_tot_icmstot_nf", "saida_tot_icmstot_nf"])
        apiTotalizadorNotas(paramsGrouper).then(result => {
            setTotalNotas(result.data[0].tot_icmstot_nf || 0)
            setTotalNotasEntrada(result.data[0].entrada_tot_icmstot_nf || 0)
            setTotalNotasSaida(result.data[0].saida_tot_icmstot_nf || 0)
        })
    }

    useEffect(() => {
        buscaTotalizadorNotaFiscal()

        return () => setTotalNotas(0)
    }, [])

    const statusNotaBadgeClass = statusNota => {
        switch (statusNota) {
            case 0: {
                return "danger"
            }
            case 1:
            case 2: {
                return "warning"
            }
            case 3: {
                return "success"
            }
            case 4: {
                return "danger higher"
            }
            case 5: {
                return "primary higher"
            }
            case 6: {
                return "soft-dark"
            }
            case 7: {
                return "soft-success"
            }
            case 8: {
                return "soft-danger"
            }
            case 9: {
                return "danger"
            }
            case 10: {
                return "soft-dark"
            }
            default: {
                return "soft-dark"
            }
        }
    }

    const describeStatusNota = statusNota => {
        switch (statusNota) {
            case 0: {
                return "Não transmitida"
            }
            case 1: {
                return "Assinada"
            }
            case 2: {
                return "Validada"
            }
            case 3: {
                return "Autorizada"
            }
            case 4: {
                return "Em Processamento na SEFAZ"
            }
            case 5: {
                return "Transmitida com Pendência"
            }
            case 6: {
                return "Cancelada"
            }
            case 7: {
                return "Denegada"
            }
            case 8: {
                return "Rejeita"
            }
            case 9: {
                return "Offline"
            }
            case 10: {
                return "Número inutilizado"
            }
            default: {
                return "Inválida"
            }
        }
    }

    const onChaveCopy = chave => {
        navigator.clipboard.writeText(chave)

        toastr.options.positionClass = "toast-bottom-right"
        // toastr.options.timeOut = 0;
        // toastr.options.extendedTimeOut = 0;

        toastr.success("Copiada para a área de transferências.", "Chave")
    }

    const colunas = useMemo(() =>
        [
            {header: "ID Cloud", accessorKey: "id", size: 120, enableHiding: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {header: "ID Sistema", accessorKey: "idID", size: 115, maxSize: 115},
            {header: "Loja", accessorKey: "Loja", size: 90},
            {header: "Razão social", accessorKey: "empresa_RazaoSocial", size: 180, filterFn: 'contains', enableColumnFilter: false},
            {header: "Tipo NF", accessorKey: "tipo_nf", size: 120, enableColumnFilter: false,
                Cell: ({cell}) =>
                    (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={ "font-size-12 badge-soft-" + (cell.getValue() === '0' ? "success" : "secondary") }
                            color={ cell.getValue() === '0' ? "success" : "secondary" }
                            pill>
                            { cell.getValue() === '0' ? "Entrada" : "Saída" }
                        </Badge>
                    </Box>)
            },
            {header: "Modelo", accessorKey: "modelo", size: 110},
            {header: "Serie", accessorKey: "serie", size: 90},
            {header: "Nº NF", accessorKey: "nf", size: 90},
            {
                header: "Chave", accessorKey: "chave", minSize: 100, maxSize: 600,
                Cell: ({renderedCellValue, row}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <div className="d-flex justify-content-between align-items-center"
                             onClick={e => onChaveCopy(renderedCellValue)}>
                            {renderedCellValue} <i className={"bx bx-copy ms-2 hover__cursor"}/>
                        </div>
                    </Box>
                )
            },
            {
                header: 'Data Emissão', size: 150,
                Cell: ({cell}) => describeDateTime(cell?.getValue()), //transform data to readable format for cell render
                accessorFn: (row) => new Date(row.data_emissao), //transform data before processing so sorting works
                accessorKey: 'data_emissao',
                sortingFn: 'datetime',
                enableColumnFilter: false,
            },
            {
                header: "Status", accessorKey: "Status", size: 180,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={"font-size-12 badge-soft-" + statusNotaBadgeClass(cell.getValue())}
                            color={statusNotaBadgeClass(cell.getValue())}
                            pill
                        >
                            {describeStatusNota(cell.getValue())}
                        </Badge>
                    </Box>),
                filterSelectOptions: [
                    {text: "Não transmitida", value: 0},
                    {text: "Assinada", value: 1},
                    {text: "Validada", value: 2},
                    {text: "Autorizada", value: 3},
                    {text: "Em Processamento na SEFAZ", value: 4},
                    {text: "Transmitida com Pendência", value: 5},
                    {text: "Cancelada", value: 6},
                    {text: "Denegada", value: 7},
                    {text: "Rejeita", value: 8},
                    {text: "Offline", value: 9},
                    {text: "Número inutilizado", value: 10},
                ],
                filterVariant: 'multi-select'
            },
            {
                header: "Vlr. Total Produtos", accessorKey: "tot_icmstot_prod",
                size: 180, enableColumnFilter: false, Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'right', gap: '1rem',}}>
                        {parseFloat(cell.getValue()).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })}
                    </Box>
                )
            },
            {
                header: "Vlr. Total Nota", accessorKey: "tot_icmstot_nf",
                size: 150, enableColumnFilter: false, Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'right', gap: '1rem',}}>
                        {parseFloat(cell.getValue()).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })}
                    </Box>
                )
            },
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarNotas(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{idID: false, chave: false}}
                enableRowActions={true}
                renderRowActions={(row, table) =>
                    (<Box sx={{display: 'flex', gap: '1rem'}}>
                        {usePermissionListar && row.original.chave && <a
                            className="text-primary"
                            href={`/notas/ver/${row.original.id}/visualizar`}
                            target={"_blank"}>
                            <i className="mdi mdi-xml font-size-18 ms-1" id="edittooltip"></i>
                        </a>}
                        {usePermissionListar && row.original.chave && <a className="text-success"
                                                                         href={`/notas/ver/${row.original.id}/download`}
                                                                         target={"_blank"}>
                            <i className="mdi mdi-cloud-download font-size-18"></i>
                        </a>}
                    </Box>)
                }
            />

            <div className="d-flex flex-row-reverse my-3">
                <div className="ms-3 text-right">
                    <p className="text-muted mb-2">Total em Notas</p>
                    <h5>R$ {parseFloat(totalNotas || 0).toLocaleString("pt-br", {minimumFractionDigits: 2})}</h5>
                </div>
                <div className="ms-3 text-right">
                    <p className="text-muted mb-2">Total em Notas de Entrada</p>
                    <h5>R$ {parseFloat(totalNotasEntrada || 0).toLocaleString("pt-br", {minimumFractionDigits: 2})}</h5>
                </div>
                <div className="text-right">
                    <p className="text-muted mb-2">Total em Notas de Saída</p>
                    <h5>R$ {parseFloat(totalNotasSaida || 0).toLocaleString("pt-br", {minimumFractionDigits: 2})}</h5>
                </div>

            </div>

        </React.Fragment>
    )
})

export default NotasTable

NotasTable.propTypes = {
    filters: PropTypes.array,
    searchFn: PropTypes.func
}
