import React, {useEffect, useRef} from "react"
import PropTypes from "prop-types"
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import {alterarVinculos, buscarPerfis} from "../../store/auth/profile/actions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import SolicatacaoVinculosTable from "./solicitacao-vinculos-table"
import usePermission from "../../helpers/permission/usePermission"
import VinculosTable from "./vinculos-table"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const Vinculos = props => {

    const refVinculos = useRef()
    const refSolicatacaoVinculosTable = useRef()

    const usePermissionEdit = usePermission('vinculos_editar')
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
    const isInforangraOrVinculoActive = props.user?.usuario_info || possuiVinculo

    useEffect(() => {
        props.buscarPerfis()
    }, [])

    useEffect(() => {
        if (!props.vinculosLoading) {
            setTimeout(() => refSolicatacaoVinculosTable.current.search(), 300)
        }
    }, [props.vinculosLoading])

    const alterarVinculos = (selecionados, status, category) => {
        props.alterarVinculos(selecionados, status, category)
    }

    if (!props.user || !possuiVinculo) {
        return (<AlertaSemVinculo/>)
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Row className="justify-content-center">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">Listagem dos Meus Vínculos</CardTitle>

                                <VinculosTable ref={refVinculos}>
                                </VinculosTable>
                            </CardBody>
                        </Card>
                        <hr/>
                    </Col>
                </Row>

                <hr/>

                {isInforangraOrVinculoActive && (
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">Solicitações de Vínculos</CardTitle>

                            <SolicatacaoVinculosTable ref={refSolicatacaoVinculosTable}
                                                      perfis={props.perfis}
                                                      alterarVinculos={(selecionados, status, category) => alterarVinculos(selecionados, status, category)}
                                                      usePermissionEdit={usePermissionEdit}>
                            </SolicatacaoVinculosTable>

                        </CardBody>
                    </Card>
                )}
            </div>

        </React.Fragment>
    )
}

Vinculos.propTypes = {
    buscarPerfis: PropTypes.func,
    perfis: PropTypes.array,
    user: PropTypes.any,
    vinculosLoading: PropTypes.bool,
}

const mapStatetoProps = state => {
    const {user, perfis, vinculosLoading} = state.Login
    return {user, perfis, vinculosLoading}
}

export default connect(mapStatetoProps, {
    buscarPerfis,
    alterarVinculos
})(withRouter(Vinculos))
