import {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_RESULT,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESSFUL,
  SOLICITAR_VINCULO,
  SOLICITAR_VINCULO_FAILED,
  SOLICITAR_VINCULO_SUCCESSFUL
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history }
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user
  }
}

export const solicitarVinculo = (dados, history) => {
  return {
    type: SOLICITAR_VINCULO,
    payload: { dados, history }
  }
}

export const solicitarVinculoSuccessful = data => {
  return {
    type: SOLICITAR_VINCULO_SUCCESSFUL,
    payload: data
  }
}

export const solicitarVinculoFailed = data => {
  return {
    type: SOLICITAR_VINCULO_FAILED,
    payload: data
  }
}

export const confirmEmail = (data, history) => {
  return {
    type: CONFIRM_EMAIL,
    payload: { data, history }
  }
}

export const confirmEmailResult = data => {
  return {
    type: CONFIRM_EMAIL_RESULT,
    payload: data
  }
}
