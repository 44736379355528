import PropTypes from "prop-types"
import React from "react"
import {MultiSelect} from "react-multi-select-component"
import {Col, FormGroup, Label} from "reactstrap"
import Select from "react-select"

const FormSelectCompany = props => {

  const { attribute, options, value, changeHandeler, className, required, disabled, placeholder, description, setValue, multiple } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
        if(validacao !== null && validacao !== undefined) {
            if(validacao === false) {
                document.getElementById(elementId).style.display = 'block'
            } else {
                document.getElementById(elementId).style.display = 'none'
            }
        } else {
            if (valor !== "" && valor !== undefined && valor !== null) {
                document.getElementById(elementId).style.display = 'none'
            } else {
                document.getElementById(elementId).style.display = 'block'
            }
        }
    }

  const ArrowRenderer = ({ expanded }) =><span className="arrow"></span>;
  const ClearRenderer = () =><span className="clear"></span>;

  return (
      <React.Fragment>
          <FormGroup className="mb-4" row>
              <Label htmlFor={ (attribute || 'empresa') + "-select" } className="col-form-label col-lg-2">
                  { description || ( 'Empresa' + (multiple ? '(s)' : '')) }
              </Label>
              <Col lg="10">
                  { multiple && <MultiSelect
                      id={ (attribute || 'empresa') + "-multi-multi-select" }
                      name={ (attribute || 'empresa') + "-multi-multi-select" }
                      className={ (className || "")  + (required && !value? "is-invalid" : "")}
                      options={options}
                      value={value}
                      onChange={ value => {
                          setValue(value)
                          if(required) {
                              if(changeHandeler !== undefined) {
                                  changeHandeler(e.target.value, `validate-required-${ (attribute || 'empresa') }-select`)
                              } else {
                                  changeHandelerInput(e.target.value, `validate-required-${ (attribute || 'empresa') }-select`)
                              }
                          }
                      } }
                      labelledBy="Selecione"
                      disableSearch={true}
                      overrideStrings={{
                          "allItemsAreSelected": "Todos selecionados.",
                          "clearSearch": "Limpar",
                          "clearSelected": "Deselecionar todos",
                          "noOptions": "Não há opções",
                          "search": "Pesquisar",
                          "selectAll": "Selecionar todos",
                          "selectAllFiltered": "Selecionar todos",
                          "selectSomeItems": "Selecione",
                          "create": "Criar",}
                      }
                      ClearIcon={<ClearRenderer />}
                      ClearSelectedIcon={<ClearRenderer />}
                      ArrowRenderer={ ArrowRenderer }
                      disabled={ disabled }
                  /> }

                  { !multiple && <Select
                      id={ (attribute || 'empresa') + "-select" }
                      name={ (attribute || 'empresa') + "-select" }
                      className={ "basic-single " + (required && !value? "is-invalid" : "")}
                      options={ options }
                      placeholder={ placeholder || "Digite a empresa..." }
                      isClearable={true}
                      isSearchable={true}
                      noOptionsMessage={() => "Nenhuma empresa encontrada"}
                      onChange={e => {
                          setValue(e?.value)
                          if(required) {
                              if(changeHandeler !== undefined) {
                                  changeHandeler(e?.value, `validate-required-${ (attribute || 'empresa') }-select`)
                              } else {
                                  changeHandelerInput(e?.value, `validate-required-${ (attribute || 'empresa') }-select`)
                              }
                          }
                      }}
                      value={ value ? options.find(e => e.value === value) : undefined}
                      required={ required }
                      isDisabled={ disabled }
                  /> }

                  { required &&
                      <div className={ value ? "" : "invalid-tooltip" }
                           name="validate-required" id={ `validate-required-${ (attribute || 'empresa') }-select` }>
                          { value ? "" : `${ description } é obrigatório(a)`}
                      </div>
                  }
              </Col>
          </FormGroup>
      </React.Fragment>
  )
}

FormSelectCompany.propTypes = {
    value: PropTypes.any,
    changeHandeler: PropTypes.func,
    options: PropTypes.any,
    attribute: PropTypes.any,
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    description: PropTypes.string,
    setValue: PropTypes.func,
}

export default FormSelectCompany