import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR, FORGET_PASSWORD_SUCCESS } from "./actionTypes"

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  forgetStep: null,
  loading: false
}

const forgetPassword = (state = initialState, action) => {
  console.debug("[forgetPasswordReducer]", { initialState, action })

  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
        loading: true
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload.message,
        forgetStep: action.payload.step,
        loading: false
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = {
        ...state,
        loading: false,
        forgetError:
          action.payload &&
          action.payload.response &&
          action.payload.response.data
            ? Array.isArray(action.payload.response.data)
              ? action.payload.response.data.map(e => e.message).join("<br/>")
              : action.payload.response.data.error
            : null
      }
      break
    default:
      return state
  }
  return state
}

export default forgetPassword
