import React, {useEffect, useState} from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {Badge} from "reactstrap"
import {describeDate, describeDateTime} from "../../helpers/utils"
import LoaderList from "../../components/Common/loaderList"
import {MDBDataTable} from "mdbreact"
import {Box} from "@mui/material"

const EmpresaTarefaLista = props => {

    const [data, setData] = useState({
        columns: [
            {label: "Código", field: "Código", sort: "asc", width: 110},
            {label: "Tipo", field: "Tipo", sort: "asc", width: 230},
            {label: "Tarefa", field: "Tarefa", sort: "asc", width: 110},
            {label: "Data", field: "Data", sort: "asc", width: 110},
            {label: "Periodo inicial", field: "Periodo inicial", sort: "asc", width: 110},
            {label: "Período final", field: "Período final", sort: "asc", width: 110},
            {label: "Situação", field: "Situação", sort: "asc", width: 110},
            {label: "Início", field: "Início", sort: "asc", width: 110},
            {label: "Próxima execução", field: "Próxima execução", sort: "asc", width: 110},
            {label: "E-mail cliente", field: "E-mail cliente", sort: "asc", width: 110},
            {label: "E-mail contabilidade", field: "E-mail contabilidade", sort: "asc", width: 110},
            {label: "E-mail suporte", field: "E-mail suporte", sort: "asc", width: 110},
            {
                label: "Registros", field: "Registros", sort: "asc", width: 110,
                Cell: ({cell}) => <span className="text-right">{cell.getValue()}</span>
            },
            {label: "Registros rejeitados", field: "Registros rejeitados", sort: "asc", width: 110},
            {label: "Registros aceitos", field: "Registros aceitos", sort: "asc", width: 110},
            {label: "Registros cancelados", field: "Registros cancelados", sort: "asc", width: 110},
            {label: "Registros inutilizados", field: "Registros inutilizados", sort: "asc", width: 110},
            {label: "Qtd. execuções", field: "Qtd. execuções", sort: "asc", width: 110},
            {label: "Limite execuções", field: "Limite execuções", sort: "asc", width: 110},
            {label: "Inicio execução", field: "Inicio execução", sort: "asc", width: 110},
            {label: "Final execução", field: "Final execução", sort: "asc", width: 110},
            {label: "Qtd. envio e-mail", field: "Qtd. envio e-mail", sort: "asc", width: 110},
            {label: "Limite de envio de e-mail", field: "Limite de envio de e-mail", sort: "asc", width: 110},
            {label: "Tipo relatório", field: "Tipo relatório", sort: "asc", width: 110},
        ],
        rows: []
    })

    useEffect(() => {
        if (Array.isArray(props.listaTarefa)) {
            const rows = props.listaTarefa?.map(item => ({
                "Código": item.id,
                "Tipo": (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge className={"font-size-12 badge-soft-" + (item.Tipo === 1 ? 'primary' : 'secondary')}
                               color={item.Tipo === 1 ? 'primary' : 'secondary'}
                               pill>
                            {item.Tipo === 1 ? 'Automatizado' : 'Manual'}
                        </Badge>
                    </Box>),
                "Tarefa": item.Tarefa,
                "Data": describeDateTime(item.Data),
                "Periodo inicial": describeDate(item.PeriodoInicial),
                "Período final": describeDate(item.PeriodoFinal),
                "Situação": item.Situacao,
                "Início": describeDateTime(item.DataInicio),
                "Próxima execução": describeDateTime(item.DataProximaExecucao),
                "E-mail cliente": (item.EmailCliente !== '0' ? item.EmailCliente : 'N/A'),
                "E-mail contabilidade": (item.EmailContabilidade !== '0' ? item.EmailContabilidade : 'N/A'),
                "E-mail suporte": (item.EmailSuporte !== '0' ? item.EmailSuporte : 'N/A'),
                "Registros": (<div className="text-right">{item.QtdRegistros}</div>),
                "Registros rejeitados": (<div className="text-right">{item.QtdRegistrosRejeitados}</div>),
                "Registros aceitos": (<div className="text-right">{item.QtdRegistrosAceitos}</div>),
                "Registros cancelados": (<div className="text-right">{item.QtdRegistrosCancelados}</div>),
                "Registros inutilizados": (<div className="text-right">{item.QtdRegistrosInutilizados}</div>),
                "Qtd. execuções": (<div className="text-right">{item.QtdExecucacao}</div>),
                "Limite execuções": (<div className="text-right">{item.LimiteExecucao}</div>),
                "Inicio execução": describeDateTime(item.DataExecucaoInicio),
                "Final execução": describeDateTime(item.DataExecucaoTermino),
                "Qtd. envio e-mail": (<div className="text-right">{item.QtdEnvioEmail}</div>),
                "Limite de envio de e-mail": (<div className="text-right">{item.QtdEnvioEmail}</div>),
                "Tipo relatório": item.TipoRelatorio,
            }))

            setData({
                columns: data.columns,
                rows: rows
            })
        }
    }, [props.listaTarefa])

    return <>
        {props.loading ? <LoaderList></LoaderList> :
            <>
                <div className="table-responsive">
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={data}
                        entriesLabel={"Mostrar Registros"}
                        infoLabel={["Exibindo", "a", "de", "registros"]}
                        noRecordsFoundLabel={"Nenhum registro encontrado"}
                        paginationLabel={["Anterior", "Próximo"]}
                        searchLabel={"Pesquisar"}
                        entries={5}
                        entriesOptions={[5, 10, 20, 50, 100]}
                    />
                </div>
            </>
        }
    </>
}

EmpresaTarefaLista.propTypes = {
    listaTarefa: PropTypes.array,
    loading: PropTypes.bool,
    empresa: PropTypes.any
}

export default EmpresaTarefaLista