import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Input, Label } from "reactstrap"

const FormInputEmail = props => {
  const { attribute, description, value, setValue, changeHandeler, required, minLength, maxLength, disabled, placeholder, afterChange, validacao, validacaoMensagem } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-email" } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          <Input
            id={ attribute + "-email"}
            name={ attribute + "-email"}
            type="email"
            className={ "form-control " + ((required && !value) || validacao === false ? "is-invalid" : "") }
            value={ value }
            onChange={ e => {
              setValue(e.target.value)

              if(required) {
                if(changeHandeler !== undefined) {
                  changeHandeler(e.target.value, `validate-required-${ attribute }-email`)
                } else {
                  changeHandelerInput(e.target.value, `validate-required-${ attribute }-email`)
                }
              }

              if(validacao === true || validacao === false) {
                if(changeHandeler !== undefined) {
                  changeHandeler(e.target.value, `validate-${ attribute }-email`, validacao)
                } else {
                  changeHandelerInput(e.target.value, `validate-${ attribute }-email`, validacao)
                }
              }

              if(afterChange) afterChange(e.target.value)
            } }
            placeholder= { disabled ? '' : (placeholder ? placeholder : `Digite a(o) ${description}`)}
            required={ required }
            minLength={ minLength }
            maxLength={ maxLength }
            disabled={ disabled }
          />

          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-email` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }

          { validacaoMensagem &&
              <div className="invalid-tooltip" name="validate" id={`validate-${ attribute }-email`}
                   style={ { display: validacao === false && value ? "block" : "none"} }>
                { validacaoMensagem }
              </div> }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputEmail.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  changeHandeler: PropTypes.func,
  afterChange: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number
}

export default FormInputEmail
