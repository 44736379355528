import { del, get, post, put } from "./api_helper"

import { api } from "../config"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const postJwtRegister = data => post(`${ api.url }usuarios/cadastro`, data)
const postJwtLogin = data => post(`${ api.url }usuarios/auth`, data)
export const apiConfirmEmail = data => post(`${ api.url }usuarios/confirmar_email`, data)
const getJwtDashboardData = data => get(`${ api.url }usuarios/dashboard_data`, { params: data })
const getPermissoes = () => get(`${ api.url }usuarios/permissoes`)


// postForgetPwd
const postJwtForgetPwd = data => post(`${ api.url }usuarios/forgot_password`, data)

// postSocialLogin
export const postSocialLogin = data => post(`${ api.url }usuarios/social_auth`, data)

export const apiBuscarVinculos = data => get(`${ api.url }vinculos`, { params: data })
export const apiAlterarVinculos = data => post(`${ api.url }vinculos/alterar`, data)
export const apiBuscarVinculosSolicitacoes = data => get(`${ api.url }vinculos/solicitacoes`, { params: data })
export const apiCadastrarVinculo = data => post(`${ api.url }vinculos`, data)
export const apiBuscarQuantidadeSolicitacoesPendentes = () => get(`${ api.url }vinculos/quantidadeSolicitacoesPendentes`)
export const apiBuscarVinculosPorUsuario = data => get(`${ api.url }vinculos/porUsuario`, { params: data })
export const apiRejeitarVinculos = data => post(`${ api.url }vinculos/rejeitar`, { params: data })


export const apiCadastrarCategoria = data => post(`${ api.url }faqs/categorias/cadastrar`, data)
export const apiExcluirCategoria = data => del(`${ api.url }faqs/categorias/` + data.id)
export const apiExcluirPergunta = data => del(`${ api.url }faqs/perguntas/` + data.id)
export const apiCadastrarPergunta = data => post(`${ api.url }faqs/perguntas/cadastrar`, data)

export const apiListarFaqs = () => get(`${ api.url }faqs`)
export const apiBuscarNotasSituacoes = () => get(`${ api.url }usuarios/notas_situacoes`)
export const apiBuscarCaixasOperadores = () => get(`${ api.url }usuarios/caixas_operadores`)
export const apiBuscarNotas = data => get(`${ api.url }usuarios/notas`, { params: data })
export const apiTotalizadorNotas = data => get(`${ api.url }usuarios/notas/totalizador`, { params: data })

export const apiBuscarNota = data => get(`${ api.url }usuarios/nota`, { params: data })
export const apiBuscarSugestoesStatus = () => get(`${ api.url }sugestoes/status`)
export const apiBuscarSugestoesProdutos = () => get(`${ api.url }sugestoes/produtos`)
export const apiCadastrarSugestao = data => post(`${ api.url }sugestoes`, data)
export const apiBuscarSugestoes = () => get(`${ api.url }sugestoes`)
export const apiBuscarNotificacoes = () => get(`${ api.url }notificacoes`)
export const apiCadastrarNotificacao = data => post(`${ api.url }notificacoes`, data)
export const apiAtualizarNotificacao = data => put(`${ api.url }notificacoes/${ data.id }`, data)
export const apiExcluirNotificacao = data => del(`${ api.url }notificacoes/${ data.id }`)
export const apiBuscarNCMs = data => get(`${ api.url }ncms`, { params: data })
export const apiBuscarNCMsPesquisa = data => get(`${ api.url }ncms/pesquisa`, { params: data })
export const apiBuscarNCMCategorias = data => get(`${ api.url }ncms/categorias`)
export const apiCadastrarNCM = data => post(`${ api.url }ncms`, data)
export const apiAtualizarNCM = data => put(`${ api.url }ncms/${ data.id }`, data)
export const apiExcluirNCM = data => del(`${ api.url }ncms/${ data.id }`)

export const apiUsuarioPreferencia = data => post(`${ api.url }usuarios/preferencia`, data)
export const apiAtualizarPerfil = data => post(`${ api.url }usuarios/perfil`, data)
export const apiUsuariosEmpresa = data => get(`${ api.url }usuarios/usuarios_empresa`, { params: data })
export const apiBuscarDispositivos = data => get(`${ api.url }dispositivos`)
export const apiBuscarDispositivoLogs = data => get(`${ api.url }dispositivos/logs/${ data.dispositivoId }`)
export const apiBuscarTemplatesEmail = data => get(`${ api.url }template-email`)
export const apiTemplateEmail = data => put(`${ api.url }template-email/${ data.id }`, data)
export const apiBuscarNotificacoesUsuario = data => get(`${ api.url }notificacaoUsuarios`, { params: data })
export const apiBuscarNotificacoesUsuarioNaoLidas = () => get(`${ api.url }notificacaoUsuarios/unread`)
export const apiMarcarNotificacaoUsuarioLida = data => post(`${ api.url }notificacaoUsuarios/markRead/${ data.id }`)
export const apiMarcarNotificacaoUsuarioNaoLida = data => post(`${ api.url }notificacaoUsuarios/markUnread/${ data.id }`)

export const apiBuscarDRE = data => get(`${ api.url }dre`, { params: data })
export const apiRelatorioExecutar = data => post(`${ api.url }relatorios/executar`, data)

export const apiBuscarContasPagar = data => post(`${ api.url }finDespesas/contasPagar`, { params: data })
export const apiBuscarContasPagarHoje = data => post(`${ api.url }finDespesas/contasPagarDia`, { params: data })
export const apiBuscarContasPagarMes = data => post(`${ api.url }finDespesas/contasPagarMes`, { params: data })
export const apiBuscarContasPagarProximosMeses = data => post(`${ api.url }finDespesas/contasPagarProximoMes`, { params: data })
export const apiBuscarContasPagarAtrasados = data => post(`${ api.url }finDespesas/contasPagarAtrasados`, { params: data })
export const apiBuscarEvolucaoContasPagar = data => post(`${ api.url }finDespesas/evolucaoContasPagar`, { params: data })
export const apiCadastrarLancamento = data => post(`${ api.url }finDespesas`, data)
export const apiEditarLancamento = data => put(`${ api.url }finDespesas/${data.id}`, data)
export const apiRemoverLancamento = data => del(`${ api.url }finDespesas/${data.id}`)
export const apiPagarLancamento = data => put(`${ api.url }finDespesas/${data.id}/confirmarPagamento`, data)
export const apiEstornarPagamentoLancamento = data => put(`${ api.url }finDespesas/${data.id}/cancelar`)

export const apiBuscarPlanoContas = data => get(`${ api.url }planoContas`)

export const apiBuscarFornecedores = data => get(`${ api.url }fornecedores`, { params: data })
export const apiBuscarFornecedoresPesquisa = data => get(`${ api.url }fornecedores/pesquisa`, { params: data })
export const apiBuscarFornecedor = data => get(`${ api.url }fornecedores/${data.id}`)
export const apiQuantidadeFornecedores = data => get(`${ api.url }fornecedores/quantidade`)
export const apiEditarFornecedor = data => put(`${ api.url }fornecedores/${data.id}`, data)
export const apiCadastrarFornecedor = data => post(`${ api.url }fornecedores`, data)
export const apiRemoverFornecedor = data => del(`${ api.url }fornecedores/${data.id}`)

export const getBuscarPerfis = () => get(`${ api.url }perfis`)
export const getBuscarPerfil = data => get(`${ api.url }perfis/${data.id}`)
export const apiAlterarPerfil = data => put(`${ api.url }perfis/${data.id}`, data)
export const apiCadastrarPerfil = data => post(`${ api.url }perfis`, data)
export const apiRemoverPerfil = data => del(`${ api.url }perfis/${data.id}`)

export const apiBuscarPermissoesPorPerfil = data => get(`${ api.url }permissoes/perfil/${data ? data?.id : '0'}`)
export const apiCadastrarPermissao = data => post(`${ api.url }permissoes`, data)
export const apiRemoverPermissao = data => del(`${ api.url }permissoes/${data.id}`)

export const apiBuscarGruposProduto = data => get(`${ api.url }gruposProduto`, { params: data })
export const apiBuscarGruposProdutoPesquisa = data => get(`${ api.url }gruposProduto/pesquisa`, { params: data })
export const apiBuscarGrupoProduto = data => get(`${ api.url }gruposProduto/${data.id}`)
export const apiQuantidadeGruposProduto = data => get(`${ api.url }gruposProduto/quantidade`)
export const apiEditarGrupoProduto = data => put(`${ api.url }gruposProduto/${data.id}`, data)
export const apiCadastrarGrupoProduto = data => post(`${ api.url }gruposProduto`, data)
export const apiRemoverGrupoProduto = data => del(`${ api.url }gruposProduto/${data.id}`)

export const apiBuscarProdutos = data => get(`${ api.url }produtos`, { params: data })
export const apiBuscarProduto = data => get(`${ api.url }produtos/${data.id}`)
export const apiBuscarProdutosPesquisa = data => get(`${ api.url }produtos/pesquisa`, { params: data })
export const apiQuantidadeProdutos = data => get(`${ api.url }produtos/quantidade`, { params: data })
export const apiEditarProduto = data => put(`${ api.url }produtos/${data.id}`, data)
export const apiCadastrarProduto = data => post(`${ api.url }produtos`, data)
export const apiRemoverProduto = data => del(`${ api.url }produtos/${data.id}`)

export const apiBuscarTabelaUnidadesPesquisa = data => get(`${ api.url }tabelaUnidades/pesquisa`, { params: data })
export const apiBuscarTabelaCestPesquisa = data => get(`${ api.url }tabelaCest/pesquisa`, { params: data })
export const apiBuscarNfeImpostoPesquisa = data => get(`${ api.url }nfeImposto/pesquisa`, { params: data })

export const apiBuscarCodigosBarras = data => get(`${ api.url }codigoBarras`, { params: data })
export const apiBuscarCodigosBarrasPorProduto = data => get(`${ api.url }codigoBarras/porProduto`, { params: data })
export const apiEditarCodigoBarras = data => put(`${ api.url }codigoBarras/${data.id}`, data)
export const apiCadastrarCodigoBarras = data => post(`${ api.url }codigoBarras`, data)
export const apiRemoverCodigoBarras = data => del(`${ api.url }codigoBarras/${data.id}`)

export const apiBuscarProdutosReferencia = data => get(`${ api.url }produtoReferencia`, { params: data })
export const apiBuscarProdutosReferenciaPorProduto = data => get(`${ api.url }produtoReferencia/porProduto`, { params: data })
export const apiEditarProdutoReferencia = data => put(`${ api.url }produtoReferencia/${data.id}`, data)
export const apiCadastrarProdutoReferencia = data => post(`${ api.url }produtoReferencia`, data)
export const apiRemoverProdutoReferencia = data => del(`${ api.url }produtoReferencia/${data.id}`)
export const apiBuscarProdutosComposicao = data => get(`${ api.url }produtoComposicao`, { params: data })
export const apiBuscarProdutosComposicaoPorProduto = data => get(`${ api.url }produtoComposicao/porProduto`, { params: data })
export const apiEditarProdutoComposicao = data => put(`${ api.url }produtoComposicao/${data.id}`, data)
export const apiCadastrarProdutoComposicao = data => post(`${ api.url }produtoComposicao`, data)
export const apiRemoverProdutoComposicao = data => del(`${ api.url }produtoComposicao/${data.id}`)
export const apiBuscarImagemPorProduto = data => get(`${ api.url }produtoImagem/porProduto`, { params: data })
export const apiCadastrarImagemProduto = data => post(`${ api.url }produtoImagem`, data)
export const apiEditarImagemProduto = data => put(`${ api.url }produtoImagem/${data.id}`, data)
export const apiRemoverImagemProduto = data => del(`${ api.url }produtoImagem/${data.id}`, data)
export const apiBuscarFileImagemProduto = data => get(`${ api.url }produtoImagem/${data.id}/imagem`, { responseType: 'blob' })
export const apiInserirFileImagemProduto = (id, data, config) => post(`${ api.url }produtoImagem/${id}/imagem`, data, config)

export const apiBuscarGruposCardapio = data => get(`${ api.url }gruposCardapio`, { params: data })
export const apiBuscarGruposCardapioPesquisa = data => get(`${ api.url }gruposCardapio/pesquisa`, { params: data })
export const apiBuscarGrupoCardapio = data => get(`${ api.url }gruposCardapio/${data.id}`)
export const apiQuantidadeGruposCardapio = data => get(`${ api.url }gruposCardapio/quantidade`)
export const apiEditarGrupoCardapio = data => put(`${ api.url }gruposCardapio/${data.id}`, data)
export const apiCadastrarGrupoCardapio = data => post(`${ api.url }gruposCardapio`, data)
export const apiRemoverGrupoCardapio = data => del(`${ api.url }gruposCardapio/${data.id}`)
export const apiBuscarFileGrupoCardapio = data => get(`${ api.url }gruposCardapio/${data.id}/imagem`, { responseType: 'blob' })
export const apiInserirFileGrupoCardapio = (id, data, config) => post(`${ api.url }gruposCardapio/${id}/imagem`, data, config)

export const apiBuscarConfiguracoesImpressoraPesquisa = data => get(`${ api.url }configuracaoImpressora/pesquisa`, { params: data })

export const apiBuscarGruposCardapioObservacao = data => get(`${ api.url }gruposCardapioObservacao`, { params: data })
export const apiBuscarGruposCardapioObservacaoPorGrupoCardapio = data => get(`${ api.url }gruposCardapioObservacao/porGrupoCardapio`, { params: data })
export const apiBuscarGrupoCardapioObservacao = data => get(`${ api.url }gruposCardapioObservacao/${data.id}`)
export const apiEditarGrupoCardapioObservacao = data => put(`${ api.url }gruposCardapioObservacao/${data.id}`, data)
export const apiCadastrarGrupoCardapioObservacao = data => post(`${ api.url }gruposCardapioObservacao`, data)
export const apiRemoverGrupoCardapioObservacao = data => del(`${ api.url }gruposCardapioObservacao/${data.id}`)

export const apiBuscarGruposCardapioItem = data => get(`${ api.url }gruposCardapioItem`, { params: data })
export const apiBuscarGruposCardapioItemPesquisa = data => get(`${ api.url }gruposCardapioItem/pesquisa`, { params: data })
export const apiBuscarGruposCardapioItemPorGrupoCardapio = data => get(`${ api.url }gruposCardapioItem/porGrupoCardapio`, { params: data })
export const apiBuscarGrupoCardapioItem = data => get(`${ api.url }gruposCardapioItem/${data.id}`)

export const apiBuscarVendas = data => get(`${ api.url }vendas`, { params: data })
export const apiBuscarVendasDia = data => get(`${ api.url }vendas/dia`, { params: data })
export const apiBuscarVendasSemana = data => get(`${ api.url }vendas/semana`, { params: data })
export const apiBuscarVendasMes = data => get(`${ api.url }vendas/mes`, { params: data })
export const apiBuscarVendasAno = data => get(`${ api.url }vendas/ano`, { params: data })
export const apiTotalizadorVendas = data => get(`${ api.url }vendas/totalizador`, { params: data })

export const apiBuscarEmpresas = data => get(`${ api.url }empresas`, { params: data })
export const apiBuscarEmpresa = data => get(`${ api.url }empresas/${data.id}`)
export const apiEditarEmpresa = data => put(`${ api.url }empresas/${data.id}`, data)
export const apiBuscarEmpresaPorUsuario = data => get(`${ api.url }empresas/porUsuario`, { params: data })
export const apiInserirFileEmpresaCertificado = (id, data, config) => post(`${ api.url }empresas/${id}/certificado`, data, config)
export const apiInserirFileCertificado = (data, config) => post(`${ api.url }empresaCertificados`, data, config)
export const apiRemoverEmpresaCertificado = (id) => del(`${ api.url }empresas/${id}/certificado`)
export const apiTotalizadorEmpresaCertificado = data => get(`${ api.url }empresas/certificados/totalizador`, { params: data })

export const apiBuscarUsuarios = data => get(`${ api.url }usuarios`, { params: data })
export const apiExportaCSVUsuarios = data => get(`${ api.url }usuarios/exportar/csv`, { params: data, responseType: 'blob' })
export const apiBuscarLicencasPorEmpresa = data => get(`${ api.url }empresaLicencas/porEmpresa`, { params: data })
export const apiBuscarNotificoesSistemaPorEmpresa = data => get(`${ api.url }notificacoesSistema/porEmpresa`, { params: data })
export const apiBuscarTarefasPorEmpresa = data => get(`${ api.url }tarefas/porEmpresa`, { params: data })

export const apiBuscarGruposEstoque = data => get(`${ api.url }gruposEstoque`, { params: data })
export const apiBuscarGrupoEstoque = data => get(`${ api.url }gruposEstoque/${data.id}`)
export const apiEditarGrupoEstoque = data => put(`${ api.url }gruposEstoque/${data.id}`, data)
export const apiCadastrarGrupoEstoque = data => post(`${ api.url }gruposEstoque`, data)
export const apiRemoverGrupoEstoque = data => del(`${ api.url }gruposEstoque/${data.id}`)
export const apiBuscarGrupoEstoquePesquisa = data => get(`${ api.url }gruposEstoque/pesquisa`, { params: data })

export const apiBuscarEstoques = data => get(`${ api.url }estoques`, { params: data })
export const apiBuscarEstoque = data => get(`${ api.url }estoques/${data.id}`)
export const apiEditarEstoque = data => put(`${ api.url }estoques/${data.id}`, data)
export const apiCadastrarEstoque = data => post(`${ api.url }estoques`, data)
export const apiRemoverEstoque = data => del(`${ api.url }estoques/${data.id}`)
export const apiBuscarGrupoPesquisa = data => get(`${ api.url }estoques/pesquisa`, { params: data })

export const apiBuscarEstoqueFilialPorEstoque = data => get(`${ api.url }estoqueInsumosFiliais/porEstoque`, { params: data })

// export const apiBuscarMovimentacaoCaixas = data => get(`${ api.url }usuarios/movimentacao_caixas`, { params: data })
export const apiBuscarMovimentacaoCaixas = data => get(`${ api.url }movimentacoesCaixa`, { params: data })
export const apiTotalizadorMovimentacaoCaixas = data => get(`${ api.url }movimentacoesCaixa/totalizador`, { params: data })

export const apiBuscarEvolucaoFiscal = data => get(`${ api.url }nfe/buscarEvolucaoFiscal`, { params: data })

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  getJwtDashboardData,
  getPermissoes
}
