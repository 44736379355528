import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {
    apiBuscarEstoques,
    apiBuscarGruposEstoque,
    apiBuscarVendas,
    apiBuscarVinculos
} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {Badge, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip} from "reactstrap"
import {Link} from "react-router-dom"
import {AvForm} from "availity-reactstrap-validation"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const EstoqueTable = forwardRef((props, refFather) => {

    const ref = useRef()

    const { filters, columns, usePermissionRemove, usePermissionEdit, removerEstoque, navegarEditarEstoque } = props
    const [data, setData] = useState([])
    const [modalRemove, setModalRemove] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState()

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        }
    }));

    const showConfirmDelete = item => {
        setItemSelecionado(item)
        setModalRemove(true)
    }

    const remover = () => {
        removerEstoque(itemSelecionado)
        setModalRemove(false)
    }

    const getBadgeElement = (cell) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (cell.getValue() == 'S' ? 'primary higher' : 'soft-dark')}
            color={cell.getValue() == 'S' ? 'primary higher' : 'soft-dark'}
            pill
        >
            {cell.getValue() == 'S' ? 'Sim' : 'Não'}
        </Badge>
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "ID Cloud", accessorKey: "id", size: 80, enableHiding: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {header: "Código", accessorKey: "codigo", size: 120},
            {header: "Cardápio", accessorKey: "Cod_Cardapio_Descricao", size: 80, enableColumnFilter: false,},
            {header: "Descrição", accessorKey: "descricao", size: 120, filterFn: 'contains'},
            {header: "EAN (código de barra)", accessorKey: "codigo_barra", minSize: 100, maxSize: 600, enableColumnFilter: false,
                Cell: ({renderedCellValue, row}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <div className="d-flex justify-content-between align-items-center"
                             onClick={e => onChaveCopy(renderedCellValue)}>
                            {renderedCellValue} <i className={"bx bx-copy ms-2 hover__cursor"}/>
                        </div>
                    </Box>
                )
            },
            {header: "Grupo", accessorKey: "grupo_Descricao", size: 80, enableColumnFilter: false,},
            {header: "Est. Mínimo", accessorKey: "est_minimo", size: 80, filterFn: 'lessThanOrEqualTo'},
            {header: "Est. Atual", accessorKey: "est_atual", size: 80, filterFn: 'lessThanOrEqualTo'},
            {header: "Vlr. Custo", accessorKey: "custo", size: 150, enableColumnFilter: false,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'right', gap: '1rem',}}>
                        {parseFloat(cell.getValue()).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })}
                    </Box>
                )},
            {header: "Vlr. Venda", accessorKey: "venda", size: 150, enableColumnFilter: false,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'right', gap: '1rem',}}>
                        {parseFloat(cell.getValue()).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL"
                        })}
                    </Box>
                )},
            {header: "NCM", accessorKey: "cod_ncm_Descricao", size: 120, enableColumnFilter: false,},
            {header: "CEST", accessorKey: "cest_Descricao", size: 120, enableColumnFilter: false,},
            {header: "Imposto", accessorKey: "imp_Descricao", size: 120, enableColumnFilter: false,},
            {
                header: "Ativo", accessorKey: "ativo", size: 90,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        {getBadgeElement(cell)}
                    </Box>),
                filterVariant: 'checkbox'
            },
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarEstoques(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{
                    codigo_barra: false,
                    est_minimo: false,
                    cod_ncm_Descricao: false,
                    cest_Descricao: false,
                    imp_Descricao: false,
                }}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
                enableRowActions={true}
                renderRowActions={(row, table) =>
                    (<Box sx={{display: 'flex', gap: '1rem'}}>
                        <Link to="#" className="text-primary">
                            <i className="mdi mdi-eye font-size-18 ms-1" id="viewtooltip"
                               onClick={e => navegarEditarEstoque(row.original)}
                               title="Visualizar"
                            />
                        </Link>
                        {usePermissionEdit && <Link to="#" className="text-primary">
                            <i className="mdi mdi-pencil font-size-18 ms-1" id="edittooltip"
                               onClick={e => navegarEditarEstoque(row.original)}
                               title="Editar"
                            />
                        </Link>}
                        {usePermissionRemove && <Link to="#" className="text-danger">
                            <i className="mdi mdi-delete font-size-18" id="btnDelete"
                               onClick={() => showConfirmDelete(row.original)}
                                title="Remover"/>
                        </Link>}
                    </Box>)
                }
            />
            <Modal isOpen={modalRemove}>
                <ModalHeader tag="h4">Confirmação</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={(e, v) => remover()}>
                        <Row form>
                            <Col className="col-12">
                                Deseja realmente remover esse registro?
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div className="text-right">
                                    <button type="submit" className="btn btn-success save-event">
                                        Confirmar
                                    </button>
                                    <button type="button" className="btn btn-light ms-2"
                                            onClick={e => setModalRemove(false)}>
                                        Cancelar
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
})

export default EstoqueTable

EstoqueTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
    usePermissionRemove: PropTypes.bool,
    usePermissionEdit: PropTypes.bool,
    history: PropTypes.any,
    navegarEditarEstoque: PropTypes.func,
    removerEstoque: PropTypes.func,
}
