import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import usePermission from "helpers/permission/usePermission"
import DatePicker from "react-datepicker"
import { connect } from "react-redux"
import {
  buscarFornecedoresNomeCNPJ,
  buscarPlanoContas,
} from "store/auth/profile/actions"
import Select from "react-select"
import CurrencyInput from "react-currency-input-field"
import moment from "moment"

const PagamentoLancamentoModal = props => {

  const { despesa, isOpen, toggle, saveLancamento, companies } = props

  const [valor, setValor] = useState()
  const [contas, setContas] = useState()

  const [pagamento, setPagamento] = useState()
  const [multa, setMulta] = useState()
  const [juros, setJuros] = useState()
  const [desconto, setDesconto] = useState()
  const [contaPagamento, setContaPagamento] = useState()
  const [cheque, setCheque] = useState()
  const [impresso, setImpresso] = useState()
  const [observacao, setObservacao] = useState()

  const [company, setCompany] = useState("")

  const usePermissionEdit = usePermission('contasPagar_editar')

  useEffect(() => {
    setPagamento(despesa?.data_pagamento ? new Date(despesa.data_pagamento) : null)
    setValor(despesa?.valor_real ? new String(despesa.valor_real).replaceAll('.', ',') : '0,00')
    setObservacao(despesa?.obs)
    setMulta(despesa?.multa ? new String(despesa.multa).replaceAll('.', ',') : '0,00')
    setJuros(despesa?.juros ? new String(despesa.juros).replaceAll('.', ',') : '0,00')
    setDesconto(despesa?.desconto ? new String(despesa.desconto).replaceAll('.', ',') : '0,00')
    setContaPagamento(despesa?.conta_pagamento)
    setCheque(despesa?.cheque)
    setImpresso(despesa?.impresso)
  }, [despesa])

  useEffect(() => {
    const opcoesCompany = (companies || []).filter(option => option.value)
    const companyValue = opcoesCompany.length > 0 ? opcoesCompany[0].value : undefined
    setCompany(companyValue)
  }, [companies])

  useEffect(() => {
    if(!company) {
      const opcoesCompany = (companies || []).filter(option => option.value)
      let companyValue = opcoesCompany.length > 0 ? opcoesCompany[0].value : undefined
      if(companyValue) {
        props.buscarPlanoContas({ empresaId: companyValue })
      }
      setCompany(companyValue)
    } else {
      if(company) {
        props.buscarPlanoContas({ empresaId: company })
      }
    }
  }, [company])

  useEffect(() => {
    if(Array.isArray(props.planoContas)) {
      const contas = props.planoContas.map(plano => ({ label: `${plano.codigo} - ${plano.descricao}`, value: plano.codigo }))
      setContas(contas)
    } else {
      setContas([])
    }
    return () => {
      setContas([])
    }
  }, [props.planoContas])

  const save = () => {
    let valid = true
    valid = valid && (pagamento ? true : false)
    valid = valid && (valor ? true : false)
    valid = valid && (contaPagamento ? true : false)

    if(valid) {
      salvarLancamento()
    } else {
      const divs = document.getElementsByName("validate-required")
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.display = "block"
      }
    }
  }

  const salvarLancamento = () => {
    const lancamento = despesa ? Object.assign({}, despesa) : {}
    lancamento.data_pagamento = pagamento
    lancamento.valor_real = Number.parseFloat((valor || '0').replaceAll(',', '.'))
    lancamento.obs = observacao
    lancamento.multa = Number.parseFloat((multa || '0').replaceAll(',', '.'))
    lancamento.juros = Number.parseFloat((juros || '0').replaceAll(',', '.'))
    lancamento.desconto = Number.parseFloat((desconto || '0').replaceAll(',', '.'))
    lancamento.conta_pagamento = contaPagamento
    lancamento.cheque = cheque
    lancamento.impresso = impresso
    saveLancamento(lancamento)
  }

  const changeHandeler = (event, elementId) => {
    if (event.target.value !== "") {
      document.getElementById(elementId).style.display = 'none'
    } else {
      document.getElementById(elementId).style.display = 'block'
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const divs = document.getElementsByName("validate-required")
    for (let i = 0; i < divs.length; i++) {
      divs[i].style.display = "block"
    }
  }

  return (
    <React.Fragment>
      { despesa && (
        <Modal
          isOpen={ isOpen }
          role="dialog"
          autoFocus={ true }
          centered={ true }
          tabIndex="-1"
          scrollable={ true }
          size="xl"
          toggle={ toggle }>
          <div className="modal-content">
            <ModalHeader toggle={ toggle }>Despesa</ModalHeader>
            <ModalBody>
              <form>
                <div className="outer">
                  <div className="outer">

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="valor" className="col-form-label col-lg-2">
                        Valor
                      </Label>
                      <Col lg="10">
                        <CurrencyInput value={ valor }
                                       className="form-control input-color"
                                       readOnly={ despesa.id }
                                       intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                       onValueChange={(value, name) => {
                                         setValor(value)
                                         changeHandeler(event, "validateValor")
                                       }}
                                       id="valor"
                                       name="valor"
                                       placeholder="Digite o valor do lançamento"
                                       defaultValue={ 0 }
                                       decimalScale={ 2 }
                                       disableAbbreviations={ true }
                                       required
                        />
                        <div className={ valor ? "" : "invalid-tooltip" }
                             name="validate" id="validateValor">
                          { valor ? "" : "Valor é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="data_pagamento" className="col-form-label col-lg-2">
                        Data de pagamento
                      </Label>
                      <Col lg="10">
                        <DatePicker
                          selected={ pagamento }
                          onChange={ date => {
                            setPagamento(date)
                            changeHandeler(event, "validatePagamento")
                          } }
                          className="form-control"
                          placeholderText="Selecione uma data"
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          required
                        />
                        <div className={ pagamento ? "" : "invalid-tooltip" }
                             name="validate" id="validatePagamento">
                          { pagamento ? "" : "Data de pagamento é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="centro_custo_pagamento" className="col-form-label col-lg-2">
                        Centro de custo de pagamento
                      </Label>
                      <Col lg="10">
                        <Select
                          id="centro_custo_pagamento"
                          name="centro_custo_pagamento"
                          className="basic-single"
                          options={ contas }
                          placeholder="Digite o centro de custo..."
                          isClearable={ true }
                          isSearchable={ true }
                          noOptionsMessage={ () => "Nenhuma centro de custo encontrada" }
                          onChange={ e => {
                            setContaPagamento(e?.value)
                            changeHandeler(event, "validateContaPagamento")
                          } }
                          value={ contaPagamento ? contas.find(e => e.value === contaPagamento) : undefined }
                          required
                        />
                        <div className={ contaPagamento ? "" : "invalid-tooltip" }
                             name="validate" id="validateContaPagamento">
                          { contaPagamento ? "" : "Centro de custo de pagamento é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="multa" className="col-form-label col-lg-2">
                        Multa
                      </Label>
                      <Col lg="10">
                        <CurrencyInput value={ multa }
                                       className="form-control input-color"
                                       readOnly={ despesa.id }
                                       intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                       onValueChange={(value, name) => setMulta(value)}
                                       id="multa"
                                       name="multa"
                                       placeholder="Digite o valor da multa"
                                       defaultValue={ 0 }
                                       decimalScale={ 2 }
                                       disableAbbreviations={ true }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="juros" className="col-form-label col-lg-2">
                        Juros
                      </Label>
                      <Col lg="10">
                        <CurrencyInput value={ juros }
                                       className="form-control input-color"
                                       readOnly={ despesa.id }
                                       intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                       onValueChange={(value, name) => setJuros(value)}
                                       id="juros"
                                       name="juros"
                                       placeholder="Digite o valor do juros"
                                       defaultValue={ 0 }
                                       decimalScale={ 2 }
                                       disableAbbreviations={ true }
                        />

                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="desconto" className="col-form-label col-lg-2">
                        Desconto
                      </Label>
                      <Col lg="10">
                        <CurrencyInput value={ desconto }
                                       className="form-control input-color"
                                       readOnly={ despesa.id }
                                       intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                       onValueChange={(value, name) => setDesconto(value)}
                                       id="desconto"
                                       name="desconto"
                                       placeholder="Digite o valor do desconto"
                                       defaultValue={ 0 }
                                       decimalScale={ 2 }
                                       disableAbbreviations={ true }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="cheque" className="col-form-label col-lg-2">
                        Cheque
                      </Label>
                      <Col lg="10">
                        <Input
                          id="cheque"
                          name="cheque"
                          type="text"
                          className="form-control"
                          value={ cheque }
                          onChange={ e => setCheque(e.target.value) }
                          placeholder="Digite a observação"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="impresso" className="col-form-label col-lg-2">
                        Impresso
                      </Label>
                      <Col lg="10">
                        <DatePicker
                          selected={ impresso }
                          onChange={ date => setImpresso(date) }
                          className="form-control"
                          placeholderText="Selecione uma data"
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="observacao" className="col-form-label col-lg-2">
                        Observação
                      </Label>
                      <Col lg="10">
                        <Input
                          id="observacao"
                          name="observacao"
                          type="text"
                          className="form-control"
                          value={ observacao }
                          onChange={ e => setObservacao(e.target.value) }
                          placeholder="Digite a observação"
                        />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" disabled={ !usePermissionEdit } onClick={ save }>
                Pagar
              </Button>
              <Button type="button" color="secondary" className="ms-2" onClick={ toggle }>
                Fechar
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      ) }
    </React.Fragment>
  )
}

PagamentoLancamentoModal.propTypes = {
  toggle: PropTypes.func,
  saveLancamento: PropTypes.func,
  isOpen: PropTypes.bool,
  despesa: PropTypes.object
}

const mapStatetoProps = state => {
  const {
    fornecedores,
    fornecedoresLoading,
    planoContas,
    planoContasLoading
  } = state.Profile
  return {
    fornecedores,
    fornecedoresLoading,
    planoContas,
    planoContasLoading
  }
}

const bindActionToPromise = (dispatch, actionCreator) => (payload) => {
  return new Promise((resolve, reject) => dispatch(actionCreator(payload, { resolve, reject })));
};

function mapDispatchToProps(dispatch) {
  return {
    buscarFornecedores : bindActionToPromise(dispatch, buscarFornecedoresNomeCNPJ),
    buscarPlanoContas: () => buscarPlanoContas
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)((PagamentoLancamentoModal))

