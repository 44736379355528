import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {
    Button,
    ButtonDropdown,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import {relatorioExecutar} from "../../store/auth/profile/actions"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import MovimentoVendasTable from "./movimento-vendas-table"
import FormFilterSelect from "../../components/Common/form-filter-select"
import FormFilterPeriod from "../../components/Common/form-filter-period"
import moment from "moment-timezone"
import {getCompanies, getCompaniesValuesByOptions, getDatasPeloPeriodo} from "../../helpers/utils"
import MovimentoVendasKpiCards from "./movimento-vendas-kpi-cards"
import MovimentoVendasKpiEvolucao from "./movimento-vendas-kpi-evolucao"
import FormFilterCompany from "../../components/Common/form-filter-company"

import "moment/locale/pt-br"
moment.locale("pt-br")

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const MovimentoVendasLista = props => {

    const ref = useRef()

    const [filters, setFilters] = useState(null)
    const [companies, setCompanies] = useState([])
    const [companiesOptions, setCompaniesOptions] = useState([{label: "Todas", value: ""}])
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [periods, setPeriods] = useState([])
    const [period, setPeriod] = useState(null)
    const [startDate, setStartDate] = useState(moment().subtract(30, "days").toDate())
    const [endDate, setEndDate] = useState(new Date())

    const [situacao, setSituacao] = useState("V")
    const [tipoAtendimento, setTipoAtendimento] = useState("")
    const [atendimento, setAtendimento] = useState("")
    const [fitaCaixa, setFitaCaixa] = useState("")
    const [mesa, setMesa] = useState("")
    const [usuarioCaixa, setUsuarioCaixa] = useState("")
    const [usuarioVenda, setUsuarioVenda] = useState("")
    const [usuarioEntregador, setUsuarioEntregador] = useState("")
    const [botaoTipoRelatorio, setBotaoTipoRelatorio] = useState(false)
    const [atualizarTotalizadores, setAtualizarTotalizadores] = useState(false)

    useEffect(() => {
        const tmpPeriods = []
        for (let i = 0; i <= 11; i++) {
            tmpPeriods.push({
                label: moment().subtract(i, "month").format("MMMM/YY"),
                value: moment().subtract(i, "month").format("YYYY-MM-DD")
            })
        }

        let primeiroPeriodo = tmpPeriods[0].value
        setPeriod(primeiroPeriodo)

        tmpPeriods.unshift({label: "Selecione", value: null})
        tmpPeriods.push({label: "Personalizado", value: "Personalizado"})
        setPeriods(tmpPeriods)

        setCompaniesOptions(getCompanies(props.user))
        setCompanies(props.dashboardFilters.companiesSelected)

        if (possuiVinculo) {
            populateFiltros(primeiroPeriodo, props.dashboardFilters.companiesSelected)
        }

        return () => {
            setCompaniesOptions([])
        }
    }, [])

    const populateFiltros = (periodoSelecionado, companiesSelected) => {
        const filters = []

        filters.push({id: 'Status', value: ['V', 'C', 'T', 'D', 'I', 'P'], condition: Filters.arrIncludes})

        if (companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        } else if (companies) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies)})
        }

        if (situacao) filters.push({id: 'Status', value: situacao})
        if (tipoAtendimento) filters.push({id: 'Tipo', value: tipoAtendimento})
        if (atendimento) filters.push({id: 'Atendimento', value: atendimento})
        if (fitaCaixa) filters.push({id: 'Seq_Caixa', value: fitaCaixa})
        if (mesa) filters.push({id: 'Mesa', value: mesa ? mesa.split(',') : mesa, condition: Filters.arrIncludes})
        if (usuarioCaixa) filters.push({id: 'Func_Caixa', value: usuarioCaixa})
        if (usuarioVenda) filters.push({id: 'Func_VEnda', value: usuarioVenda})
        if (usuarioEntregador) filters.push({id: 'Ent_Entregador', value: usuarioEntregador})

        let periodo = period || periodoSelecionado
        let datasPeloPeriodo = getDatasPeloPeriodo(periodo, startDate, endDate)
        if(datasPeloPeriodo) {
            filters.push({id: 'Data', value: datasPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo})
            filters.push({id: 'Data', value: datasPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo})
        }
        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => {
            ref?.current?.search()
            setAtualizarTotalizadores(!atualizarTotalizadores)
        }, 300)
    }

    const printPDF = (tipoRelatorio, agruparPorStatus) => {
        const reportName = 'movimento_vendas'

        let dateStartStr
        let dateEndStr
        if (period && period !== "Selecione") {
            if (period !== 'Personalizado') {
                dateStartStr = moment(period).startOf('month').format('YYYY-MM-DD')
                dateEndStr = moment(period).endOf('month').format('YYYY-MM-DD')
            } else {
                dateStartStr = startDate ? moment(startDate).format('YYYY-MM-DD') : null
                dateEndStr = startDate ? moment(endDate).format('YYYY-MM-DD') : null
            }
        }

        dateStartStr = dateStartStr ? dateStartStr : moment(new Date()).format('YYYY-MM-DD')
        dateEndStr = dateEndStr ? dateEndStr : moment(new Date()).format('YYYY-MM-DD')

        props.relatorioExecutar({
            company: getCompaniesValuesByOptions(companies),
            status: situacao,
            tipo: tipoAtendimento,
            caixa: fitaCaixa,
            mesa: mesa,
            funcionarioCaixa: usuarioCaixa,
            funcionarioVenda: usuarioVenda,
            funcionarioEntregador: usuarioEntregador,
            agrupaStatus: (agruparPorStatus === undefined ? false : agruparPorStatus),
            tipoRelatorio,
            dataInicial: dateStartStr,
            dataFinal: dateEndStr,
            reportName,
        });
    }

    const onPickStartDate = date => {
        setStartDate(date)
        setEndDate(date)
    }

    if (!props.user || !props.user.vinculos.length || !(props.user.vinculos || []).filter(e => ["A", "T"].find(status => status == e.vinculo_status)).length) {
        return (<AlertaSemVinculo/>)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Cadastro" breadcrumbItem="Movimento de vendas"/>
                    <Row>
                        <Col xl="12">
                            <MovimentoVendasKpiCards periodo={period} inicio={startDate} fim={endDate} atualizarTotalizadores={atualizarTotalizadores}>
                            </MovimentoVendasKpiCards>
                        </Col>
                        <Col xl="12">
                            <MovimentoVendasKpiEvolucao>
                            </MovimentoVendasKpiEvolucao>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Movimento de vendas</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-md-2 col-sm-12">
                                                    <FormFilterCompany
                                                        attribute="companies"
                                                        value={companies}
                                                        description="Empresa(s)"
                                                        setValue={value => setCompanies(value)}
                                                        options={companiesOptions}
                                                        multiple={true}
                                                    />
                                                </div>

                                                <FormFilterPeriod
                                                    periodAttribute="period"
                                                    periodDescription="Período"
                                                    periodValue={period}
                                                    periodChangeHandeler={e => setPeriod(e)}
                                                    options={periods}
                                                    placeHolder="Selecione uma data"
                                                    startDateAttribute="startDate"
                                                    startDateDescription="Data Inicial"
                                                    startDateValue={startDate}
                                                    startDateChangeHandeler={e => onPickStartDate(e)}
                                                    endDateAttribute="endDate"
                                                    endDateDescription="Data Final"
                                                    endDateValue={endDate}
                                                    endDateChangeHandeler={e => setEndDate(e)}
                                                />

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="situacao"
                                                        value={situacao}
                                                        description="Situação"
                                                        changeHandeler={value => setSituacao(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "V", label: "Venda confirmada"},
                                                            {value: "C", label: "Venda cancelada"},
                                                            {value: "T", label: "Cortesia"},
                                                            {value: "D", label: "Devolvida"},
                                                            {value: "I", label: "Consumo interno"},
                                                            {value: "P", label: "Desperdício"},
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="tipoAtendimento"
                                                        value={tipoAtendimento}
                                                        description="Tipo"
                                                        changeHandeler={value => setTipoAtendimento(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "I", label: "Consumo interno"},
                                                            {value: "P", label: "Desperdício"},
                                                            {value: "D", label: "Venda"},
                                                            {value: "T", label: "Cortesia"},
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                        attribute="atendimento"
                                                        value={atendimento}
                                                        description="Atendimento"
                                                        changeHandeler={value => setAtendimento(value)}
                                                        options={[
                                                            {value: "", label: "Qualquer", defaultValue: true},
                                                            {value: "D", label: "Delivery"},
                                                            {value: "B", label: "Balcão"},
                                                            {value: "M", label: "Mesa"}
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Fita de caixa</Label>
                                                        <Input
                                                            id="fitaCaixa"
                                                            name="fitaCaixa"
                                                            type="number"
                                                            className="form-control"
                                                            value={fitaCaixa}
                                                            onChange={e => setFitaCaixa(e.target.value)}
                                                            min={0}>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Mesa(s)</Label>
                                                        <Input
                                                            id="mesa"
                                                            name="mesa"
                                                            type="text"
                                                            className="form-control"
                                                            value={mesa}
                                                            title="Informe um número de mesa para a pesquisa ou mais de um número separando por vírgula, exemplo: 1, 2, 3"
                                                            onChange={e => setMesa(e.target.value.replace(new RegExp("[^\\d\,]"), ''))}>
                                                        </Input>
                                                    </FormGroup>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-2 col-sm-6">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Usuário caixa</Label>
                                                        <Input
                                                            id="usuarioCaixa"
                                                            name="usuarioCaixa"
                                                            title="Usuário caixa é o usuário que realizou o recebimento da venda no caixa ou máquinas de cartão"
                                                            type="text"
                                                            className="form-control"
                                                            value={usuarioCaixa}
                                                            onChange={e => setUsuarioCaixa(e.target.value)}>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-2 col-sm-6">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Usuário venda</Label>
                                                        <Input
                                                            id="usuarioVenda"
                                                            name="usuarioVenda"
                                                            title="Usuário venda é o usuário que inicio o atendimento na mesa ou que realizou o atendimento balcão ou delivery"
                                                            type="text"
                                                            className="form-control"
                                                            value={usuarioVenda}
                                                            onChange={e => setUsuarioVenda(e.target.value)}>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-2 col-sm-6">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Usuário entregador</Label>
                                                        <Input
                                                            id="usuarioEntregador"
                                                            name="usuarioEntregador"
                                                            title="Usuário que realizou a entrega"
                                                            type="text"
                                                            className="form-control"
                                                            value={usuarioEntregador}
                                                            onChange={e => setUsuarioEntregador(e.target.value)}>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>

                                                    {!props.executaRelatorioLoading &&
                                                        <div className="mt-3 col col-auto btn-group">
                                                            <ButtonDropdown
                                                                isOpen={botaoTipoRelatorio}
                                                                toggle={() => setBotaoTipoRelatorio(!botaoTipoRelatorio)}>
                                                                <Button type="button" color="light"
                                                                        onClick={e => printPDF('V')}
                                                                        title="Relatório impresso em PDF detalhado por venda">
                                                                    Relatório PDF - Vendas
                                                                </Button>
                                                                <DropdownToggle caret color="light">
                                                                    <i className="mdi mdi-chevron-down"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={e => printPDF('H')}
                                                                                  title="Relatório impresso em PDF detalhado por hora">Por
                                                                        hora</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('W')}
                                                                                  title="Relatório impresso em PDF detalhado por dia da semana">Por
                                                                        dia da semana</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('D')}
                                                                                  title="Relatório impresso em PDF detalhado por dia">Diário</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('M')}
                                                                                  title="Relatório impresso em PDF detalhado por mês">Mensal</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('A')}
                                                                                  title="Relatório impresso em PDF detalhado por ano">Anual</DropdownItem>
                                                                    <DropdownItem divider/>
                                                                    <DropdownItem header>Agrupado por
                                                                        situação</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('V', true)}
                                                                                  title="Relatório impresso em PDF agrupado por situação de venda detalhado por venda">Por
                                                                        venda</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('H', true)}
                                                                                  title="Relatório impresso em PDF agrupado por situação de venda detalhado por hora">Por
                                                                        hora</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('W', true)}
                                                                                  title="Relatório impresso em PDF agrupado por situação de venda detalhado por dia da semana">Por
                                                                        dia da semana</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('D', true)}
                                                                                  title="Relatório impresso em PDF agrupado por situação de venda detalhado por dia">Diário</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('M', true)}
                                                                                  title="Relatório impresso em PDF agrupado por situação de venda detalhado por mês">Mensal</DropdownItem>
                                                                    <DropdownItem onClick={e => printPDF('A', true)}
                                                                                  title="Relatório impresso em PDF agrupado por situação de venda detalhado por ano">Anual</DropdownItem>
                                                                </DropdownMenu>
                                                            </ButtonDropdown>
                                                        </div>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && <Card>
                                    <CardBody>
                                        <MovimentoVendasTable ref={ref} filters={filters} history={props.history}>
                                        </MovimentoVendasTable>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

MovimentoVendasLista.propTypes = {
    relatorioExecutar: PropTypes.func,
}

const mapStatetoProps = state => {
    const {} = state.Profile
    const {user, dashboardFilters} = state.Login
    return {user, dashboardFilters}
}

export default connect(mapStatetoProps, {
    relatorioExecutar,
})(withRouter(MovimentoVendasLista))
