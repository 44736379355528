import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardTitle, Col, Collapse, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import 'react-phone-number-input/style.css'

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
    buscarEmpresa,
    buscarLicencasPorEmpresa,
    buscarNotificacoesSistemaPorEmpresa,
    buscarTarefasPorEmpresa,
    editarEmpresa
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import Loader from "react-loader-spinner"
import FormInputSwitch from "../../components/Common/form-input-switch"
import FormInputNumber from "../../components/Common/form-input-number"
import FormInputText from "../../components/Common/form-input-text"
import "toastr/build/toastr.min.css"
import FormInputDate from "../../components/Common/form-input-date"
import moment from "moment-timezone"
import EmpresaLicencaLista from "./empresa-licenca-lista"
import EmpresaTarefaLista from "./empresa-tarefas-lista"
import EmpresaNotificacaoLista from "./empresa-notificacoes-lista"
import {isEmailValid} from "../../helpers/utils"
import FormInputEmail from "../../components/Common/form-input-email"
import toastr from "toastr"

const brazilMoment = (...args) => moment(...args)
registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastrarEmpresa = props => {

    const isInforangra = props.user?.usuario_info
    const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
    const usePermissionEdit = usePermission("empresas_editar") && isInforangra
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    const [loading, setLoading] = useState(false)

    const [id, setId] = useState()
    const [empresaId, setEmpresaId] = useState()
    const [razaoSocial, setRazaoSocial] = useState()
    const [fantasia, setFantasia] = useState()
    const [cnpj, setCnpj] = useState()
    const [emailContator, setEmailContador] = useState()
    const [email, setEmail] = useState()
    const [envioRemessaAutomatico, setEnvioRemessaAutomatico] = useState()
    const [diaEnviarRemessa, setDiaEnviarRemessa] = useState()
    const [urlCertificado, setUrlCertificado] = useState()
    const [tipoCertificado, setTipoCertificado] = useState()
    const [validadeCertificado, setValidadeCertificado] = useState()
    const [dataEnvioCertificado, setDataEnvioCertificado] = useState()
    const [usuarioEnvioCertificado, setUsuarioDataEnvioCertificado] = useState()
    const [dataAlteracao, setDataAlteracao] = useState()
    const [usuarioAlteracao, setUsuarioAlteracao] = useState()
    const [accordionRemessa, setAccordionRemessa] = useState(true)
    const [accordionCertificadoDigital, setAccordionCertificadoDigital] = useState(true)
    const [accordionRegistroLicencas, setAccordionRegistroLicencas] = useState(true)
    const [accordionTarefas, setAccordionTarefas] = useState(true)
    const [accordionNotificacoes, setAccordionNotificacoes] = useState(true)

    useEffect(() => {
        if (props.empresaAtivo && props.empresaAtivo.id) {
            props.buscarEmpresa(props.empresaAtivo)
        } else {
            preencherFormulario(null)
            setId(null)
        }
    }, [props.empresaAtivo])

    useEffect(() => {
        if (props.empresa && props.empresa?.id) {
            preencherFormulario(props.empresa)

            props.buscarTarefasPorEmpresa({
                company: props.empresa?.idEmpresa,
                ignoreCompany: true
            })

            props.buscarLicencasPorEmpresa({
                company: props.empresa?.idEmpresa,
                ignoreCompany: true
            })

            props.buscarNotificacoesSistemaPorEmpresa({
                company: props.empresa?.idEmpresa,
                ignoreCompany: true
            })

        }
    }, [props.empresa])

    const preencherFormulario = (empresa) => {
        setId(empresa?.id)
        setEmpresaId(empresa?.idEmpresa)
        setRazaoSocial(empresa?.RazaoSocial)
        setFantasia(empresa?.Fantasia)
        setCnpj(empresa?.CNPJ)
        setEmailContador(empresa?.EmailContador)
        setEmail(empresa?.Email)
        setEnvioRemessaAutomatico(empresa?.RemessaAutomatica ? empresa?.RemessaAutomatica : 'N')
        setDiaEnviarRemessa(empresa?.RemessaDia)
        setUrlCertificado(empresa?.certificado?.Certificado_url_s3)
        setTipoCertificado(empresa?.certificado?.Certificado_tipo)
        setValidadeCertificado(empresa?.certificado?.Certificado_validade ? brazilMoment(empresa?.certificado?.Certificado_validade, "YYYY-MM-DD").toDate() : null)
        setDataEnvioCertificado(empresa?.certificado?.Certificado_data_envio ? brazilMoment(empresa?.certificado?.Certificado_data_envio, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setUsuarioDataEnvioCertificado(empresa?.certificado?.Certificado_usuario || 'N/A')
        setDataAlteracao(empresa?.AltData ? brazilMoment(empresa?.AltData, "YYYY-MM-DD HH:mm:ss").toDate() : null)
        setUsuarioAlteracao(`${empresa?.AltFunc} - ${(empresa?.AltFunc_Nome || 'N/A')}`)

        if(isCertificadoVencido()) {
            const divs = document.getElementById("validate-validadeCertificado-date")
            divs.style.display = "block"
        }
    }

    const gravarEmpresa = (e) => {
        const empresa = props.empresa ? Object.assign({}, props.empresa) : {}

        let valid = true
        const emailValid = email && email !== '' ? isEmailValid(email) : false
        valid = valid && emailValid

        const emailContatorValid = emailContator && emailContator !== '' ? isEmailValid(emailContator) : false
        valid = valid && emailContatorValid

        if(valid) {
            empresa.id = id
            empresa.Email = email
            empresa.EmailContador = emailContator
            empresa.RemessaAutomatica = envioRemessaAutomatico
            empresa.RemessaDia = diaEnviarRemessa

            props.editarEmpresa(empresa, props.history)
        } else {
            const divs = [...document.getElementsByName("validate-required")]
            for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "block"
            }

            window.scrollTo(0, 0)
            toastr.error('Há campos obrigatórios não preenchidos ou inválidos!', 'Validação de informações')
        }
    }

    const isCertificadoVencido = () => {
        return validadeCertificado ? validadeCertificado < new Date() : false
    }

    if (!props.user || !possuiVinculo) {
        return (<AlertaSemVinculo/>)
    }

    return <React.Fragment>
        {props.empresaLoading || loading ? (<div className="loader-container position-absolute">
            <Loader
                type="ThreeDots"
                color="#556EE6"
                height={80}
                width={80}
            />
        </div>) : (<div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title="Cadastro"
                    breadcrumbItem="Empresa"
                />
                <StickerToolbarButtons>
                    {id && <>
                        <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light me-2"
                            disabled={!usePermissionEdit}
                            onClick={e => gravarEmpresa(e)}>
                            {"Salvar Empresa"}
                        </Button>
                    </>
                    }
                </StickerToolbarButtons>

                <Row>
                    <Col lg="12">
                        <div>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Cadastro</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">
                                                {id && <FormGroup className="mb-4" row>
                                                    <Label htmlFor="empresa"
                                                           className="col-form-label col-lg-2">
                                                        Identificador
                                                    </Label>
                                                    <Col lg="10" className="col-form-label">
                                                        {id}
                                                    </Col>
                                                </FormGroup>}

                                                {id && <FormGroup className="mb-4" row>
                                                    <Label htmlFor="codigo" className="col-form-label col-lg-2">
                                                        Código
                                                    </Label>
                                                    <Col lg="10" className="col-form-label">
                                                        {empresaId}
                                                    </Col>
                                                </FormGroup>}

                                                <FormInputText
                                                    attribute="razaoSocial"
                                                    description="Razão Social/Nome"
                                                    value={razaoSocial}
                                                    setValue={setRazaoSocial}
                                                    required={true}
                                                    maxLength={70}
                                                    disabled={true}
                                                />

                                                <FormInputText
                                                    attribute="fantasia"
                                                    description="Fantasia"
                                                    value={fantasia}
                                                    setValue={setFantasia}
                                                    required={true}
                                                    maxLength={70}
                                                    disabled={true}
                                                />

                                                <FormInputText
                                                    attribute="cnpj"
                                                    description="CNPJ/CPF"
                                                    value={cnpj}
                                                    setValue={setCnpj}
                                                    required={true}
                                                    maxLength={70}
                                                    disabled={true}
                                                />

                                                <FormInputEmail
                                                    attribute="email"
                                                    description="E-mail"
                                                    value={email}
                                                    setValue={setEmail}
                                                    required={true}
                                                    maxLength={70}
                                                    disabled={!usePermissionEdit}
                                                    validacao={ isEmailValid(email) }
                                                    validacaoMensagem={"E-mail está inválido"}
                                                />

                                                <FormInputEmail
                                                    attribute="emailContator"
                                                    description="E-mail da contabilidade"
                                                    value={emailContator}
                                                    setValue={setEmailContador}
                                                    required={true}
                                                    maxLength={70}
                                                    disabled={!usePermissionEdit}
                                                    validacao={ isEmailValid(emailContator) }
                                                    validacaoMensagem={"E-mail da contabilidade está inválido"}
                                                />

                                            </div>
                                        </div>
                                    </Form>

                                    <div className="hstack gap-3 mb-2">
                                        <Label className="col-form-label">Quadros de informações</Label>
                                        <button type="button" className="btn btn-soft-primary btn-sm" onClick={(e) => {
                                            setAccordionRemessa(true)
                                            setAccordionCertificadoDigital(true)
                                            setAccordionRegistroLicencas(true)
                                            setAccordionTarefas(true)
                                            setAccordionNotificacoes(true)
                                        }}>Exibir todos</button>
                                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={(e) => {
                                            setAccordionRemessa(false)
                                            setAccordionCertificadoDigital(false)
                                            setAccordionRegistroLicencas(false)
                                            setAccordionTarefas(false)
                                            setAccordionNotificacoes(false)
                                        }}>Não exibir</button>
                                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={(e) => {
                                            setAccordionRemessa(true)
                                            setAccordionCertificadoDigital(false)
                                            setAccordionRegistroLicencas(false)
                                            setAccordionTarefas(false)
                                            setAccordionNotificacoes(false)
                                        }}>Exibir padrões</button>
                                    </div>

                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className={accordionRemessa ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionRemessa(!accordionRemessa) }}/>{" "}
                                            Remessa de arquivo XML (DF-e)
                                        </label>
                                        <label className={accordionCertificadoDigital ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionCertificadoDigital(!accordionCertificadoDigital) }}/>{" "}
                                            Certificado digital
                                        </label>
                                        <label className={accordionRegistroLicencas ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionRegistroLicencas(!accordionRegistroLicencas) }}/>{" "}
                                            Registro de licenças
                                        </label>
                                        <label className={accordionTarefas ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionTarefas(!accordionTarefas) }}/>{" "}
                                            Tarefas
                                        </label>
                                        <label className={accordionNotificacoes ? "btn btn-secondary active" : "btn btn-secondary"} >
                                            <input type="checkbox" className="btn-check" onClick={() => { setAccordionNotificacoes(!accordionNotificacoes) }}/>{" "}
                                            Notificações
                                        </label>
                                    </div>

                                </CardBody>
                            </Card>

                            <Collapse isOpen={accordionRemessa}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Remessa de arquivo XML (DF-e)</CardTitle>
                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputSwitch
                                                        attribute="envioRemessaAutomatico"
                                                        description="Envio de Remessa XML Automático"
                                                        value={envioRemessaAutomatico}
                                                        setValue={setEnvioRemessaAutomatico}
                                                        disabled={!usePermissionEdit}
                                                    />

                                                    <FormInputNumber
                                                        attribute="diaEnviarRemessa"
                                                        description="Dia para enviar remessa"
                                                        value={diaEnviarRemessa}
                                                        setValue={setDiaEnviarRemessa}
                                                        allowNegative={false}
                                                        max={9999999}
                                                        min={0}
                                                        disabled={!usePermissionEdit}
                                                    />

                                                </div>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>

                            <Collapse isOpen={accordionCertificadoDigital}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Certificado digital</CardTitle>

                                        <Form>
                                            <div className="outer">
                                                <div className="outer">

                                                    <FormInputText
                                                        attribute="urlCertificado"
                                                        description="URL Certificado"
                                                        value={ urlCertificado }
                                                        setValue={setUrlCertificado}
                                                        disabled={true}
                                                    />

                                                    <FormInputText
                                                        attribute="tipoCertificado"
                                                        description="Tipo Certificado"
                                                        value={ tipoCertificado }
                                                        setValue={setTipoCertificado}
                                                        disabled={true}
                                                    />

                                                    <FormInputDate
                                                        attribute="validadeCertificado"
                                                        description="Validade"
                                                        value={validadeCertificado}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy'}
                                                        validacao={ !isCertificadoVencido() }
                                                        validacaoMensagem={"O certificado está vencido!"}
                                                    />

                                                    <FormInputDate
                                                        attribute="dataEnvioCertificado"
                                                        description="Data de envio"
                                                        value={dataEnvioCertificado}
                                                        disabled={true}
                                                        dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                    />

                                                    <FormInputText
                                                        attribute="usuarioEnvioCertificado"
                                                        description="Usuário enviou certificado"
                                                        value={ usuarioEnvioCertificado }
                                                        setValue={setUsuarioDataEnvioCertificado}
                                                        disabled={true}
                                                    />

                                                </div>
                                            </div>
                                        </Form>

                                    </CardBody>
                                </Card>
                            </Collapse>

                            <Collapse isOpen={accordionRegistroLicencas}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Registro de licenças</CardTitle>
                                        <EmpresaLicencaLista
                                            listaLicenca={props.licencas}
                                            loading={props.licencasLoading} >
                                        </EmpresaLicencaLista>
                                    </CardBody>
                                </Card>
                            </Collapse>

                            <Collapse isOpen={accordionTarefas}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Tarefas</CardTitle>
                                        <EmpresaTarefaLista
                                            listaTarefa={props.tarefas}
                                            loading={props.tarefasLoading} >
                                        </EmpresaTarefaLista>
                                    </CardBody>
                                </Card>
                            </Collapse>

                            <Collapse isOpen={accordionNotificacoes}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Notificações</CardTitle>
                                        <EmpresaNotificacaoLista
                                            listaNotificacao={props.notificacoesSistema}
                                            loading={props.notificacoesSistemaLoading} >
                                        </EmpresaNotificacaoLista>
                                    </CardBody>
                                </Card>
                            </Collapse>

                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Dados de alteração do registro</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">

                                                <FormInputDate
                                                    attribute="dataAlteracao"
                                                    description="Alteração do cadastro"
                                                    value={dataAlteracao}
                                                    disabled={true}
                                                    dateFormat={'dd/MM/yyyy HH:mm:ss'}
                                                    withHour={true}
                                                />

                                                <FormInputText
                                                    attribute="usuarioAlteracao"
                                                    description="Usuário que alterou"
                                                    value={usuarioAlteracao}
                                                    disabled={true}
                                                />

                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)}
    </React.Fragment>
}

CadastrarEmpresa.propTypes = {
    empresaAtivo: PropTypes.any,
    empresa: PropTypes.any,
    empresaLoading: PropTypes.bool,
    permanecerCadastro: PropTypes.any,
    empresaLicencas: PropTypes.any,
    empresaLicencasLoading: PropTypes.bool,
    empresaTarefas: PropTypes.any,
    empresaTarefasLoading: PropTypes.bool,
    empresaNotificacoes: PropTypes.any,
    empresaNotificacoesLoading: PropTypes.bool,
    novoEmpresaObservacao: PropTypes.any,

    buscarEmpresa: PropTypes.func,
    editarEmpresa: PropTypes.func,
    buscarTarefasPorEmpresa: PropTypes.func,
    cadastrarEmpresaObservacao: PropTypes.func,
    removerEmpresaObservacao: PropTypes.func,
    editarEmpresaObservacao: PropTypes.func,
}

const mapStatetoProps = state => {
    const {
        empresaAtivo,
        empresa,
        empresaLoading,
        licencas,
        licencasLoading,
        tarefas,
        tarefasLoading,
        notificacoesSistema,
        notificacoesSistemaLoading,
    } = state.Profile
    const {user} = state.Login
    return {
        user,
        empresaAtivo,
        empresa,
        empresaLoading,
        licencas,
        licencasLoading,
        tarefas,
        tarefasLoading,
        notificacoesSistema,
        notificacoesSistemaLoading,
    }
}

export default connect(mapStatetoProps, {
    editarEmpresa,
    buscarEmpresa,
    buscarTarefasPorEmpresa,
    buscarLicencasPorEmpresa,
    buscarNotificacoesSistemaPorEmpresa,
})(withRouter(CadastrarEmpresa))
