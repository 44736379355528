import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={ 8 }>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="py-5 px-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 className="mb-1">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        <span className="text-primary">ERP</span> InfoCloud - A
                        Gestão da sua empresa !
                      </h4>
                      <Carousel showThumbs={ false } className="slider_css">
                        <div>
                          <div className="item">
                            <div className="py-3">
                              <p className="font-size-16 mb-4">
                                " Acesse a ferramenta de gestão da informação
                                que ajuda no acompanhamento e exibição de
                                indicadores chave de performance (KPIs),
                                métricas e dados que indicam a saúde de um
                                negócio, setor ou processo. Eles são
                                customizáveis para atender às demandas
                                específicas de cada empresa e suas áreas. "
                              </p>

                              <div>
                                <h4 className="font-size-16 text-primary">
                                  Desde 1999
                                </h4>
                                <p className="font-size-14 mb-0">
                                  - Inforangra Automação
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="item">
                            <div className="py-3">
                              <p className="font-size-16 mb-4">
                                "Monitore os resultados do seu negócio com
                                diversos indicadores com acesso rápido e fácil.
                                "
                              </p>

                              <div>
                                <h4 className="font-size-16 text-primary">
                                  Desde 1999-2021
                                </h4>
                                <p className="font-size-14 mb-0">
                                  - Inforangra Automação
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
