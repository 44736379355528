import React, {useState} from "react"
import PropTypes from "prop-types"
import {Button, Card, CardBody, CardTitle, Col, Container, Form, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

//Date Picker
import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import 'react-phone-number-input/style.css'

import ptBR from "date-fns/locale/pt-BR"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {editarEmpresaCertificadoDigitalSemAutenticacao} from "../../store/auth/profile/actions"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons"
import Loader from "react-loader-spinner"
import "toastr/build/toastr.min.css"
import moment from "moment-timezone"
import FormInputFile from "../../components/Common/form-input-file";
import FormInputPassword from "../../components/Common/form-input-password";
import {withTranslation} from "react-i18next";
import FormInputCpfCnpj from "../../components/Common/form-input-cpf-cnpj";

const brazilMoment = (...args) => moment(...args)
registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const CadastroCertificadoDigitalSemAutenticacao = props => {

    const [loading, setLoading] = useState(false)

    const [cnpj, setCnpj] = useState()
    const [certificado, setCertificado] = useState()
    const [certificadoSenha, setCertificadoSenha] = useState()

    const gravarCertificado = (e) => {
        const empresa = {
            certificado: certificado,
            senhaCertificado: certificadoSenha,
            cnpj: cnpj
        }
        props.editarEmpresaCertificadoDigitalSemAutenticacao(empresa)
    }

    return <React.Fragment>
        {props.empresaLoading || loading ? (<div className="loader-container position-absolute">
            <Loader
                type="ThreeDots"
                color="#556EE6"
                height={80}
                width={80}
            />
        </div>) : (<div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title="Cadastro"
                    breadcrumbItem="Certificado digital"
                />
                <StickerToolbarButtons>
                    <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light me-2"
                        disabled={ !cnpj || !certificado || !certificadoSenha}
                        onClick={e => gravarCertificado(e)}>
                        {"Enviar"}
                    </Button>

                    <Button
                        type="submit"
                        color="secondary"
                        className="waves-effect waves-light me-2"
                        onClick={e => props.history.push("/dashboard-padrao") }>
                        {"Ir para login"}
                    </Button>
                </StickerToolbarButtons>

                <Row>
                    <Col lg="12">
                        <div>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Enviar certificado</CardTitle>
                                    <Form>
                                        <div className="outer">
                                            <div className="outer">

                                                <FormInputCpfCnpj
                                                    attribute="cnpj"
                                                    description={ props.t('certificadosemautenticacao.edicao.cnpj.title') }
                                                    value={cnpj}
                                                    setValue={setCnpj}
                                                />

                                                <FormInputFile
                                                    attribute="certificado"
                                                    description={ props.t('certificadosemautenticacao.edicao.certificado.title') }
                                                    extension={".pfx"}
                                                    value={certificado}
                                                    setValue={setCertificado}
                                                    loading={ false }
                                                    required={ true}
                                                    removeHandeler={ () => setCertificado(null) }/>

                                                <FormInputPassword
                                                    attribute="certificadoSenha"
                                                    description={ props.t('certificadosemautenticacao.edicao.senha.title') }
                                                    value={certificadoSenha}
                                                    setValue={setCertificadoSenha}
                                                    required={true}
                                                />

                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)}
    </React.Fragment>
}

CadastroCertificadoDigitalSemAutenticacao.propTypes = {
    editarEmpresaCertificadoDigitalSemAutenticacao: PropTypes.func,
}

const mapStatetoProps = state => {
    const { empresaCertificadoLoading, } = state.Profile
    return { empresaCertificadoLoading, }
}

export default withTranslation()(connect(mapStatetoProps, {
    editarEmpresaCertificadoDigitalSemAutenticacao,
})(withRouter(CadastroCertificadoDigitalSemAutenticacao)))
