import React, { Component } from "react"
import { Button, Card, CardBody, Col } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../store/auth/register/actions"
import { apiError, getDashboardData } from "../../store/auth/login/actions"
import Loader from "react-loader-spinner"
import { setUserPreference } from "../../store/auth/profile/actions"
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";

class VendasHora extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [],
      options: {
        labels: [],
        colors: [],
        legend: { show: !1 },
        plotOptions: {
          pie: {
            donut: {
              size: "70%"
            }
          }
        }
      },
      graphType:
        props.preferences && props.preferences.Grafico_Vendas_Hora
          ? props.preferences.Grafico_Vendas_Hora
          : "quantity"
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    if (props.dashboardVendasHora && props.dashboardVendasHora.vendas) {
      const dataVendas = []
      const dataEntregas = []

      for (let i = 0; i <= 23; i++) {
        const valueVenda = props.dashboardVendasHora.vendas[0]
          ? props.dashboardVendasHora.vendas[0].find(e => e.hour == i)
          : null
        dataVendas[i] = valueVenda
          ? current_state.graphType === "quantity"
            ? valueVenda.Qtd_Vendas
            : valueVenda.Valor_Total
          : 0

        const valueEntrega = props.dashboardVendasHora.entregas[0]
          ? props.dashboardVendasHora.entregas[0].find(e => e.hour == i)
          : null
        dataEntregas[i] = valueEntrega
          ? current_state.graphType === "quantity"
            ? valueEntrega.Qtd_Vendas
            : valueEntrega.Valor_Total
          : 0
      }

      return {
        ...props,
        ...{
          series: [
            {
              name: props.t('dashboard.lista.vendashora.vendas.label'),
              data: dataVendas
            },
            {
              name: props.t('dashboard.lista.vendashora.entregas.label'),
              data: dataEntregas
            }
          ],
          options: {
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: "smooth",
              width: 3
            },

            colors: ["#556ee6", "#34c38f"],
            yaxis: [
              {
                title: {
                  text:
                    current_state.graphType === "quantity"
                      ? "Quantidade"
                      : "Valor",
                  style: {
                    color: "#FF1654"
                  }
                },
                labels: {
                  formatter: val => {
                    return VendasHora.abbreviateNumber(val)
                  }
                }
              }
            ],
            xaxis: {
              type: "hour",
              categories: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                18, 19, 20, 21, 22, 23
              ]
            },
            grid: {
              borderColor: "#f1f1f1"
            },
            tooltip: {
              x: {
                format: "HH"
              }
            }
          }
        }
      }
    }

    return props
  }

  static abbreviateNumber(num, fixed) {
    if (num === null) {
      return null
    } // terminate early
    if (num === 0) {
      return "0"
    } // terminate early
    fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
    var b = num.toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ["", "K", "M", "B", "T"][k] // append power
    return e
  }

  componentDidMount() {
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "VendasHora")
  }

  setGraphType(graphType) {
    this.setState(prevState => ({ graphType }))
    this.props.setUserPreference("Grafico_Vendas_Hora", graphType)
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="6">
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h4 className="card-title mb-4">{ this.props.t('dashboard.lista.vendashora.label') }</h4>
                <Tooltip id='vendashora' tooltip={ this.props.t('dashboard.lista.vendashora.tooltip') }></Tooltip>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 text-end">
                      <Button
                        color="light"
                        size="sm"
                        type="button"
                        className={
                          this.state.graphType === "quantity" ? "active" : ""
                        }
                        onClick={ () => this.setGraphType("quantity") }
                      >
                        Em Quantidade
                      </Button>
                      <Button
                        color="light"
                        size="sm"
                        type="button"
                        className={
                          this.state.graphType === "value" ? "active" : ""
                        }
                        onClick={ () => this.setGraphType("value") }
                      >
                        Em Valor
                      </Button>
                    </div>
                  </div>
              </div>
              { this.props.loadingDashboardVendasHora ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : (
                <div>
                  <ReactApexChart
                    options={ this.state.options }
                    series={ this.state.series }
                    type="area"
                    height="300"
                  />
                </div>
                ) }
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

VendasHora.propTypes = {
  loadingDashboardVendasHora: PropTypes.any,
  dashboards: PropTypes.any,
  getDashboardData: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardVendasHora,
    dashboardVendasHora, preferences
  } = state.Login
  return {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardVendasHora,
    dashboardVendasHora,
    preferences
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  getDashboardData,
  setUserPreference
})(VendasHora))
