import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Label } from "reactstrap"
import {isArray} from "leaflet/src/core/Util"
import DatePicker from "react-datepicker"

const FormFilterPeriod  = props => {
  const { periodAttribute, periodDescription, periodValue, periodChangeHandeler, options, placeHolder,
          startDateAttribute, startDateDescription, startDateValue, startDateChangeHandeler,
          endDateAttribute, endDateDescription, endDateValue, endDateChangeHandeler } = props

  return (
    <React.Fragment>
      <div className="col-xl col-sm-6">
        <FormGroup className="mt-3 mb-0">
          <Label>{periodDescription}</Label>
          <select id={periodAttribute}
              className="form-select select2-search-disable"
              value={periodValue}
              onChange={e => periodChangeHandeler(e.target.value)}
          >
            {isArray(options) &&
                options.map((periodItem, key) => (
                    <option key={periodItem.value} value={periodItem.value}>
                      {periodItem.label}
                    </option>
                ))}
          </select>
        </FormGroup>
      </div>

      {periodValue && periodValue === "Personalizado" && (
          <div className="col-xl col-sm-6">
            <div>
              <FormGroup className="mt-3 mb-0">
                <Label>{startDateDescription}</Label>
                <DatePicker
                    id={startDateAttribute}
                    selected={startDateValue}
                    onChange={startDateChangeHandeler}
                    className="form-control"
                    placeholderText={placeHolder}
                    locale="ptBR"
                    dateFormat="dd/MM/yyyy"
                />
              </FormGroup>

              <FormGroup className="mt-3 mb-0">
                <Label>{endDateDescription}</Label>
                <DatePicker
                    id={endDateAttribute}
                    selected={endDateValue}
                    onChange={date => endDateChangeHandeler(date)}
                    className="form-control"
                    placeholderText={placeHolder}
                    locale="ptBR"
                    minDate={startDateValue}
                    dateFormat="dd/MM/yyyy"
                />
              </FormGroup>
            </div>
          </div>
      )}
    </React.Fragment>
  )
}

FormFilterPeriod.propTypes = {
  periodAttribute: PropTypes.string,
  periodDescription: PropTypes.string,
  periodValue: PropTypes.any,
  periodChangeHandeler: PropTypes.func,
  options: PropTypes.any,
  placeHolder: PropTypes.string,
  startDateAttribute: PropTypes.string,
  startDateDescription: PropTypes.string,
  startDateValue: PropTypes.object,
  startDateChangeHandeler: PropTypes.func,
  endDateAttribute: PropTypes.string,
  endDateDescription: PropTypes.string,
  endDateValue: PropTypes.object,
  endDateChangeHandeler: PropTypes.func
}

export default FormFilterPeriod
