import React from "react"
import { Card, Col, Row } from "reactstrap"

//Import Image
import features from "../../assets/images/crypto/features-img/img-1.png"

const CardWelcome = () => {
  return (
    <React.Fragment>
      <Card>
        <div>
          <Row>
            <Col lg="9" sm="8">
              <div className="p-4">
                <h5 className="text-primary">
                  Bem-vindo a Gestão de Documentos Fiscais!
                </h5>
                <p>
                  Gerencie todos os seus documentos fiscais de forma simples e
                  intuitiva
                </p>

                <div className="text-muted">
                  <p className="mb-1">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Acesse os valores do dia, mês e ano de forma rápida e fácil
                  </p>
                  <p className="mb-1">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Acompanhe a evolução fiscal da sua empresa, não espere
                    finalizar o ano
                  </p>
                  <p className="mb-0">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Agilidade e simplicidade para a gestão de seus documentos
                    fiscais
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="3" sm="4" className="align-self-center">
              <div>
                <img src={ features } alt="" className="img-fluid d-block" />
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default CardWelcome
