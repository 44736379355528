import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label, Modal, ModalBody,
  ModalHeader,
  Row
} from "reactstrap"

// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { connect } from "react-redux"
import {
  alterarPerfil,
  cadastrarPerfil,
  buscarPerfil,
  buscarPermissaoPerfil,
  persistirPermissoes,
  removerPerfil
} from "../../store/auth/profile/actions"
import Loader from "react-loader-spinner"
import usePermission from "helpers/permission/usePermission"
import PropTypes from "prop-types"
import { MDBDataTable } from "mdbreact"
import { AvForm } from "availity-reactstrap-validation"
import { logoutUser } from "store/auth/login/actions"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import {getCompanies} from "../../helpers/utils"

const CadastrarPerfil = props => {

  const [id, setId] = useState()
  const [nome, setNome] = useState()
  const [administrador, setAdministrador] = useState(false)
  const [empresaId, setEmpresaId] = useState()
  const [companies, setCompanies] = useState()
  // const [permissoes, setPermissoes] = useState([])
  const [modal, setModal] = useState(false)
  const [modalLogoff, setModalLogoff] = useState(false)
  const isInforangra = props.user?.usuario_info
  const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador

  const [tableData, setTableData] = useState({
    columns: [
      { label: "Recurso", field: "recurso", sort: "asc", width: 150 },
      { label: "Tela", field: "tela", sort: "asc", width: 270 },
      { label: "Ação", field: "acao", sort: "asc", width: 200 },
      { label: "Possui permissão?", field: "permissao", sort: "asc", width: 100 }
    ],
    rows: []
  })

  const usePermissionEdit = usePermission('perfil_editar')
  const usePermissionInsert = usePermission('perfil_inserir')
  const usePermissionRemove = usePermission('perfil_excluir')

  const popularCampos = (perfil) => {
    setId(perfil?.id)
    setNome(perfil?.nome)
    setAdministrador(perfil?.administrador)
    doSetCompany(perfil?.idEmpresa)
  }

  const deletePerfil = () => {
    props.removerPerfil({ id }, props.history)
    setModal(false)
  }

  const setPermissao = (objPermissao) => {
    const permitido = !objPermissao.permissao

    let find
    if(objPermissao.idPermissao) {
      let find = props.permissoes.find(permissao => permissao.idPermissao === objPermissao.idPermissao)
      if(find) {
        find.permissao = permitido
        find.idPerfil = id
        find.update = true
      }
    }

    if(!find) {
      find = props.permissoes.find(permissao => permissao.idAcao === objPermissao.idAcao)
      if(find) {
        find.permissao = permitido
        find.idPerfil = id
        find.update = true
      }
    }

    setTable(props.permissoes)
  }

  useEffect(() => {
    if (props.perfil) {
      popularCampos(props.perfil)
      props.buscarPermissaoPerfil(props.perfil)
    }

    return () => {
      setId(null)
      setNome(null)
      setAdministrador(null)
      doSetCompany(null)
    }
  }, [props.perfil])

  useEffect(() => {
    if(props.loading === false) {
      if (props.permissoes && props.permissoes.length > 0) {
        console.warn(props.permissoes[0].nomeTela)
        props.permissoes.forEach(objPermissao => {
          if(!props.perfilMode) {
            objPermissao.permissao = true
            objPermissao.update = true;
          }
          objPermissao.bloqueia = (!isInforangra && !empresaId)
        })
        setTable(props.permissoes)
      } else {
        setTable([])
      }
    }
    return () => setTableData({ columns: [], rows: []})
  }, [props.permissoes])

  useEffect(() => {
    setCompanies(getCompanies(props.user))

    if (props.activePerfil) {
      props.buscarPerfil(props.activePerfil)
    } else {
      popularCampos(null)
      props.buscarPermissaoPerfil(null)
      if(!isAdmin) {
        doSetCompany(tmpCompanies[0].value)
      }
    }

    return () => {
      setCompanies([])
      setId(null)
      setNome(null)
      setAdministrador(null)
      doSetCompany(null)
    }
  }, [])

  const setTable = (data) => {
    setTableData({
      columns: [
        { label: "Recurso", field: "recurso", sort: "asc", width: 150 },
        { label: "Tela", field: "tela", sort: "asc", width: 270 },
        { label: "Ação", field: "acao", sort: "asc", width: 200 },
        { label: "Possui permissão?", field: "permissao", width: 100 }
      ],
      rows: data.sort((one, other) => {
        let compare = one.nomeRecurso.localeCompare(other.nomeRecurso)
        if (compare == 0) compare = one.nomeTela.localeCompare(other.nomeTela)
        if (compare == 0) compare = one.nomeAcao.localeCompare(other.nomeAcao)
        return compare
      }).map(objPermissao => ({
        recurso: objPermissao.nomeRecurso,
        tela: objPermissao.nomeTela,
        acao: objPermissao.nomeAcao,
        permissao: (
          <div>
            <div className="square-switch">
              <input
                type="checkbox"
                id={ `permissao-switch-${ objPermissao.idAcao }` }
                switch="info"
                checked={ objPermissao.permissao }
                onChange={ () => setPermissao(objPermissao) }
                disabled={ objPermissao.bloqueia }
              />
              <label
                htmlFor={ `permissao-switch-${ objPermissao.idAcao }` }
                data-on-label="Sim"
                data-off-label="Não"
              />
            </div>
          </div>
        )
      }))
    })
  }

  const clonarPerfil = (e, v) => {
    setId(null)
    setNome(`${nome} (cópia)`)
    setAdministrador(false)

    if(!isAdmin) {
      doSetCompany(companies[0].value)
    }

    props.permissoes.forEach(permissao => {
      permissao.idPermissao = null
      permissao.update = true;
      permissao.idPerfil = null
      permissao.bloqueia = false

      if(!permissao.permissao) {
        permissao.bloqueia = true
      }
    })

    setTable(props.permissoes)

    window.scrollTo(0, 0)
  }

  const handleValidSubmitLogoff = (e) => {
    handleValidSubmit(e)
    props.logoutUser(props.history)
  }
  const handleValidSubmit = (e) => {
    if(id) {
      props.alterarPerfil({
          id: id,
          nome: nome,
          administrador: administrador,
          idEmpresa: empresaId
        },
        props.permissoes,
        props.history)
    } else {
      props.cadastrarPerfil({
          nome: nome,
          administrador: administrador,
          idEmpresa: empresaId
        },
        props.permissoes,
        props.history)
    }
  }

  const gravarPerfil = (e) => {
    let possuiPerfil = false
    if(id && props.user) {
      possuiPerfil = (props.user.vinculos || []).some(vinculo => vinculo.perfil?.id === id)
      possuiPerfil = possuiPerfil || props.user.usuario_info?.perfil?.id === id
    }
    if(possuiPerfil) {
      setModalLogoff(true)
    } else {
      handleValidSubmit(e)
    }
  }

  const doSetCompany = value => {
    const empresaId = value ? value : null
    setEmpresaId(empresaId)
  }

  return (
    <>
      { (props.loading && (
        <div className="loader-container">
          <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
        </div>
      )) || (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Perfis"
              breadcrumbItem={
                (props.perfilMode === "edit" && "Editar Perfil") ||
                (props.perfilMode === "view" && "Visualizar Perfil") || "Criar Perfil"
              }
            />
            <StickerToolbarButtons>
              <Button
                  type="submit"
                  color="primary"
                  className="waves-effect waves-light me-2"
                  disabled={ !nome || props.perfilMode === "view" ||
                      !((id && usePermissionEdit) || (!id && usePermissionInsert)) ||
                      (!isAdmin && administrador) ||
                      (!isInforangra && !empresaId) }
                  onClick={ e => gravarPerfil(e) }>
                { id ? "Salvar Perfil" : "Cadastrar Perfil" }
              </Button>
              <Button
                  type="submit"
                  color="primary"
                  className="waves-effect waves-light me-2"
                  disabled={ props.perfilMode === "view" || !(id && usePermissionInsert) || (!isAdmin && administrador) }
                  onClick={ e => clonarPerfil(e) }>
                { "Clonar Perfil" }
              </Button>
              <Button
                  type="submit"
                  color="danger"
                  className="waves-effect waves-light"
                  disabled={ props.perfilMode === "view" ||
                      !(id && usePermissionRemove) ||
                      (!isAdmin && administrador) ||
                      (!isInforangra && !empresaId)}
                  onClick={ e => setModal(true) }>
                { "Remover Perfil" }
              </Button>
            </StickerToolbarButtons>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      { (props.perfilMode === "edit" &&
                          "Altere a Perfil") ||
                        (props.perfilMode === "view" &&
                          "Visualize a Perfil") ||
                        "Salvar a Perfil" }
                    </CardTitle>
                    <form>
                      <div className="outer">
                        <div className="outer">
                          <FormGroup className="mb-4" row>
                            <Label htmlFor="nome" className="col-form-label col-lg-2">
                              Título
                            </Label>
                            <Col lg="10">
                              <Input
                                id="nome"
                                name="nome"
                                type="text"
                                className="form-control"
                                placeholder="Digite o nome..."
                                value={ nome }
                                onChange={ e => setNome(e.target.value) }
                                readOnly={ props.perfilMode === "view" || (!isInforangra && !empresaId) }
                                maxLength={ 100 }
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="administrador-switch" className="col-form-label col-lg-2">
                              Perfil administrador
                            </Label>
                            <Col lg="10">
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="administrador-switch"
                                  switch="info"
                                  checked={ administrador }
                                  onChange={ () => {
                                    setAdministrador(!administrador)
                                  } }
                                  disabled={ id && !isAdmin }
                                />
                                <label
                                  htmlFor="administrador-switch"
                                  data-on-label="Sim"
                                  data-off-label="Não"
                                />
                              </div>
                            </Col>
                          </FormGroup>

                          <FormGroup className="mb-4" row>
                            <Label htmlFor="empresa" className="col-form-label col-lg-2">
                              Empresa
                            </Label>
                            <Col lg="10">
                              <select id="empresa" className="form-select" value={ empresaId }
                                      onChange={ e => doSetCompany(e.target.value) }
                                      disabled={ id }>

                                { (id || isAdmin) &&
                                  <option key={ 0 } value={ null }>
                                    Todas
                                  </option>
                                }

                                { (companies || []).map((companyItem, key) => (
                                  <option key={ companyItem.value } value={ companyItem.value }>
                                    { companyItem.label }
                                  </option>
                                )) }
                              </select>
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                    </form>

                    <Card>
                      {/*{ permissoes.length > 0 ? permissoes[0].nomeTela : 'NA' }*/}
                      { props.permissoes.length ? props.permissoes[0].nomeTela : 'NA' }
                      { props.loading && props.permissoes ? (
                        <div className="loader-container position-absolute">
                          <Loader
                            type="ThreeDots"
                            color="#556EE6"
                            height={ 80 }
                            width={ 80 }
                          />
                        </div>
                      ) : (
                        <CardBody>
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            data={ tableData }
                            entriesLabel={ "Mostrar Registros" }
                            infoLabel={ ["Exibindo", "a", "de", "registros"] }
                            paging={ false}
                            noRecordsFoundLabel={ "Nenhum registro encontrado" }
                            searchLabel={ "Pesquisar" }
                          />
                        </CardBody>
                      ) }
                    </Card>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) }

      <Modal isOpen={ modal }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              deletePerfil()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover esse registro? Todos os usuários vinculados neste perfil terão seus acessos atualizados.
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModal(false) }>
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal isOpen={ modalLogoff }>
        <ModalHeader tag="h4">Aviso</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={ (e, v) => { handleValidSubmitLogoff(e) } } >
            <Row form>
              <Col className="col-12">
                Será alterado o perfil que está vinculado ao seu usuário. Após salvar você será redirecionado para a tela de login para acessar novamente a plataforma.
                Deseja realmente alterar esse registro?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModalLogoff(false) }>
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

    </>
  )
}

CadastrarPerfil.propTypes = {
  perfil: PropTypes.object,
  loading: PropTypes.bool,
  cadastrarPerfil: PropTypes.func,
  alterarPerfil: PropTypes.func,
  buscarPerfil: PropTypes.func,
  buscarPermissaoPerfil: PropTypes.func,
  persistirPermissoes: PropTypes.func,
  logoutUser: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    perfilMode,
    perfil,
    permissoes,
    activePerfil,
    loading,
  } = state.Profile
  const { user } = state.Login
  return {
    perfilMode,
    perfil,
    permissoes,
    activePerfil,
    loading,
    user
  }
}

export default connect(mapStatetoProps, {
  cadastrarPerfil,
  alterarPerfil,
  buscarPerfil,
  removerPerfil,
  buscarPermissaoPerfil,
  persistirPermissoes,
  logoutUser
})(CadastrarPerfil)
