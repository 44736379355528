import PropTypes from "prop-types"
import React from "react"
import {Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap"
import { useState, useEffect } from 'react';

const StickerToolbarButtons = props => {
    const { hide, children } = props

    const [sticked, setSticked] = useState(true);
    const [topOffset, setTopOffset] = useState(109);
    const [topOffsetReturn, setTopOffsetReturn] = useState(5);

    useEffect(() => {
        const handleScroll = (event) => {
            const isReachingTop = getTop() > topOffset;
            if (isReachingTop) {
                setSticked(false)
            } else {
                if(getTop() < topOffsetReturn) {
                    setSticked(true)
                }
            }

        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getTop = () => {
        return (
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0
        );
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    {!hide && <Card className={ sticked ? 'stickytoolbar-default' : 'stickytoolbar'}>
                        <CardBody>
                            <div className="outer">
                                <div className="outer">
                                    <div className="d-flex justify-content-start">
                                        { children }
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card> }
                </Col>
            </Row>
        </React.Fragment>
    )
}

StickerToolbarButtons.propTypes = {
    hide: PropTypes.bool,
    children: PropTypes.node
}

export default StickerToolbarButtons
