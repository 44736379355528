import {useContext, useState} from 'react';
import PermissionContext from "./permissionContext"

const usePermission = (permission) => {
  const [allowed, setAllowed] = useState(true);

  const {isAllowedTo} = useContext(PermissionContext);

  isAllowedTo(permission).then((allowed) => {
    setAllowed(allowed);
  }).catch((e) => {
      console.error(`usePermission - ${e.message}`)
      setAllowed(false)
    }
  );

  return allowed
}

export default usePermission;
