import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap"

// action
import {
  buscarNotificacoesUsuario,
  marcarNotificacaoUsuarioLida,
  marcarNotificacaoUsuarioNaoLida
} from "../../store/actions"

// Redux
import { connect } from "react-redux"
import Loader from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

const PainelNotificacoesList = props => {
  const [notificacoesUsuarioSelected, setNotificacoesUsuarioSelected] =
    useState({ selecionados: [] })
  const [statusLeitura, setStatusLeitura] = useState("")
  const [tipoNotificacao, setTipoNotificacao] = useState("")
  const ehUsuarioInforAngra = props.user?.usuario_info

  const usePermissionEdit = usePermission('painelNotificacoes_editar')
  const usePermissionList = usePermission('painelNotificacoes_listar')

  const [dataNotificacoes, setDataNotificacoes] = useState({
    columns: ehUsuarioInforAngra
      ? [
        { label: "", field: "", sort: "asc", width: 100 },
        { label: "ID", field: "id", sort: "asc", width: 100 },
        { label: "Data de envio", field: "envio", sort: "asc", width: 100 },
        { label: "Destinatário", field: "usuario", sort: "asc", width: 100 },
        { label: "Título", field: "titulo", sort: "asc", width: 150 },
        {
          label: "Notificação",
          field: "notificacao",
          sort: "asc",
          width: 150
        },
        { label: "Tipo", field: "tipoNotificao", sort: "asc", width: 150 },
        { label: "Lido", field: "lido", sort: "asc", width: 150 },
        { label: "Data leitura", field: "leitura", sort: "asc", width: 100 }
      ]
      : [
        { label: "", field: "", sort: "asc", width: 100 },
        { label: "ID", field: "id", sort: "asc", width: 100 },
        { label: "Data de envio", field: "envio", sort: "asc", width: 100 },
        { label: "Título", field: "titulo", sort: "asc", width: 150 },
        {
          label: "Notificação",
          field: "notificacao",
          sort: "asc",
          width: 150
        },
        { label: "Tipo", field: "tipoNotificao", sort: "asc", width: 150 },
        { label: "Lido", field: "lido", sort: "asc", width: 150 },
        { label: "Data leitura", field: "leitura", sort: "asc", width: 100 }
      ],
    rows: []
  })

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm:ss")
  }

  const atualizarDados = e => {
    const filtros = {}
    if (statusLeitura && statusLeitura !== "") {
      filtros["lido"] = statusLeitura === "L" ? true : false
    }
    if (tipoNotificacao && tipoNotificacao !== "") {
      filtros["tipoNotificacao"] = tipoNotificacao
    }
    props.buscarNotificacoesUsuario(filtros)
    setNotificacoesUsuarioSelected({ selecionados: [] })
  }

  useEffect(() => {
    props.buscarNotificacoesUsuario()
  }, [])

  useEffect(() => {
    if (Array.isArray(props.notificacoesUsuario)) {
      setDataNotificacoes(prevState => ({
        ...prevState,
        ...{
          rows: props.notificacoesUsuario.map(notificacaoUser => ({
            "":
              notificacaoUser.tipo_notificacao !== "E" &&
              props.user.id === notificacaoUser.usuario_id ? (
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={ notificacaoUser.id }
                    value={ notificacaoUser.id }
                    onChange={ e => onSelect(e) }
                  />
                </div>
              ) : (
                <div></div>
              ),
            id: notificacaoUser.id,
            envio: describeDateTime(notificacaoUser.created_at),
            usuario: notificacaoUser.usuario?.nome_completo,
            titulo: notificacaoUser.notificacao
              ? notificacaoUser.notificacao.titulo
              : notificacaoUser.usuarioVinculo
                ? "Solicitação de vínculo"
                : "N/D",
            notificacao: describeNotificacao(notificacaoUser),
            lido: notificacaoUser.lido ? (
              <div>
                <span className="bx bx-check"></span>
              </div>
            ) : (
              ""
            ),
            leitura: describeDateTime(notificacaoUser.data_lida),
            tipoNotificao: describeType(notificacaoUser.tipo_notificacao)
          }))
        }
      }))
    }

  }, [props.notificacoesUsuario])

  useEffect(() => {
    if (props.notificacoesUsuarioAlteracaoLoading === false) {
      // atualizar tabelas ao finalizar alteração nas notificações
      atualizarDados()
    }
  }, [props.notificacoesUsuarioAlteracaoLoading])

  const marcarNotificacaoComoNaoLido = () => {
    const { selecionados } = notificacoesUsuarioSelected
    if (Array.isArray(selecionados) && selecionados.length > 0) {
      for (const selecionado of selecionados) {
        const notificacaoUsuario = props.notificacoesUsuario.find(
          notificacao => notificacao.id == selecionado
        )
        props.marcarNotificacaoUsuarioNaoLida(notificacaoUsuario)
      }
    }
  }

  const marcarNotificacaoComoLido = () => {
    const { selecionados } = notificacoesUsuarioSelected
    if (Array.isArray(selecionados) && selecionados.length > 0) {
      for (const selecionado of selecionados) {
        props.marcarNotificacaoUsuarioLida({ id: selecionado })
      }
    }
  }

  const describeNotificacao = notificacaoUser => {
    if (notificacaoUser.notificacao) {
      return notificacaoUser.notificacao.notificacao
    } else if (notificacaoUser.usuarioVinculo) {
      return `Solicitante ${ notificacaoUser.usuarioVinculo?.usuario?.nome_completo }`
    } else {
      return "N/D"
    }
  }

  const describeType = tipo => {
    switch (tipo) {
      case "S":
        return "Sistema"
      case "P":
        return "Push Web"
      case "E":
        return "E-mail"
      default:
        return "N/D"
    }
  }

  const onSelect = e => {
    const { value, checked } = e.target
    const { selecionados } = notificacoesUsuarioSelected

    if (checked) {
      selecionados.push(value)
    } else {
      selecionados.splice(selecionados.indexOf(value), 1)
    }

    setNotificacoesUsuarioSelected({
      ...notificacoesUsuarioSelected,
      ...{
        selecionados: selecionados
      }
    })
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return props.notificacoesUsuarioLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="button-items my-3">
            <Button
              onClick={ e => atualizarDados(e) }
              type="button"
              color="success"
              className="btn-rounded waves-effect waves-light"
              disabled={ !usePermissionList }
            >
              <i className="bx bx-analyse" /> Atualizar
            </Button>
          </div>
          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Painel de Notificações</h4>

                  <Form>
                    <Row>
                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Leitura</Label>
                          <select
                            className="form-select select2-search-disable"
                            value={ statusLeitura }
                            onChange={ e => setStatusLeitura(e.target.value) }
                          >
                            <option value="" defaultValue>
                              Todos(as)
                            </option>
                            <option value="L">Somente lidas</option>
                            <option value="N">Somente não lidas</option>
                          </select>
                        </FormGroup>
                      </div>

                      <div className="col-xl col-sm-6">
                        <FormGroup className="mt-3 mb-0">
                          <Label>Tipo de envio</Label>
                          <select
                            className="form-select select2-search-disable"
                            value={ tipoNotificacao }
                            onChange={ e => setTipoNotificacao(e.target.value) }
                          >
                            <option value="" defaultValue>
                              Todos(as)
                            </option>
                            <option value="S">Sistema</option>
                            <option value="P">Push Web</option>
                            <option value="E">E-mail</option>
                          </select>
                        </FormGroup>
                      </div>

                      <div className="col-xl col-sm-6 align-self-start mt-4 pt-1">
                        <div className="mt-3">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md"
                            onClick={ e => atualizarDados() }
                            disabled={ !usePermissionList }
                          >
                            Buscar
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    data={ dataNotificacoes }
                    entriesLabel={ "Mostrar Registros" }
                    infoLabel={ ["Exibindo", "a", "de", "registros"] }
                    noRecordsFoundLabel={ "Nenhum registro encontrado" }
                    paginationLabel={ ["Anterior", "Próximo"] }
                    searchLabel={ "Pesquisar" }
                  />

                  <div className="button-items my-3">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded waves-effect waves-light me-2"
                      onClick={ e => marcarNotificacaoComoLido(e) }
                      disabled={
                        notificacoesUsuarioSelected?.selecionados?.length <= 0 ||
                        !usePermissionEdit
                      }
                    >
                      Marcar como lida
                    </Button>

                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded waves-effect waves-light"
                      onClick={ e => marcarNotificacaoComoNaoLido(e) }
                      disabled={
                        notificacoesUsuarioSelected?.selecionados?.length <= 0 ||
                        !usePermissionEdit
                      }
                    >
                      Marcar como não lida
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PainelNotificacoesList.propTypes = {
  notificacoesUsuario: PropTypes.array,
  notificacoesUsuarioLoading: PropTypes.bool,
  notificacoesUsuarioAlteracaoLoading: PropTypes.bool
}

const mapStatetoProps = state => {
  const {
    notificacoesUsuario,
    notificacoesUsuarioLoading,
    notificacoesUsuarioAlteracaoLoading
  } = state.Profile
  const { user } = state.Login
  return {
    notificacoesUsuario,
    notificacoesUsuarioLoading,
    notificacoesUsuarioAlteracaoLoading,
    user
  }
}

export default connect(mapStatetoProps, {
  buscarNotificacoesUsuario,
  marcarNotificacaoUsuarioLida,
  marcarNotificacaoUsuarioNaoLida
})(PainelNotificacoesList)
