import React, {useEffect, useState} from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {describeDateTime} from "../../helpers/utils"
import LoaderList from "../../components/Common/loaderList"
import {MDBDataTable} from "mdbreact"

const GrupoCardapioItemLista = props => {

    const [data, setData] = useState({
        columns: [
            { label: "Código", field: "Código", sort: "asc", width: 110 },
            { label: "Descrição", field: "Descrição", sort: "asc", width: 230 },
            { label: "Preço", field: "Preço", sort: "asc", width: 110 },
            { label: "Alteração do cadastro", field: "Alteração do cadastro", width: 110 },
            { label: "Usuário que alterou", field: "Usuário que alterou", sort: "asc", width: 110 },
        ],
        rows: []
    })

    useEffect(() => {
        if(Array.isArray(props.listaGrupoCardapioItem)) {
            const rows = props.listaGrupoCardapioItem?.map(item => ({
                Código: item.Codigo,
                Descrição: item.Descricao,
                "Preço": parseFloat(item.Preco || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                }),
                "Alteração do cadastro": describeDateTime(item.AltData),
                "Usuário que alterou": `${item?.Altfunc} - ${(item?.AltFunc_Nome ? item.AltFunc_Nome : 'N/A')}`,
            }))

            setData({
                columns: data.columns,
                rows: rows
            })
        }
    }, [props.listaGrupoCardapioItem])

    return <>
        { props.loading ? <LoaderList></LoaderList> :
            <>
                <div className="table-responsive">
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={ data }
                        entriesLabel={ "Mostrar Registros" }
                        infoLabel={ ["Exibindo", "a", "de", "registros"] }
                        noRecordsFoundLabel={ "Nenhum registro encontrado" }
                        paginationLabel={ ["Anterior", "Próximo"] }
                        searchLabel={ "Pesquisar" }
                        entries={5}
                        entriesOptions={[5,10,20,50,100]}
                    />
                </div>
            </>
        }
    </>
}

GrupoCardapioItemLista.propTypes = {
    listaGrupoCardapioItem: PropTypes.array,
    loading: PropTypes.bool,
    empresa: PropTypes.any
}

export default GrupoCardapioItemLista