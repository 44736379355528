import React, { useEffect, useState } from "react"
import { Button, Card, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, CardHeader, CardBody, Collapse } from "reactstrap"

// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import usePermission from "helpers/permission/usePermission"
import DatePicker from "react-datepicker"
import { connect } from "react-redux"
import {
  buscarPlanoContas,
} from "store/auth/profile/actions"
import Select from "react-select"
import AsyncSelect from 'react-select/async';
import { apiBuscarFornecedoresPesquisa } from "helpers/backend_helper"
import CurrencyInput from "react-currency-input-field"
import moment from "moment"

const LancamentoModal = props => {

  const { despesa, isOpen, toggle, saveLancamento, companies } = props
  const [inputValue, setInputValue] = useState() // utilizado no auto complete do fornecedor

  const [loja, setLoja] = useState()
  const [fornecedor, setFornecedor] = useState()
  const [notaEntrada, setNotaEntrada] = useState()
  const [emissao, setEmissao] = useState()
  const [vencimento, setVencimento] = useState()
  const [descricao, setDescricao] = useState()
  const [documento, setDocumento] = useState()
  const [valor, setValor] = useState()
  const [conta, setConta] = useState()
  const [contas, setContas] = useState()

  const [pagamento, setPagamento] = useState()
  const [multa, setMulta] = useState()
  const [juros, setJuros] = useState()
  const [desconto, setDesconto] = useState()
  const [contaPagamento, setContaPagamento] = useState()
  const [cheque, setCheque] = useState()
  const [impresso, setImpresso] = useState()
  const [reaberta, setReaberta] = useState()

  const [jurosDiario, setJurosDiario] = useState()
  const [multaMensal, setMultaMensal] = useState()
  const [descontoAteVencimento, setDescontoAteVencimento] = useState(false)
  const [descontoAntecipacao, setDescontoAntecipacao] = useState(false)
  const [observacao, setObservacao] = useState()
  const [company, setCompany] = useState("")

  const [opcoesAvancada, setOpcoesAvancada] = useState(false)

  const usePermissionInsert = usePermission('contasPagar_inserir')
  const usePermissionEdit = usePermission('contasPagar_editar')

  useEffect(() => {
    setCompany(despesa?.idEmpresa)
    setLoja(despesa?.loja)
    if(despesa?.fornec) {
      setFornecedor({ label: `${despesa.cnpj_fornecedor} - ${despesa.nome_fornecedor}`, value: despesa.fornec })
    } else {
      setFornecedor(null)
    }
    setNotaEntrada(despesa?.nota_entrada)
    setEmissao(despesa?.emissao ? new Date(despesa.emissao) : null)
    setVencimento(despesa?.vencimento ? moment(despesa.vencimento).toDate() : null)
    setPagamento(despesa?.data_pagamento ? new Date(despesa.data_pagamento) : null)
    setDescricao(despesa?.descricao)
    setDocumento(despesa?.documento)
    setValor(despesa?.valor_real ? new String(despesa.valor_real).replaceAll('.', ',') : '0,00')
    setJurosDiario(despesa?.juros_diario_vlr ? new String(despesa.juros_diario_vlr).replaceAll('.', ',') : '0,00')
    setMultaMensal(despesa?.multa_mensal_vlr ? new String(despesa.multa_mensal_vlr).replaceAll('.', ',') : '0,00')
    setDescontoAteVencimento(despesa?.desconto_ate_vencto)
    setDescontoAntecipacao(despesa?.desconto_dia_antecipacao)
    setObservacao(despesa?.obs)
    setConta(despesa?.codigo_conta)
    setMulta(despesa?.multa ? new String(despesa.multa).replaceAll('.', ',') : '0,00')
    setJuros(despesa?.juros ? new String(despesa.juros).replaceAll('.', ',') : '0,00')
    setDesconto(despesa?.desconto ? new String(despesa.desconto).replaceAll('.', ',') : '0,00')
    setContaPagamento(despesa?.conta_pagamento)
    setCheque(despesa?.cheque)
    setImpresso(despesa?.impresso)
    setReaberta(despesa?.reaberta)
  }, [despesa])

  useEffect(() => {
    const opcoesCompany = (companies || []).filter(option => option.value)
    const companyValue = opcoesCompany.length > 0 ? opcoesCompany[0].value : undefined
    setCompany(companyValue)
  }, [companies])

  useEffect(() => {
    if(!company) {
      const opcoesCompany = (companies || []).filter(option => option.value)
      let companyValue = opcoesCompany.length > 0 ? opcoesCompany[0].value : undefined
      if(companyValue) {
        props.buscarPlanoContas({ empresaId: companyValue })
      }
      setCompany(companyValue)
    } else {
      if(company) {
        props.buscarPlanoContas({ empresaId: company })
      }
    }
  }, [company])

  useEffect(() => {
    if(Array.isArray(props.planoContas)) {
      const contas = props.planoContas.map(plano => ({ label: `${plano.codigo} - ${plano.descricao}`, value: plano.codigo }))
      setContas(contas)
    } else {
      setContas([])
    }
    return () => {
      setContas([])
    }
  }, [props.planoContas])

  const salvarLancamento = () => {
    const lancamento = despesa ? Object.assign({}, despesa) : {}
    lancamento.idEmpresa = company
    lancamento.loja = loja
    lancamento.fornec = fornecedor.value
    lancamento.nota_entrada = notaEntrada
    lancamento.emissao = emissao
    lancamento.vencimento = vencimento
    lancamento.data_pagamento = pagamento
    lancamento.descricao = descricao
    lancamento.documento = documento
    lancamento.valor_real = Number.parseFloat((valor || "0").replaceAll(",", "."))
    lancamento.juros_diario_vlr = Number.parseFloat((jurosDiario || "0").replaceAll(",", "."))
    lancamento.multa_mensal_vlr = Number.parseFloat((multaMensal || "0").replaceAll(",", "."))
    lancamento.desconto_ate_vencto = descontoAteVencimento
    lancamento.desconto_dia_antecipacao = descontoAntecipacao
    lancamento.obs = observacao
    lancamento.codigo_conta = conta
    lancamento.multa = Number.parseFloat((multa || "0").replaceAll(",", "."))
    lancamento.juros = Number.parseFloat((juros || "0").replaceAll(",", "."))
    lancamento.desconto = Number.parseFloat((desconto || "0").replaceAll(",", "."))
    lancamento.conta_pagamento = contaPagamento
    lancamento.cheque = cheque
    lancamento.impresso = impresso
    lancamento.reaberta = reaberta
    saveLancamento(lancamento)
  }

  const save = () => {
    let valid = true
    valid = valid && (company ? true : false)
    valid = valid && (loja ? true : false)
    valid = valid && (fornecedor ? true : false)
    valid = valid && (emissao ? true : false)
    valid = valid && (vencimento ? true : false)
    valid = valid && (valor ? true : false)
    valid = valid && (conta ? true : false)
    valid = valid && (descricao ? true : false)
    valid = valid && (documento ? true : false)

    if(valid) {
      salvarLancamento()
    } else {
      const divs = document.getElementsByName("validate-required")
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.display = "block"
      }
    }
  }

  const buscarFornecedoresPorNome = (inputValue, callback) => {
    apiBuscarFornecedoresPesquisa({ empresaId: company, argumento: inputValue })
      .then(results => {
        const options = results.map(({ codigo, cnpj, razaosocial }) => {
          return { label: `${cnpj} - ${razaosocial}`, value: codigo };
        })
        if(fornecedor && fornecedor === despesa.fornec) {
          if(!options.some(option => option.value === fornecedor)) {
            options.unshift({ label: `${despesa.cnpj_fornecedor} - ${despesa.nome_fornecedor}`, value: despesa.fornec })
          }
        }
        callback(options)
      })
  }

  const changeHandeler = (event, elementId) => {
    if (event.target.value !== "") {
      document.getElementById(elementId).style.display = 'none'
    } else {
      document.getElementById(elementId).style.display = 'block'
    }
  }

  return (
    <React.Fragment>
      { despesa && (
        <Modal
          isOpen={ isOpen }
          role="dialog"
          autoFocus={ true }
          centered={ true }
          tabIndex="-1"
          scrollable={ true }
          size="xl"
          toggle={ toggle }>
          <div className="modal-content">
            <ModalHeader toggle={ toggle }>Despesa</ModalHeader>
            <ModalBody>
              <form>
                <div className="outer">
                  <div className="outer">
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="empresa" className="col-form-label col-lg-2">
                        Empresa
                      </Label>
                      <Col lg="10">
                        <Select
                          id="empresa"
                          name="empresa"
                          className="basic-single"
                          options={ companies.filter(c => c.value) }
                          placeholder="Digite a empresa..."
                          isClearable={ true }
                          isSearchable={ true }
                          noOptionsMessage={ () => "Nenhuma empresa encontrada" }
                          onChange={ e => {
                            setCompany(e?.value)
                            setConta(null)
                            setContaPagamento(null)
                            props.buscarPlanoContas({ empresaId: company })
                            changeHandeler(event, "validateCompany")
                          } }
                          value={ company ? companies.find(e => e.value === company) : undefined }
                          required
                          isDisabled={ despesa.id }
                        />
                        <div className={ company ? "" : "invalid-tooltip" }
                            name="validate" id="validateCompany">
                          { company ? "" : "Empresa é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="loja" className="col-form-label col-lg-2">
                        Loja
                      </Label>
                      <Col lg="10">
                        <Input
                          id="loja"
                          name="loja"
                          type="number"
                          className="form-control"
                          value={ loja }
                          onChange={ e => {
                            setLoja(e.target.value)
                            changeHandeler(event, "validateLoja")
                          } }
                          placeholder="Digite a loja"
                          required
                          readOnly={ pagamento }
                        />
                        <div className={ loja ? "" : "invalid-tooltip" }
                             name="validate" id="validateLoja">
                          { loja ? "" : "Loja é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="fornecedor" className="col-form-label col-lg-2">
                        Fornecedor
                      </Label>
                      <Col lg="10">
                        <AsyncSelect
                          key={ company }
                          id="fornecedor"
                          name="fornecedor"
                          className="basic-single"
                          placeholder="Digite o cnpj ou nome do fornecedor..."
                          isClearable={ true }
                          isSearchable={ true }
                          value={ fornecedor }
                          noOptionsMessage={ () => "Nenhuma fornecedor encontrado" }
                          loadingMessage={ () => "Carregando" }
                          onChange={ e => {
                            setFornecedor(e)
                            changeHandeler(event, "validateFornecedor")
                          }}
                          cacheOptions
                          loadOptions={buscarFornecedoresPorNome}
                          defaultOptions
                          required
                          readOnly={ pagamento }
                        />
                        <div className={ fornecedor ? "" : "invalid-tooltip" }
                             name="validate" id="validateFornecedor">
                          { fornecedor ? "" : "Fornecedor é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Emissão
                      </Label>
                      <Col lg="10">
                        <DatePicker
                          selected={ emissao }
                          onChange={ date => {
                            setEmissao(date)
                            changeHandeler(event, "validateEmissao")
                          } }
                          className="form-control"
                          placeholderText="Selecione uma data"
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          required
                          readOnly={ pagamento }
                        />
                        <div className={ emissao ? "" : "invalid-tooltip" }
                             name="validate" id="validateEmissao">
                          { emissao ? "" : "Emissão é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Vencimento
                      </Label>
                      <Col lg="10">
                        <DatePicker
                          selected={ vencimento }
                          onChange={ date => {
                            setVencimento(date)
                            changeHandeler(event, "validateVencimento")
                          } }
                          className="form-control"
                          placeholderText="Selecione uma data"
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          required
                          readOnly={ pagamento }
                        />
                        <div className={ vencimento ? "" : "invalid-tooltip" }
                             name="validate" id="validateVencimento">
                          { vencimento ? "" : "Vencimento é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="valor" className="col-form-label col-lg-2">
                        Valor
                      </Label>
                      <Col lg="10">
                        <CurrencyInput value={ valor }
                                       className="form-control input-color"
                                       intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                       onValueChange={(value, name) => {
                                         setValor(value)
                                         changeHandeler(event, "validateValor")
                                       }}
                                       id="valor"
                                       name="valor"
                                       placeholder="Digite o valor do lançamento"
                                       defaultValue={ 0 }
                                       decimalScale={ 2 }
                                       disableAbbreviations={ true }
                                       readOnly={ pagamento }
                                       required
                        />
                      </Col>
                      <div className={ valor ? "" : "invalid-tooltip" }
                           name="validate" id="validateValor">
                        { valor ? "" : "Valor é obrigatório"}
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="centro_custo" className="col-form-label col-lg-2">
                        Centro de custo
                      </Label>
                      <Col lg="10">
                        <Select
                          id="centro_custo"
                          name="centro_custo"
                          className="basic-single"
                          options={ contas }
                          placeholder="Digite o centro de custo..."
                          isClearable={ true }
                          isSearchable={ true }
                          noOptionsMessage={ () => "Nenhuma centro de custo encontrada" }
                          onChange={ e => {
                            setConta(e?.value)
                            changeHandeler(event, "validateConta")
                          } }
                          value={ conta ? contas.find(e => e.value === conta) : undefined }
                          required
                          isDisabled={ pagamento }
                        />
                        <div className={ conta ? "" : "invalid-tooltip" }
                             name="validate" id="validateConta">
                          { conta ? "" : "Centro de custo é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="descricao" className="col-form-label col-lg-2">
                        Descrição
                      </Label>
                      <Col lg="10">
                        <Input
                          id="descricao"
                          name="descricao"
                          type="text"
                          className="form-control"
                          value={ descricao }
                          onChange={ e => {
                            setDescricao(e.target.value)
                            changeHandeler(event, "validateDescricao")
                          } }
                          placeholder="Digite a descrição"
                          required
                          readOnly={ pagamento }
                        />
                        <div className={ descricao ? "" : "invalid-tooltip" }
                             name="validate" id="validateDescricao">
                          { descricao ? "" : "Descrição é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="documento" className="col-form-label col-lg-2">
                        Documento
                      </Label>
                      <Col lg="10">
                        <Input
                          id="documento"
                          name="documento"
                          type="text"
                          className="form-control"
                          value={ documento }
                          onChange={ e => {
                            setDocumento(e.target.value)
                            changeHandeler(event, "validateDocumento")
                          } }
                          placeholder="Digite o documento"
                          required
                          readOnly={ pagamento }
                        />
                        <div className={ documento ? "" : "invalid-tooltip" }
                             name="validate" id="validateDocumento">
                          { documento ? "" : "Documento é obrigatório"}
                        </div>
                      </Col>
                    </FormGroup>

                    { despesa.id && <FormGroup className="mb-4" row>
                      <Label htmlFor="data_pagamento" className="col-form-label col-lg-2">
                        Data de pagamento
                      </Label>
                      <Col lg="10">
                        <DatePicker
                          selected={ pagamento }
                          onChange={ date => setPagamento(date) }
                          className="form-control"
                          placeholderText="Selecione uma data"
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          disabled
                        />
                      </Col>
                    </FormGroup> }

                    { despesa.id && <FormGroup className="mb-4" row>
                      <Label htmlFor="centro_custo_pagamento" className="col-form-label col-lg-2">
                        Centro de custo de pagamento
                      </Label>
                      <Col lg="10">
                        <Select
                          id="centro_custo_pagamento"
                          name="centro_custo_pagamento"
                          className="basic-single"
                          options={ contas }
                          placeholder="Digite o centro de custo..."
                          isClearable={ true }
                          isSearchable={ true }
                          noOptionsMessage={ () => "Nenhuma centro de custo encontrada" }
                          onChange={ e => setContaPagamento(e?.value) }
                          value={ contaPagamento ? contas.find(e => e.value === contaPagamento) : undefined }
                          isDisabled={ true }
                        />
                      </Col>
                    </FormGroup> }

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="observacao" className="col-form-label col-lg-2">
                        Observação
                      </Label>
                      <Col lg="10">
                        <Input
                          id="observacao"
                          name="observacao"
                          type="text"
                          className="form-control"
                          value={ observacao }
                          onChange={ e => setObservacao(e.target.value) }
                          placeholder="Digite a observação"
                        />
                      </Col>
                    </FormGroup>

                    { despesa.id && <FormGroup className="mb-4" row>
                      <Label htmlFor="impresso" className="col-form-label col-lg-2">
                        Reaberturas do lançamento
                      </Label>
                      <Col lg="10" className="col-form-label">
                        { reaberta || 0 } vezes
                      </Col>
                    </FormGroup> }

                    <div id="accordion">
                      <Card className="mb-1">
                        <CardHeader className="p-3" id="headingOne">
                          <h6 className="m-0 font-14">
                          <span onClick={() => { setOpcoesAvancada(!opcoesAvancada) }} style={{ cursor: "pointer" }} className="text-dark ">
                            Informações avançadas
                          </span>
                          </h6>
                        </CardHeader>
                        <Collapse isOpen={opcoesAvancada}>
                          <Card>
                            <CardBody>
                              <FormGroup className="mb-4" row>
                                <Label htmlFor="nota_entrada" className="col-form-label col-lg-2">
                                  Nota de entrada
                                </Label>
                                <Col lg="10">
                                  <Input
                                    id="nota_entrada"
                                    name="nota_entrada"
                                    type="text"
                                    className="form-control"
                                    value={ notaEntrada }
                                    onChange={ e => setNotaEntrada(e.target.value) }
                                    placeholder="Digite a nota de entrada"
                                    readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-4" row>
                                <Label htmlFor="juros_diarios" className="col-form-label col-lg-2">
                                  Juros diários
                                </Label>
                                <Col lg="10">
                                  <CurrencyInput value={ jurosDiario }
                                                 className="form-control input-color"
                                                 intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                 onValueChange={(value, name) => setJurosDiario(value)}
                                                 id="juros_diarios"
                                                 name="juros_diarios"
                                                 placeholder="Digite o valor do juros diário"
                                                 defaultValue={ 0 }
                                                 decimalScale={ 2 }
                                                 disableAbbreviations={ true }
                                                 readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-4" row>
                                <Label htmlFor="multa_mensal" className="col-form-label col-lg-2">
                                  Multa Mensal
                                </Label>
                                <Col lg="10">
                                  <CurrencyInput value={ multaMensal }
                                                 className="form-control input-color"
                                                 intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                 onValueChange={(value, name) => setMultaMensal(value)}
                                                 id="multa_mensal"
                                                 name="multa_mensal"
                                                 placeholder="Digite o valor da multa mensal"
                                                 defaultValue={ 0 }
                                                 decimalScale={ 2 }
                                                 disableAbbreviations={ true }
                                                 readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-4" row>
                                <Label htmlFor="desconto_vencimento" className="col-form-label col-lg-2">
                                  Desconto até o vencimento
                                </Label>
                                <Col lg="10">
                                  <div className="square-switch">
                                    <input
                                      type="checkbox"
                                      id="desconto-vencimento-switch"
                                      switch="none"
                                      checked={ descontoAteVencimento }
                                      onChange={ () => setDescontoAteVencimento(!descontoAteVencimento) }
                                      disabled={ pagamento }
                                    />
                                    <label
                                      htmlFor="desconto-vencimento-switch"
                                      data-on-label="Sim"
                                      data-off-label="Não"
                                    />
                                  </div>
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-4" row>
                                <Label htmlFor="desconto_antecipacao" className="col-form-label col-lg-2">
                                  Desconto antecipação
                                </Label>
                                <Col lg="10">
                                  <div className="square-switch">
                                    <input
                                      type="checkbox"
                                      id="desconto-antecipacao-switch"
                                      switch="info"
                                      checked={ descontoAntecipacao }
                                      onChange={ () => setDescontoAntecipacao(!descontoAntecipacao) }
                                      disabled={ pagamento }
                                    />
                                    <label
                                      htmlFor="desconto-antecipacao-switch"
                                      data-on-label="Sim"
                                      data-off-label="Não"
                                    />
                                  </div>
                                </Col>
                              </FormGroup>

                              { despesa.id && <FormGroup className="mb-4" row>
                                <Label htmlFor="multa" className="col-form-label col-lg-2">
                                  Multa
                                </Label>
                                <Col lg="10">
                                  <CurrencyInput value={ multa }
                                                 className="form-control input-color"
                                                 intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                 onValueChange={(value, name) => setMulta(value)}
                                                 id="multa"
                                                 name="multa"
                                                 placeholder="Digite o valor da multa"
                                                 defaultValue={ 0 }
                                                 decimalScale={ 2 }
                                                 disableAbbreviations={ true }
                                                 readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup> }

                              { despesa.id && <FormGroup className="mb-4" row>
                                <Label htmlFor="juros" className="col-form-label col-lg-2">
                                  Juros
                                </Label>
                                <Col lg="10">
                                  <CurrencyInput value={ juros }
                                                 className="form-control input-color"
                                                 intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                 onValueChange={(value, name) => setJuros(value)}
                                                 id="juros"
                                                 name="juros"
                                                 placeholder="Digite o valor do juros"
                                                 defaultValue={ 0 }
                                                 decimalScale={ 2 }
                                                 disableAbbreviations={ true }
                                                 readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup> }

                              { despesa.id && <FormGroup className="mb-4" row>
                                <Label htmlFor="desconto" className="col-form-label col-lg-2">
                                  Desconto
                                </Label>
                                <Col lg="10">
                                  <CurrencyInput value={ desconto }
                                                 className="form-control input-color"
                                                 intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                 onValueChange={(value, name) => setDesconto(value)}
                                                 id="desconto"
                                                 name="desconto"
                                                 placeholder="Digite o valor do desconto"
                                                 defaultValue={ 0 }
                                                 decimalScale={ 2 }
                                                 disableAbbreviations={ true }
                                                 readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup> }

                              { despesa.id && <FormGroup className="mb-4" row>
                                <Label htmlFor="cheque" className="col-form-label col-lg-2">
                                  Cheque
                                </Label>
                                <Col lg="10">
                                  <Input
                                    id="cheque"
                                    name="cheque"
                                    type="text"
                                    className="form-control"
                                    value={ cheque }
                                    onChange={ e => setCheque(e.target.value) }
                                    placeholder="Digite a observação"
                                    readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup> }

                              { despesa.id && <FormGroup className="mb-4" row>
                                <Label htmlFor="impresso" className="col-form-label col-lg-2">
                                  Impresso
                                </Label>
                                <Col lg="10">
                                  <DatePicker
                                    selected={ impresso }
                                    onChange={ date => setImpresso(date) }
                                    className="form-control"
                                    placeholderText="Selecione uma data"
                                    locale="ptBR"
                                    dateFormat="dd/MM/yyyy"
                                    readOnly={ pagamento }
                                  />
                                </Col>
                              </FormGroup> }
                            </CardBody>
                          </Card>
                        </Collapse>
                      </Card>

                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" disabled={ (!despesa.id && !usePermissionInsert) || (despesa && !usePermissionEdit) } onClick={ save }>
                Salvar
              </Button>
              <Button type="button" color="secondary" className="ms-2" onClick={ toggle }>
                Fechar
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      ) }
    </React.Fragment>
  )
}

LancamentoModal.propTypes = {
  toggle: PropTypes.func,
  saveLancamento: PropTypes.func,
  isOpen: PropTypes.bool,
  despesa: PropTypes.object
}

const mapStatetoProps = state => {
  const {
    fornecedores,
    fornecedoresLoading,
    planoContas,
    planoContasLoading
  } = state.Profile
  return {
    fornecedores,
    fornecedoresLoading,
    planoContas,
    planoContasLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    buscarPlanoContas: () => buscarPlanoContas
  }
}

export default connect(mapStatetoProps, mapDispatchToProps)((LancamentoModal))

