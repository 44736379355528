import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import ptBR from "./locales/pt-BR.json";
i18n
    .use(initReactI18next)
    .init({
        fallbackLng: "pt-BR",
        resources: { 'pt-BR': { translation: ptBR }},
        debug: process.env.I18N_DEBUG || false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    }, (err, t) => {
        if (err) return console.log('Erro ao carregar arquivo de internacionalização', err);
    });

export default i18n;