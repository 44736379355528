import {Filters} from "../common/data/definitions"

export const buildProjection = (columns) => {
  return JSON.stringify(columns?.map(column => column.id))
}

export const buildFilters = (columnFilters, advancedFilters, columns) => {
  const filters = [];
  if(Array.isArray(columnFilters) && columnFilters.length > 0) {
    const columnsCodition = columns.reduce((obj,item) => {   obj[item.id] = item._filterFn; return obj; }, {});
    columnFilters.forEach(columnFilter => {
      const filter = Object.assign({}, columnFilter)

      if(!columnFilter.condition) {
        const condition = columnsCodition[columnFilter.id];
        if(condition) {
          filter.condition = Filters[condition]
        }
      }

      filters.push(buildFilter(filter))
    })
  }

  if(Array.isArray(advancedFilters) && advancedFilters.length > 0) {
    advancedFilters.forEach(filter => {
      filters.push(buildFilter(filter))
    })
  }

  return JSON.stringify(filters);
}

const buildFilter = (filter) => {
  if(!filter.condition) {
    if(Array.isArray(filter.value)) {
      filter.condition = Filters.arrIncludes
    } else {
      filter.condition = Filters.equals
    }
  }

  if(Array.isArray(filter.value)) {
    filter.value = filter.value.map( item => item.value || item)
  }

  return filter
}

const buildSorting = (sort) => {
  if(!Array.isArray(sort) || sort.length == 0) {
    sort = [{"id": "id", "desc":true}];
  }
  return JSON.stringify(sort)
}

export const buildParams = (pagination, globalFilter, columnFilters, advancedFilters, columns, sort) => {
  const params = {}
  params['offset'] = `${pagination.pageIndex * pagination.pageSize}`
  params['limit'] = `${pagination.pageSize}`
  params['filters'] = buildFilters(columnFilters, advancedFilters, columns)
  params['projection'] = buildProjection(columns)
  params['globalFilter'] = globalFilter ?? ''
  params['sorting'] = buildSorting(sort)
  return params;
}

export const buildParamsGrouper = (columnFilters, advancedFilters, columns, group) => {
  const params = {}
  params['filters'] = buildFilters(columnFilters, advancedFilters, columns)
  params['projection'] = JSON.stringify(group)
  return params;
}

export const buildParamsExport = (columnFilters, advancedFilters, columns, sort) => {
  const params = {}
  params['filters'] = buildFilters(columnFilters, advancedFilters, columns)
  params['projection'] = buildProjection(columns)
  params['sorting'] = buildSorting(sort)
  return params;
}