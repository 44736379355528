import PropTypes from "prop-types"
import React from "react"
import { Col, FormGroup, Input, Label } from "reactstrap"

const FormInputPassword = props => {
  const { attribute, description, value, setValue, changeHandeler, required, minLength, maxLength, disabled, placeholder, afterChange } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-password" } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          <Input
            id={ attribute + "-password"}
            name={ attribute + "-password"}
            type="password"
            className={ "form-control " + (required && !value? "is-invalid" : "") }
            value={ value }
            onChange={ e => {
              setValue(e.target.value)

              if(required) {
                if(changeHandeler !== undefined) {
                  changeHandeler(e.target.value, `validate-required-${ attribute }-password`)
                } else {
                  changeHandelerInput(e.target.value, `validate-required-${ attribute }-password`)
                }
              }

              if(afterChange) afterChange(e.target.value)
            } }
            placeholder= { disabled ? '' : (placeholder ? placeholder : `Digite a(o) ${description}`)}
            required={ required }
            minLength={ minLength }
            maxLength={ maxLength }
            disabled={ disabled }
          />
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
                 name="validate-required" id={ `validate-required-${ attribute }-password` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputPassword.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  changeHandeler: PropTypes.func,
  afterChange: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number
}

export default FormInputPassword
