import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {Badge, UncontrolledTooltip} from "reactstrap"
import {apiBuscarEmpresas} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {Link} from "react-router-dom"
import {Box, TextField} from "@mui/material"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {describeDate, describeDateTime, removeHour} from "../../helpers/utils"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const EmpresasTable = forwardRef((props, refFather) => {

    const ref = useRef()

    const {filters, columns, usePermissionEdit, navegarEditarEmpresa} = props

    const [data, setData] = useState([])

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        }
    }))

    const getBadgeElement = (cell) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (cell.getValue() == 'S' ? 'primary higher' : 'soft-dark')}
            color={cell.getValue() == 'S' ? 'primary higher' : 'soft-dark'}
            pill
        >
            {cell.getValue() == 'S' ? 'Sim' : 'Não'}
        </Badge>
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "ID Cloud", accessorKey: "id", size: 80, enableHiding: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {header: "Registro", accessorKey: "idEmpresa", size: 80},
            {header: "Razão social/Nome", accessorKey: "RazaoSocial", size: 200, filterFn: 'contains'},
            {header: "Fantasia", accessorKey: "Fantasia", size: 120, filterFn: 'contains'},
            {header: "CNPJ/CPF", accessorKey: "CNPJ", size: 120},
            {header: "E-mail", accessorKey: "Email", size: 120, filterFn: 'contains'},
            {header: "E-mail Contabilidade", accessorKey: "EmailContador", size: 150, filterFn: 'contains'},
            {
                header: "Envio de Remessa XML Aut.", accessorKey: "RemessaAutomatica", size: 180,
                filterVariant: 'checkbox',
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        {getBadgeElement(cell)}
                    </Box>),
            },
            {header: "Dia para enviar remessa", accessorKey: "RemessaDia", size: 150},
            {header: "URL Certificado", accessorKey: "certificado.Certificado_url_s3", size: 120, filterFn: 'contains'},
            {header: "Tipo Certificado", accessorKey: "certificado.Certificado_tipo", size: 120, filterFn: 'contains'},
            {header: "Validade Certificado", accessorKey: "certificado.Certificado_validade", size: 130,
                Cell: ({cell}) => describeDate(cell?.getValue()),
                accessorFn: (row) => row.certificado?.Certificado_validade ? new Date(removeHour(row.certificado?.Certificado_validade)) : null,
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Certificado digital enviado em", accessorKey: "certificado.Certificado_data_envio", size: 200, enableColumnFilter: false,
                Cell: ({cell}) => describeDateTime(cell?.getValue()),
                sortingFn: 'datetime' },
            {header: "Usuário enviou certificado", accessorKey: "certificado.Certificado_usuario", size: 180, enableColumnFilter: false},
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarEmpresas(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{
                    'certificado.Certificado_url_s3': false,
                    'certificado.Certificado_tipo': false,
                    'certificado.Certificado_data_envio': false,
                    'certificado.Certificado_usuario': false
                }}
                enableRowActions={true}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
                renderRowActions={(row, table) =>
                    (<Box sx={{display: 'flex', gap: '1rem'}}>
                        <Link to="#" className="text-primary">
                            <i className="mdi mdi-eye font-size-18 ms-1" id="viewtooltip"
                               onClick={e => navegarEditarEmpresa(row.original)}
                               title="Visualizar"
                            />
                        </Link>
                        {usePermissionEdit && <Link to="#" className="text-primary">
                            <i className="mdi mdi-pencil font-size-18 ms-1" id="edittooltip"
                               onClick={e => navegarEditarEmpresa(row.original)}
                               title="Editar"
                            />
                        </Link>}
                    </Box>)
                }
            />
        </React.Fragment>
    )
})

export default EmpresasTable

EmpresasTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
    usePermissionEdit: PropTypes.bool,
    navegarEditarEmpresa: PropTypes.func,
}
