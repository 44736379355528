import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import { connect } from "react-redux"
import { buscarSugestoesProdutos, buscarSugestoesStatus, sugestao } from "../../store/auth/profile/actions"
import { isArray } from "leaflet/src/core/Util"
import Loader from "react-loader-spinner"
import Select from "react-select"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";

const CadastrarSugestao = props => {
  const [sugestoesStatusArr, setSugestoesStatusArr] = useState([])
  const [sugestoesProdutosArr, setSugestoesProdutosArr] = useState([])

  const [sugestao, setSugestao] = useState("")
  const [produto, setProduto] = useState("")
  const [anexo, setAnexo] = useState("")
  const [statusId, setStatusId] = useState(0)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const usePermissionEdit = usePermission('sugestoesEdit_editar')
  const usePermissionInsert = usePermission('sugestoesEdit_inserir')

  useEffect(() => {
    props.buscarSugestoesProdutos()
    props.buscarSugestoesStatus()
  }, [])

  useEffect(() => {
    if (props.activeSuggestion) {
      setSugestao(props.activeSuggestion.conteudo)
      setProduto(props.activeSuggestion.produto)
      setAnexo(props.activeSuggestion.anexo)
      setStatusId(props.activeSuggestion.status_id)
      setEditorState(prevState =>
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(props.activeSuggestion.detalhes))
        )
      )
    } else {
      setSugestao("")
      setProduto("")
      setAnexo("")
      setStatusId(0)
      setEditorState(prevState => EditorState.createEmpty())
    }

    return () => {
      setSugestao("")
      setProduto("")
      setAnexo("")
      setStatusId(0)
      setEditorState(prevState => EditorState.createEmpty())
    }
  }, [props.activeSuggestion])

  useEffect(() => {
    setSugestoesStatusArr(
      props.sugestoesStatus
        ? props.sugestoesStatus.map(e => ({
          label: e.nome,
          value: e.id.toString()
        }))
        : []
    )
    return () => { setSugestoesStatusArr([])}
  }, [props.sugestoesStatus])

  useEffect(() => {
    setSugestoesProdutosArr(
      props.sugestoesProdutos
        ? props.sugestoesProdutos.map(e => ({
          label: e.descricao,
          value: e.descricao
        }))
        : []
    )

    return () => { setSugestoesProdutosArr([])}
  }, [props.sugestoesProdutos])

  const handleFile = files => {
    const reader = new FileReader()
    reader.onload = () => {
      setAnexo({
        name: files[0].name,
        b64: reader.result
      })
    }
    reader.readAsDataURL(files[0])
  }

  const handleValidSubmit = (e, v) => {
    props.sugestao(
      {
        id: props.activeSuggestion ? props.activeSuggestion.id : null,
        sugestao,
        produto,
        anexo,
        statusId,
        detalhes: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      },
      props.history
    )
  }

  return props.sugestoesStatusLoading ||
  props.sugestoesProdutosLoading ||
  props.sugestaoLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */ }
          <Breadcrumbs
            title="Sugestões"
            breadcrumbItem={
              (props.suggestionMode === "edit" && "Editar Sugestão") ||
              (props.suggestionMode === "view" && "Visualizar Sugestão") ||
              "Criar Sugestão"
            }
          />
          <StickerToolbarButtons>
            { usePermissionEdit && props.suggestionMode === "edit" &&
                <Button type="submit" color="primary"
                        disabled={ !sugestao || !produto || props.suggestionMode === "view" }
                        onClick={ e => handleValidSubmit() } >
                  Salvar Sugestão
                </Button>
            }
            { usePermissionInsert && props.suggestionMode !== "edit" &&
                <Button type="submit" color="primary"
                        disabled={ !sugestao || !produto || props.suggestionMode === "view" }
                        onClick={ e => handleValidSubmit() } >
                  Cadastrar Sugestão
                </Button>
            }
          </StickerToolbarButtons>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    { (props.suggestionMode === "edit" && "Altere a Sugestão") ||
                    (props.suggestionMode === "view" &&
                      "Visualize sua Sugestão") ||
                    "Envie sua Sugestão" }
                  </CardTitle>
                  <form>
                    <div className="outer">
                      <div className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="sugestao"
                            className="col-form-label col-lg-2"
                          >
                            Sugestão
                          </Label>
                          <Col lg="10">
                            <Input
                              id="sugestao"
                              name="sugestao"
                              type="text"
                              className="form-control"
                              placeholder="Digite a Sugestão..."
                              value={ sugestao }
                              onChange={ e => setSugestao(e.target.value) }
                              readOnly={ props.suggestionMode === "view" }
                            />
                          </Col>
                        </FormGroup>

                        { props.suggestionMode === "edit" && (
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Status
                            </Label>
                            <Col lg="10">
                              <select
                                className="form-select select2-search-disable"
                                value={ statusId }
                                onChange={ e => setStatusId(e.target.value) }
                              >
                                { isArray(props.sugestoesStatus) &&
                                props.sugestoesStatus.map((item, key) => (
                                  <option key={ item.value } value={ item.id }>{ item.nome }</option>
                                )) }
                              </select>
                            </Col>
                          </FormGroup>
                        ) }

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="produto"
                            className="col-form-label col-lg-2"
                          >
                            Produto
                          </Label>
                          <Col lg="10">
                            <Select
                              id="produto"
                              name="produto"
                              className="basic-single"
                              options={ sugestoesProdutosArr }
                              placeholder="Digite o Produto..."
                              isClearable={ true }
                              isSearchable={ true }
                              noOptionsMessage={ () =>
                                "Nenhum produto encontrado"
                              }
                              onChange={ e => setProduto(e?.value) }
                              value={
                                produto
                                  ? sugestoesProdutosArr.find(
                                    u => u.value === produto
                                  )
                                  : undefined
                              }
                              isDisabled={ props.suggestionMode === "view" }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Detalhes
                          </Label>
                          <Col lg="10">
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              editorState={ editorState }
                              onEditorStateChange={ e => setEditorState(e) }
                              placeholder="Digite os detalhes da sua sugestão..."
                              readOnly={ props.suggestionMode === "view" }
                            />
                          </Col>
                        </FormGroup>

                        <div className="inner-repeater mb-4">
                          <div className="inner form-group mb-0 row">
                            <Label className="col-form-label col-lg-2">
                              Anexar Arquivo (Opcional)
                            </Label>
                            <div className="inner col-lg-10 ms-md-auto">
                              <div className="mb-3 row align-items-center">
                                <Col md="12">
                                  { props.activeSuggestion &&
                                  props.activeSuggestion.arquivo && (
                                    <a href={ props.activeSuggestion.arquivo } target={ "_blank" }>
                                      <i className="bx bx-link" />
                                      <span>Clique para baixar o anexo</span>
                                    </a>
                                  ) }

                                  { props.suggestionMode !== "view" && (
                                    <div className="mt-4 mt-md-0">
                                      <Input type="file" className="form-control-file" onChange={ e => handleFile(e.target.files) } />
                                    </div>
                                  ) }
                                </Col>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStatetoProps = state => {
  const {
    sugestoesStatus,
    sugestoesProdutos,
    suggestionMode,
    activeSuggestion,
    sugestoesStatusLoading,
    sugestoesProdutosLoading,
    sugestaoLoading
  } = state.Profile
  return {
    sugestoesStatus,
    sugestoesProdutos,
    suggestionMode,
    activeSuggestion,
    sugestoesStatusLoading,
    sugestoesProdutosLoading,
    sugestaoLoading
  }
}

export default connect(mapStatetoProps, {
  buscarSugestoesStatus,
  buscarSugestoesProdutos,
  sugestao
})(CadastrarSugestao)
