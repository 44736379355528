import PropTypes from "prop-types"
import React, {useRef, useState} from "react"
import {Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip} from "reactstrap"
import LoaderList from "./loaderList";
import {Link} from "react-router-dom";
import {AvForm} from "availity-reactstrap-validation";

const FormInputFile = (props) => {
  const { attribute, description, value, setValue, changeHandeler, removeHandeler, required, loading, disabled, extension } = props
  const inputRef = useRef()
  const [file, setFile] = useState()
  const [modalRemove, setModalRemove] = useState(false)
  const [validacaoMensagem, setValidacaoMensagem] = useState()

  const showConfirmDelete = () => {
    setModalRemove(true)
  }

  const remover = item => {
    setModalRemove(false)
    removeHandeler(value)
  }

  const getNome = () => {
    if(value instanceof Blob) {
      return ''
    } else {
      return
    }
  }

  const changeFile = (e, file) => {
    // if(file.size > 16777215) { // maximo que o campo suporta
    if(file.size > 2777215) { // maximo que o campo suporta
      setValidacaoMensagem("Somente é permitido imagem com menos de 16 MB")
      changeHandelerInput(file, `validate-${ attribute }-file`, false)

      e.target.value = null
    } else {
      let valid = true
      if(".png, .jpeg, .jpg" === extension && !"image/png image/jpeg".includes(file.type)) {
        setValidacaoMensagem("Somente é permitido arquivos do tipo imagem com extensão png e jpeg")
        valid = false
      } else if(".pfx" === extension && !"application/x-pkcs12".includes(file.type)) {
        setValidacaoMensagem("Somente é permitido arquivo do tipo pfx")
        valid = false
      }

      if(valid) {
        changeHandelerInput(file, `validate-${ attribute }-file`, true)
        setValue( { nome: file.name, file: file })
      } else {
        changeHandelerInput(file, `validate-${ attribute }-file`, false)
        e.target.value = null
      }
    }
  }

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  const buildPreview = () => {
    return <div className="p-2">
      { ".png, .jpeg, .jpg" === extension &&
          <Row className="align-items-center">
            <Col className="col-auto">
              { value && value.file && value.file instanceof Blob && <img
                  data-dz-thumbnail=""
                  height="80"
                  className="rounded bg-light"
                  alt={value.nome}
                  src={URL.createObjectURL(value.file)}
              />}
            </Col>
          </Row>
      }
      <Row className="align-items-left">
        <Col className="col-auto">
          <Link to="#" className="text-muted font-weight-bold">
            {value.nome}
          </Link>
        </Col>
        <Col>
          { <Link to="#" className="text-danger">
            <i className="mdi mdi-delete font-size-18 me-3" id="btnDelete"
               onClick={() => showConfirmDelete()} title="Remover"/>
          </Link>
          }
        </Col>
      </Row>
    </div>;
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-file" } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          { loading ? <LoaderList></LoaderList> :
              value && !value.remover ? buildPreview() :
            <>
              <div className="input-group">
                <Input
                   id={ attribute + "-file"}
                   name={ attribute + "-file"}
                   type="file"
                   className="form-control"
                   ref={ inputRef }
                   required={ required }
                   disabled={ disabled }
                   accept={ extension }
                   onChange={e => {
                     if (e.target.files) {
                       const file = e.target.files[0]
                       changeFile(e, file)
                     }

                     if(required) {
                       if(changeHandeler !== undefined) {
                         changeHandeler(e.target.value, `validate-required-${ attribute }-file`)
                       } else {
                         changeHandelerInput(e.target.value, `validate-required-${ attribute }-file`)
                       }
                     }
                    }
                 }/>
                { file && <button className="btn btn-primary"type="button" onClick={ submitFile }>Anexar</button> }
              </div>
              { required &&
                  <div className={ value ? "" : "invalid-tooltip" }
                       name="validate-required" id={ `validate-required-${ attribute }-file` }>
                    { value ? "" : `${ description } é obrigatório(a)`}
                  </div>
              }
              <div className="invalid-tooltip"
                   name="validate" id={ `validate-${ attribute }-file` } style={ { display: "none" } }>
                { validacaoMensagem }
              </div>
            </>
          }
        </Col>
      </FormGroup>

      <Modal isOpen={modalRemove}>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={(e, v) => { remover() }} >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover este arquivo?
              </Col>
            </Row>
            <Row style={{paddingTop: 10}}>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                      type="button"
                      className="btn btn-light ms-2"
                      onClick={e => setModalRemove(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

    </React.Fragment>
  )
}

FormInputFile.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  removerImagemFile: PropTypes.func,
  loading: PropTypes.bool,
  extension: PropTypes.any,
  changeHandeler: PropTypes.func,
}

export default FormInputFile
