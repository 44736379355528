import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import {decodeToken, isExpired} from "react-jwt";

import {BrowserRouter, Switch} from "react-router-dom"
import {connect} from "react-redux"

// Import Routes all
import {authRoutes, userRoutes} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// Import scss
import "./assets/scss/theme.scss"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import "assets/scss/_styles.scss"

// Import Firebase Configuration file
import {getOneSignalHelper, initOneSignalHelper} from "./helpers/onesignal_helper"
import PermissionProvider from "./helpers/permission/permissionProvider"

initOneSignalHelper()
getOneSignalHelper().initialize()

const App = props => {
  const [authed, setAuthed] = useState()

  const fetchPermission = (user) => async (permission) =>{
    if(user && Array.isArray(user.permissoes)) {
      if(Array.isArray(permission)) {
        return user.permissoes.some(permissao => permission.includes(permissao))
      } else {
        return user.permissoes.includes(permission);
      }
    }
    return false;
  }

  useEffect(() => {
    let tokenValid = false;
    if(props.user) {
      try {
        let token = localStorage.getItem("authToken")
        if(token) {
          tokenValid = !isExpired(token) && decodeToken(token) !== null
        }
      } catch (error) {
      }
    }

    setAuthed(tokenValid)
  }, [props.user])

  return (
    <React.Fragment>
        { authed &&
          <PermissionProvider fetchPermission={fetchPermission(props.user)}>
              <BrowserRouter>
                <Switch>
                  { userRoutes.map((route, idx) => (
                      <Authmiddleware path={ route.path } exact={route.exact} layout={ route.layout } component={ route.component } key={ idx } permissionKey={ route.key } authedRouter={ route.authedRouter } />
                  )) }
                </Switch>
              </BrowserRouter>
          </PermissionProvider>
        }

        { !authed &&
          <BrowserRouter>
            <Switch>
              { authRoutes.map((route, idx) => (
                <Authmiddleware path={ route.path } exact={route.exact} path={ route.path } layout={ route.layout } component={ route.component } key={ idx } authedRouter={ route.authedRouter } />
              )) }
            </Switch>
          </BrowserRouter>
         }
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { layout: state.Layout, user: user }
}

export default connect(mapStateToProps, {
})(App)
