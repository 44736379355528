import React, { Component } from "react"
import { Card, CardBody, Col } from "reactstrap"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../store/auth/register/actions"
import { apiError, getDashboardData } from "../../store/auth/login/actions"
import ReactEcharts from "echarts-for-react"
import Loader from "react-loader-spinner"
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";

class TiposPagamentos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      percentages: {
        taxa_servico: 0,
        taxa_entrega: 0,
        total_descontos: 0,
        total_arredondamentos: 0
      }
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    console.debug("[tipos-pagamentos][getDerivedStateFromProps]", {
      props,
      current_state
    })

    if (props.dashboardTiposPagamentos) {
      const total =
        parseFloat(props.dashboardTiposPagamentos[0][0].CARTAO || 0) +
        parseFloat(props.dashboardTiposPagamentos[0][0].CHEQUE || 0) +
        parseFloat(props.dashboardTiposPagamentos[0][0].CONTRA_VALE || 0) +
        parseFloat(props.dashboardTiposPagamentos[0][0].DINHEIRO || 0) +
        parseFloat(props.dashboardTiposPagamentos[0][0].TICKET || 0) +
        parseFloat(props.dashboardTiposPagamentos[0][0].VALE || 0)

      return {
        ...props,
        ...{
          percentages: {
            taxa_servico: parseInt(
              (parseFloat(
                  props.dashboardTiposPagamentos[0][0].Valor_Taxa_Servico || 0
                ) /
                total) *
              100
            ),
            taxa_entrega: parseInt(
              (parseFloat(
                  props.dashboardTiposPagamentos[0][0].Valor_Taxa_Entrega || 0
                ) /
                total) *
              100
            ),
            total_descontos: parseInt(
              (parseFloat(
                  props.dashboardTiposPagamentos[0][0].VALOR_DESCONTO || 0
                ) /
                total) *
              100
            ),
            total_arredondamentos: parseInt(
              (parseFloat(
                  props.dashboardTiposPagamentos[0][0].VALOR_ARREDONDAMENTO || 0
                ) /
                total) *
              100
            )
          },
          chart_options: {
            toolbox: {
              show: false
            },
            tooltip: {
              trigger: "item",
              formatter: function(object) {
                return `${ parseFloat(object.value).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) } (${ object.percent }%)`
              }
            },
            legend: {
              orient: "vertical",
              x: "left",
              data: [
                props.t('dashboard.lista.tipopagamento.cartao.label'),
                props.t('dashboard.lista.tipopagamento.cheque.label'),
                props.t('dashboard.lista.tipopagamento.contravale.label'),
                props.t('dashboard.lista.tipopagamento.dinheiro.label'),
                props.t('dashboard.lista.tipopagamento.ticket.label'),
                props.t('dashboard.lista.tipopagamento.vale.label'),
                props.t('dashboard.lista.tipopagamento.faturado.label'),
              ],
              textStyle: {
                color: ["#74788d"]
              },
              formatter: function(name) {
                const formatters = []
                formatters[props.t('dashboard.lista.tipopagamento.cartao.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].CARTAO || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                formatters[props.t('dashboard.lista.tipopagamento.cheque.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].CHEQUE || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                formatters[props.t('dashboard.lista.tipopagamento.contravale.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].CONTRA_VALE || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                formatters[props.t('dashboard.lista.tipopagamento.dinheiro.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].DINHEIRO || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                formatters[props.t('dashboard.lista.tipopagamento.ticket.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].TICKET || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                formatters[props.t('dashboard.lista.tipopagamento.vale.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].VALE || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                formatters[props.t('dashboard.lista.tipopagamento.faturado.label')] = `${ name } ${ parseFloat(
                  props.dashboardTiposPagamentos[0][0].VALOR_FATURADO || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                }) }`

                return formatters[name] || name
              }
            },
            color: [
              "#02a499",
              "#f8b425",
              "#ec4561",
              "#38a4f8",
              "#3c4ccf",
              "#74788d",
              "#EC6BF3"
            ],
            series: [
              {
                name: props.t('dashboard.lista.tipopagamento.total.label'),
                type: "pie",
                radius: ["50%", "70%"],
                center: ["70%", "40%"],
                avoidLabelOverlap: false,
                label: {
                  normal: {
                    show: false,
                    position: "center"
                  },
                  emphasis: {
                    show: true,
                    textStyle: {
                      fontSize: "30",
                      fontWeight: "bold"
                    }
                  }
                },
                labelLine: {
                  normal: {
                    show: false
                  }
                },
                data: [
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].CARTAO
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.cartao.label')
                  },
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].CHEQUE
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.cheque.label')
                  },
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].CONTRA_VALE
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.contravale.label')
                  },
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].DINHEIRO
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.dinheiro.label')
                  },
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].TICKET
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.ticket.label')
                  },
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].VALE
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.vale.label')
                  },
                  {
                    value: parseFloat(
                      props.dashboardTiposPagamentos[0][0].VALOR_FATURADO
                    ).toFixed(2),
                    name: props.t('dashboard.lista.tipopagamento.faturado.label')
                  }
                ]
              }
            ]
          }
        }
      }
    }

    return props
  }

  componentDidMount() {
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "TiposPagamentos")
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="6">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">{ this.props.t('dashboard.lista.tipopagamento.label') }
                <Tooltip id='tipopagamento' tooltip={ this.props.t('dashboard.lista.tipopagamento.tooltip') }></Tooltip>
              </h4>

              <div>
                { this.props.loadingDashboardVendasDias ||
                !this.state.chart_options ||
                !this.props.dashboardTiposPagamentos ? (
                  <div className="loader-container position-absolute">
                    <Loader
                      type="ThreeDots"
                      color="#556EE6"
                      height={ 80 }
                      width={ 80 }
                    />
                  </div>
                ) : (
                  <div>
                    <ReactEcharts
                      style={ { height: "300px" } }
                      option={ this.state.chart_options }
                    />
                    <div className="table-responsive">
                      <table className="table table-centered table-nowrap mb-2">
                        <tbody>
                        <tr>
                          <td style={ { width: "30%" } }>
                            <p className="mb-0">{ this.props.t('dashboard.lista.tipopagamento.taxaservico.label') }</p>
                          </td>
                          <td style={ { width: "25%" } }>
                            <h5 className="mb-0">
                              { parseFloat(
                                this.props.dashboardTiposPagamentos[0][0]
                                  .Valor_Taxa_Servico || 0
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                              }) }
                            </h5>
                          </td>
                          <td>
                            <p className="mb-0">{ this.props.t('dashboard.lista.tipopagamento.taxaentrega.label') }</p>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              { parseFloat(
                                this.props.dashboardTiposPagamentos[0][0]
                                  .Valor_Taxa_Entrega || 0
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                              }) }
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">{ this.props.t('dashboard.lista.tipopagamento.totaldescontos.label') }</p>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              { parseFloat(
                                this.props.dashboardTiposPagamentos[0][0]
                                  .VALOR_DESCONTO || 0
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                              }) }
                            </h5>
                          </td>

                          <td>
                            <p className="mb-0">{ this.props.t('dashboard.lista.tipopagamento.totalarrendodamento.label') }</p>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              { parseFloat(
                                this.props.dashboardTiposPagamentos[0][0]
                                  .VALOR_ARREDONDAMENTO || 0
                              ).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL"
                              }) }
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="mb-0">{ this.props.t('dashboard.lista.tipopagamento.qtdpessoasatendidas.label') }</p>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              { this.props.dashboardTiposPagamentos[0][0]
                                .Qtd_Pessoas || 0 }
                            </h5>
                          </td>

                          <td>
                            <p className="mb-0">{ this.props.t('dashboard.lista.tipopagamento.qtdatendimentos.label') }</p>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              { this.props.dashboardTiposPagamentos[0][0]
                                .Qtd_Atendimentos || 0 }
                            </h5>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) }
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

TiposPagamentos.propTypes = {
  loadingDashboardTiposPagamentos: PropTypes.any,
  dashboards: PropTypes.any,
  getDashboardData: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardTiposPagamentos,
    dashboardTiposPagamentos
  } = state.Login
  return {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardTiposPagamentos,
    dashboardTiposPagamentos
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  getDashboardData
})(TiposPagamentos))
