import React from "react"
import { Card, Col, Row } from "reactstrap"

//Import Image
import features from "../../assets/images/crypto/features-img/img-1.png"

const CardWelcome = () => {
  return (
    <React.Fragment>
      <Card>
        <div>
          <Row>
            <Col lg="9" sm="8">
              <div className="p-4">
                <h5 className="text-primary">
                  Bem-vindo a Gestão de Contas a Pagar!
                </h5>
                <p>
                  O controle de contas a pagar possibilita a identificação dos seguintes elementos:
                </p>

                <div className="text-muted">
                  <p className="mb-1">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Identificar todas as obrigações a pagar;
                  </p>
                  <p className="mb-1">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Priorizar os pagamentos, na hipótese de dificuldade financeira;
                  </p>
                  <p className="mb-0">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Verificar as obrigações contratadas e não pagas;
                  </p>
                  <p className="mb-0">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{ " " }
                    Não permitir a perda de prazo, de forma a conseguir descontos;
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="3" sm="4" className="align-self-center">
              <div>
                <img src={ features } alt="" className="img-fluid d-block" />
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default CardWelcome
