import React, {useEffect, useRef, useState} from "react"
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form, FormGroup, Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import usePermission from "helpers/permission/usePermission"
import {Filters} from "../../common/data/definitions"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"
import UsuariosTable from "./usuarios-table"
import {
    buscarEmpresaPorUsuario,
    buscarVinculosPorUsuario,
    exportarUsuarioCSV,
    rejeitarVinculos
} from "../../store/auth/profile/actions"
import {isEmpty, map} from "lodash"
import PropTypes from "prop-types"
import FormFilterNumber from "../../components/Common/form-filter-number"
import moment from "moment"
import FormFilterCompany from "../../components/Common/form-filter-company"
import {getCompanies, getCompaniesValuesByOptions} from "../../helpers/utils"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const UsuariosLista = props => {

    const ref = useRef()

    const [filters, setFilters] = useState(null)
    const [companiesOption, setCompaniesOption] = useState([])
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
    const isAdmin = props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador
    const usePermissionEdit = usePermission("empresas_editar")
    const [itemSelecionado, setItemSelecionado] = useState()
    const [modalVisualizar, setModalVisualizar] = useState(false)
    const [modalCancelar, setModalCancelar] = useState(false)
    const [usuarioSemAcessoDias, setUsuarioSemAcessoDias] = useState()
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        props.buscarEmpresaPorUsuario()
    }, [])

    useEffect(() => {
        if(Array.isArray(props.empresas)) {
            setCompaniesOption(props.empresas.map(empresa => { return {
                label: empresa.Fantasia,
                value: empresa.idEmpresa
            }}))
        } else {
            setCompaniesOption([])
        }

        setCompanies(props.dashboardFilters.companiesSelected)
        populateFiltros(props.dashboardFilters.companiesSelected)

        return () => {
            setCompaniesOption([])
        }
    }, [props.empresas])

    const populateFiltros = (companiesSelected) => {
        const filters = []

        if(companiesSelected) {
            filters.push({id: 'vinculos.idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        } else if(companies) {
            filters.push({id: 'vinculos.idEmpresa', value: getCompaniesValuesByOptions(companies)})
        }

        if (isAdmin) filters.push({id: 'ignoreCompany', value: true, condition: Filters.equals})

        if (usuarioSemAcessoDias !== undefined && usuarioSemAcessoDias !== null) {
            const usuarioSemAcessoDiasStr = moment().subtract(usuarioSemAcessoDias, "days").format('YYYY-MM-DD 00:00:00')
            filters.push({ id: 'data_ultimo_login', value: usuarioSemAcessoDiasStr, condition: Filters.lessThanOrEqualTo})
        }
        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => ref?.current?.search(), 300)
    }

    const exportarCSV = () => {
        populateFiltros()
        setTimeout(() => {
            let paramsExport = ref.current.getParamsExport()
            props.exportarUsuarioCSV({ name: 'usuario', paramsExport })
        }, 300)
    }

    const visualizarVinculos = (usuario) => {
        props.buscarVinculosPorUsuario({ ignoreCompany: isAdmin, usuarioId: usuario.id })
        setModalVisualizar(true)
    }

    const cancelarVinculos = (usuario) => {
        setItemSelecionado(usuario)
        setModalCancelar(true)
    }

    const removerVinculos = () => {
        // C = Cancelado (ele será gravado como rejeitado mas precisa da diferenciação devido o tipo do e-mail
        props.rejeitarVinculos({ id: itemSelecionado.id, status: 'C' }, props.history)
        setModalCancelar(false)
    }

    const describeStatusColor = vinculo_status => {
        switch (vinculo_status) {
            case "A":
            case "T":
                return "success"
            case "R":
                return "danger"
            case "P":
            case "E":
                return "warning"
            default:
                return ""
        }
    }

    const describeStatus = vinculo_status => {
        switch (vinculo_status) {
            case "A":
                return "Aceito"
            case "R":
                return "Rejeitado"
            case "P":
                return "Pendente"
            case "T":
                return "Aceite Temporário"
            case "E":
                return "Expirado"
            default:
                return "N/D"
        }
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Cadastro" breadcrumbItem="Usuários"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <FormFilterCompany
                                                            attribute="companies"
                                                            value={companies}
                                                            description="Empresa(s)"
                                                            setValue={ value => setCompanies(value) }
                                                            options={companiesOption}
                                                            multiple={ true }
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-12 col-md-3 col-lg-2">
                                                    <FormFilterNumber
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="usuarioSemAcessoDias"
                                                        description="Usuários sem acesso a X dias"
                                                        value={usuarioSemAcessoDias}
                                                        changeHandeler={setUsuarioSemAcessoDias}
                                                        allowNegative={false}
                                                        max={9999999}
                                                    />
                                                </div>
                                                { !props.exportarCSVLoading &&
                                                    <div className="col col-auto align-self-start mt-md-4 pt-1">
                                                        <div className="mt-3 col col-auto">
                                                            <Button color="primary" className="w-md"
                                                                    onClick={e => exportarCSV()}>
                                                                <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                                                CSV
                                                            </Button>
                                                        </div>
                                                    </div>}
                                                <div className="col col-auto align-self-start mt-md-4 pt-1">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && Array.isArray(props.empresas) && !props.empresasLoading && <Card>
                                    <CardBody>
                                        <UsuariosTable ref={ref} filters={filters}
                                                       history={props.history}
                                                       usePermissionEdit={usePermissionEdit}
                                                       visualizarVinculos={usuario => visualizarVinculos(usuario)}
                                                       cancelarVinculos={usuario => cancelarVinculos(usuario)}
                                                       user={props.user}>
                                        </UsuariosTable>
                                        <Modal isOpen={ modalVisualizar } role="dialog"
                                            autoFocus={ true } centered={ true }
                                            tabIndex="-1" scrollable={ true } size="xl"
                                            onExit={ () => setModalVisualizar(false) }>
                                            <div className="modal-content">
                                                <ModalHeader tag="h4">Vinculo(s) do usuário</ModalHeader>
                                                <ModalBody>
                                                    <div className="table-responsive">
                                                        <Table className="table table-centered table-nowrap">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">Código</th>
                                                                <th scope="col">Empresa</th>
                                                                <th scope="col">Perfil</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            { (props.vinculosLoading) || (isEmpty(props.vinculos) && (
                                                                    <tr>
                                                                        <td>
                                                                            <h5 className="text-truncate font-size-14 m-0">
                                                                                Não há nenhum vínculo para este usuário
                                                                            </h5>
                                                                        </td>
                                                                    </tr>
                                                                )) ||
                                                                map(props.vinculos, (item, i) => (
                                                                    <tr key={ i }>
                                                                        <td>
                                                                            <div>
                                                                                <p className="text-muted mb-0">{ item.id }</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <p className="text-muted mb-0">
                                                                                    { item.empresa_RazaoSocial }
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <p className="text-muted mb-0">
                                                                                    { item.perfil_Nome }
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <Badge
                                                                                    className={ "font-size-12 badge-soft-" + describeStatusColor(item.vinculo_status) }
                                                                                    color={ describeStatusColor(item.vinculo_status) }
                                                                                    pill
                                                                                >
                                                                                    { describeStatus(item.vinculo_status) }
                                                                                </Badge>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button type="button" color="secondary" onClick={ () => setModalVisualizar(false) }>
                                                        Fechar
                                                    </Button>
                                                </ModalFooter>
                                            </div>
                                        </Modal>
                                        <Modal isOpen={modalCancelar}>
                                            <ModalHeader tag="h4">Confirmação</ModalHeader>
                                            <ModalBody>
                                                <Row form>
                                                    <Col className="col-12">
                                                        { props.user?.id !== itemSelecionado?.id ? 'Deseja realmente remover os vínculos deste usuário' : 'Você não pode remover seus próprios vínculos'}
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                { props.user?.id !== itemSelecionado?.id && <Button type="submit" color="danger" onClick={ e => removerVinculos() }>
                                                    Remover
                                                </Button> }
                                                <Button type="button" color="secondary" className="ms-2" onClick={e => setModalCancelar(false)}>
                                                    Cancelar
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

UsuariosLista.propTypes = {
    buscarVinculosPorUsuario: PropTypes.func,
    rejeitarVinculos: PropTypes.func,
    vinculos: PropTypes.array,
    vinculosLoading: PropTypes.bool,
    loading: PropTypes.bool,
    user: PropTypes.any,
}

const mapStatetoProps = state => {
    const {vinculos, vinculosLoading, loading, exportarCSVLoading, empresas, empresasLoading} = state.Profile
    const {user, dashboardFilters } = state.Login
    return {user, vinculos, vinculosLoading, loading, dashboardFilters, exportarCSVLoading, empresas, empresasLoading}
}

export default connect(mapStatetoProps, {
    buscarVinculosPorUsuario,
    rejeitarVinculos,
    exportarUsuarioCSV,
    buscarEmpresaPorUsuario
})(withRouter(UsuariosLista))
