export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"
export const SOLICITAR_VINCULO = "register_solicitar_vinculo"
export const SOLICITAR_VINCULO_SUCCESSFUL =
  "register_solicitar_vinculo_successfull"
export const SOLICITAR_VINCULO_FAILED = "register_solicitar_vinculo_failed"

export const CONFIRM_EMAIL = "CONFIRM_EMAIL"
export const CONFIRM_EMAIL_RESULT = "CONFIRM_EMAIL_RESULT"
