import { applyMiddleware, compose, createStore } from "redux"
import createSagaMiddleware from "redux-saga"

import rootReducer from "./reducers"
import rootSaga from "./sagas"

import { persistReducer, persistStore } from "redux-persist"
// import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import localforage from "localforage"

const persistConfig = {
  key: "primary",
  storage: localforage,
  blacklist: ["ForgetPassword", "Register"]
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

const persistor = persistStore(store)

export { store, persistor }
