import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Badge, Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    editarEstoque,
    navegarEditarEstoque, navegarListarEstoque, relatorioExecutar,
    removerEstoque
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {getCompanies, getCompaniesValuesByOptions, getCompanyOption} from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import EstoqueTable from "./estoque-table"
import FormFilterSelect from "../../components/Common/form-filter-select"
import FormFilterNumber from "../../components/Common/form-filter-number"
import {
    apiBuscarFornecedoresPesquisa, apiBuscarGrupoEstoquePesquisa,
    apiBuscarGruposCardapioItemPesquisa,
    apiBuscarTabelaCestPesquisa
} from "../../helpers/backend_helper"
import FormInputAsyncSelect from "../../components/Common/form-input-async-select"
import FormFilterFornecedor from "../../components/Common/form-filter-fornecedor"
import FormFilterAsyncSelect from "../../components/Common/form-filter-async-select"
import FormFilterSwitch from "../../components/Common/form-filter-switch"
import {Filters} from "../../common/data/definitions"

const EstoqueLista = props => {

    const ref = useRef();

    const [filters, setFilters] = useState(null)

    const [companies, setCompanies] = useState([])
    const [companiesOption, setCompaniesOption] = useState([])
    const [grupo, setGrupo] = useState()
    const [fornecedor, setFornecedor] = useState()
    const [estoqueAbaixo, setEstoqueAbaixo] = useState()
    const [cest, setCest] = useState()

    const usePermissionRemove = usePermission("estoques_excluir")
    const usePermissionInsert = usePermission("estoques_inserir")
    const usePermissionEdit = usePermission("estoques_editar")
    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))

    useEffect(() => {
        setCompaniesOption(getCompanies(props.user))
        setCompanies(props.dashboardFilters.companiesSelected)

        if (possuiVinculo) {
            populateFiltros(props.dashboardFilters.companiesSelected)
        }

        return () => {
            setCompaniesOption([])
        }
    }, [])

    useEffect(() => {
        if(props.estoqueRemovido) {
            pesquisar()
        }
    }, [props.estoqueRemovido])

    useEffect(() => {
        if(props.grupoEstoqueAtivo) {
            let grupoFiltro = buildGruposCardapioItem(props.grupoEstoqueAtivo.Codigo, props.grupoEstoqueAtivo.descricao)
            setGrupo([grupoFiltro])

            let companies = getCompanies(props.user)
            let companySelected = companies.filter(company => (props.company || []).includes(company.value))
            setCompanies(companySelected)

            props.navegarListarEstoque(null)

            setTimeout(() => pesquisar(companySelected, grupoFiltro), 300)
        }
    }, [props.grupoEstoqueAtivo])

    const populateFiltros = (companiesSelected, grupoFiltro) => {
        const filters = []

        if(companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        } else if(companies) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies)})
        }

        if(grupoFiltro) {
            filters.push({ id: 'grupo', value: grupoFiltro.value, condition: Filters.equals })
        } else if (grupo) {
            filters.push({ id: 'grupo', value: grupo.map(item => item.value), condition: Filters.arrIncludes })
        }

        if (fornecedor) filters.push({ id: 'fornec', value: fornecedor.value })
        if (estoqueAbaixo) filters.push({ id: 'estoqueAbaixo', value: estoqueAbaixo })
        if (cest) filters.push({ id: 'cest', value: cest.value })

        setFilters(filters)
    }

    const pesquisar = (idEmpresaFiltro, grupoFiltro) => {
        populateFiltros(idEmpresaFiltro, grupoFiltro)
        setTimeout(() => ref?.current?.search(), 300)
    }

    const goToEstoque = item => {
        props.history.push("/cadastrar-estoque")
        props.navegarEditarEstoque(item)
    }

    const buildGruposCardapioItem = (Codigo, descricao) => {
        return {label: `${Codigo} - ${descricao}`, value: Codigo}
    }

    const buscarGrupoEstoque = (inputValue, callback) => {
        apiBuscarGrupoEstoquePesquisa({empresaId: getCompaniesValuesByOptions(companies), argumento: inputValue})
            .then(results => {
                const options = results.map(({Codigo, descricao}) => {
                    return buildGruposCardapioItem(Codigo, descricao)
                })
                callback(options)
            })
    }

    const buildTabelaCest = (cest, descricao) => {
        return {label: `${cest} - ${descricao}`, value: cest}
    }

    const buscarTabelaCest = (inputValue, callback) => {
        apiBuscarTabelaCestPesquisa({empresaId: getCompaniesValuesByOptions(companies), argumento: inputValue})
            .then(results => {
                const options = results.map(({cest, descricao}) => {
                    return buildTabelaCest(cest, descricao)
                })

                callback(options)
            })
    }

    const printPDF = () => {
        let reportName = 'estoque';
        props.relatorioExecutar({
            company: getCompaniesValuesByOptions(companies),
            estoqueAbaixo: estoqueAbaixo === 'true' ? 'S' : 'N',
            grupo: grupo ? grupo.map(item => item.value) : -1,
            fornec: fornecedor?.value,
            cest: cest?.value,
            reportName
        });
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Cadastro" breadcrumbItem="Estoques"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">Estoques</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-md-3 col-sm-12">
                                                    <FormFilterCompany
                                                        attribute="companies"
                                                        value={companies}
                                                        description="Empresa(s)"
                                                        setValue={ value => setCompanies(value) }
                                                        options={companiesOption}
                                                        multiple={ true }
                                                    />
                                                </div>

                                                <div className="col col-3">
                                                    <FormFilterSwitch
                                                        classNameFormGrupo="mt-3 mb-0"
                                                        attribute="estoqueAbaixo"
                                                        description="Estoque abaixo do mínimo"
                                                        value={estoqueAbaixo}
                                                        changeHandeler={setEstoqueAbaixo}
                                                    />
                                                </div>

                                                <div className="col col-6">
                                                    <FormFilterAsyncSelect
                                                        attribute="grupo"
                                                        description="Grupo(s)"
                                                        value={grupo}
                                                        setValue={setGrupo}
                                                        multiple={true}
                                                        searchOptions={ buscarGrupoEstoque }
                                                    />
                                                </div>

                                                <div className="col col-4">
                                                    <FormFilterFornecedor
                                                        attribute="fornecedor"
                                                        description="Fornecedor"
                                                        empresas={getCompaniesValuesByOptions(companies)}
                                                        value={fornecedor}
                                                        setValue={setFornecedor}
                                                    />
                                                </div>

                                                <div className="col col-4">
                                                    <FormFilterAsyncSelect
                                                        attribute="cest"
                                                        description="Cest"
                                                        value={cest}
                                                        setValue={setCest}
                                                        searchOptions={ buscarTabelaCest }
                                                    />
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md" onClick={e => pesquisar()}>
                                                            Buscar
                                                        </Button>
                                                    </div>

                                                    <div className="mt-3 col col-auto">
                                                        <Button
                                                            type="button"
                                                            color="success"
                                                            disabled={ !(usePermissionEdit || usePermissionInsert) }
                                                            onClick={ e => goToEstoque(null) }>
                                                            <i className="bx bx-plus" /> Cadastrar
                                                        </Button>
                                                    </div>

                                                    { !props.executaRelatorioLoading &&
                                                        <div className="mt-3 col col-auto">
                                                            <Button type="button" color="light" onClick={ e => printPDF() }>
                                                                <i className="bx bx-download align-middle font-size-16 me-2"></i>
                                                                PDF
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                { filters && <Card>
                                    <CardBody>
                                        <EstoqueTable ref={ref} filters={filters}
                                                      history={props.history}
                                                      usePermissionRemove={ usePermissionRemove }
                                                      usePermissionEdit={ usePermissionEdit }
                                                      navegarEditarEstoque={ goToEstoque }
                                                      removerEstoque={ (data) => props.removerEstoque({ id : data.id }, props.history)}>
                                        </EstoqueTable>
                                    </CardBody>
                                </Card> }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

EstoqueLista.propTypes = {
    estoqueRemovido: PropTypes.any,
    grupoEstoqueAtivo: PropTypes.any,
    company: PropTypes.any,
    removerEstoque: PropTypes.func,
    editarEstoque: PropTypes.func,
    relatorioExecutar: PropTypes.func,
    navegarEditarEstoque: PropTypes.func,
    navegarListarEstoque: PropTypes.func,
}

const mapStatetoProps = state => {
    const { estoqueRemovido, grupoEstoqueAtivo, company } = state.Profile
    const { user, dashboardFilters } = state.Login
    return { user, estoqueRemovido, dashboardFilters, grupoEstoqueAtivo, company }
}

export default connect(mapStatetoProps, {
    navegarEditarEstoque,
    navegarListarEstoque,
    removerEstoque,
    editarEstoque,
    relatorioExecutar,
})(withRouter(EstoqueLista))
