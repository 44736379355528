import React, {Component} from 'react'
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import Restricted from "../../components/Restricted"

class Authmiddleware extends Component {
  static propTypes = {
    component: PropTypes.any,
    layout: PropTypes.any,
    path: PropTypes.string,
    exact: PropTypes.bool,
    permissionKey: PropTypes.any,
    authedRouter: PropTypes.bool
  }

  render = () => {
    const {component, layout, path, exact, permissionKey, authedRouter } = this.props
    let routeComponent;

    if (layout) {
      routeComponent = props => React.createElement(layout, props, React.createElement(component, { ...props, authed: authedRouter }))
    } else {
      routeComponent = props => React.createElement(component, {...props, authed: authedRouter } )
    }

    if(permissionKey) {
      return <Restricted to={ permissionKey }><Route path={path} exact={exact} render={routeComponent}/></Restricted>
    } else {
      return <Route path={path} exact={exact} render={routeComponent}/>
    }

  }
}

export default Authmiddleware
