import React, { useState } from "react"
import CarouselPage from "./CarouselPage"

//Verification code package
import AuthCode from "react-auth-code-input"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import { Alert, Button, Col, Form, FormGroup, Label, Row } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { confirmEmail } from "../../store/auth/register/actions"
import Loader from "react-loader-spinner"

const TwostepVerification = props => {
  const [code, setCode] = useState("")

  const onChangeCode = e => {
    setCode(e)
  }

  const onConfirm = () =>
    props.confirmEmail(
      {
        code,
        email: props.currentEmail
      },
      props.history
    )

  return props.loading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div>
        <div className="container-fluid p-0">
          <div className="row g-0">
            <CarouselPage />

            <Col xl={ 4 }>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={ logodark }
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={ logolight }
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verifique o seu E-mail</h4>
                          <p className="mb-5">
                            Por favor insira o código que enviamos ao seu e-mail
                          </p>

                          { props.confirmEmailResult &&
                          props.confirmEmailResult.type === "error" && (
                            <Alert color="danger">
                              { props.confirmEmailResult.message }
                            </Alert>
                          ) }

                          { props.confirmEmailResult &&
                          props.confirmEmailResult.type === "success" && (
                            <Alert color="success">
                              { props.confirmEmailResult.message }
                            </Alert>
                          ) }

                          <Form>
                            <Row>
                              <Col xs={ 12 }>
                                <FormGroup className="verification-2">
                                  <Label
                                    htmlFor="digit1-input"
                                    className="sr-only"
                                  >
                                    Dight 1
                                  </Label>
                                  <AuthCode
                                    characters={ 6 }
                                    className="form-control form-control-lg text-center"
                                    inputStyle={ {
                                      width: "48px",
                                      height: "calc(1.5em + 1rem + 2px)",
                                      padding: ".5rem 1rem",
                                      borderRadius: "8px",
                                      fontSize: "20px",
                                      textAlign: "center",
                                      marginRight: "8px",
                                      border: "1px solid #ced4da",
                                      textTransform: "uppercase"
                                    } }
                                    onChange={ e => onChangeCode(e) }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>

                          <div className="mt-4">
                            <Button
                              to="dashboard"
                              className="btn btn-success w-md"
                              disabled={ !code || code.length < 6 }
                              onClick={ e => onConfirm() }
                            >
                              Confirmar
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © { new Date().getFullYear() } Inforangra.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { loading, confirmEmailResult, currentEmail } = state.Account
  return { loading, confirmEmailResult, currentEmail }
}

export default withRouter(
  connect(mapStatetoProps, {
    confirmEmail
  })(TwostepVerification)
)
