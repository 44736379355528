import React, { Component } from "react"
import { Button, Card, CardBody, Col } from "reactstrap"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { registerUser, registerUserFailed } from "../../store/auth/register/actions"
import { apiError, getDashboardData } from "../../store/auth/login/actions"
import ReactEcharts from "echarts-for-react"
import Loader from "react-loader-spinner"
import { setUserPreference } from "../../store/auth/profile/actions"
import {withTranslation} from "react-i18next";
import Tooltip from "../../components/Common/tooltip";

class GruposMaisVendas extends Component {
  constructor(props) {
    super(props)

    this.state = {
      graphType:
        props.preferences && props.preferences.Grafico_Grupos_Mais_Vendas
          ? props.preferences.Grafico_Grupos_Mais_Vendas
          : "value"
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    // console.debug("[grupos-mais-vendas][getDerivedStateFromProps]", { props, current_state })

    if (props.dashboardGruposMaisVendas) {
      const data = props.dashboardGruposMaisVendas[0]
        ? props.dashboardGruposMaisVendas[0].sort((n1, n2) =>
          current_state.graphType === "quantity"
            ? n2.qtd_total - n1.qtd_total
            : n2.valor_total - n1.valor_total
        )
        : []

      return {
        ...props,
        ...{
          chart_options: {
            toolbox: {
              show: false
            },
            tooltip: {
              trigger: "item",
              formatter:
                current_state.graphType === "quantity"
                  ? "{a} <br/>{b} : {c} ({d}%)"
                  : object =>
                    `${ parseFloat(object.value).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    }) } (${ object.percent }%)`
            },
            legend: {
              orient: "vertical",
              left: "left",
              data: data.map(e => e.categoria),
              textStyle: {
                color: ["#74788d"]
              }
            },
            color: data
              ? data.map(
                e => `#${ Math.floor(Math.random() * 16777215).toString(16) }`
              )
              : [],
            series: [
              {
                name: props.t('dashboard.lista.gruposmaisvendidos.total.label'),
                type: "pie",
                radius: "55%",
                center: ["70%", "40%"],
                data: data.map(e => ({
                  value:
                    current_state.graphType === "quantity"
                      ? e.qtd_total
                      : e.valor_total,
                  name: e.categoria
                })),
                itemStyle: {
                  normal: {
                    label: {
                      show: window.innerWidth > 700
                    },
                    labelLine: {
                      show: window.innerWidth > 700
                    }
                  },
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)"
                  }
                }
              }
            ]
          }
        }
      }
    }

    return props
  }

  componentDidMount() {
    this.props.getDashboardData(this.props.dashboardFilters.periodStart,
        this.props.dashboardFilters.periodEnd,
        this.props.dashboardFilters.companiesId, "GruposMaisVendas")
  }

  setGraphType(graphType) {
    this.setState(prevState => ({ graphType }))
    this.props.setUserPreference("Grafico_Grupos_Mais_Vendas", graphType)
  }

  render() {
    return (
      <React.Fragment>
        <Col xl="6">
          <Card>
            <CardBody>
              <div className="d-flex flex-wrap">
                <h4 className="card-title mb-4">
                  { this.props.t('dashboard.lista.gruposmaisvendidos.label') }
                  <Tooltip id='gruposmaisvendidos' tooltip={ this.props.t('dashboard.lista.gruposmaisvendidos.tooltip') }></Tooltip>
                </h4>
                <div className="ms-auto">
                  <div className="toolbar d-flex flex-wrap gap-2 text-end">
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={
                        this.state.graphType === "quantity" ? "active" : ""
                      }
                      onClick={ () => this.setGraphType("quantity") }
                    >
                      { this.props.t('dashboard.lista.gruposmaisvendidos.emquantidade.label') }
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={
                        this.state.graphType === "value" ? "active" : ""
                      }
                      onClick={ () => this.setGraphType("value") }
                    >
                      { this.props.t('dashboard.lista.gruposmaisvendidos.emvalor.label') }
                    </Button>
                  </div>
                </div>
              </div>
              { this.props.loadingDashboardVendasDias || !this.state.chart_options ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : (
                <div>
                  <ReactEcharts
                    style={ { height: "300px" } }
                    option={ this.state.chart_options }
                  />
                </div>
              ) }
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

GruposMaisVendas.propTypes = {
  loadingDashboardGruposMaisVendas: PropTypes.any,
  dashboards: PropTypes.any,
  getDashboardData: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardGruposMaisVendas,
    dashboardGruposMaisVendas, preferences
  } = state.Login
  return {
    dashboardData,
    dashboardFilters,
    dashboards,
    loadingDashboardGruposMaisVendas,
    dashboardGruposMaisVendas,
    preferences
  }
}

export default withTranslation()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  getDashboardData,
  setUserPreference
})(GruposMaisVendas))
