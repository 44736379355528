import React, { Component } from "react"
import { Badge, Card, CardBody, CardTitle, Col } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Loader from "react-loader-spinner"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { MDBDataTable } from "mdbreact"

import moment from "moment"
import "moment/locale/pt-br"

moment.locale("pt-br")

class UltimasNotasEmitidas extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {
        columns: [
          { label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150 },
          { label: "ID Sistema", field: "ID Cloud", sort: "asc", width: 270 },
          { label: "Loja", field: "ID Cloud", sort: "asc", width: 200 },
          { label: "Modelo", field: "ID Cloud", sort: "asc", width: 100 },
          { label: "Serie", field: "ID Cloud", sort: "asc", width: 150 },
          { label: "Nº NF", field: "ID Cloud", sort: "asc", width: 100 },
          { label: "Chave", field: "ID Cloud", sort: "asc", width: 100 },
          { label: "Data Emissão", field: "ID Cloud", sort: "asc", width: 100 },
          { label: "Status", field: "ID Cloud", sort: "asc", width: 100 },
          {
            label: "Vlr. Total Produtos",
            field: "ID Cloud",
            sort: "asc",
            width: 100
          },
          {
            label: "Vlr. Total Nota",
            field: "ID Cloud",
            sort: "asc",
            width: 100
          },
          { label: "Ver Nota", field: "ID Cloud", sort: "asc", width: 100 }
        ],
        rows: []
      }
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    // console.debug('[ultimas-notas-emitidas]', {props, current_state})

    if (props.dashboardNotasPeriodo) {
      return {
        ...props,
        ...{
          data: {
            columns: [
              { label: "ID Cloud", field: "ID Cloud", sort: "asc", width: 150 },
              {
                label: "ID Sistema",
                field: "ID Sistema",
                sort: "asc",
                width: 270
              },
              { label: "Loja", field: "Loja", sort: "asc", width: 200 },
              { label: "Modelo", field: "Modelo", sort: "asc", width: 100 },
              { label: "Serie", field: "Serie", sort: "asc", width: 150 },
              { label: "Nº NF", field: "Nº NF", sort: "asc", width: 100 },
              { label: "Chave", field: "Chave", sort: "asc", width: 100 },
              {
                label: "Data Emissão",
                field: "Data Emissão",
                sort: "asc",
                width: 100
              },
              { label: "Status", field: "Status", sort: "asc", width: 100 },
              {
                label: "Vlr. Total Produtos",
                field: "Vlr. Total Produtos",
                sort: "asc",
                width: 100
              },
              {
                label: "Vlr. Total Nota",
                field: "Vlr. Total Nota",
                sort: "asc",
                width: 100
              },
              { label: "Ação", field: "Ação", sort: "asc", width: 100 }
            ],
            rows: props.dashboardNotasPeriodo[0].map(nota => ({
              "ID Cloud": nota.idCloud,
              "ID Sistema": nota.idSistema,
              Loja: nota.Loja,
              Modelo: nota.modelo,
              Serie: nota.serie,
              "Nº NF": nota.nf,
              Chave: (
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={ e => UltimasNotasEmitidas.onChaveCopy(nota) }
                >
                  { nota.chave } <i className={ "bx bx-copy hover__cursor" } />
                </div>
              ),
              "Data Emissão": UltimasNotasEmitidas.describeDateTime(
                nota.data_emissao
              ),
              Status: (
                <Badge
                  className={
                    "font-size-12 badge-soft-" +
                    UltimasNotasEmitidas.statusBadgeClass(nota)
                  }
                  color={ UltimasNotasEmitidas.statusBadgeClass(nota) }
                  pill
                >
                  { nota.status_descricao }
                </Badge>
              ),
              "Vlr. Total Produtos": (
                <div className="text-right">
                  { parseFloat(nota.total_produtos).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  }) }
                </div>
              ),
              "Vlr. Total Nota": (
                <div className="text-right">
                  { parseFloat(nota.total_nf).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  }) }
                </div>
              ),
              Ação: (
                <div>
                  { nota.chave && <a
                    className="me-3 text-primary"
                    href={ `/notas/ver/${ nota.idCloud }/visualizar` }
                    target={ "_blank" }
                  >
                    <i
                      className="mdi mdi-xml font-size-18 me-3"
                      id="edittooltip"
                    ></i>
                  </a> }
                  { nota.chave && <a
                    className="text-success"
                    href={ `/notas/ver/${ nota.idCloud }/download` }
                    target={ "_blank" }
                  >
                    <i
                      className="mdi mdi-cloud-download font-size-18 me-3"
                      id="deletetooltip"
                    ></i>
                  </a> }
                </div>
              )
            }))
          }
        }
      }
    }

    return props
  }

  static describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  // static verNota = nota => undefined

  static statusBadgeClass = nota => {
    switch (nota.status_descricao) {
      case "Nao transmitida": {
        return "danger"
      }
      case "Assinada":
      case "Validada": {
        return "warning"
      }
      case "Autorizada": {
        return "success"
      }
      case "Em Processamento na SEFAZ": {
        return "danger higher"
      }
      case "Transmitida com Pendencia": {
        return "primary higher"
      }
      case "Cancelada": {
        return "soft-dark"
      }
      case "Denegada": {
        return "soft-success"
      }
      case "Rejeitada": {
        return "soft-danger"
      }
      case "Offline": {
        return "danger"
      }
      case "Número inutilizado": {
        return "soft-dark"
      }
      case "Invalida": {
        return "soft-dark"
      }
      default: {
        return "primary"
      }
    }
  }

  static onChaveCopy = nota => {
    navigator.clipboard.writeText(nota.chave)

    toastr.options.positionClass = "toast-bottom-right"
    // toastr.options.timeOut = 0;
    // toastr.options.extendedTimeOut = 0;

    toastr.success("Copiada para a área de transferências.", "Chave")
  }

  render() {
    return (
      <React.Fragment>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Últimas Notas Emitidas</CardTitle>
              { this.props.loadingDashboardNotasPeriodo ||
              !this.props.dashboardNotasPeriodo ? (
                <div className="loader-container position-absolute">
                  <Loader
                    type="ThreeDots"
                    color="#556EE6"
                    height={ 80 }
                    width={ 80 }
                  />
                </div>
              ) : (
                <MDBDataTable
                  responsive
                  striped
                  bordered
                  data={ this.state.data }
                  entriesLabel={ "Mostrar Registros" }
                  infoLabel={ ["Exibindo", "a", "de", "registros"] }
                  noRecordsFoundLabel={ "Nenhum registro encontrado" }
                  paginationLabel={ ["Anterior", "Próximo"] }
                  searchLabel={ "Pesquisar" }
                />
              ) }
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

UltimasNotasEmitidas.propTypes = {
  loadingDashboardNotas: PropTypes.any
}

const mapStatetoProps = state => {
  const { loadingDashboardNotas, dashboardNotasPeriodo } = state.Login
  return { loadingDashboardNotas, dashboardNotasPeriodo }
}

export default connect(mapStatetoProps, {})(UltimasNotasEmitidas)
