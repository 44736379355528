import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//Pages
import SolicitarVinculo from "../pages/Authentication/SolicitarVinculo"
import Vinculos from "../pages/Vinculos/vinculos"
import DashboardPadrao from "../pages/Dashboard-padrao/index"
import PerguntasFrequentes from "../pages/PerguntasFrequentes"
import Pergunta from "../pages/Pergunta"
import Categoria from "../pages/Categoria"
import Perfil from "../pages/Perfil"
import NotasEmitidas from "../pages/NotasFiscais/NotasEmitidas"
import VerNota from "../pages/NotasFiscais/VerNota"
import SugestoesLista from "../pages/Sugestoes/sugestoes-lista"
import CadastrarSugestao from "../pages/Sugestoes/cadastrar-sugestao"
import EmailVerification from "../pages/Authentication/auth-email-verification"
import TwostepVerification from "../pages/Authentication/auth-two-step-verification"
import ConfirmMail from "../pages/Authentication/page-confirm-mail"
import TabelaNCM from "../pages/Tabelas/TabelaNCM"
import NCM from "../pages/Tabelas/NCM"
import NotificacoesLista from "../pages/Notificacoes/notificacoes-lista"
import CadastrarNotificacao from "../pages/Notificacoes/cadastrar-notificacao"
import PainelNotificacoesList from "../pages/Notificacoes/painel-notificacoes-lista"
import CardarioDispositivosLista from "../pages/CardapioDigital/cardapio-dispositivos-lista"
import TemplatesEmailLista from "../pages/TemplateEmail/templates-email-lista"
import ContasPagar from "../pages/ContasPagar/ContasPagar"

// layouts Format
import NonAuthLayout from "./../components/NonAuthLayout"
import Layout from "./../components/VerticalLayout"
import Dre from "../pages/Dre/Dre"
import Pages404 from "pages/Pages404"
import PerfisLista from "../pages/Perfis/perfis-lista"
import CadastrarPerfil from "../pages/Perfis/cadastrar-perfil"
import FornecedoresLista from "../pages/Fornecedores/fornecedores-lista"
import CadastrarFornecedor from "../pages/Fornecedores/cadastrar-fornecedor"
import GruposProdutoLista from "pages/GrupoProdutos/grupo-produtos-lista"
import CadastrarGrupoProduto from "pages/GrupoProdutos/cadastrar-grupo-produto"
import CadastrarProduto from "pages/Produtos/cadastrar-produto"
import ProdutosLista from "pages/Produtos/produtos-lista"
import CardapioDigitalGrupoLista from "../pages/CardapioDigitalGrupo/cardapio-digital-grupo-lista"
import CadastrarCardapioDigitalGrupo from "../pages/CardapioDigitalGrupo/cadastrar-cardapio-digital-grupo"
import MovimentoVendasLista from "../pages/MovimentoVendas/movimento-vendas-lista"
import EmpresaLista from "../pages/Empresas/empresa-lista"
import CadastrarEmpresa from "../pages/Empresas/cadastrar-empresa"
import UsuariosLista from "../pages/Usuarios/usuarios-lista"
import GrupoEstoqueLista from "../pages/GrupoEstoque/grupo-estoque-lista"
import EstoqueLista from "../pages/Estoque/estoque-lista"
import CadastrarGrupoEstoque from "../pages/GrupoEstoque/cadastrar-grupo-estoque"
import CadastrarEstoque from "../pages/Estoque/cadastrar-estoque"
import MovimentoCaixaLista from "../pages/MovimentoCaixa/movimento-caixa-lista";
import CadastroCertificadoDigital from "../pages/CertificadosDigitaisEmpresa/cadastro-certificado-digital";
import CertificadoDigitalLista from "../pages/CertificadosDigitaisEmpresa/certificado-digital-lista";
import CadastroCertificadoDigitalSemAutenticacao
  from "../pages/CertificadosDigitaisEmpresa/cadastro-certificado-digital-sem-autenticacao";

const userRoutes = [
  { path: "/solicitar-vinculo", component: SolicitarVinculo, layout: Layout, key: "solicitarVinculos_inserir" },
  { path: "/vinculos", component: Vinculos, layout: Layout, key: "vinculos_listar" },
  { path: "/dashboard-padrao", component: DashboardPadrao, layout: Layout, key: "dashboard_listar" },
  { path: "/perguntas-frequentes", component: PerguntasFrequentes, layout: Layout, key: "perguntas_listar" },
  { path: "/pergunta", component: Pergunta, layout: Layout, key: "perguntasEdit_editar" },
  { path: "/categoria", component: Categoria, layout: Layout, key: "categoriasEdit_editar" },
  { path: "/meu-perfil", component: Perfil, layout: Layout },
  { path: "/notas-emitidas", component: NotasEmitidas, layout: Layout, key: "notasEmitidas_listar" },
  { path: "/notas/ver/:idCloud/:action", component: VerNota, layout: Layout, key: "" },
  { path: "/movimento-caixas", component: MovimentoCaixaLista, layout: Layout, key: "movimentoCaixa_listar" },
  { path: "/tabela-ncm", component: TabelaNCM, layout: Layout, key: "tabelaNCM_listar" },
  { path: "/ncm", component: NCM, layout: Layout, key: ["tabelaNCMEdit_editar", "tabelaNCM_inserir"] },
  { path: "/sugestoes", component: SugestoesLista, layout: Layout, key: "sugestoes_listar" },
  { path: "/cadastrar-sugestao", component: CadastrarSugestao, layout: Layout, key: "sugestoes_inserir" },
  { path: "/sugestao", component: CadastrarSugestao, layout: Layout, key: "sugestoes_editar" },
  { path: "/notificacoes", component: NotificacoesLista, layout: Layout, key: "notificacoes_listar" },
  { path: "/cadastrar-notificacao", component: CadastrarNotificacao, layout: Layout, key: "notificacoes_inserir" },
  { path: "/notificacao", component: CadastrarNotificacao, layout: Layout, key: "notificacoes_editar" },
  { path: "/cardapio-dispositivos", component: CardarioDispositivosLista, layout: Layout, key: "dispositivos_listar" },
  { path: "/templates-email", component: TemplatesEmailLista, layout: Layout, key: "templatesEmail_listar" },
  { path: "/painel-notificacao", component: PainelNotificacoesList, layout: Layout, key: "painelNotificacoes_listar" },
  { path: "/dre", component: Dre, layout: Layout, key: "dreGerencial_listar" },
  { path: "/contas-pagar", component: ContasPagar, layout: Layout, key: "contasPagar_listar" },
  { path: "/perfil", component: PerfisLista, layout: Layout, key: "perfil_listar" },
  { path: "/cadastrar-perfil", component: CadastrarPerfil, layout: Layout, key: "perfil_editar" },
  { path: "/fornecedores", component: FornecedoresLista, layout: Layout, key: "fornecedores_listar" },
  { path: "/cadastrar-fornecedor", component: CadastrarFornecedor, layout: Layout, key: "fornecedores_editar" },
  { path: "/grupos-produto", component: GruposProdutoLista, layout: Layout, key: "gruposProduto_listar" },
  { path: "/cadastrar-grupo-produto", component: CadastrarGrupoProduto, layout: Layout, key: "gruposProduto_editar" },
  { path: "/produtos", component: ProdutosLista, layout: Layout, key: "produtos_listar" },
  { path: "/cadastrar-produto", component: CadastrarProduto, layout: Layout, key: "produtos_editar" },
  { path: "/grupos-cardapio", component: CardapioDigitalGrupoLista, layout: Layout, key: "gruposCardapio_listar" },
  { path: "/cadastrar-cardapio-digital-grupo", component: CadastrarCardapioDigitalGrupo, layout: Layout, key: "gruposCardapio_editar" },
  { path: "/movimento-vendas", component: MovimentoVendasLista, layout: Layout, key: "movimentoVendas_listar" },
  { path: "/empresas", component: EmpresaLista, layout: Layout, key: "empresas_listar" },
  { path: "/cadastrar-empresa", component: CadastrarEmpresa, layout: Layout, key: "empresas_editar" },
  { path: "/usuarios", component: UsuariosLista, layout: Layout, key: "usuarios_listar" },
  { path: "/grupos-estoque", component: GrupoEstoqueLista, layout: Layout, key: "gruposEstoque_listar" },
  { path: "/cadastrar-grupo-estoque", component: CadastrarGrupoEstoque, layout: Layout, key: "gruposEstoque_editar" },
  { path: "/estoques", component: EstoqueLista, layout: Layout, key: "estoques_listar" },
  { path: "/cadastrar-estoque", component: CadastrarEstoque, layout: Layout, key: "estoques_editar" },
  { path: "/enviar-certificado", component: CadastroCertificadoDigital, layout: Layout, key: "empresas_editar" },
  { path: "/certificados-digitais", component: CertificadoDigitalLista, layout: Layout, key: "empresas_listar" },

  // this route should be at the end of all other routes
  { path: "/404", component: Pages404, authedRouter: true },
  { path: "/dashboard", exact: true, component: () => <Redirect to="/vinculos" />},
  { path: "/logout", component: Logout, layout: NonAuthLayout },
  { path: "/login", component: Login, layout: NonAuthLayout },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard-padrao" />, layout: Layout },
  { path: "*", component: () => <Redirect to="/dashboard-padrao" />, layout: Layout }
]

const authRoutes = [
  { path: "/dashboard-padrao", component: DashboardPadrao, layout: Layout },
  { path: "/404", component: Pages404, authedRouter: false },
  { path: "/logout", component: Logout, layout: NonAuthLayout },
  { path: "/login", component: Login, layout: NonAuthLayout },
  { path: "/forgot-password", component: ForgetPwd, layout: NonAuthLayout },
  { path: "/register", component: Register, layout: NonAuthLayout },
  { path: "/verificar-email", component: EmailVerification, layout: NonAuthLayout },
  { path: "/confirmar-email", component: TwostepVerification, layout: NonAuthLayout },
  { path: "/email-confirmado", component: ConfirmMail, layout: NonAuthLayout },
  { path: "/enviar-certificado-digital", component: CadastroCertificadoDigitalSemAutenticacao, layout: NonAuthLayout },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard-padrao" />, layout: Layout },
]

export { userRoutes, authRoutes }
