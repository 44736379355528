import PropTypes from "prop-types"
import React from "react"
import {Col, FormGroup, Label} from "reactstrap"

const FormInputSwitch = props => {
  const { attribute, description, value, setValue, trueValue, falseValue, disabled } = props

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          <div className="square-switch">
            <input
              type="checkbox"
              id={ attribute + "-switch" }
              name={ attribute + "-switch"}
              switch="none"
              checked={ value === (trueValue || 'S') ? true : false }
              onChange={ () => {
                setValue(value === (trueValue || 'S') ? (falseValue || 'N') : (trueValue || 'S') )
              } }
              disabled={ disabled }
            />
            <label
              htmlFor={ attribute + "-switch"}
              data-on-label="Sim"
              data-off-label="Não"
            />
          </div>
        </Col>
      </FormGroup>

    </React.Fragment>
  )
}

FormInputSwitch.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  trueValue: PropTypes.any,
  falseValue: PropTypes.any,
  disabled: PropTypes.bool
}

export default FormInputSwitch
