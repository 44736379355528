export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const ALTERAR_VINCULOS = "ALTERAR_VINCULOS"
export const ALTERAR_VINCULOS_RESULT = "ALTERAR_VINCULOS_RESULT"
export const BUSCAR_PERFIS = "BUSCAR_PERFIS"
export const BUSCAR_PERFIS_RESULT = "BUSCAR_PERFIS_RESULT"
export const BUSCAR_PERFIL = "BUSCAR_PERFIL"
export const BUSCAR_PERFIL_RESULT = "BUSCAR_PERFIL_RESULT"
export const CADASTRAR_PERFIL = "CADASTRAR_PERFIL"
export const CADASTRAR_PERFIL_RESULT = "CADASTRAR_PERFIL_RESULT"
export const ALTERAR_PERFIL = "ALTERAR_PERFIL"
export const ALTERAR_PERFIL_RESULT = "ALTERAR_PERFIL_RESULT"
export const REMOVER_PERFIL = "REMOVER_PERFIL"
export const REMOVER_PERFIL_RESULT = "REMOVER_PERFIL_RESULT"

export const VIEW_PERFIL = "VIEW_PERFIL"
export const EDIT_PERFIL = "EDIT_PERFIL"
export const CREATE_PERFIL = "CREATE_PERFIL"

export const CADASTRAR_CATEGORIA = "CADASTRAR_CATEGORIA"
export const CADASTRAR_CATEGORIA_RESULT = "CADASTRAR_CATEGORIA_RESULT"
export const CADASTRAR_PERGUNTA = "CADASTRAR_PERGUNTA"
export const CADASTRAR_PERGUNTA_RESULT = "CADASTRAR_PERGUNTA_RESULT"
export const LISTAR_FAQS = "LISTAR_FAQS"
export const LISTAR_FAQS_RESULT = "LISTAR_FAQS_RESULT"

export const DO_EDIT_CATEGORY = "DO_EDIT_CATEGORY"
export const DO_DELETE_CATEGORY = "DO_DELETE_CATEGORY"

export const DO_EDIT_QUESTION = "DO_EDIT_QUESTION"
export const DO_DELETE_QUESTION = "DO_DELETE_QUESTION"

export const BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES = "BUSCAR_QUANTIDADE_SOLICITACOES_PENDENTES"
export const QUANTIDADE_SOLICITACOES_PENDENTES = "QUANTIDADE_SOLICITACOES_PENDENTES"

export const BUSCAR_NOTAS_SITUACOES = "BUSCAR_NOTAS_SITUACOES"
export const NOTAS_SITUACOES = "NOTAS_SITUACOES"

export const BUSCAR_CAIXAS_OPERADORES = "BUSCAR_CAIXAS_OPERADORES"
export const CAIXAS_OPERADORES = "CAIXAS_OPERADORES"

export const BUSCAR_NOTAS = "BUSCAR_NOTAS"
export const NOTAS = "NOTAS"

export const BUSCAR_NOTA = "BUSCAR_NOTA"
export const NOTA = "NOTA"

export const BUSCAR_SUGESTOES = "BUSCAR_SUGESTOES"
export const SUGESTOES = "SUGESTOES"

export const BUSCAR_SUGESTOES_STATUS = "BUSCAR_SUGESTOES_STATUS"
export const SUGESTOES_STATUS = "SUGESTOES_STATUS"

export const SUGESTAO = "SUGESTAO"
export const SUGESTAO_RESULT = "SUGESTAO_RESULT"

export const BUSCAR_SUGESTOES_PRODUTOS = "BUSCAR_SUGESTOES_PRODUTOS"
export const SUGESTOES_PRODUTOS = "SUGESTOES_PRODUTOS"

export const VIEW_SUGGESTION = "VIEW_SUGGESTION"
export const EDIT_SUGGESTION = "EDIT_SUGGESTION"
export const CREATE_SUGGESTION = "CREATE_SUGGESTION"

export const CREATE_NCM = "CREATE_NCM"
export const REMOVE_NCM = "REMOVE_NCM"
export const EDIT_NCM = "EDIT_NCM"
export const BUSCAR_NCMS = "BUSCAR_NCMS"
export const BUSCAR_NCMS_DESCRICAO = "BUSCAR_NCMS_DESCRICAO"
export const NCMS = "NCMS"
export const BUSCAR_NCM_CATEGORIAS = "BUSCAR_NCM_CATEGORIAS"
export const NCM_CATEGORIAS = "NCM_CATEGORIAS"
export const NCM = "NCM"
export const NCM_RESULT = "NCM_RESULT"

export const BUSCAR_MOVIMENTACAO_CAIXAS = "BUSCAR_MOVIMENTACAO_CAIXAS"
export const MOVIMENTACAO_CAIXAS = "MOVIMENTACAO_CAIXAS"

export const SET_USER_PREFERENCE = "SET_USER_PREFERENCE"

export const BUSCAR_NOTIFICACOES = "BUSCAR_NOTIFICACOES"
export const NOTIFICACOES = "NOTIFICACOES"
export const CADASTRAR_NOTIFICACAO_RESULT = "CADASTRAR_NOTIFICACAO_RESULT"

export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION"
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION"
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const NOTIFICACAO = "NOTIFICACAO"
export const NOTIFICACAO_RESULT = "NOTIFICACAO_RESULT"

export const BUSCAR_USUARIOS_POR_EMPRESA = "BUSCAR_USUARIOS_POR_EMPRESA"
export const USUARIOS_POR_EMPRESA = "USUARIOS_POR_EMPRESA"

export const BUSCAR_NOTIFICACOES_USUARIO = "BUSCAR_NOTIFICACOES_USUARIO"
export const BUSCAR_NOTIFICACOES_USUARIO_RESULT =
  "BUSCAR_NOTIFICACOES_USUARIO_RESULT"
export const BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS =
  "BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS"
export const BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS_RESULT =
  "BUSCAR_NOTIFICACOES_USUARIO_NAO_LIDAS_RESULT"
export const MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA =
  "MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA"
export const MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA_RESULT =
  "MARCAR_NOTIFICACAO_USUARIO_NAO_LIDA_RESULT"
export const MARCAR_NOTIFICACAO_USUARIO_LIDA = "MARCAR_NOTIFICACAO_USUARIO_LIDA"
export const MARCAR_NOTIFICACAO_USUARIO_LIDA_RESULT =
  "MARCAR_NOTIFICACAO_USUARIO_LIDA_RESULT"

export const NOTIFICACAO_USUARIO_WEBSOCKET = "NOTIFICACAO_USUARIO_WEBSOCKET"
export const NOTIFICACAO_USUARIO_WEBSOCKET_RESULT =
  "NOTIFICACAO_USUARIO_WEBSOCKET_RESULT"

export const BUSCAR_DISPOSITIVOS = "BUSCAR_DISPOSITIVOS"
export const DISPOSITIVOS = "DISPOSITIVOS"
export const BUSCAR_DISPOSITIVO_LOGS = "BUSCAR_DISPOSITIVO_LOGS"
export const DISPOSITIVO_LOGS = "DISPOSITIVO_LOGS"

export const BUSCAR_TEMPLATES_EMAIL = "BUSCAR_TEMPLATES_EMAIL"
export const TEMPLATES_EMAIL = "TEMPLATES_EMAIL"
export const ALTERAR_TEMPLATE_EMAIL = "ALTERAR_TEMPLATE_EMAIL"
export const ALTERAR_TEMPLATE_EMAIL_RESULT = "ALTERAR_TEMPLATE_EMAIL_RESULT"

export const BUSCAR_DRE = "BUSCAR_DRE"
export const LIMPAR_DRE = "LIMPAR_DRE"
export const BUSCAR_DRE_RESULT = "BUSCAR_DRE_RESULT"
export const LIMPAR_DRE_RESULT = "LIMPAR_DRE_RESULT"

export const RELATORIO_EXECUTAR = "RELATORIO_EXECUTAR"
export const RELATORIO_EXECUTAR_RESULT = "RELATORIO_EXECUTAR_RESULT"

export const BUSCAR_CONTAS_PAGAR = "BUSCAR_CONTAS_PAGAR"
export const BUSCAR_CONTAS_PAGAR_RESULT = "BUSCAR_CONTAS_PAGAR_RESULT"
export const BUSCAR_CONTAS_PAGAR_HOJE = "BUSCAR_CONTAS_PAGAR_HOJE"
export const BUSCAR_CONTAS_PAGAR_HOJE_RESULT = "BUSCAR_CONTAS_PAGAR_HOJE_RESULT"
export const BUSCAR_CONTAS_PAGAR_MES = "BUSCAR_CONTAS_PAGAR_MES"
export const BUSCAR_CONTAS_PAGAR_MES_RESULT = "BUSCAR_CONTAS_PAGAR_MES_RESULT"
export const BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES = "BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES"
export const BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES_RESULT = "BUSCAR_CONTAS_PAGAR_PROXIMOS_MESES_RESULT"
export const BUSCAR_CONTAS_PAGAR_ATRASADOS = "BUSCAR_CONTAS_PAGAR_ATRASADOS"
export const BUSCAR_CONTAS_PAGAR_ATRASADOS_RESULT = "BUSCAR_CONTAS_PAGAR_ATRASADOS_RESULT"
export const BUSCAR_EVOLUCAO_CONTAS_PAGAR = "BUSCAR_EVOLUCAO_CONTAS_PAGAR"
export const BUSCAR_EVOLUCAO_CONTAS_PAGAR_RESULT = "BUSCAR_EVOLUCAO_CONTAS_PAGAR_RESULT"

export const BUSCAR_PLANO_CONTAS = "BUSCAR_PLANO_CONTAS"
export const BUSCAR_PLANO_CONTAS_RESULT = "BUSCAR_PLANO_CONTAS_RESULT"

export const BUSCAR_FORNECEDORES_NOME_CNPJ = "BUSCAR_FORNECEDORES_NOME_CNPJ"
export const BUSCAR_FORNECEDORES_NOME_CNPJ_RESULT = "BUSCAR_FORNECEDORES_NOME_CNPJ_RESULT"
export const BUSCAR_FORNECEDORES = "BUSCAR_FORNECEDORES"
export const BUSCAR_FORNECEDORES_RESULT = "BUSCAR_FORNECEDORES_RESULT"
export const BUSCAR_FORNECEDOR = "BUSCAR_FORNECEDOR"
export const BUSCAR_FORNECEDOR_RESULT = "BUSCAR_FORNECEDOR_RESULT"
export const BUSCAR_QUANTIDADE_FORNECEDORES = "BUSCAR_QUANTIDADE_FORNECEDORES"
export const BUSCAR_QUANTIDADE_FORNECEDORES_RESULT = "BUSCAR_QUANTIDADE_FORNECEDORES_RESULT"
export const CRIAR_FORNECEDOR = "CRIAR_FORNECEDOR"
export const CRIAR_FORNECEDOR_RESULT = "CRIAR_FORNECEDOR_RESULT"
export const EDITAR_FORNECEDOR = "EDITAR_FORNECEDOR"
export const EDITAR_FORNECEDOR_RESULT = "EDITAR_FORNECEDOR_RESULT"
export const NAVEGAR_EDITAR_FORNECEDOR = "NAVEGAR_EDITAR_FORNECEDOR"
export const REMOVER_FORNECEDOR = "REMOVER_FORNECEDOR"
export const REMOVER_FORNECEDOR_RESULT = "REMOVER_FORNECEDOR_RESULT"

export const CADASTRAR_LANCAMENTO = "CADASTRAR_LANCAMENTO"
export const CADASTRAR_LANCAMENTO_RESULT = "CADASTRAR_LANCAMENTO_RESULT"
export const EDITAR_LANCAMENTO = "EDITAR_LANCAMENTO"
export const EDITAR_LANCAMENTO_RESULT = "EDITAR_LANCAMENTO_RESULT"
export const REMOVER_LANCAMENTO = "REMOVER_LANCAMENTO"
export const REMOVER_LANCAMENTO_RESULT = "REMOVER_LANCAMENTO_RESULT"
export const PAGAR_LANCAMENTO = "PAGAR_LANCAMENTO"
export const PAGAR_LANCAMENTO_RESULT = "PAGAR_LANCAMENTO_RESULT"
export const ESTORNAR_PAGAMENTO_LANCAMENTO = "ESTORNAR_PAGAMENTO_LANCAMENTO"
export const ESTORNAR_PAGAMENTO_LANCAMENTO_RESULT = "ESTORNAR_PAGAMENTO_LANCAMENTO_RESULT"

export const BUSCAR_PERMISSAO_PERFIL = "BUSCAR_PERMISSAO_PERFIL"
export const BUSCAR_PERMISSAO_PERFIL_RESULT = "BUSCAR_PERMISSAO_PERFIL_RESULT"

export const PERSISTIR_PERMISSOES = "PERSISTIR_PERMISSOES"
export const PERSISTIR_PERMISSOES_RESULT = "PERSISTIR_PERMISSOES_RESULT"

export const BUSCAR_GRUPOS_PRODUTO = "BUSCAR_GRUPOS_PRODUTO"
export const BUSCAR_GRUPOS_PRODUTO_RESULT = "BUSCAR_GRUPOS_PRODUTO_RESULT"
export const BUSCAR_GRUPOS_PRODUTO_POR_NOME = "BUSCAR_GRUPOS_PRODUTO_POR_NOME"
export const BUSCAR_GRUPOS_PRODUTO_POR_NOME_RESULT = "BUSCAR_GRUPOS_PRODUTO_POR_NOME_RESULT"
export const BUSCAR_GRUPO_PRODUTO = "BUSCAR_GRUPO_PRODUTO"
export const BUSCAR_GRUPO_PRODUTO_RESULT = "BUSCAR_GRUPO_PRODUTO_RESULT"
export const BUSCAR_QUANTIDADE_GRUPOS_PRODUTO = "BUSCAR_QUANTIDADE_GRUPOS_PRODUTO"
export const BUSCAR_QUANTIDADE_GRUPOS_PRODUTO_RESULT = "BUSCAR_QUANTIDADE_GRUPOS_PRODUTO_RESULT"
export const CRIAR_GRUPO_PRODUTO = "CRIAR_GRUPO_PRODUTO"
export const CRIAR_GRUPO_PRODUTO_RESULT = "CRIAR_GRUPO_PRODUTO_RESULT"
export const EDITAR_GRUPO_PRODUTO = "EDITAR_GRUPO_PRODUTO"
export const EDITAR_GRUPO_PRODUTO_RESULT = "EDITAR_GRUPO_PRODUTO_RESULT"
export const NAVEGAR_EDITAR_GRUPO_PRODUTO = "NAVEGAR_EDITAR_GRUPO_PRODUTO"
export const REMOVER_GRUPO_PRODUTO = "REMOVER_GRUPO_PRODUTO"
export const REMOVER_GRUPO_PRODUTO_RESULT = "REMOVER_GRUPO_PRODUTO_RESULT"

export const BUSCAR_PRODUTOS = "BUSCAR_PRODUTOS"
export const BUSCAR_PRODUTOS_RESULT = "BUSCAR_PRODUTOS_RESULT"
export const BUSCAR_PRODUTO = "BUSCAR_PRODUTO"
export const BUSCAR_PRODUTO_RESULT = "BUSCAR_PRODUTO_RESULT"
export const BUSCAR_QUANTIDADE_PRODUTOS = "BUSCAR_QUANTIDADE_PRODUTOS"
export const BUSCAR_QUANTIDADE_PRODUTOS_RESULT = "BUSCAR_QUANTIDADE_PRODUTOS_RESULT"
export const CRIAR_PRODUTO = "CRIAR_PRODUTO"
export const CRIAR_PRODUTO_RESULT = "CRIAR_PRODUTO_RESULT"
export const CRIAR_PRODUTO_MANTEM_CADASTRO = "CRIAR_PRODUTO_MANTEM_CADASTRO"
export const CRIAR_PRODUTO_MANTEM_CADASTRO_RESULT = "CRIAR_PRODUTO_MANTEM_CADASTRO_RESULT"
export const EDITAR_PRODUTO = "EDITAR_PRODUTO"
export const EDITAR_PRODUTO_RESULT = "EDITAR_PRODUTO_RESULT"
export const NAVEGAR_EDITAR_PRODUTO = "NAVEGAR_EDITAR_PRODUTO"
export const REMOVER_PRODUTO = "REMOVER_PRODUTO"
export const REMOVER_PRODUTO_RESULT = "REMOVER_PRODUTO_RESULT"

export const BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO = "BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO"
export const BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO_RESULT = "BUSCAR_TABELA_UNIDADE_UNIDADE_DESCRICAO_RESULT"
export const BUSCAR_TABELA_CEST_DESCRICAO = "BUSCAR_TABELA_CEST_DESCRICAO"
export const BUSCAR_TABELA_CEST_DESCRICAO_RESULT = "BUSCAR_TABELA_CEST__DESCRICAO_RESULT"

export const BUSCAR_CODIGOS_BARRAS_POR_PRODUTO = "BUSCAR_CODIGOS_BARRAS_POR_PRODUTO"
export const BUSCAR_CODIGOS_BARRAS_POR_PRODUTO_RESULT = "BUSCAR_CODIGOS_BARRAS_POR_PRODUTO_RESULT"
export const BUSCAR_CODIGOS_BARRAS = "BUSCAR_CODIGOS_BARRAS"
export const BUSCAR_CODIGOS_BARRAS_RESULT = "BUSCAR_CODIGOS_BARRAS_RESULT"
export const CRIAR_CODIGO_BARRAS = "CRIAR_CODIGO_BARRAS"
export const CRIAR_CODIGO_BARRAS_RESULT = "CRIAR_CODIGO_BARRAS_RESULT"
export const EDITAR_CODIGO_BARRAS = "EDITAR_CODIGO_BARRAS"
export const EDITAR_CODIGO_BARRAS_RESULT = "EDITAR_CODIGO_BARRAS_RESULT"
export const REMOVER_CODIGO_BARRAS = "REMOVER_CODIGO_BARRAS"
export const REMOVER_CODIGO_BARRAS_RESULT = "REMOVER_CODIGO_BARRAS_RESULT"

export const BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO = "BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO"
export const BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO_RESULT = "BUSCAR_PRODUTOS_REFERENCIA_POR_PRODUTO_RESULT"
export const BUSCAR_PRODUTOS_REFERENCIA = "BUSCAR_PRODUTOS_REFERENCIA"
export const BUSCAR_PRODUTOS_REFERENCIA_RESULT = "BUSCAR_PRODUTOS_REFERENCIA_RESULT"
export const CRIAR_PRODUTO_REFERENCIA = "CRIAR_PRODUTO_REFERENCIA"
export const CRIAR_PRODUTO_REFERENCIA_RESULT = "CRIAR_PRODUTO_REFERENCIA_RESULT"
export const EDITAR_PRODUTO_REFERENCIA = "EDITAR_PRODUTO_REFERENCIA"
export const EDITAR_PRODUTO_REFERENCIA_RESULT = "EDITAR_PRODUTO_REFERENCIA_RESULT"
export const REMOVER_PRODUTO_REFERENCIA = "REMOVER_PRODUTO_REFERENCIA"
export const REMOVER_PRODUTO_REFERENCIA_RESULT = "REMOVER_PRODUTO_REFERENCIA_RESULT"

export const BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO = "BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO"
export const BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO_RESULT = "BUSCAR_PRODUTOS_COMPOSICAO_POR_PRODUTO_RESULT"
export const BUSCAR_PRODUTOS_COMPOSICAO = "BUSCAR_PRODUTOS_COMPOSICAO"
export const BUSCAR_PRODUTOS_COMPOSICAO_RESULT = "BUSCAR_PRODUTOS_COMPOSICAO_RESULT"
export const CRIAR_PRODUTO_COMPOSICAO = "CRIAR_PRODUTO_COMPOSICAO"
export const CRIAR_PRODUTO_COMPOSICAO_RESULT = "CRIAR_PRODUTO_COMPOSICAO_RESULT"
export const EDITAR_PRODUTO_COMPOSICAO = "EDITAR_PRODUTO_COMPOSICAO"
export const EDITAR_PRODUTO_COMPOSICAO_RESULT = "EDITAR_PRODUTO_COMPOSICAO_RESULT"
export const REMOVER_PRODUTO_COMPOSICAO = "REMOVER_PRODUTO_COMPOSICAO"
export const REMOVER_PRODUTO_COMPOSICAO_RESULT = "REMOVER_PRODUTO_COMPOSICAO_RESULT"

export const BUSCAR_IMAGEM_PRODUTO = "BUSCAR_IMAGEM_PRODUTO"
export const BUSCAR_IMAGEM_PRODUTO_RESULT = "BUSCAR_IMAGEM_PRODUTO_RESULT"

export const BUSCAR_GRUPOS_CARDAPIO = "BUSCAR_GRUPOS_CARDAPIO"
export const BUSCAR_GRUPOS_CARDAPIO_RESULT = "BUSCAR_GRUPOS_CARDAPIO_RESULT"
export const BUSCAR_GRUPO_CARDAPIO = "BUSCAR_GRUPO_CARDAPIO"
export const BUSCAR_GRUPO_CARDAPIO_RESULT = "BUSCAR_GRUPO_CARDAPIO_RESULT"
export const BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO = "BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO"
export const BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO_RESULT = "BUSCAR_QUANTIDADE_GRUPOS_CARDAPIO_RESULT"
export const CRIAR_GRUPO_CARDAPIO = "CRIAR_GRUPO_CARDAPIO"
export const CRIAR_GRUPO_CARDAPIO_RESULT = "CRIAR_GRUPO_CARDAPIO_RESULT"
export const CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO = "CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO"
export const CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO_RESULT = "CRIAR_GRUPO_CARDAPIO_MANTEM_CADASTRO_RESULT"
export const EDITAR_GRUPO_CARDAPIO = "EDITAR_GRUPO_CARDAPIO"
export const EDITAR_GRUPO_CARDAPIO_RESULT = "EDITAR_GRUPO_CARDAPIO_RESULT"
export const NAVEGAR_EDITAR_GRUPO_CARDAPIO = "NAVEGAR_EDITAR_GRUPO_CARDAPIO"
export const REMOVER_GRUPO_CARDAPIO = "REMOVER_GRUPO_CARDAPIO"
export const REMOVER_GRUPO_CARDAPIO_RESULT = "REMOVER_GRUPO_CARDAPIO_RESULT"

export const BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO = "BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO"
export const BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_RESULT = "BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_RESULT"
export const BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO = "BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO"
export const BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO_RESULT = "BUSCAR_GRUPOS_CARDAPIO_OBSERVACAO_POR_GRUPO_RESULT"
export const BUSCAR_GRUPO_CARDAPIO_OBSERVACAO = "BUSCAR_GRUPO_CARDAPIO_OBSERVACAO"
export const BUSCAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT = "BUSCAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT"
export const CRIAR_GRUPO_CARDAPIO_OBSERVACAO = "CRIAR_GRUPO_CARDAPIO_OBSERVACAO"
export const CRIAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT = "CRIAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT"
export const EDITAR_GRUPO_CARDAPIO_OBSERVACAO = "EDITAR_GRUPO_CARDAPIO_OBSERVACAO"
export const EDITAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT = "EDITAR_GRUPO_CARDAPIO_OBSERVACAO_RESULT"
export const REMOVER_GRUPO_CARDAPIO_OBSERVACAO = "REMOVER_GRUPO_CARDAPIO_OBSERVACAO"
export const REMOVER_GRUPO_CARDAPIO_OBSERVACAO_RESULT = "REMOVER_GRUPO_CARDAPIO_OBSERVACAO_RESULT"

export const BUSCAR_GRUPOS_CARDAPIO_ITEM = "BUSCAR_GRUPOS_CARDAPIO_ITEM"
export const BUSCAR_GRUPOS_CARDAPIO_ITEM_RESULT = "BUSCAR_GRUPOS_CARDAPIO_ITEM_RESULT"
export const BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO = "BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO"
export const BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO_RESULT = "BUSCAR_GRUPOS_CARDAPIO_ITEM_POR_GRUPO_RESULT"
export const BUSCAR_GRUPO_CARDAPIO_ITEM = "BUSCAR_GRUPO_CARDAPIO_ITEM"
export const BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT = "BUSCAR_GRUPO_CARDAPIO_ITEM_RESULT"

export const BUSCAR_VENDAS_DIA = "BUSCAR_VENDAS_DIA"
export const BUSCAR_VENDAS_DIA_RESULT = "BUSCAR_VENDAS_DIA_RESULT"
export const BUSCAR_VENDAS_SEMANA = "BUSCAR_VENDAS_SEMANA"
export const BUSCAR_VENDAS_SEMANA_RESULT = "BUSCAR_VENDAS_SEMANA_RESULT"
export const BUSCAR_VENDAS_MES = "BUSCAR_VENDAS_MES"
export const BUSCAR_VENDAS_MES_RESULT = "BUSCAR_VENDAS_MES_RESULT"
export const BUSCAR_VENDAS_MES_ANTERIOR = "BUSCAR_VENDAS_MES_ANTERIOR"
export const BUSCAR_VENDAS_MES_ANTERIOR_RESULT = "BUSCAR_VENDAS_MES_ANTERIOR_RESULT"
export const BUSCAR_VENDAS_ANO = "BUSCAR_VENDAS_ANO"
export const BUSCAR_VENDAS_ANO_RESULT = "BUSCAR_VENDAS_ANO_RESULT"
export const BUSCAR_VENDAS_ANO_ANTERIOR = "BUSCAR_VENDAS_ANO_ANTERIOR"
export const BUSCAR_VENDAS_ANO_ANTERIOR_RESULT = "BUSCAR_VENDAS_ANO_ANTERIOR_RESULT"
export const BUSCAR_VENDAS_ANO_EVOLUCAO = "BUSCAR_VENDAS_ANO_EVOLUCAO"
export const BUSCAR_VENDAS_ANO_EVOLUCAO_RESULT = "BUSCAR_VENDAS_ANO_EVOLUCAO_RESULT"
export const BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO = "BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO"
export const BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO_RESULT = "BUSCAR_VENDAS_ANO_EVOLUCAO_POR_ATENDIMENTO_RESULT"
export const BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS = "BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS"
export const BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS_RESULT = "BUSCAR_VENDAS_ANO_EVOLUCAO_POR_STATUS_RESULT"

export const BUSCAR_EMPRESA = "BUSCAR_EMPRESA"
export const BUSCAR_EMPRESA_RESULT = "BUSCAR_EMPRESA_RESULT"
export const BUSCAR_EMPRESA_POR_USUARIO = "BUSCAR_EMPRESA_POR_USUARIO"
export const BUSCAR_EMPRESA_POR_USUARIO_RESULT = "BUSCAR_EMPRESA_POR_USUARIO_RESULT"
export const EDITAR_EMPRESA = "EDITAR_EMPRESA"
export const EDITAR_EMPRESA_RESULT = "EDITAR_EMPRESA_RESULT"
export const EDITAR_EMPRESA_CERTIFICADO_DIGITAL = "EDITAR_EMPRESA_CERTIFICADO_DIGITAL"
export const EDITAR_EMPRESA_CERTIFICADO_DIGITAL_RESULT = "EDITAR_EMPRESA_CERTIFICADO_DIGITAL_RESULT"
export const EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO = "EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO"
export const EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO_RESULT = "EDITAR_EMPRESA_CERTIFICADO_DIGITAL_SEM_AUTENTICACAO_RESULT"
export const REMOVER_EMPRESA_CERTIFICADO_DIGITAL = "REMOVER_EMPRESA_CERTIFICADO_DIGITAL"
export const REMOVER_EMPRESA_CERTIFICADO_DIGITAL_RESULT = "REMOVER_EMPRESA_CERTIFICADO_DIGITAL_RESULT"
export const NAVEGAR_EDITAR_EMPRESA = "NAVEGAR_EDITAR_EMPRESA"

export const BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA = "BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA"
export const BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA_RESULT = "BUSCAR_EMPRESA_LICENCAS_POR_EMPRESA_RESULT"
export const BUSCAR_TAREFAS_POR_EMPRESA = "BUSCAR_TAREFAS_POR_EMPRESA"
export const BUSCAR_TAREFAS_POR_EMPRESA_RESULT = "BUSCAR_TAREFAS_POR_EMPRESA_RESULT"

export const BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA = "BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA"
export const BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA_RESULT = "BUSCAR_NOTIFICACOES_SISTEMA_POR_EMPRESA_RESULT"

export const BUSCAR_VINCULOS_POR_USUARIO = "BUSCAR_VINCULOS_POR_USUARIO"
export const BUSCAR_VINCULOS_POR_USUARIO_RESULT = "BUSCAR_VINCULOS_POR_USUARIO_RESULT"
export const REJEITAR_VINCULOS = "REJEITAR_VINCULOS"
export const REJEITAR_VINCULOS_RESULT = "REJEITAR_VINCULOS_RESULT"
export const EXPORTAR_USUARIO_CSV = "EXPORTAR_USUARIO_CSV"
export const EXPORTAR_USUARIO_CSV_RESULT = "EXPORTAR_USUARIO_CSV_RESULT"

export const BUSCAR_GRUPOS_ESTOQUE = "BUSCAR_GRUPOS_ESTOQUE"
export const BUSCAR_GRUPOS_ESTOQUE_RESULT = "BUSCAR_GRUPOS_ESTOQUE_RESULT"
export const BUSCAR_GRUPOS_ESTOQUE_POR_NOME = "BUSCAR_GRUPOS_ESTOQUE_POR_NOME"
export const BUSCAR_GRUPOS_ESTOQUE_POR_NOME_RESULT = "BUSCAR_GRUPOS_ESTOQUE_POR_NOME_RESULT"
export const BUSCAR_GRUPO_ESTOQUE = "BUSCAR_GRUPO_ESTOQUE"
export const BUSCAR_GRUPO_ESTOQUE_RESULT = "BUSCAR_GRUPO_ESTOQUE_RESULT"
export const CRIAR_GRUPO_ESTOQUE = "CRIAR_GRUPO_ESTOQUE"
export const CRIAR_GRUPO_ESTOQUE_RESULT = "CRIAR_GRUPO_ESTOQUE_RESULT"
export const CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO = "CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO"
export const CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO_RESULT = "CRIAR_GRUPO_ESTOQUE_MANTEM_CADASTRO_RESULT"
export const EDITAR_GRUPO_ESTOQUE = "EDITAR_GRUPO_ESTOQUE"
export const EDITAR_GRUPO_ESTOQUE_RESULT = "EDITAR_GRUPO_ESTOQUE_RESULT"
export const NAVEGAR_EDITAR_GRUPO_ESTOQUE = "NAVEGAR_EDITAR_GRUPO_ESTOQUE"
export const NAVEGAR_LISTAR_ESTOQUE = "NAVEGAR_LISTAR_ESTOQUE"
export const REMOVER_GRUPO_ESTOQUE = "REMOVER_GRUPO_ESTOQUE"
export const REMOVER_GRUPO_ESTOQUE_RESULT = "REMOVER_GRUPO_ESTOQUE_RESULT"


export const BUSCAR_ESTOQUES = "BUSCAR_ESTOQUES"
export const BUSCAR_ESTOQUES_RESULT = "BUSCAR_ESTOQUES_RESULT"
export const BUSCAR_ESTOQUES_POR_NOME = "BUSCAR_ESTOQUES_POR_NOME"
export const BUSCAR_ESTOQUES_POR_NOME_RESULT = "BUSCAR_ESTOQUES_POR_NOME_RESULT"
export const BUSCAR_ESTOQUE = "BUSCAR_ESTOQUE"
export const BUSCAR_ESTOQUE_RESULT = "BUSCAR_ESTOQUE_RESULT"
export const CRIAR_ESTOQUE = "CRIAR_ESTOQUE"
export const CRIAR_ESTOQUE_RESULT = "CRIAR_ESTOQUE_RESULT"
export const CRIAR_ESTOQUE_MANTEM_CADASTRO = "CRIAR_ESTOQUE_MANTEM_CADASTRO"
export const CRIAR_ESTOQUE_MANTEM_CADASTRO_RESULT = "CRIAR_ESTOQUE_MANTEM_CADASTRO_RESULT"
export const EDITAR_ESTOQUE = "EDITAR_ESTOQUE"
export const EDITAR_ESTOQUE_RESULT = "EDITAR_ESTOQUE_RESULT"
export const NAVEGAR_EDITAR_ESTOQUE = "NAVEGAR_EDITAR_ESTOQUE"
export const REMOVER_ESTOQUE = "REMOVER_ESTOQUE"
export const REMOVER_ESTOQUE_RESULT = "REMOVER_ESTOQUE_RESULT"

export const BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE = "BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE"
export const BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE_RESULT = "BUSCAR_ESTOQUE_FILIAL_POR_ESTOQUE_RESULT"
export const BUSCAR_EVOLUCAO_FISCAL = "BUSCAR_EVOLUCAO_FISCAL"
export const BUSCAR_EVOLUCAO_FISCAL_RESULT = "BUSCAR_EVOLUCAO_FISCAL_RESULT"