import React, {useEffect, useRef, useState} from "react"
import {
    Card,
    CardBody, CardTitle,
    Col,
} from "reactstrap"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import {buscarCaixasOperadores } from "../../store/auth/profile/actions"
import {Filters} from "../../common/data/definitions"
import moment from "moment-timezone"
import { getCompaniesValuesByOptions} from "../../helpers/utils"
import MovimentoCaixaTable from "../MovimentoCaixa/movimento-caixa-table";

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const UltimosMovimentoCaixaLista = props => {

    const ref = useRef()

    const [filters, setFilters] = useState(null)

    useEffect(() => {
        pesquisar(props.dashboardFilters.periodStart, props.dashboardFilters.periodEnd, props.dashboardFilters.companiesSelected)
    }, [props.dashboardFilters])

    const pesquisar = (periodStart, periodEnd, companiesSelected) => {
        const filters = []

        if(companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        }

        if (!periodStart || !periodEnd) {
            periodStart = moment().startOf("month").format("YYYY-MM-DD 00:00:00")
            periodEnd = moment().endOf("month").format("YYYY-MM-DD 23:59:59")
        }

        filters.push({id: 'Data_Abertura', value: periodStart, condition: Filters.greaterThanOrEqualTo})
        filters.push({id: 'Data_Abertura', value: periodEnd, condition: Filters.lessThanOrEqualTo})

        setFilters(filters)

        setTimeout(() => ref?.current?.search(), 300)
    }

    return (
        <React.Fragment>
            <Col>
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">
                                Últimas Movimentações de Caixas
                            </CardTitle>
                            <MovimentoCaixaTable ref={ref} filters={filters} history={props.history}>
                            </MovimentoCaixaTable>
                        </CardBody>
                    </Card>
                </div>
            </Col>
        </React.Fragment>
    )
}

UltimosMovimentoCaixaLista.propTypes = {
}

const mapStatetoProps = state => {
    const { user, dashboardFilters } = state.Login
    return { user, dashboardFilters }
}

export default connect(mapStatetoProps, {
})(withRouter(UltimosMovimentoCaixaLista))
