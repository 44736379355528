import PropTypes from "prop-types"
import React from "react"
import { Alert, Card, CardBody, Col, Container, Input, Row } from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { apiError, registerUserFailed, solicitarVinculo } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import { google } from "../../config"
import InputMask from "react-input-mask"
import Loader from "react-loader-spinner"

class SolicitarVinculo extends React.Component {

  constructor(props) {
    super(props)

    this.user = this.props.user
    this.vinculos = (this.props.user?.vinculos || [])
    this.qtdVinculos = (this.props.user?.vinculos || []).length
    this.qtdVinculosAprovados = (this.props.user?.vinculos || []).filter(e => ["A", "T"].find(status => status == e.vinculo_status)).length
    this.qtdVinculosRejeitados = (this.props.user?.vinculos || []).filter(e => ["R"].find(status => status == e.vinculo_status)).length
    this.qtdVinculosPendentes = (this.props.user?.vinculos || []).filter(e => ["P"].find(status => status == e.vinculo_status)).length
  }

  handleValidSubmit(event, values) {
    this.props.solicitarVinculo(values, this.props.history)
    this.form && this.form.reset()
  }

  render() {
    return this.props.loading ? (
      <div className="loader-container">
        <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
      </div>
    ) : (
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm={ 8 }>

                  {
                  ( this.qtdVinculos > 0) ?
                    (
                      ( this.qtdVinculosPendentes > 0  ) ?
                        (
                          <div className="alert alert-warning fade show" role="alert">
                            Há vínculo(s) que está(ão) aguardando liberação. Aguarde ou faça contato com o administrador da empresa e peça a liberação com urgência.
                            <br/>
                            <br/>
                            Se desejar informe o CNPJ e E-mail de outra empresa e clique no botão Enviar para registrar um novo pedido de vínculo.
                          </div>
                        ) :
                        (
                          (this.qtdVinculos == this.qtdVinculosRejeitados) ?
                          (
                            <div className="alert alert-warning fade show" role="alert">
                              Seu cadastro não está vinculado a nenhuma empresa.
                              <br/>
                              <br/>
                              Você já possuía acesso, deverá solicitar novamente para a empresa.
                            </div>
                          ) :
                            (
                              <div className="alert alert-warning fade show" role="alert">
                                Você já possui acesso a pelo menos uma empresa.
                              </div>
                            )
                        )
                    ) :
                    (
                      <div className="alert alert-warning fade show" role="alert">
                        Seu cadastro não está vinculado a nenhuma empresa.
                        <br/>
                        <br/>
                        Informe o CNPJ e E-mail da empresa e clique no botão Enviar para registrar o pedido de vínculo.
                        <br />
                        <br />
                        Depois é só aguardar a liberação e retornar ao sistema.
                      </div>
                    )
                  }
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Solicitar Vínculo</h5>
                          <p>
                            Preencha o formulário e clique no botão <b>Enviar</b> para registrar o pedido de vínculo. <b>Aguarde a liberação</b> e retornar ao sistema.
                          </p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={ profileImg } alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={ logoImg }
                              alt=""
                              className="rounded-circle"
                              height="60"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm className="form-horizontal" onValidSubmit={ (e, v) => { this.handleValidSubmit(e, v) } }
                        ref={ c => (this.form = c) }
                      >
                        { this.props.registrationError &&
                        this.props.registrationError ? (
                          <Alert color="danger">
                            { this.props.registrationError }
                          </Alert>
                        ) : null }
                        <Row>
                          <Col md={ 6 }>
                            <div className="form-group">
                              <AvField
                                name="cnpj"
                                label="CNPJ da empresa"
                                className="form-control"
                                placeholder="Digite o CNPJ da empresa que seja solicitar o vínculo"
                                type="text"
                                required
                                mask="99.999.999/9999-99"
                                maskChar=""
                                tag={ [Input, InputMask] }
                                autoFocus
                              />
                            </div>
                          </Col>

                          <Col md={ 6 }>
                            <div className="form-group">
                              <AvField
                                name="email"
                                label="E-mail"
                                type="email"
                                required
                                placeholder="Digite o e-mail cadastrado na Inforangra."
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button className="btn btn-primary btn-lg waves-effect waves-light" type="submit">
                            Enviar
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

SolicitarVinculo.propTypes = {
  solicitarVinculo: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  solicitarVinculo,
  apiError,
  registerUserFailed
})(SolicitarVinculo)
