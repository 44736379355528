import PropTypes from "prop-types"
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {apiBuscarVendas, apiTotalizadorNotas, apiTotalizadorVendas} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime, describeMoney, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, Stack, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {buildParamsGrouper} from "../../helpers/data_helper"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const MovimentoVendasTable = forwardRef((props, refFather) => {

    const ref = useRef()
    const {filters, columns} = props
    const [data, setData] = useState([])

    const [taxaServico, setTaxaServico] = useState(0)
    const [taxaEntrega, setTaxaEntrega] = useState(0)
    const [total, setTotal] = useState(0)
    const [recebido, setRecebido] = useState(0)
    const [dinheiro, setDinheiro] = useState(0)
    const [outrasFormasPagto, setOutrasFormasPagto] = useState(0)
    const [faturado, setFaturado] = useState(0)
    const [pessoas, setPessoas] = useState(0)
    const [acrescimo, setAcrescimo] = useState(0)
    const [desconto, setDesconto] = useState(0)
    const [arredondamento, setArredondamento] = useState(0)
    const [cheque, setCheque] = useState(0)
    const [ticket, setTicket] = useState(0)
    const [vale, setVale] = useState(0)
    const [contraVale, setContraVale] = useState(0)
    const [cortesia, setCortesia] = useState(0)
    const [loading, setLoading] = useState(false)

    useImperativeHandle(refFather, () => ({
        search() {
            setLoading(true)
            buscaTotalizadorNotaFiscal()

            ref?.current?.search()
        }
    }));

    const buscaTotalizadorNotaFiscal = () => {
        let paramsGrouper = buildParamsGrouper(ref?.current?.getColumnFilters(), filters, colunas,
            ["Valor_Servico", "Taxa_Entrega", "Valor_Total", "Dinheiro", "Cartao",
                "Qtd_Pessoas", "Acrescimo", "Desconto", "Arredondamento", "Cheque", "Ticket",
                "Vale", "Contra_Vale", "Cortesia", "Faturado", "Recebido"])
        apiTotalizadorVendas(paramsGrouper).then(result => {
            setTaxaServico(result.data[0].Valor_Servico || 0)
            setTaxaEntrega(result.data[0].Taxa_Entrega || 0)
            setTotal((result.data[0].Valor_Total) || 0)
            setRecebido((result.data[0].Recebido) || 0)
            setDinheiro(result.data[0].Dinheiro || 0)
            setOutrasFormasPagto(result.data[0].Cartao || 0)
            setFaturado(result.data[0].Faturado || 0)
            setPessoas(result.data[0].Qtd_Pessoas || 0)
            setAcrescimo(result.data[0].Acrescimo || 0)
            setDesconto(result.data[0].Desconto || 0)
            setArredondamento(result.data[0].Arredondamento || 0)
            setCheque(result.data[0].Cheque || 0)
            setTicket(result.data[0].Ticket || 0)
            setVale(result.data[0].Vale || 0)
            setContraVale(result.data[0].Contra_Vale || 0)
            setCortesia(result.data[0].Cortesia || 0)

            setLoading(false)
        }).catch(err => setLoading(false))
    }

    useEffect(() => {
        setLoading(true)
        buscaTotalizadorNotaFiscal()

        return () => {
            setTaxaServico(0)
            setTaxaEntrega(0)
            setTotal(0)
            setRecebido(0)
            setDinheiro(0)
            setOutrasFormasPagto(0)
            setFaturado(0)
            setPessoas(0)
            setAcrescimo(0)
            setDesconto(0)
            setArredondamento(0)
            setCheque(0)
            setTicket(0)
            setVale(0)
            setContraVale(0)
            setCortesia(0)
        }
    }, [])

    const describeSituacao = (situacao) => {
        if(situacao) {
            switch (situacao) {
                case 'V':
                    return 'Venda'
                case 'C':
                    return 'Cancelada'
                case 'T':
                    return 'Cortesia'
                case 'D':
                    return 'Devolvida'
                case 'I':
                    return 'Consumo interno'
                case 'P':
                    return 'Desperdicio'
            }
        }
        return 'Venda';
    }

    const describeTipo = (tipo) => {
        if(tipo) {
            switch (tipo) {
                case 'I':
                    return 'Consumo interno'
                case 'P':
                    return 'Desperdício'
                case 'D':
                    return 'Venda'
                case 'T':
                    return 'Cortesia'
            }
        }
        return 'Venda';
    }

    const describeAtendimento = (atendimento) => {
        if(atendimento) {
            switch (atendimento) {
                case 'D':
                    return 'Delivery'
                case 'B':
                    return 'Balcão'
            }
        }
        return 'Mesa';
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "N. Venda", accessorKey: "Seq", size: 120},
            {header: "Empresa", accessorKey: "nomeEmpresa", size: 120, enableColumnFilter: false, filterFn: 'contains'},
            {
                header: "Tipo", accessorKey: "Tipo", size: 120,
                Cell: ({cell}) => describeTipo(cell?.getValue()),
                filterSelectOptions: [
                    {text: "Consumo interno", value: "I"},
                    {text: "Desperdício", value: "P"},
                    {text: "Venda", value: "D"},
                    {text: "Cortesia", value: "T"},
                ],
                filterVariant: 'multi-select'
            },
            {header: "Mesa", accessorKey: "Mesa", size: 100, Cell: ({cell}) => ( <Box sx={{display: 'flex', alignItems: 'right', gap: '1rem',}} className={ (cell?.getValue() || 0) == 0 ? "text-light" : ""}> { cell?.getValue() }</Box>)},
            {header: "Atendimento", accessorKey: "Atendimento", size: 150,
                Cell: ({cell}) => describeAtendimento(cell?.getValue()),
                filterSelectOptions: [
                    {text: "Delivery", value: "D"},
                    {text: "Balcão", value: "B"},
                    {text: "Mesa", value: "M"},
                ],
                filterVariant: 'select'},
            {header: "N. Pedido", accessorKey: "Pedido", size: 150, Header: ({ column }) => ( <span title="Número do pedido quando a venda for originado por um pedido. Exemplo: a venda originou de um pedido de Delivery ou Pedido de mesa/cartão">{column.columnDef.header}</span>)},
            {header: "Caixa", accessorKey: "Caixa", size: 100, filterFn: 'contains'},
            {header: "Usuário Caixa", accessorKey: "Func_Caixa_Nome", size: 160, enableColumnFilter: false, Header: ({ column }) => ( <span title="Usuário caixa é o usuário que realizou o recebimento da venda no caixa ou máquinas de cartão">{column.columnDef.header}</span>)},
            {header: "Usuário Venda", accessorKey: "Func_VEnda_Nome", size: 160, enableColumnFilter: false, Header: ({ column }) => ( <span title="Usuário que iniciou a operação de venda. Outros usuários podem ter auxiliado nesta venda após o início da venda">{column.columnDef.header}</span>)},
            {header: "Situação", accessorKey: "Status", size: 150,
                Cell: ({cell}) => describeSituacao(cell?.getValue()),
                filterSelectOptions: [
                    {text: "Venda", value: "V"},
                    {text: "Cancelada", value: "C"},
                    {text: "Cortesia", value: "T"},
                    {text: "Devolvida", value: "D"},
                    {text: "Consumo interno", value: "I"},
                    {text: "Desperdicio", value: "P"},
                ],
                filterVariant: 'multi-select'
            },
            {header: "Data da venda", accessorKey: "Data", size: 150, Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Abertura Mesa/Cartão", accessorKey: "Abertura", size: 150, Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime', enableColumnFilter: false },
            {header: "Fechamento Mesa/Cartão", accessorKey: "Fechamento", size: 180, Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime', enableColumnFilter: false },
            {header: "Taxa de Serviço", accessorKey: "Valor_Servico", size: 170, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Header: ({ column }) => ( <span title="Valor da taxa de serviço quando houver">{column.columnDef.header}</span>), Footer: () => (<Stack>Taxa de Serviço<Box>{ describeMoney(taxaServico) }</Box></Stack>) },
            {header: "Taxa de Entrega", accessorKey: "Taxa_Entrega", size: 170,
                Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>),
                enableColumnFilter: false, Footer: () => (<Stack>Taxa de Entrega<Box>{ describeMoney(taxaEntrega) }</Box></Stack>) },
            {header: "Total", accessorKey: "Valor_Total", size: 100, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Header: ({ column }) => ( <span title="Valor total da venda + taxa de entrega + taxa de serviço se houver">{column.columnDef.header}</span>), Footer: () => (<Stack>Total<Box>{ describeMoney(total) }</Box></Stack>) },
            {header: "Recebido", accessorKey: "Recebido", size: 130, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Header: ({ column }) => ( <span title="Somatório de todas as formas de pagamentos incluindo vale">{column.columnDef.header}</span>), Footer: () => (<Stack>Recebido<Box>{ describeMoney(recebido) }</Box></Stack>) },
            {header: "Dinheiro", accessorKey: "Dinheiro", size: 120, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Dinheiro<Box>{ describeMoney(dinheiro) }</Box></Stack>) },
            {header: "Outras Formas Pagto", accessorKey: "Cartao", size: 200, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Header: ({ column }) => ( <span title="Inclui forma de pagamento em cartão de crédito, débito, pix e outras formas de pagamento que estão cadastradas no sistema">{column.columnDef.header}</span>), Footer: () => (<Stack>Outras Formas Pagto<Box>{ describeMoney(outrasFormasPagto) }</Box></Stack>) },
            {header: "Faturado", accessorKey: "Faturado", size: 130, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Faturado<Box>{ describeMoney(faturado) }</Box></Stack>) },
            {header: "Qtd. Pessoas", accessorKey: "Qtd_Pessoas", size: 130, Footer: () => (<Stack>Qtd. Pessoas <Box>{ pessoas }</Box></Stack>),},
            {header: "Usuário Aut. Desconto", accessorKey: "Desc_Aut_Func", size: 190},
            {header: "Usuário Aut. Cancelamento", accessorKey: "Canc_Aut_Func", size: 190},
            {header: "Data Cancelamento", accessorKey: "Data_Cancelamento", size: 190, Cell: ({cell}) => describeDateTime(cell?.getValue()), accessorFn: (row) => new Date(row.Data_Cancelamento), sortingFn: 'datetime', enableColumnFilter: false},
            {header: "Usuário Autorizou Consumo Interno", accessorKey: "CI_FUNC", size: 250},
            {header: "Acréscimo", accessorKey: "Acrescimo", size: 140, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Acréscimo<Box>{ describeMoney(acrescimo) }</Box></Stack>) },
            {header: "Desconto", accessorKey: "Desconto", size: 130, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Desconto<Box>{ describeMoney(desconto) }</Box></Stack>) },
            {header: "Arredondamento", accessorKey: "Arredondamento", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Arredondamento<Box>{ describeMoney(arredondamento) }</Box></Stack>) },
            {header: "Cheque", accessorKey: "Cheque", size: 120, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Cheque<Box>{ describeMoney(cheque) }</Box></Stack>) },
            {header: "Ticket", accessorKey: "Ticket", size: 100, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Ticket<Box>{ describeMoney(ticket) }</Box></Stack>) },
            {header: "Vale", accessorKey: "Vale", size: 100, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Vale<Box>{ describeMoney(vale) }</Box></Stack>) },
            {header: "Contra Vale", accessorKey: "Contra_Vale", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Contra Vale<Box>{ describeMoney(contraVale) }</Box></Stack>) },
            {header: "Cortesia", accessorKey: "Cortesia", size: 120, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Cortesia<Box>{ describeMoney(cortesia) }</Box></Stack>) },
            {header: "Cliente", accessorKey: "Codigo_Cliente", size: 120, filterFn: 'contains'},
            {header: "Nome Entregador", accessorKey: "Ent_Entregador_Nome", size: 170, enableColumnFilter: false, Header: ({ column }) => ( <span title="Usuário que realizou a entrega">{column.columnDef.header}</span>) },
        ], [loading])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarVendas(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{
                    Func_Caixa: false,
                    Func_VEnda: false,
                    Valor_Servico: false,
                    Cheque: false,
                    Ticket: false,
                    Desc_Aut_Func: false,
                    Canc_Aut_Func: false,
                    Data_Cancelamento: false,
                    CI_FUNC: false,
                    Ent_Entregador: false,
                    Fechamento: false,
                    nomeEmpresa: false,
                }}
                defaultSorting={[{
                    id: 'Seq',
                    desc: true,
                }]}
            />
        </React.Fragment>
    )
})

export default MovimentoVendasTable

MovimentoVendasTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
}
