import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"

import Breadcrumbs from "../components/Common/Breadcrumb"
import Loader from "react-loader-spinner"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { apiError, loginUser } from "../store/auth/login/actions"
import { cadastrarCategoria, doEditCategory } from "../store/auth/profile/actions"
import PropTypes from "prop-types"
import usePermission from "helpers/permission/usePermission"
import { EditorState } from "draft-js"
import StickerToolbarButtons from "../components/Common/sticker-toolbar-buttons";

const Categoria = props => {
  const [titulo, setTitulo] = useState("")
  const [icone, setIcone] = useState("")

  const usePermissionEdit = usePermission('categoriasEdit_editar')

  const handleValidSubmit = () => {
    props.cadastrarCategoria(
      {
        id: props.editingCategory ? props.editingCategory.id : null,
        titulo,
        icone
      },
      props.history
    )
  }

  useEffect(() => {
    if (props.editingCategory) {
      setTitulo(props.editingCategory.titulo)
      setIcone(props.editingCategory.icone)
    } else {
      setTitulo("")
      setIcone("")
    }
    return () => {
      setTitulo("")
      setIcone("")
    }
  }, [props.editingCategory])

  return props.loading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */ }
          <Breadcrumbs
            title="Categorias"
            breadcrumbItem={
              props.editingCategory ? "Editar Categoria" : "Criar Categoria"
            }
          />

          <StickerToolbarButtons>
            <Button
                type="submit"
                color="primary"
                onClick={ e => handleValidSubmit() }
                disabled={ !titulo || !icone || !usePermissionEdit }
            >
              { props.editingCategory
                  ? "Salvar Categoria"
                  : "Criar Categoria" }
            </Button>
          </StickerToolbarButtons>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    { props.editingCategory
                      ? "Editar Categoria"
                      : "Criar Nova Categoria" }
                  </CardTitle>

                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="titulo"
                          className="col-form-label col-lg-2"
                        >
                          Título
                        </Label>
                        <Col lg="10">
                          <Input
                            id="titulo"
                            name="titulo"
                            type="text"
                            className="form-control"
                            placeholder="Dígite o título da Categoria..."
                            required
                            value={ titulo }
                            onChange={ e => setTitulo(e.target.value) }
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="icone"
                          className="col-form-label col-lg-2"
                        >
                          Ícone
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="icone"
                            name="icone"
                            type="text"
                            placeholder="Digite a classe do ícone... Exemplo: bx bx-credit-card-front"
                            className="form-control"
                            required
                            value={ icone }
                            onChange={ e => setIcone(e.target.value) }
                          />
                          <a
                            href="http://skote-v-light.react.themesbrand.com/icons-boxicons"
                            target="_blank"
                          >
                            Clique aqui para visualizar os ícones
                          </a>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { error, loading, editingCategory } = state.Profile
  return { error, loading, editingCategory }
}

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    apiError,
    cadastrarCategoria,
    doEditCategory
  })(Categoria)
)

Categoria.propTypes = {
  history: PropTypes.object
}
