import PropTypes from "prop-types"
import React from "react"
import {Col, FormGroup, Label} from "reactstrap"
import {NumericFormat} from 'react-number-format';

const FormInputNumber = props => {
  const { attribute, description, value, setValue, disabled, changeHandeler, required, suffix, decimalScale, max, min, allowNegative } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="mb-4" row>
        <Label htmlFor={ attribute + "-number" } className="col-form-label col-lg-2">
          { description }
        </Label>
        <Col lg="10">
          <NumericFormat
            id={ attribute + "-number" }
            name={ attribute + "-number" }
            className={ "form-control " + (required && value == null ? "is-invalid" : "") }
            value={ value }
            disabled={ disabled }
            readOnly={ disabled }
            onValueChange={ (values, sourceInfo) => {
              if(setValue) {
                setValue(values.floatValue)
                if (required) {
                  if (changeHandeler !== undefined) {
                    changeHandeler(values.floatValue, `validate-required-${attribute}-number`)
                  } else {
                    changeHandelerInput(values.floatValue, `validate-required-${attribute}-number`)
                  }
                }
              }
            } }
            required={ required }
            suffix={ suffix }
            decimalSeparator=","
            thousandSeparator="."
            allowNegative={ allowNegative !== undefined ? allowNegative : true}
            decimalScale={ decimalScale !== undefined ? decimalScale : 0  }
            isAllowed={(values, sourceInfo) => {
              const { floatValue } = values
              let valid = true
              if(floatValue !== undefined) {
                if(max !== undefined) {
                  valid &= floatValue <= max
                }
                if(min !== undefined) {
                  valid &= floatValue >= min
                }
              }
              return valid;
            }}
          />
          { required &&
            <div className={ value ? "" : "invalid-tooltip" }
               name="validate-required" id={ `validate-required-${ attribute }-number` }>
              { value ? "" : `${ description } é obrigatório(a)`}
            </div>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

FormInputNumber.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  changeHandeler: PropTypes.func,
  suffix: PropTypes.string,
  decimalScale: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  allowNegative: PropTypes.bool
}

export default FormInputNumber
