import PropTypes from "prop-types"
import React, {Component} from "react"
import {Card, CardBody, Col} from "reactstrap"
import Loader from "react-loader-spinner"
import {describeMoney} from "../../helpers/utils"

const MiniWidget = props => {
    const {icon, title, tooltip, value, badgeValue, color, desc, loading} = props

    return (
        <React.Fragment>
            <Col sm="4">
                <Card className="eq-height">
                    <CardBody>
                        <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                                    <span
                                        className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                      <i className={icon}/>
                                    </span>
                            </div>
                            <h5 className="font-size-14 mb-0" title={tooltip}>{title}</h5>
                        </div>
                        {loading ? (
                            <div className="loader-container position-absolute">
                                <Loader
                                    type="ThreeDots"
                                    color="#556EE6"
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : (
                            <div className="text-muted mt-4">
                                <h4>
                                    {value}{" "}
                                    <i className="mdi mdi-chevron-up ms-1 text-success"/>
                                </h4>
                                <div className="d-flex">
                  <span
                      className={
                          "badge badge-soft-" + color + " font-size-12"
                      }
                  >
                    {" "}
                      {badgeValue}{" "}
                  </span>{" "}
                                    <span className="ms-2 text-truncate">{desc}</span>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

MiniWidget.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.any,
    badgeValue: PropTypes.any,
    color: PropTypes.any,
    desc: PropTypes.string,
    loading: PropTypes.bool
}

export default MiniWidget
