import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"
import { Editor } from "react-draft-wysiwyg"
import { EditorState } from "draft-js"
import PropTypes from "prop-types"
import { stateFromHTML } from "draft-js-import-html"
import { stateToHTML } from "draft-js-export-html"
import usePermission from "helpers/permission/usePermission"

const TemplatesEmailModal = props => {
  const { templateEmail, isOpen, toggle, saveTemplate } = props
  const [titulo, setTitulo] = useState()
  const [emailCopia, setEmailCopia] = useState()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const usePermissionEdit = usePermission('templatesEmail_editar')

  useEffect(() => {
    if (templateEmail && templateEmail.template) {
      setTitulo(templateEmail.titulo)
      setEmailCopia(templateEmail.email_copia)
      let contentState = stateFromHTML(templateEmail.template)
      setEditorState(prevState => EditorState.createWithContent(contentState))
    } else {
      setEditorState(prevState => EditorState.createEmpty())
    }

    return () => { setEditorState(prevState => EditorState.createEmpty()) }
  }, [templateEmail])

  const save = () => {
    let template = stateToHTML(editorState.getCurrentContent())
    templateEmail.template = template
    templateEmail.titulo = titulo
    templateEmail.email_copia = emailCopia
    saveTemplate(templateEmail)
  }

  const describeTipo = tipo => {
    if (!tipo) {
      return "N/D"
    }

    switch (tipo) {
      case "CONFIRMACAO_CONTA":
        return "Confirmação de conta"
      case "RECUPERACAO_SENHA":
        return "Recuperação de senha"
      case "VINCULO_ACEITO":
        return "Vinculo aceito"
      case "VINCULO_REJEITADO":
        return "Vinculo rejeitado"
      case "SUGESTAO_CRIADA":
        return "Sugestão criada"
      case "SUGESTAO_ALTERADA":
        return "Sugestão alterada"
      case "VINCULO_CANCELADO":
        return "Vinculo cancelado"
      default:
        return "Desconhecido"
    }
  }

  return (
    <React.Fragment>
      { templateEmail && (
        <Modal
          isOpen={ isOpen }
          role="dialog"
          autoFocus={ true }
          centered={ true }
          tabIndex="-1"
          scrollable={ true }
          size="xl"
          toggle={ toggle }
        >
          <div className="modal-content">
            <ModalHeader toggle={ toggle }>Template de e-mail</ModalHeader>
            <ModalBody>
              <form>
                <div className="outer">
                  <div className="outer">
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="tipo" className="col-form-label col-lg-2">
                        Tipo
                      </Label>
                      <Col lg="10">
                        <Input
                          id="tipo"
                          name="tipo"
                          type="text"
                          className="form-control"
                          value={ describeTipo(templateEmail.tipo) }
                          readOnly={ true }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="titulo" className="col-form-label col-lg-2">
                        Título
                      </Label>
                      <Col lg="10">
                        <Input
                          id="titulo"
                          name="titulo"
                          type="text"
                          className="form-control"
                          value={ titulo }
                          onChange={ e => setTitulo(e.target.value) }
                          placeholder="Dígite o título"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="titulo" className="col-form-label col-lg-2">
                        E-mail de envio de cópia
                      </Label>
                      <Col lg="10">
                        <Input
                            id="emailCopia"
                            name="emailCopia"
                            type="text"
                            className="form-control"
                            value={ emailCopia }
                            onChange={ e => setEmailCopia(e.target.value) }
                            title="Dígite o(s) e-mails separados com ponto e vírgula (;) sem espaços"
                            placeholder="Dígite o(s) e-mails separados com ponto e vírgula (;) sem espaços"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label htmlFor="template" className="col-form-label col-lg-2">
                        Template
                      </Label>
                      <span className="col-lg-10 d-block">
                        Utilize as variáveis disponíveis para personalizar o conteúdo do seu e-mail: usuarioEmail, usuarioDataUltimoLogin, usuarioDiasUltimoLogin, usuarioNomeCompleto, usuarioEmpresas
                      </span>
                      <Col lg="12">
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorState={ editorState }
                          onEditorStateChange={ e => setEditorState(e) }
                        />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" disabled={ !templateEmail.template || !usePermissionEdit } onClick={ e => save() } >
                Salvar
              </Button>
              <Button type="button" color="secondary" className="me-2" onClick={ toggle }>
                Fechar
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      ) }
    </React.Fragment>
  )
}

TemplatesEmailModal.propTypes = {
  toggle: PropTypes.func,
  saveTemplate: PropTypes.func,
  isOpen: PropTypes.bool,
  templateEmail: PropTypes.object
}

export default TemplatesEmailModal
