import PropTypes from "prop-types"
import React from "react"
import {FormGroup, Label} from "reactstrap"
import AsyncSelect from "react-select/async"


const FormFilterAsyncSelect = props => {
    const { attribute, placeholder, description, value, setValue, multiple, searchOptions } = props

    return (
        <React.Fragment>
            <FormGroup className="mt-3 mb-0">
                <Label htmlFor={ (attribute || 'filter') + "-async-select" }>
                    { description || ( 'Campo' + (multiple ? '(s)' : '')) }
                </Label>
                <AsyncSelect
                    id={ (attribute || 'filter') + "-async-select" }
                    name={ (attribute || 'filter') + "-async-select" }
                    className="basic-single"
                    placeholder={ placeholder || `Digite o ${description}...` }
                    isClearable={ true }
                    isSearchable={ true }
                    isMulti={ multiple }
                    value={ value }
                    noOptionsMessage={ () => `Nenhum(a) ${description} encontrado(a)` }
                    loadingMessage={ () => "Carregando" }
                    onChange={ e => {
                        setValue(e)
                    }}
                    cacheOptions
                    loadOptions={ searchOptions }
                    defaultOptions
                    styles={{
                        menu: (base) => ({
                            ...base,
                            'z-index': '2'
                        }),
                    }}
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormFilterAsyncSelect.propTypes = {
    attribute: PropTypes.string,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    value: PropTypes.any,
    setValue: PropTypes.func,
    searchOptions: PropTypes.func
}

export default FormFilterAsyncSelect
