import React, {useEffect, useState} from "react"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from "reactstrap"
import {describeDateTime} from "../../helpers/utils"
import LoaderList from "../../components/Common/loaderList"
import {MDBDataTable} from "mdbreact"
import {Box} from "@mui/material"
import toastr from "toastr"

const EmpresaNotificacaoLista = props => {

    const [modalOpened, setModalOpened] = useState(false)
    const [notificacaoId, setNotificacaoId] = useState()
    const [nota, setNota] = useState()

    const [data, setData] = useState({
        columns: [
            {label: "Código", field: "Código", sort: "asc", width: 110},
            {label: "Contrato", field: "Contrato", sort: "asc", width: 230},
            {label: "Usuário", field: "Usuário", sort: "asc", width: 230},
            {label: "Sistema", field: "Sistema", sort: "asc", width: 230},
            {label: "Tipo", field: "Tipo", sort: "asc", width: 230},
            {label: "Gravidade", field: "Gravidade", sort: "asc", width: 230},
            {label: "Notificação", field: "Notificação", sort: "asc", width: 230},
            {label: "Inclusão", field: "Inclusão", sort: "asc", width: 230},
            {label: "Notas", field: "Notas", sort: "asc", width: 230},
            {label: "IP", field: "IP", sort: "asc", width: 110},
            {label: "Computador", field: "Computador", width: 110}
        ],
        rows: []
    })

    const onChaveCopy = (chave, titulo) => {
        navigator.clipboard.writeText(chave)

        toastr.options.positionClass = "toast-bottom-right"
        toastr.success("Copiada para a área de transferências.", titulo)
    }

    const gravidadeBadgeClass = gravidade => {
        switch (gravidade) {
            case 'A': {
                return "danger"
            }
            case 'B': {
                return "warning"
            }
            case 'C': {
                return "success"
            }
            case 'D': {
                return "primary"
            }
            default: {
                return "soft-dark"
            }
        }
    }

    useEffect(() => {
        if (Array.isArray(props.listaNotificacao)) {
            const rows = props.listaNotificacao?.map(item => ({
                "Código": item.id,
                "Contrato": item.idContrato,
                "Usuário": item.Usuario,
                "Sistema": item.Sistema,
                "Tipo": item.Tipo,
                "Gravidade": (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={"font-size-12 badge-soft-" + gravidadeBadgeClass(item.Gravidade)}
                            color={gravidadeBadgeClass(item.Gravidade)}
                            pill
                        >
                            {item.Gravidade}
                        </Badge>
                    </Box>),
                "Notificação": (<Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                    <div className="d-inline-block justify-content-between align-items-center text-truncate"
                         style={{maxWidth: "200px"}}
                         onClick={e => onChaveCopy(item.Notificacao, 'Notificação')}
                         title={item.Notificacao}>
                        <i className={"bx bx-copy hover__cursor"}/> {item.Notificacao}
                    </div>
                </Box>),
                "Inclusão": describeDateTime(item.Inc_Data),
                "Notas": (item.Notas ?
                    (<div className="align-items-center">
                        <span className="me-3" onClick={e => {
                            setModalOpened(true)
                            setNotificacaoId(item.id)
                            setNota(String.fromCharCode(...item.Notas.data))}}>
                            <i className={"bx bx-show hover__cursor"}/>
                        </span>
                        <span onClick={e => onChaveCopy(String.fromCharCode(...item.Notas.data), 'Nota')}>
                            <i className={"bx bx-copy hover__cursor"}/>
                        </span>
                    </div>) : "N/A"),
                "IP": item.IP,
                "Computador": item.Computador
            }))

            setData({
                columns: data.columns,
                rows: rows
            })
        }
    }, [props.listaNotificacao])

    const toggle = () => {
        setModalOpened(!modalOpened)
    }
    return <>
        {props.loading ? <LoaderList></LoaderList> :
            <>
                <div className="table-responsive">
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        data={data}
                        entriesLabel={"Mostrar Registros"}
                        infoLabel={["Exibindo", "a", "de", "registros"]}
                        noRecordsFoundLabel={"Nenhum registro encontrado"}
                        paginationLabel={["Anterior", "Próximo"]}
                        searchLabel={"Pesquisar"}
                        entries={5}
                        entriesOptions={[5, 10, 20, 50, 100]}
                    />
                </div>
                <Modal isOpen={modalOpened} toggle={ () => toggle()} scrollable={true}>
                    <ModalHeader tag="h4" toggle={ () => toggle()}>Notificação {notificacaoId} - Nota</ModalHeader>
                    <ModalBody>
                        <Row style={{paddingTop: 10}}>
                            <Col>
                                <div style={{whiteSpace: "pre-line"}}>
                                    {nota}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" className="btn btn-light me-2" onClick={ () => toggle()}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </>
        }
    </>
}

EmpresaNotificacaoLista.propTypes = {
    listaNotificacao: PropTypes.array,
    loading: PropTypes.bool,
    empresa: PropTypes.any
}

export default EmpresaNotificacaoLista