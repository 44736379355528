export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const DASHBOARD_DATA = "DASHBOARD_DATA"
export const DASHBOARD_FILTERS = "DASHBOARD_FILTERS"
