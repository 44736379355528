import PropTypes from "prop-types"
import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react"
import {Badge, UncontrolledTooltip} from "reactstrap"
import {
    apiBuscarUsuarios,
} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {Link} from "react-router-dom"
import {Box, TextField} from "@mui/material"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {describeDate, describeDateTime, removeHour} from "../../helpers/utils"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {buildParamsExport, buildParamsGrouper} from "../../helpers/data_helper"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const UsuariosTable = forwardRef((props, refFather) => {

    const ref = useRef()
    const {filters, columns, usePermissionEdit, visualizarVinculos, cancelarVinculos, user } = props

    const [data, setData] = useState([])

    useImperativeHandle(refFather, () => ({
        search() {
            ref?.current?.search()
        },
        getParamsExport() {
            return buildParamsExport(ref?.current?.getColumnFilters(), filters, colunas, ref?.current?.getSorting())
        }
    }))

    const getBadgeElement = (cell) => {
        return <Badge
            className={"font-size-12 badge-soft-" + (cell.getValue() == 'S' ? 'primary higher' : 'soft-dark')}
            color={cell.getValue() == 'S' ? 'primary higher' : 'soft-dark'}
            pill
        >
            {cell.getValue() == 'S' ? 'Sim' : 'Não'}
        </Badge>
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "ID Cloud", accessorKey: "id", size: 80, enableHiding: false,
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {header: "Nome", accessorKey: "nome_completo", size: 120, filterFn: 'contains' },
            {header: "E-mail", accessorKey: "email", size: 120, filterFn: 'contains'},
            {header: "Login pelo Google", accessorKey: "google_auth", size: 180,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={ "font-size-12 badge-soft-" + (cell.getValue() ? "success" : "secondary") }
                            color={ cell.getValue() ? "success" : "secondary" }
                            pill>
                            { cell.getValue() ? "Sim" : "Não" }
                        </Badge>
                    </Box>),
                filterSelectOptions: [
                    {text: "Sim", value: 'S'},
                    {text: "Não", value: 'N'}
                ],
                filterVariant: 'select'
            },
            {header: "Data inclusão", accessorKey: "data_inclusao", minSize:130, size: 130,
                Cell: ({cell}) => describeDate(cell?.getValue()),
                accessorFn: (row) => row.data_inclusao ? new Date(removeHour(row.data_inclusao)) : null,
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Data alteração", accessorKey: "data_alteracao", size: 130,
                Cell: ({cell}) => describeDate(cell?.getValue()),
                accessorFn: (row) => row.data_alteracao ? new Date(removeHour(row.data_alteracao)) : null,
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Data último acesso", accessorKey: "data_ultimo_login", size: 180,
                Cell: ({cell}) => describeDate(cell?.getValue()),
                accessorFn: (row) => row.data_ultimo_login ? new Date(removeHour(row.data_ultimo_login)) : null,
                sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "IP do último acesso", accessorKey: "ip", size: 150, filterFn: 'contains'},
            {header: "Telefone", accessorKey: "telefone", size: 120, filterFn: 'contains'},
            {header: "E-mail validado", accessorKey: "email_validado", size: 150,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                        <Badge
                            className={ "font-size-12 badge-soft-" + (cell.getValue() === 1 ? "success" : "danger") }
                            color={ cell.getValue() === 1 ? "success" : "danger" }
                            pill>
                            { cell.getValue() === 1 ? "Sim" : "Não" }
                        </Badge>
                    </Box>),
                filterSelectOptions: [
                    {text: "Sim", value: 'S'},
                    {text: "Não", value: 'N'}
                ],
                filterVariant: 'select'

            },
            {header: "Perfil(is)", accessorKey: "vinculos.perfil.nome", size: 200, enableColumnFilter: false,
                accessorFn: (row) => (row.vinculos || []).map(vinculo => vinculo.perfil?.nome).join(','),
                Cell: ({ renderedCellValue, row }) => (
                    <Box sx={{display: 'flex', alignItems: 'left', gap: '1rem',}}>
                        <span title={ renderedCellValue }>{ renderedCellValue }</span>
                    </Box>)}
        ], [])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarUsuarios(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{
                    data_alteracao: false,
                    ip: false
                }}
                enableRowActions={true}
                defaultSorting={[{
                    id: 'id',
                    desc: true,
                }]}
                renderRowActions={(row, table) =>
                    (<Box sx={{display: 'flex', gap: '1rem'}}>
                        <Link to="#" className="text-primary">
                            <i className="mdi mdi-eye font-size-18 ms-1" id="viewtooltip"
                               onClick={e => visualizarVinculos(row.original)}
                               title="Visualizar vínculos"
                            />
                        </Link>
                        {usePermissionEdit && <Link to="#" className="text-danger">
                            <i className="mdi mdi-delete font-size-18 ms-1" id="edittooltip"
                               onClick={e => cancelarVinculos(row.original)}
                               title="Cancelar vínculos"
                            />
                        </Link>}
                    </Box>)
                }
            />
        </React.Fragment>
    )
})

export default UsuariosTable

UsuariosTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
    usePermissionEdit: PropTypes.bool,
    visualizarVinculos: PropTypes.func,
    cancelarVinculos: PropTypes.func,
}
