import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash"
import { Link, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  buscarNotificacoes,
  doCreateNotification,
  doEditNotification,
  doRemoveNotification,
  doViewNotification
} from "../../store/auth/profile/actions"
import moment from "moment"
import Loader from "react-loader-spinner"
import { AvForm } from "availity-reactstrap-validation"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

const NotificacoesLista = props => {
  const { notificacoes } = props

  const [itemRemove, setItemRemove] = useState()
  const [modal, setModal] = useState(false)

  const usePermissionEdit = usePermission('notificacoes_editar')
  const usePermissionRemove = usePermission('notificacoes_excluir')
  const usePermissionInsert = usePermission('notificacoes_inserir')
  const isInforangra = props.user?.usuario_info

  useEffect(() => {
    props.buscarNotificacoes()
  }, [])

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const goToNotification = item => {
    (usePermissionEdit && props.doEditNotification(item)) ||
    props.doViewNotification(item)
    props.history.push("/notificacao")
  }

  const showConfirmDeleteNotification = item => {
    setItemRemove(item)
    setModal(true)
  }

  const deleteNotification = () => {
    props.doRemoveNotification(itemRemove)
    setModal(false)
    props.buscarNotificacoes()
  }

  if (
    !props.user ||
    !props.user.vinculos.length ||
    !(props.user.vinculos || []).filter(e =>
      ["A", "T"].find(status => status == e.vinculo_status)
    ).length
  ) {
    return (
        <AlertaSemVinculo/>
    )
  }

  return props.notificacoesLoading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Notificações"
            breadcrumbItem="Lista de Notificações"
          />
          {/* Render Breadcrumbs */ }
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Suas Notificações</CardTitle>
                  <div className="button-items my-3">
                    <Link to="/cadastrar-notificacao">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded waves-effect waves-light"
                        onClick={ e => props.doCreateNotification() }
                        disabled={ !usePermissionInsert }>
                        <i className="bx bx-plus" /> Cadastrar
                      </Button>
                    </Link>
                  </div>

                  <div className="table-responsive">
                    <table
                      className="dataTable table table-bordered table-striped table-nowrap table-centered mb-0 table-hover">
                      <thead>
                      <tr>
                        <th>Título</th>
                        <th>Notificação</th>
                        <th>Data para entregar</th>
                        { isInforangra && <th>Empresa</th> }
                        { usePermissionEdit && <th>Ações</th> }
                      </tr>
                      </thead>

                      <tbody>
                      { (isEmpty(notificacoes) && (
                        <tr>
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              Você não há nenhuma notificação até o momento, crie a primeira agora mesmo!
                            </h5>
                          </td>
                        </tr>
                      )) ||
                      map(notificacoes, (item, i) => (
                        <tr key={ i }>
                          <td onClick={ e => goToNotification(item) }>
                            <Link
                              to="#"
                              className="text-truncate text-dark"
                            >
                              { item.titulo }
                            </Link>
                          </td>
                          <td onClick={ e => goToNotification(item) }>
                            <Link
                              to="#"
                              className="text-truncate text-dark"
                            >
                              { item.notificacao }
                            </Link>
                          </td>
                          <td onClick={ e => goToNotification(item) }>
                            <Link
                              to="#"
                              className="text-truncate text-dark"
                            >
                              { describeDateTime(item.data_entrega) }
                            </Link>
                          </td>
                          { isInforangra && (
                            <td onClick={ e => goToNotification(item) }>
                              <Link
                                to="#"
                                className="text-truncate text-dark"
                              >
                                { item.empresa
                                  ? item.empresa.Fantasia
                                  : "Todas" }
                              </Link>
                            </td>
                          ) }
                          { (usePermissionEdit ||
                            usePermissionRemove) && (
                            <td>
                              { usePermissionEdit && <Link to="#" className="me-3 text-primary">
                                <i
                                  className="mdi mdi-pencil font-size-18 me-3"
                                  id="edittooltip"
                                  onClick={ e => goToNotification(item) }
                                  title="Editar"
                                />
                              </Link> }
                              { usePermissionRemove && <Link to="#" className="text-danger">
                                <i
                                  className="mdi mdi-delete font-size-18 me-3"
                                  id="btnDelete"
                                  onClick={ () =>
                                    showConfirmDeleteNotification(item)
                                  }
                                  title="Remover"
                                />
                              </Link> }
                            </td>
                          ) }
                        </tr>
                      )) }
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={ modal }>
        <ModalHeader tag="h4">Confirmação</ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={ (e, v) => {
              deleteNotification()
            } }
          >
            <Row form>
              <Col className="col-12">
                Deseja realmente remover esse registro?
              </Col>
            </Row>
            <Row style={ { paddingTop: 10 } }>
              <Col>
                <div className="text-right">
                  <button type="submit" className="btn btn-success save-event">
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={ e => setModal(false) }
                  >
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

NotificacoesLista.propTypes = {
  notificacoes: PropTypes.array,
  notificacoesLoading: PropTypes.bool,
  buscarNotificacoes: PropTypes.func
}

const mapStatetoProps = state => {
  const { notificacoes, notificacoesLoading } = state.Profile
  const { user } = state.Login
  return {
    notificacoes,
    notificacoesLoading,
    user
  }
}

export default connect(mapStatetoProps, {
  buscarNotificacoes,
  doRemoveNotification,
  doViewNotification,
  doEditNotification,
  doCreateNotification
})(withRouter(NotificacoesLista))
