import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"

// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { connect } from "react-redux"
import { notificacao } from "../../store/auth/profile/actions"
import Loader from "react-loader-spinner"
import DatePicker from "react-datepicker"
import ToggleButton from "react-bootstrap/ToggleButton"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Select from "react-select"
import { buscarUsuariosPorEmpresa } from "store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import StickerToolbarButtons from "../../components/Common/sticker-toolbar-buttons";
import {getCompanies} from "../../helpers/utils"
import {ToggleButtonGroup} from "react-bootstrap";
import FormInputNumber from "../../components/Common/form-input-number";
import FormInputText from "../../components/Common/form-input-text";
import FormSelectCompany from "../../components/Common/form-select-company";

const CadastrarNotificacao = props => {
  const [titulo, setTitulo] = useState()
  const [notificacao, setNotificacao] = useState()
  const [dataEntrega, setDataEntrega] = useState()
  const [empresaId, setEmpresaId] = useState(null)
  const [usuarioId, setUsuarioId] = useState(null)
  const [diasSemAcesso, setDiasSemAcesso] = useState(null)
  const [diasCertificadoVencer, setDiasCertificadoVencer] = useState(null)
  const [usuarioSemVinculo, setUsuarioSemVinculo] = useState(false)
  const [usuarioComVinculo, setUsuarioComVinculo] = useState(false)
  const [usuarioComVinculoPendente, setUsuarioComVinculoPendente] = useState(false)
  const [semCertificado, setSemCertificado] = useState(false)
  const [certificadoDigitalVencido, setCertificadoDigitalVencido] = useState(false)
  const [certificadoDigitalVencimentoProximo, setCertificadoDigitalVencimentoProximo] = useState(false)

  const [notificaEmail, setNotificaEmail] = useState(true)
  const [notificaSistema, setNotificaSistema] = useState()
  const [notificaPush, setNotificaPush] = useState()
  const [loaded, setLoaded] = useState(false)

  const [companies, setCompanies] = useState()
  const [usuariosArr, setUsuariosArr] = useState([])

  const usePermissionEdit = usePermission('notificacoesEdit_editar')
  const usePermissionInsert = usePermission('notificacoesEdit_inserir')

  useEffect(() => {
    let companies = getCompanies(props.user, true);
    setCompanies(companies)

    if (props.activeNotification) {
      setTitulo(props.activeNotification.titulo)
      setNotificacao(props.activeNotification.notificacao)
      setDataEntrega(new Date(props.activeNotification.data_entrega))
      doSetCompany(props.activeNotification.empresa_id)
      setUsuarioId(props.activeNotification.usuario_id)
      setDiasSemAcesso(props.activeNotification.dias_sem_acesso)
      setUsuarioSemVinculo(props.activeNotification.usuario_sem_vinculo)
      setUsuarioComVinculo(props.activeNotification.usuario_com_vinculo)
      setUsuarioComVinculoPendente(props.activeNotification.usuario_com_vinculo_pendente)
      setNotificaEmail(props.activeNotification.notifica_email)
      setNotificaSistema(props.activeNotification.notifica_sistema)
      setNotificaPush(props.activeNotification.notifica_push)
      setDiasCertificadoVencer(props.activeNotification.dias_certificado_vencer)
      setSemCertificado(props.activeNotification.sem_certificado)
      setCertificadoDigitalVencido(props.activeNotification.certificado_digital_vencido)
      setCertificadoDigitalVencimentoProximo(props.activeNotification.certificado_digital_vencimento_proximo)
    } else {
      setDataEntrega(new Date())
      setTitulo(null)
      setNotificacao(null)
      doSetCompany(null)
      setUsuarioId(null)
      setDiasCertificadoVencer(null)
      setUsuarioSemVinculo(false)
      setUsuarioComVinculo(false)
      setUsuarioComVinculoPendente(null)
      setNotificaEmail(false)
      setNotificaSistema(false)
      setNotificaPush(false)
      setDiasCertificadoVencer(null)
      setSemCertificado(false)
      setCertificadoDigitalVencido(false)
      setCertificadoDigitalVencimentoProximo(false)
    }
    setLoaded(true)

    return () => {
      setCompanies([])
      setLoaded(false)
    }
  }, [props.activeNotification])

  useEffect(() => {
    setUsuariosArr(
      Array.isArray(props.usuariosEmpresa)
        ? props.usuariosEmpresa.map(e => ({
          label: e.nome_completo,
          value: e.id
        }))
        : []
    )

    return () => setUsuariosArr([])
  }, [props.usuariosEmpresa])

  const handleValidSubmit = (e, v) => {
    props.notificacao(
      {
        id: props.activeNotification ? props.activeNotification.id : null,
        titulo: titulo,
        notificacao: notificacao,
        data_entrega: dataEntrega,
        empresa_id: empresaId ? empresaId : null,
        usuario_id: usuarioId ? usuarioId : null,
        dias_sem_acesso: diasSemAcesso ? diasSemAcesso : null,
        usuario_sem_vinculo: usuarioSemVinculo,
        usuario_com_vinculo: usuarioComVinculo,
        usuario_com_vinculo_pendente: usuarioComVinculoPendente,
        notifica_email: notificaEmail,
        notifica_sistema: notificaSistema,
        notifica_push: notificaPush,
        dias_certificado_vencer: diasCertificadoVencer ? diasCertificadoVencer : null,
        sem_certificado: semCertificado,
        certificado_digital_vencido: certificadoDigitalVencido,
        certificado_digital_vencimento_proximo: certificadoDigitalVencimentoProximo
      },
      props.history
    )
  }

  const doSetCompany = value => {
    const empresaId = value ? value : null
    setEmpresaId(empresaId)
    doSetUser(null)
    if (value) {
      props.buscarUsuariosPorEmpresa({ empresaId: value })
    }

    onChangeMotivo(null)
  }

  const doSetUser = value => {
    setUsuarioId(value)
    onChangeMotivo(null)
  }

  const onChangeMotivo = value => {
    setUsuarioSemVinculo(false)
    setUsuarioComVinculo(false)
    setUsuarioComVinculoPendente(false)
    setSemCertificado(false)
    setCertificadoDigitalVencido(false)
    setCertificadoDigitalVencimentoProximo(false)
    setDiasSemAcesso(0)
    setDiasCertificadoVencer(0)

    if(!value) return

    switch (value) {
      case 'usuarioSemVinculo':
        setUsuarioSemVinculo(true);
        break;
      case 'usuarioComVinculo':
        setUsuarioComVinculo(true);
        break;
      case 'usuarioComVinculoPendente':
        setUsuarioComVinculoPendente(true);
        break;
      case 'semCertificado':
        setSemCertificado(true);
        break;
      case 'certificadoDigitalVencido':
        setCertificadoDigitalVencido(true);
        break;
      case 'certificadoDigitalVencimentoProximo':
        setCertificadoDigitalVencimentoProximo(true);
        break;
      default:
        break;
    }
  }

  const onNotificacao = values => {
    setNotificaEmail(false)
    setNotificaSistema(false)
    setNotificaPush(false)
    for (const value of values) {
      switch (value) {
        case 'notificaEmail':
          setNotificaEmail(true)
          break
        case 'notificaSistema':
          setNotificaSistema(true)
          break
        case 'notificaPush':
          setNotificaPush(true)
          break
      }
    }
  }

  return (
    <>
      { (loaded && (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */ }
            <Breadcrumbs
              title="Notificações"
              breadcrumbItem={
                (props.notificationMode === "edit" && "Editar Notificação") ||
                (props.notificationMode === "view" &&
                  "Visualizar Notificação") ||
                "Criar Notificação"
              }
            />
            <StickerToolbarButtons>
              <Button
                  type="submit"
                  color="primary"
                  disabled={
                      !titulo ||
                      !notificacao ||
                      !dataEntrega ||
                      props.notificationMode === "view" ||
                      !((props.activeNotification && usePermissionEdit) || (!props.activeNotification && usePermissionInsert))
                  }
                  onClick={ e => handleValidSubmit() }
              >
                { props.activeNotification
                    ? "Salvar Notificação"
                    : "Cadastrar Notificação" }
              </Button>
            </StickerToolbarButtons>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      { (props.notificationMode === "edit" &&
                        "Altere a Notificação") ||
                      (props.notificationMode === "view" &&
                        "Visualize a Notificação") ||
                      "Salvar a Notificação" }
                    </CardTitle>
                    <form>
                      <div className="outer">
                        <div className="outer">
                          <FormInputText
                              attribute="titulo"
                              description="Título"
                              value={titulo}
                              setValue={setTitulo}
                              disabled={props.notificationMode === "view"}
                              maxLength={ 100 }
                          />

                          <FormInputText
                              attribute="notificacao"
                              description="Notificação"
                              value={notificacao}
                              setValue={setNotificacao}
                              disabled={props.notificationMode === "view"}
                              maxLength={ 100 }
                              placeholder="Informe mais detalhes da notificação..."
                          />

                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="dataEntrega"
                              className="col-form-label col-lg-2"
                            >
                              Data para entregar
                            </Label>
                            <Col lg="10">
                              <DatePicker
                                id="dataEntrega"
                                selected={ dataEntrega }
                                onChange={ date => setDataEntrega(date) }
                                className="form-control"
                                placeholderText="Selecione uma data"
                                locale="ptBR"
                                minDate={ new Date() }
                                // maxDate={startDate}
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={ 15 }
                                timeCaption="Tempo"
                              />
                            </Col>
                          </FormGroup>

                          <FormSelectCompany
                              attribute="empresaId"
                              value={empresaId}
                              setValue={ doSetCompany }
                              options={companies}
                              disabled={props.notificationMode === "view"}
                          />

                          { empresaId > 0 && (
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="usuario" className="col-form-label col-lg-2">
                                Usuário
                              </Label>
                              <Col lg="10">
                                <Select
                                  className="basic-single"
                                  value={
                                    usuarioId
                                      ? usuariosArr.find(
                                        e => e.value === usuarioId
                                      )
                                      : undefined
                                  }
                                  options={ usuariosArr }
                                  placeholder="Todos"
                                  isClearable={ true }
                                  isSearchable={ true }
                                  noOptionsMessage={ () =>
                                    "Nenhum usuário encontrado"
                                  }
                                  onChange={ e => doSetUser(e?.value) }
                                  disabled={props.notificationMode === "view"}
                                />
                              </Col>
                            </FormGroup>
                          ) }

                          { (!usuarioId) && (
                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-2">
                                Motivo de envio
                              </Label>
                              <Col lg="10">
                                <ToggleButtonGroup type="radio" className="mb-0" name="motivo" onChange={ e => onChangeMotivo(e) }
                                     value={  usuarioSemVinculo ? 'usuarioSemVinculo' :
                                              usuarioComVinculo ? 'usuarioComVinculo' :
                                              usuarioComVinculoPendente ? 'usuarioComVinculoPendente' :
                                              semCertificado ? 'semCertificado' :
                                              certificadoDigitalVencido ? 'certificadoDigitalVencido' :
                                              certificadoDigitalVencimentoProximo ? 'certificadoDigitalVencimentoProximo' : null}
                                             disabled={props.notificationMode === "view"}>
                                  { !empresaId && <ToggleButton
                                      id="usuarioSemVinculo"
                                      variant="outline-primary"
                                      value="usuarioSemVinculo">
                                    Usuário sem vínculo
                                  </ToggleButton> }

                                  { empresaId && <ToggleButton
                                      id="usuarioComVinculo"
                                      variant="outline-primary"
                                      value="usuarioComVinculo">
                                      Usuário com vínculo
                                  </ToggleButton> }

                                  { empresaId && <ToggleButton
                                      id="usuarioComVinculoPendente"
                                      variant="outline-primary"
                                      value="usuarioComVinculoPendente">
                                    Usuário com vínculo pendente
                                  </ToggleButton> }

                                  <ToggleButton
                                      id="semCertificado"
                                      variant="outline-primary"
                                      value="semCertificado">
                                      Empresa sem certificado digital
                                  </ToggleButton>

                                  <ToggleButton
                                      id="certificadoDigitalVencido"
                                      variant="outline-primary"
                                      value="certificadoDigitalVencido">
                                      Empresa com certificado digital vencido
                                  </ToggleButton>

                                  <ToggleButton
                                      id="certificadoDigitalVencimentoProximo"
                                      variant="outline-primary"
                                      value="certificadoDigitalVencimentoProximo">
                                      Empresa com certificado digital próximo ao vencimento
                                  </ToggleButton>

                                </ToggleButtonGroup>
                              </Col>
                            </FormGroup>
                          ) }

                          { (usuarioId || usuarioSemVinculo || usuarioComVinculo || usuarioComVinculoPendente) &&
                              <FormInputNumber
                                  attribute="diasSemAcesso"
                                  description="Dias sem acesso"
                                  value={diasSemAcesso}
                                  setValue={setDiasSemAcesso}
                                  allowNegative={false}
                                  max={365}
                                  min={0}
                                  disabled={ props.notificationMode === "view" }
                              />
                          }

                          { (certificadoDigitalVencimentoProximo) &&
                              <FormInputNumber
                                  attribute="diasCertificadoVencer"
                                  description="Dias para vencer o certificado digital"
                                  value={diasCertificadoVencer}
                                  setValue={setDiasCertificadoVencer}
                                  allowNegative={false}
                                  max={365}
                                  min={0}
                                  disabled={ props.notificationMode === "view" }
                              />
                          }

                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Tipo de envio
                            </Label>
                            <Col lg="10">
                              <ToggleButtonGroup type="checkbox" className="mb-2" name="notificacao" onChange={ e => onNotificacao(e) }
                                                 value={ notificaEmail ? 'notificaEmail' :
                                                         notificaSistema ? 'notificaSistema' :
                                                         notificaPush ? 'notificaPush' : null }
                                                 disabled={props.notificationMode === "view"} >
                                <ToggleButton
                                    id="notificaEmail"
                                    variant="outline-primary"
                                    value="notificaEmail">
                                  E-mail
                                </ToggleButton>

                                <ToggleButton
                                    id="notificaSistema"
                                    variant="outline-primary"
                                    value="notificaSistema">
                                  Sistema
                                </ToggleButton>

                                <ToggleButton
                                    id="notificaPush"
                                    variant="outline-primary"
                                    value="notificaPush">
                                  Push Web
                                </ToggleButton>

                              </ToggleButtonGroup>
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )) || (
        <div className="loader-container">
          <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
        </div>
      ) }
    </>
  )
}

const mapStatetoProps = state => {
  const {
    notificationMode,
    activeNotification,
    notificacaoLoading,
    usuariosEmpresa,
    usuariosEmpresaLoading
  } = state.Profile
  const { user } = state.Login
  return {
    notificationMode,
    activeNotification,
    notificacaoLoading,
    usuariosEmpresa,
    usuariosEmpresaLoading,
    user
  }
}

export default connect(mapStatetoProps, {
  notificacao,
  buscarUsuariosPorEmpresa
})(CadastrarNotificacao)
