import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// users
import avatar from "../../../assets/images/users/default-avatar.png"
import { GoogleLogout } from "react-google-login"
import { google } from "config"
import usePermission from "helpers/permission/usePermission"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { user } = props

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    setusername(props.user ? props.user.nome_completo : "N/D")
  }, [props.user])

  const isLoginByGoogle = () => {
    const userStr = localStorage.getItem("authUser")
    if (userStr) {
      const user = JSON.parse(userStr)
      return user &&
      user.object &&
      user.object.google_auth !== undefined &&
      user.object.google_auth !== ""
        ? true
        : false
    }
    return false
  }

  const googleLogout = response => {
    props.history.push("/logout")
  }

  const googleErrorResponse = response => {
    console.log("[Logout][googleErrorResponse] Exception", response)
    props.history.push("/logout")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={ menu }
        toggle={ () => setMenu(!menu) }
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect pt-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={ (user && user.avatar) || avatar }
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{ username }</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="/meu-perfil">
            { " " }
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Meu Perfil{ " " }
          </DropdownItem>

          <div className="dropdown-divider" />
          { isLoginByGoogle() && (
            <GoogleLogout
              clientId={ google.CLIENT_ID }
              render={ renderProps => (
                <Link onClick={ renderProps.onClick } className="dropdown-item" to="#">
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>Sair</span>
                </Link>
              ) }
              onLogoutSuccess={ googleLogout }
              onFailure={ googleErrorResponse }
            ></GoogleLogout>
          ) }
          { !isLoginByGoogle() && (
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Sair</span>
            </Link>
          ) }
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  const { user } = state.Login
  return { error, success, user }
}

export default withRouter(
  connect(mapStatetoProps, {})((ProfileMenu))
)
