import PropTypes from "prop-types"
import React from "react"
import {Col, FormGroup, Input, Label} from "reactstrap"

const FormInputColor = props => {
  const { attribute, description, value, setValue, disabled, changeHandeler, required } = props

  const changeHandelerInput = (valor, elementId, validacao) => {
    if(validacao !== null && validacao !== undefined) {
      if(validacao === false) {
        document.getElementById(elementId).style.display = 'block'
      } else {
        document.getElementById(elementId).style.display = 'none'
      }
    } else {
      if (valor !== "" && valor !== undefined && valor !== null) {
        document.getElementById(elementId).style.display = 'none'
      } else {
        document.getElementById(elementId).style.display = 'block'
      }
    }
  }

  return (
      <React.Fragment>
        <FormGroup className="mb-4" row>
          <Label htmlFor={ attribute + "-color" } className="col-form-label col-lg-2">
            { description }
          </Label>
          <Col className="col-1 col-md-1 col-sm-2">
            <Input
                id={ attribute + "-color"}
                name={ attribute + "-color"}
                type="color"
                className={ "form-control " + (required && !value? "is-invalid" : "") }
                value={ value }
                onChange={ e => {
                  setValue(e.target.value)

                  if(required) {
                    if(changeHandeler !== undefined) {
                      changeHandeler(e.target.value, `validate-required-${ attribute }-color`)
                    } else {
                      changeHandelerInput(e.target.value, `validate-required-${ attribute }-color`)
                    }
                  }
                } }
                required={ required }
                disabled={ disabled }
            />
            { required &&
                <div className={ value ? "" : "invalid-tooltip" }
                     name="validate-required" id={ `validate-required-${ attribute }-color` }>
                  { value ? "" : `${ description } é obrigatório(a)`}
                </div>
            }
          </Col>
        </FormGroup>
      </React.Fragment>
  )
}

FormInputColor.propTypes = {
  attribute: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  changeHandeler: PropTypes.func,
}

export default FormInputColor
