import PropTypes from "prop-types"
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {
    apiBuscarMovimentacaoCaixas, apiTotalizadorMovimentacaoCaixas,
    apiTotalizadorVendas
} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import {describeDateTime, describeMoney, removeHour} from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import {ptBR as ptBRDatePickers} from '@mui/x-date-pickers'
import {registerLocale, setDefaultLocale} from "react-datepicker"
import {Box, Stack, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {buildParamsGrouper} from "../../helpers/data_helper"
import {Badge} from "reactstrap";

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const MovimentoCaixaTable = forwardRef((props, refFather) => {

    const ref = useRef()
    const {filters, columns} = props
    const [data, setData] = useState([])

    const [valorVenda, setValorVenda] = useState(0)
    const [entradaDinheiro, setEntradaDinheiro] = useState(0)
    const [diferencaDinheiro, setDiferencaDinheiro] = useState(0)
    const [entradaCartao, setEntradaCartao] = useState(0)
    const [diferencaCartao, setDiferencaCartao] = useState(0)
    const [totalCartao, setTotalCartao] = useState(0)
    const [taxaEntrega, setTaxaEntrega] = useState(0)
    const [taxaServico, setTaxaServico] = useState(0)
    const [valorCortesia, setValorCortesia] = useState(0)
    const [suprimento, setSuprimento] = useState(0)
    const [sangria, setSangria] = useState(0)
    const [trocoAbertura, setTrocoAbertura] = useState(0)
    const [trocoFechamento, setTrocoFechamento] = useState(0)
    const [fechamentoDinheiro, setFechamentoDinheiro] = useState(0)
    const [fechamentoCartao, setFechamentoCartao] = useState(0)
    const [fechamentoCheque, setFechamentoCheque] = useState(0)
    const [fechamentoTicket, setFechamentoTicket] = useState(0)
    const [loading, setLoading] = useState(false)

    useImperativeHandle(refFather, () => ({
        search() {
            setLoading(true)
            buscaTotalizadorMovimentoCaixa()

            ref?.current?.search()
        }
    }));

    const buscaTotalizadorMovimentoCaixa = () => {
        let paramsGrouper = buildParamsGrouper(ref?.current?.getColumnFilters(), filters, colunas,
            ["Total_Venda", "Total_Entrada_Dinheiro", "Diferenca_Dinheiro", "Total_Entrada_Cartao", "Diferenca_Cartao",
                "Total_Cartao", "Taxa_Entrega", "Taxa_Servico", "Venda_Cortesia", "Suprimento", "Sangria",
                "Troco_Abertura", "Troco_Fechamento", "Dinheiro", "Cartao", "Cheque", "Ticket",])

        apiTotalizadorMovimentacaoCaixas(paramsGrouper).then(result => {
            setValorVenda(result.data[0].Total_Venda || 0)
            setEntradaDinheiro(result.data[0].Total_Entrada_Dinheiro || 0)
            setDiferencaDinheiro((result.data[0].Diferenca_Dinheiro) || 0)
            setEntradaCartao((result.data[0].Total_Entrada_Cartao) || 0)
            setDiferencaCartao(result.data[0].Diferenca_Cartao || 0)
            setTotalCartao(result.data[0].Total_Cartao || 0)
            setTaxaEntrega(result.data[0].Taxa_Entrega || 0)
            setTaxaServico(result.data[0].Taxa_Servico || 0)
            setValorCortesia(result.data[0].Venda_Cortesia || 0)
            setSuprimento(result.data[0].Suprimento || 0)
            setSangria(result.data[0].Sangria || 0)
            setTrocoAbertura(result.data[0].Troco_Abertura || 0)
            setTrocoFechamento(result.data[0].Troco_Fechamento || 0)
            setFechamentoDinheiro(result.data[0].Dinheiro || 0)
            setFechamentoCartao(result.data[0].Cartao || 0)
            setFechamentoCheque(result.data[0].Cheque || 0)
            setFechamentoTicket(result.data[0].Ticket || 0)

            setLoading(false)
        }).catch(err => setLoading(false))
    }

    useEffect(() => {
        setLoading(true)
        buscaTotalizadorMovimentoCaixa()

        return () => {
            setValorVenda(0)
            setEntradaDinheiro(0)
            setDiferencaDinheiro(0)
            setEntradaCartao(0)
            setDiferencaCartao(0)
            setTotalCartao(0)
            setTaxaEntrega(0)
            setTaxaServico(0)
            setValorCortesia(0)
            setSuprimento(0)
            setSangria(0)
            setTrocoAbertura(0)
            setTrocoFechamento(0)
            setFechamentoDinheiro(0)
            setFechamentoCartao(0)
            setFechamentoCheque(0)
            setFechamentoTicket(0)
        }
    }, [])

    const statusBadgeClass = (status, row) => {
        switch (status) {
            case "A": {
                return "warning"
            }
            case "F": {
                return row.Diferenca_Cartao < 0 || row.Diferenca_Dinheiro < 0
                    ? "danger"
                    : row.Diferenca_Cartao > 0 || row.Diferenca_Dinheiro > 0
                        ? "primary"
                        : "success"
            }
            default: {
                return "warning"
            }
        }
    }

    const statusBadge = (cell) => {
        return <Badge
            className={ "font-size-12 badge-soft-" + statusBadgeClass(cell?.getValue(), cell?.row) }
            color={ statusBadgeClass(cell?.getValue(), cell?.row) }
            pill>
            { cell?.getValue() === "A" ? "Aberto" : "Fechado" }
        </Badge>
    }


    const diferencaBadge = (value) => {
        return <Badge className={ "font-size-12 badge-soft-" + (value > 0 ? "primary" : value === 0 ? "success" : "danger") }
                      color={ value > 0 ? "primary" : value === 0 ? "success" : "danger" } pill>
            { describeMoney(value) }
        </Badge>
    }

    const colunas = useMemo(() => columns ||
        [
            {header: "Registro", accessorKey: "Seq", size: 120, enableHiding: false },
            {header: "Loja", accessorKey: "Loja", size: 120, filterFn: 'contains'},
            {header: "Caixa", accessorKey: "Caixa", size: 120, filterFn: 'contains'},
            {header: "Status", accessorKey: "Status", size: 150,
                Cell: ({cell}) => statusBadge(cell),
                filterSelectOptions: [
                    {text: "Aberto", value: "A"},
                    {text: "Fechado", value: "F"},
                ],
                filterVariant: 'select'
            },
            {header: "Nº Func", accessorKey: "Func_Caixa", size: 120},
            {header: "Aberto em", accessorKey: "Data_Abertura", size: 150, Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Fechado em", accessorKey: "Data_Fechamento", size: 150, Cell: ({cell}) => describeDateTime(cell?.getValue()), sortingFn: 'datetime',
                Filter: ({column}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
                        <DatePicker
                            onChange={(newValue) => {
                                if (newValue) {
                                    newValue = removeHour(newValue, false)
                                }
                                column.setFilterValue(newValue)
                            }}
                            inputFormat="dd/MM/yyyy"
                            componentsProps={{
                                actionBar: {
                                    actions: ['accept', 'clear'],
                                },
                            }}
                            value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
                            views={['year', 'month', 'day']}
                            renderInput={(props) => <TextField {...props} variant="standard"/>}
                        />
                    </LocalizationProvider>
                ),
            },
            {header: "Vlr. Total Venda", accessorKey: "Total_Venda", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> <span>{describeMoney(cell?.getValue())}</span></Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(valorVenda) }</Box></Stack>) },
            {header: "Total Entrada Dinheiro", accessorKey: "Total_Entrada_Dinheiro", size: 200, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(entradaDinheiro) }</Box></Stack>) },
            {header: "Diferença Dinheiro", accessorKey: "Diferenca_Dinheiro", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}}> {diferencaBadge(cell?.getValue())} </Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(diferencaDinheiro) }</Box></Stack>), enableHiding: false},
            {header: "Total Entrada Cartão", accessorKey: "Total_Entrada_Cartao", size: 200, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(entradaCartao) }</Box></Stack>) },
            {header: "Diferença Cartão", accessorKey: "Diferenca_Cartao", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}}> {diferencaBadge(cell?.getValue())} </Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(diferencaCartao) }</Box></Stack>), enableHiding: false },
            {header: "Qtd. CVs Cartão", accessorKey: "Total_Cartao", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(totalCartao) }</Box></Stack>) },
            {header: "Vlr. Taxa Entrega", accessorKey: "Taxa_Entrega", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(taxaEntrega) }</Box></Stack>) },
            {header: "Vlr. Taxa Serviço", accessorKey: "Taxa_Servico", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(taxaServico) }</Box></Stack>) },
            {header: "Vlr. Cortesia", accessorKey: "Venda_Cortesia", size: 150,
                Cell: ({cell}) => (
                    <Box sx={{display: 'flex', alignItems: 'right', gap: '1rem',}}>
                        { cell?.getValue() > 0 ? (
                            <Badge
                                className={ "font-size-12 badge-soft-warning" }
                                color={ "warning" }
                                pill>
                            { describeMoney(cell?.getValue())}
                            </Badge>) :
                            (describeMoney(cell?.getValue()))
                        }
                    </Box>),
                enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(valorCortesia) }</Box></Stack>) },
            {header: "Suprimento", accessorKey: "Suprimento", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(suprimento) }</Box></Stack>) },
            {header: "Sangria", accessorKey: "Sangria", size: 100, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(sangria) }</Box></Stack>) },
            {header: "Troco Abertura", accessorKey: "Troco_Abertura", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(trocoAbertura) }</Box></Stack>) },
            {header: "Troco Fechamento", accessorKey: "Troco_Fechamento", size: 180, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(trocoFechamento) }</Box></Stack>) },
            {header: "Fech. Dinheiro", accessorKey: "Dinheiro", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(fechamentoDinheiro) }</Box></Stack>) },
            {header: "Fech. Cartão", accessorKey: "Cartao", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(fechamentoCartao) }</Box></Stack>) },
            {header: "Fech. Cheque", accessorKey: "Cheque", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(fechamentoCheque) }</Box></Stack>) },
            {header: "Fech. Ticket", accessorKey: "Ticket", size: 150, Cell: ({cell}) => (<Box sx={{display: 'block', gap: '1rem', textAlign: 'right'}} className={ (cell?.getValue() || 0) === 0 ? "text-light" : ""}> { describeMoney(cell?.getValue()) }</Box>), enableColumnFilter: false, Footer: () => (<Stack>Total<Box>{ describeMoney(fechamentoTicket) }</Box></Stack>) },
        ], [loading])

    return (
        <React.Fragment>
            <ListDataTable
                ref={ref}
                searchFn={params => apiBuscarMovimentacaoCaixas(params)}
                advancedFilters={filters}
                columns={colunas}
                data={data}
                setData={setData}
                columnVisibility={{
                }}
                defaultSorting={[{
                    id: 'Data_Abertura',
                    desc: true,
                }]}
            />
        </React.Fragment>
    )
})

export default MovimentoCaixaTable

MovimentoCaixaTable.propTypes = {
    filters: PropTypes.array,
    columns: PropTypes.array,
}
