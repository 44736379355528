import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash"
import { buscarDispositivoLogs } from "store/auth/profile/actions"
import moment from "moment"
import Skeleton from "react-loading-skeleton"

const DispositivoLogsModal = props => {
  const [modal, setModal] = useState(false)
  const { dispositivoId, setDispositivoId } = props
  const [refreshData, setRefreshData] = useState()

  const toggleModal = () => {
    setModal(!modal)

    if (modal) {
      if (refreshData) {
        clearInterval(refreshData)
      }
    }
  }

  const buscarLogs = () => {
    const interval = setInterval(() => {
      props.buscarDispositivoLogs({ dispositivoId: dispositivoId })
    }, 10000)
    setRefreshData(interval)
    toggleModal()
  }

  const describeType = tipo => {
    if (tipo) {
      switch (tipo) {
        case "featured":
          return "Funcionalidade"
        case "products":
          return "Produto"
        case "app_version":
          return "Versão aplicativo"
        default:
          return "Outro"
      }
    } else {
      return "N/D"
    }
  }

  const describeDateTime = dateStr => {
    if (!dateStr) {
      return "N/D"
    }
    return moment(dateStr).format("DD/MM/YY HH:mm")
  }

  const renderSkeleton = () => {
    return (
      <tr>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>
    )
  }
  let rows = []
  for (let i = 0; i < 10; i++) {
    rows.push(i)
  }

  return (
    <React.Fragment>
      <div>
        <a className="me-3 text-primary" onClick={ () => buscarLogs() }>
          <i className="mdi mdi-arrange-bring-to-front font-size-18 me-3"></i>
        </a>
      </div>
      <Modal
        isOpen={ modal }
        role="dialog"
        autoFocus={ true }
        centered={ true }
        tabIndex="-1"
        scrollable={ true }
        size="xl"
        onExit={ () => toggleModal() }
      >
        <div className="modal-content">
          <ModalHeader toggle={ () => toggleModal() }>
            Logs do dispositivos (últimos 500 registros){ " " }
          </ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                <tr>
                  <th scope="col">Código</th>
                  <th scope="col">Data</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Detalhes</th>
                </tr>
                </thead>
                <tbody>
                { (props.dispositivoLogsLoading && rows.map(renderSkeleton)) ||
                (isEmpty(props.dispositivoLogs) && (
                  <tr>
                    <td>
                      <h5 className="text-truncate font-size-14 m-0">
                        Não há nenhum log para este dispositivo
                      </h5>
                    </td>
                  </tr>
                )) ||
                map(props.dispositivoLogs, (item, i) => (
                  <tr key={ i }>
                    <td>
                      <div>
                        <p className="text-muted mb-0">{ item.id }</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p className="text-muted mb-0">
                          { describeDateTime(item.ultimaAtualizacao) }
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p className="text-muted mb-0">
                          { describeType(item.tipo) }
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p className="text-muted mb-0">
                          { item.erro ? "Erro: " : "" }
                          { item.erroMessage }
                        </p>
                      </div>
                    </td>
                  </tr>
                )) }
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={ () => toggleModal() }>
              Fechar
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

DispositivoLogsModal.propTypes = {
  dispositivoLogs: PropTypes.array,
  dispositivoLogsLoading: PropTypes.bool,
  buscarDispositivoLogs: PropTypes.func
}

const mapStatetoProps = state => {
  const { dispositivoLogs, dispositivoLogsLoading } = state.Profile
  const { user } = state.Login
  return {
    dispositivoLogs,
    dispositivoLogsLoading,
    user
  }
}

export default connect(mapStatetoProps, { buscarDispositivoLogs })(
  DispositivoLogsModal
)
